import Link from 'next/link'
import { twMerge } from 'tailwind-merge'
import { m, useMotionValue, useTransform } from 'framer-motion'
import { useSwipeable } from 'react-swipeable'
import { UI_ButtonGradient, UI_IconSvg, UI_Image, UI_Typography } from '@/ui'
import { useLocale, useMobile, usePlayerActions, useTranslate } from '@/hooks'
import { categoryToIcon, imageUrl } from '@/tools'
import { Element, TCategoryType, TMusic } from '@/types'
import { Actions } from '@/pages/global'

export type THorizontalCardItemProps = TMusic & {
  type?: TCategoryType
}

export const HorizontalCardItem = (
  props: THorizontalCardItemProps,
): Element => {
  const { type, ...item } = props
  const isAlbum = type === 'album'
  const { artists, name: fa_name, name_en: en_name, id, album, image } = item
  const isMobile = useMobile()
  const { isPersian } = useLocale()
  const { translate } = useTranslate()
  const { isSelected, handleClickPlay, isPlaying, handleListenNext } =
    usePlayerActions({
      item,
    })

  let icon: TCategoryType = 'song'
  if (isAlbum || item.album?.[isPersian ? 'persian_name' : 'english_name'])
    icon = 'album'

  const x = useMotionValue(0)

  const background = useTransform(
    x,
    [-1, 0, 1],
    [
      'linear-gradient(90deg, #3369FF 30%, #C600F9, #FF334B)',
      'rgba(0, 0, 0, 0)',
      'linear-gradient(90deg, #3369FF 30%, #C600F9, #FF334B)',
    ],
  )

  const opacityLeft = useTransform(x, [0, 30, 60], [0, 0, 1])
  const opacityRight = useTransform(x, [-60, -30, 0], [1, 0, 0])

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () =>
      isPlaying || isSelected ? handleClickPlay(item) : handleListenNext(item),
    onSwipedRight: () => handleClickPlay(item),
  })

  return (
    <m.div
      style={isMobile ? { background } : {}}
      className='relative rounded-space-100 lg:overflow-visible overflow-hidden'
    >
      {isMobile && (
        <>
          <m.div
            className='absolute right-4 top-7'
            style={isMobile ? { opacity: opacityRight } : {}}
          >
            <UI_IconSvg
              component={isPlaying || isSelected ? 'Pause' : 'AddSong'}
            />
          </m.div>
          <m.div
            className='absolute left-4 top-7'
            style={isMobile ? { opacity: opacityLeft } : {}}
          >
            <UI_IconSvg component={isPlaying ? 'Pause' : 'Play'} />
          </m.div>
        </>
      )}
      <UI_ButtonGradient
        rounded='rounded-space-100'
        className='w-full h-fit transition-all delay-100 ease-in-out'
        border='p-0.5'
        gradient={'background-conic-gradient2'}
        isGradient={Boolean(isSelected)}
      >
        <m.div
          {...(isMobile && swipeHandlers)}
          drag={isMobile && 'x'}
          dragConstraints={isMobile && { left: 0, right: 0 }}
          style={isMobile ? { x } : {}}
          className={twMerge('text-lg card-item-2 !rounded-space-100')}
        >
          <div className='flex-1 w-fit flex flex-row items-center justify-start gap-8'>
            <div
              className={twMerge(
                'flex flex-row items-center justify-start lg:gap-6 gap-3',
                !isMobile && 'flex-1',
              )}
            >
              <Link
                href={`/track/${id}`}
                className='flex'
                onClick={(e) => e.stopPropagation()}
              >
                <UI_Image
                  className='rounded-lg object-cover'
                  alt=''
                  src={image}
                  width={isMobile ? 60 : 96}
                  height={isMobile ? 60 : 96}
                />
              </Link>
              <div className='flex-1 flex flex-col lg:justify-center justify-around lg:gap-6'>
                <Link
                  href={`/track/${id}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <UI_Typography
                    variant='enLabelBoldLabel3'
                    component='h4'
                    className='ellipsis max-w-[200px]'
                  >
                    {isPersian && fa_name !== '-' ? fa_name : en_name}
                  </UI_Typography>
                </Link>
                <Link
                  href={`/artist/${artists?.[0]?.id}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <UI_Typography
                    variant='enLabelRegularLabel2'
                    component='div'
                    className='ellipsis max-w-[200px]'
                  >
                    {artists?.[0]?.name}
                  </UI_Typography>
                </Link>
              </div>
            </div>
            {!isMobile && (
              <div className='flex-1 flex items-center gap-3'>
                <UI_IconSvg component={categoryToIcon(icon)} />
                {album?.[isPersian ? 'persian_name' : 'english_name'] ? (
                  <Link
                    href={`/album/${album?.id}`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <UI_Typography
                      variant='enLabelRegularLabel3'
                      className='capitalize'
                    >
                      {album?.[isPersian ? 'persian_name' : 'english_name']}
                    </UI_Typography>
                  </Link>
                ) : (
                  <Link href={`/track/${id}`}>
                    <UI_Typography
                      variant='enLabelRegularLabel3'
                      className='capitalize'
                    >
                      {translate('detail.single-track')}
                    </UI_Typography>
                  </Link>
                )}
              </div>
            )}
          </div>
          <Actions type={type} data={item} />
        </m.div>
      </UI_ButtonGradient>
    </m.div>
  )
}
