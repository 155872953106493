import * as React from 'react'
import type { SVGProps } from 'react'
const SvgExternalLinkFlipped = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='External_link_Flipped_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#External_link_Flipped_svg__a)'>
      <path
        fill={props.color}
        d='M19 21c.55 0 1.02-.196 1.413-.587.391-.392.587-.863.587-1.413V5c0-.55-.196-1.02-.587-1.413A1.926 1.926 0 0 0 19 3h-6a.968.968 0 0 0-.713.288A.967.967 0 0 0 12 4c0 .283.096.52.287.713.192.191.43.287.713.287h6v14H5v-6a.968.968 0 0 0-.287-.713A.967.967 0 0 0 4 12a.967.967 0 0 0-.712.287A.968.968 0 0 0 3 13v6c0 .55.196 1.02.587 1.413C3.98 20.803 4.45 21 5 21h14ZM5 6.4l8.6 8.6a.948.948 0 0 0 .7.275A.948.948 0 0 0 15 15a.948.948 0 0 0 .275-.7.948.948 0 0 0-.275-.7L6.4 5H9c.283 0 .52-.096.713-.287A.968.968 0 0 0 10 4a.968.968 0 0 0-.287-.712A.968.968 0 0 0 9 3H3v6c0 .283.096.52.288.713.191.191.429.287.712.287s.52-.096.713-.287A.968.968 0 0 0 5 9V6.4Z'
      />
    </g>
  </svg>
)
export default SvgExternalLinkFlipped
