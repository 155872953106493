import { useEffect, useState } from 'react'
import { Inter } from 'next/font/google'
import { twMerge } from 'tailwind-merge'
import { useRouter } from 'next/router'
import { useUserAgent } from 'next-useragent'
import { Body } from './body/Body'
import { Footer } from './footer/Footer'
import style from './layout.module.css'
import { Children, Element } from '@/types'
import { useMobile, useUser } from '@/hooks'
import {
  useAppDispatch,
  setIsMobile,
  useAppSelector,
  selectAllSongs,
  selectedSong,
} from '@/store'
import { downloadImage, musicImage } from '@/tools'
import { PlayerMobile, Sidebar } from '@/pages/global'
import { Navigation, Player, ScrollToTop } from '@/components'

const inter = Inter({ subsets: ['latin'] })

type TLayoutProps = Children & {
  header?: Element | null
  mobileHeader?: Element | null
  sidebar?: boolean
  displayPlayer?: boolean
}

export const Layout = (props: TLayoutProps): Element => {
  const {
    children,
    header,
    mobileHeader,
    sidebar = true,
    displayPlayer = true,
  } = props
  const isMobile = useMobile()
  const { isLogin } = useUser()
  const dispatch = useAppDispatch()
  const allSongs = useAppSelector(selectAllSongs)
  const selectedTrack = useAppSelector(selectedSong)
  const { uuid, id, image } = selectedTrack ?? {}
  const [src, setSrc] = useState('')
  const { push, pathname } = useRouter()

  useEffect(() => {
    if (id && image) {
      setSrc(image)
    } else if (allSongs?.[0]?.uuid) {
      const src = musicImage({
        id: allSongs?.[0].id,
        uuid: allSongs?.[0].uuid,
        type: 'track',
      })
      downloadImage(src).then((res) => {
        setSrc(String(res))
      })
    }
  }, [id, allSongs?.[0]?.uuid])

  useEffect(() => {
    document.getElementById('page-body')?.scrollTo({
      top: 0,
    })

    if (pathname?.toLowerCase() === '/login' && isLogin) {
      push('/')
    }
  }, [pathname])

  useEffect(() => {
    window.addEventListener('resize', () => {
      setTimeout(() => {
        const ua = useUserAgent(window.navigator.userAgent)
        dispatch(setIsMobile(ua?.isMobile))
      }, 10)
    })

    return () => {
      window.removeEventListener('resize', () => {
        //
      })
    }
  }, [])

  return (
    <div
      style={{ backgroundImage: `url(${src})` }}
      className={twMerge(
        'font-peyda bg-cover relative w-full h-[100dvh] overflow-hidden text-left text-xl text-gray-50 font-leading-none-text-sm-font-medium lg:p-4',
        inter.className,
        src && 'transition-all duration-1000',
      )}
    >
      <div
        className={twMerge(
          'lg:rounded-t-21xl lg:rounded-b-13xl bg-cards-glass-secondary w-full lg:overflow-hidden flex flex-col items-start justify-start lg:p-4 box-border lg:gap-4',
          'lg:h-[calc(100dvh_-_32px)] h-[100dvh]',
          !isMobile && 'backdrop-blur-3xl',
        )}
      >
        <div className='lg:self-stretch flex-1 flex flex-row items-start justify-start gap-2 w-full'>
          {!isMobile && sidebar ? <Sidebar /> : null}
          <div
            id='page-body'
            className={twMerge(
              style.main,
              !isMobile
                ? 'scroll lg:h-[calc(100dvh_-_160px)] h-[calc(100dvh_-_130px)]'
                : 'lg:pb-[160px] pb-[130px]',
              isMobile ? 'h-[100dvh] pb-[130px]' : 'h-[calc(100vh-120px)]',
              !displayPlayer
                ? 'h-screen'
                : 'lg:h-[calc(100dvh_-_160px)] pb-140px',
              isMobile ? style.mainMobileScroll : 'scroll-smooth',
            )}
          >
            <div className='w-full'>
              {header && (
                <div className='w-full lg:px-6 px-3 lg:pt-6 pt-3'>
                  {isMobile ? mobileHeader : header}
                </div>
              )}

              <Body className='lg:px-6 px-3'>{children}</Body>
            </div>
            {!isMobile && <Footer className='p-4' />}
            <ScrollToTop />
          </div>
        </div>

        {displayPlayer && (
          <div
            className={twMerge(
              isMobile
                ? 'fixed bottom-[64px] left-0 right-0 z-10'
                : 'fixed bottom-4 left-4 right-4 z-10',
            )}
          >
            {isMobile ? selectedTrack ? <PlayerMobile /> : null : <Player />}
          </div>
        )}

        {isMobile && (
          <div
            className={twMerge(
              isMobile && 'w-full fixed bottom-0 left-0 right-0',
            )}
          >
            <Navigation />
          </div>
        )}
      </div>
      <div id='react-portal' />
    </div>
  )
}

export default Layout
