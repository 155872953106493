import { forwardRef, useRef, useState } from 'react'
import { TUI_InputProps } from '../ui_input/UI_Input'
import { UI_Input } from '@/ui'
import { Element } from '@/types'

type TUI_Input_PasswordProps = TUI_InputProps

export const UI_Input_Password = forwardRef<
  HTMLInputElement,
  TUI_Input_PasswordProps
>((props, ref): Element => {
  const passwordRef = useRef<HTMLInputElement>(null)
  const [displayPassword, setDisplayPassword] = useState(false)
  const handleToggleDisplayPassword = () => {
    const passwordInput = passwordRef.current
    if (passwordInput) {
      passwordInput.type = displayPassword ? 'password' : 'text'
    }
    setDisplayPassword((prevDisplayPassword) => !prevDisplayPassword)
  }

  return (
    <UI_Input
      {...props}
      ref={ref}
      type={displayPassword ? 'text' : 'password'}
      icon={displayPassword ? 'EyeHide' : 'EyeView'}
      onClickIcon={handleToggleDisplayPassword}
    />
  )
})

UI_Input_Password.displayName = 'UI_Input_Password'
