import Link from 'next/link'
import { UI_IconSvg, UI_Typography } from '@/ui'
import { Element } from '@/types'
import { useTranslate } from '@/hooks'

type TExtraLinksProps = {
  isOtpCode: boolean
  onChangePhoneNumber: () => void
}

export const ExtraLinks = (props: TExtraLinksProps): Element => {
  const { isOtpCode, onChangePhoneNumber } = props
  const { translate } = useTranslate()

  return (
    <UI_Typography variant='enBodyBoldText2' align='center'>
      {isOtpCode ? (
        <div
          className='flex items-center cursor-pointer justify-center gap-2'
          onClick={onChangePhoneNumber}
        >
          <UI_IconSvg component='ArrowLeftBig' className='rtl:rotate-180' />
          <span>{translate('login.change-phone-number')}</span>
        </div>
      ) : (
        <div className='flex items-center gap-1 justify-center -mt-7 md:mt-0'>
          <span>{translate('login.haven-not-account')}</span>
          <Link
            href='/register'
            className='[text-decoration:underline] cursor-pointer'
          >
            {translate('login.sign-up')}
          </Link>
        </div>
      )}
    </UI_Typography>
  )
}
