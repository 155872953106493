import * as React from 'react'
import type { SVGProps } from 'react'
const SvgPrivateFilled16Px = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 16 16'
    {...props}
  >
    <path
      fill={props.color}
      d='M4 14.667c-.367 0-.68-.13-.942-.392a1.284 1.284 0 0 1-.391-.942V6.667c0-.367.13-.68.391-.942.261-.261.575-.392.942-.392h.667V4c0-.922.325-1.708.974-2.358A3.213 3.213 0 0 1 8 .667c.922 0 1.708.325 2.358.975.65.65.975 1.436.975 2.358v1.333H12c.367 0 .68.131.941.392.262.261.392.575.392.942v6.666c0 .367-.13.681-.392.942a1.284 1.284 0 0 1-.941.392H4Zm4-3.334c.367 0 .68-.13.941-.391.262-.261.392-.575.392-.942s-.13-.68-.392-.942A1.284 1.284 0 0 0 8 8.667c-.367 0-.68.13-.942.391a1.284 1.284 0 0 0-.391.942c0 .367.13.68.391.942.261.26.575.391.942.391Zm-2-6h4V4c0-.555-.195-1.028-.584-1.417A1.929 1.929 0 0 0 8 2c-.556 0-1.028.195-1.417.583A1.929 1.929 0 0 0 6 4v1.333Z'
    />
  </svg>
)
export default SvgPrivateFilled16Px
