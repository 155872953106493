import * as React from 'react'
import type { SVGProps } from 'react'
const SvgMusicCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Music_circle_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Music_circle_svg__a)'>
      <path
        fill={props.color}
        d='M10 18c.833 0 1.542-.292 2.125-.875A2.893 2.893 0 0 0 13 15V8h2c.283 0 .52-.096.713-.287A.967.967 0 0 0 16 7a.967.967 0 0 0-.287-.713A.968.968 0 0 0 15 6h-2.5a.968.968 0 0 0-.713.287A.967.967 0 0 0 11.5 7v5.4A2.993 2.993 0 0 0 10 12c-.833 0-1.542.292-2.125.875A2.893 2.893 0 0 0 7 15c0 .833.292 1.542.875 2.125A2.893 2.893 0 0 0 10 18Zm2 4a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0 1 12 22Zm0-2c2.233 0 4.125-.775 5.675-2.325C19.225 16.125 20 14.233 20 12c0-2.233-.775-4.125-2.325-5.675C16.125 4.775 14.233 4 12 4c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 2.233.775 4.125 2.325 5.675C7.875 19.225 9.767 20 12 20Z'
      />
    </g>
  </svg>
)
export default SvgMusicCircle
