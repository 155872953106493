import toast from 'react-hot-toast'
import { TError } from '@/types'

export const errorHandler = (error: TError | string): void => {
  if (typeof error === 'string') {
    toast.error(error)
  } else if (error?.response?.data?.message) {
    toast.error(error?.response?.data?.message)
  }
}
