import { useMemo } from 'react'
import { selectPlayerAd, isAdOnTrackSelector, useAppSelector } from '@/store'
import { TAdvertisement } from '@/types'

type TUseAdvertisementOutput = {
  adItem: TAdvertisement | undefined
}

export const useAdvertisement = (): TUseAdvertisementOutput => {
  const isAdOnTrack = useAppSelector(isAdOnTrackSelector)
  const playerAd = useAppSelector(selectPlayerAd)

  const adItem = useMemo(() => {
    if (playerAd?.extra?.before_song_ids?.includes(Number(isAdOnTrack?.id))) {
      return playerAd as TAdvertisement
    } else {
      return undefined
    }
  }, [isAdOnTrack, playerAd])

  return { adItem }
}
