import * as React from 'react'
import type { SVGProps } from 'react'
const SvgMusic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Music_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Music_svg__a)'>
      <path
        fill={props.color}
        d='M10 21c-1.1 0-2.042-.392-2.825-1.175C6.392 19.042 6 18.1 6 17s.392-2.042 1.175-2.825C7.958 13.392 8.9 13 10 13c.383 0 .738.046 1.063.137.324.092.637.23.937.413V5c0-.55.196-1.02.588-1.413A1.926 1.926 0 0 1 14 3h2c.55 0 1.02.196 1.413.587C17.803 3.98 18 4.45 18 5s-.196 1.02-.587 1.412A1.926 1.926 0 0 1 16 7h-2v10c0 1.1-.392 2.042-1.175 2.825C12.042 20.608 11.1 21 10 21Z'
      />
    </g>
  </svg>
)
export default SvgMusic
