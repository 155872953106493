import { TApiImageEndpoint } from '../types/global'

export const imageUrl = (
  uuid: string | number,
  endpoint: TApiImageEndpoint = 'songs',
): string =>
  `${
    endpoint === 'users'
      ? process.env.NEXT_OAUTH_URL
      : process.env.NEXT_CONTENT_URL
  }/${endpoint}/${uuid}/image`
