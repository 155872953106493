import moment from 'moment'
import { MutableRefObject, Ref, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { usePlayer } from './usePlayer'
import { UI_IconSvg, UI_IconButton } from '@/ui'
import { Element } from '@/types'
import {
  isPlayingSelector,
  selectedSong,
  useAppContext,
  useAppSelector,
} from '@/store'
import { useLocale } from '@/hooks'

type TSeekBarProps = {
  isMouseOver?: boolean
  isAd?: boolean
}

export const SeekBar = forwardRef<HTMLVideoElement, TSeekBarProps>(
  (props, ref): Element => {
    const { isMouseOver, isAd } = props
    const {
      downloaded,
      playVideo,
      handlePause,
      handleLoop,
      isLooped,
      currentTime,
      durationTime,
      percentage,
      seekWidth,
      seekBarRef,
      playerRef,
      handleSeekBarClick,
      handleNextSong,
      handleBackSong,
      handleShuffle,
    } = usePlayer(ref as MutableRefObject<HTMLVideoElement | null>)
    const { globalState } = useAppContext()
    const { isPersian } = useLocale()
    const selectedTrack = useAppSelector(selectedSong)
    const isMusicVideo = selectedTrack?.medias?.video
    const isPlaying = useAppSelector(isPlayingSelector)
    const isPlayerFullScreen = Boolean(globalState?.isPlayerFullScreen)
    const transitionClass = 'transition-all duration-300 ease-in-out'
    const loadedSeekBar = percentage
      ? `${seekWidth * percentage}px`
      : `${(seekWidth * Number(currentTime)) / 100}%`

    return (
      <>
        <div
          className={twMerge(
            'flex-1 flex flex-col items-center justify-center gap-3 relative z-[1]',
            isPlayerFullScreen && 'flex-col-reverse mb-10',
            transitionClass,
            isMusicVideo &&
              !isMouseOver &&
              isPlaying &&
              isPlayerFullScreen &&
              'opacity-0',
          )}
        >
          <div
            className={twMerge(
              'flex flex-row items-center justify-center gap-6',
              isPlayerFullScreen &&
                'absolute bottom-0 w-fit m-auto top-[48px] z-10',
            )}
          >
            <UI_IconSvg
              className={twMerge(
                'w-6 h-6 cursor-pointer',
                globalState?.isShuffle && 'stroke-gradient-primary',
                isAd && 'opacity-30',
              )}
              component='Shuffle'
              onClick={isAd ? undefined : handleShuffle}
            />
            <UI_IconSvg
              className={twMerge(
                'w-6 h-6 cursor-pointer',
                isAd && 'opacity-30',
              )}
              component='PreviousFilled'
              onClick={isAd ? undefined : handleBackSong}
            />
            <UI_IconButton
              aria-label='Play Button'
              borderLess
              className='background-conic-gradient w-8 h-8'
              icon={{
                iconType: isPlaying ? 'Pause' : 'Play',
                className: 'w-5 h-5',
              }}
              onClick={() => (isPlaying ? handlePause() : playVideo())}
            />
            <UI_IconSvg
              className={twMerge(
                'w-6 h-6 cursor-pointer',
                isAd && 'opacity-30',
              )}
              component='NextFilled'
              onClick={isAd ? undefined : handleNextSong}
            />
            <UI_IconSvg
              className={twMerge(
                'w-6 h-6 cursor-pointer',
                isLooped && 'stroke-gradient-primary',
                isAd && 'opacity-30',
              )}
              component={isLooped ? 'RepeatSong' : 'Repeat'}
              onClick={isAd ? undefined : handleLoop}
            />
          </div>
          <div className='self-stretch flex flex-row items-center justify-center gap-2'>
            <div className='leading-[14px] font-medium'>
              {currentTime
                ? moment.utc(currentTime * 1000).format('mm:ss')
                : '00:00'}
            </div>
            <div
              role='button'
              ref={seekBarRef}
              className={twMerge(
                'w-[310px] flex flex-col gap-1 text-content-content-primary cursor-pointer',
                isPlayerFullScreen && 'w-[calc(100vw_-_180px)]',
              )}
              onClick={handleSeekBarClick}
            >
              <div className='self-stretch relative rounded-13xl bg-content-disable-secondary h-2 overflow-hidden shrink-0'>
                <div
                  className='transition-all absolute top-0 left-0 rounded-13xl bg-gray-300 h-2 opacity-30'
                  style={{
                    width: `${downloaded}%`,
                  }}
                />
                <div
                  className='transition-all absolute top-[0px] left-[0px] rounded-13xl [background:linear-gradient(-89.99deg,_#ff334b,_#c600f9_45.83%,_#3369ff)] h-2'
                  style={{
                    width: percentage && loadedSeekBar,
                  }}
                />
              </div>
            </div>
            <div className='leading-[14px] font-medium'>
              {durationTime
                ? moment.utc(durationTime * 1000).format('mm:ss')
                : '00:00'}
            </div>
          </div>
        </div>

        <div
          className={twMerge(
            'absolute top-0 w-full h-full',
            (!isMusicVideo || !isPlayerFullScreen) && 'opacity-0 -z-10',
          )}
        >
          <video
            preload='none'
            ref={playerRef as Ref<HTMLVideoElement>}
            autoPlay={false}
            controls={false}
            className={twMerge(isMusicVideo && 'w-full h-full')}
          >
            <track
              kind='captions'
              srcLang='en'
              label={isPersian ? selectedTrack?.name : selectedTrack?.name_en}
            />
          </video>
          {isMusicVideo && isPlayerFullScreen && (
            <div
              className={twMerge(
                'absolute inset-0 -mb-[1px] z-[0]',
                'flex items-center justify-center',
                transitionClass,
                !isMouseOver && isPlaying && 'opacity-0',
                'full-gradient',
              )}
              onClick={isPlaying ? handlePause : playVideo}
            />
          )}
        </div>
      </>
    )
  },
)

SeekBar.displayName = 'SeekBar'
