import { ResultItem } from './ResultItem'
import { ResultLoading } from './ResultLoading'
import { TCommonDataLoading } from './useSearch'
import { TAlbum, TArtist, TMusic, TPlaylist, Element } from '@/types'
import { useAppContext } from '@/store'

type TResultProps = TCommonDataLoading

export const Result = (props: TResultProps): Element => {
  const { searchData, searchLoading } = props
  const { globalState } = useAppContext()
  const selectedLibrary = globalState?.searchInHeader?.selectedLibrary

  if (searchLoading) return <ResultLoading />

  const songs = (
    selectedLibrary === 'all' ? [searchData?.songs?.[0]] : searchData?.songs
  )?.filter((x) => x !== undefined) as TMusic[]
  const albums = (
    selectedLibrary === 'all' ? [searchData?.albums?.[0]] : searchData?.albums
  )?.filter((x) => x !== undefined) as TAlbum[]
  const artists = (
    selectedLibrary === 'all' ? [searchData?.artists?.[0]] : searchData?.artists
  )?.filter((x) => x !== undefined) as TArtist[]
  const playlists = (
    selectedLibrary === 'all'
      ? [searchData?.playlists?.[0]]
      : searchData?.playlists
  )?.filter((x) => x !== undefined) as TPlaylist[]

  return (
    <div className='flex flex-col gap-2 text-xs'>
      {songs?.length > 0 &&
        songs?.map((item) => (
          <ResultItem key={item?.id} {...item} type='track' />
        ))}
      {albums?.length > 0 &&
        albums?.map((item) => (
          <ResultItem key={item?.id} {...item} type='album' />
        ))}
      {artists?.length > 0 &&
        artists?.map((item) => (
          <ResultItem key={item?.id} {...item} type='artist' />
        ))}
      {playlists?.length > 0 &&
        playlists?.map((item) => (
          <ResultItem key={item?.id} {...item} type='playlist' />
        ))}
    </div>
  )
}
