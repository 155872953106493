import { useMemo } from 'react'
import { TProfile } from '@/types'
import { useAppSelector, RootState } from '@/store'

type TUseUserOutput = Partial<TProfile> & {
  isLogin: boolean
  avatar: string
}

export const useUser = (): TUseUserOutput => {
  const userProfile = useAppSelector(
    (state: RootState) => state.userProfile.profile,
  )
  const loggedIn = useAppSelector(
    (state: RootState) => state.userProfile.profile.loggedIn,
  )

  const isLogin = Boolean(loggedIn)

  const avatar = useMemo(
    () =>
      userProfile?.avatar_path
        ? `${process.env.NEXT_OAUTH_URL}${userProfile?.avatar_path}?date=${userProfile?.latest_update}`
        : '/images/avatar/myProfile.svg',
    [userProfile],
  )

  return {
    isLogin,
    avatar,
    ...userProfile,
  }
}
