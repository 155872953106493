import * as React from 'react'
import type { SVGProps } from 'react'
const SvgArrowUpBig = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Arrow_Up_Big_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Arrow_Up_Big_svg__a)'>
      <path
        fill={props.color}
        d='m11 7.825-4.9 4.9c-.2.2-.433.296-.7.287a1.006 1.006 0 0 1-.7-.312c-.183-.2-.28-.433-.287-.7a.916.916 0 0 1 .287-.7l6.6-6.6c.1-.1.208-.17.325-.213.117-.041.242-.062.375-.062s.258.02.375.062a.877.877 0 0 1 .325.213l6.6 6.6a.933.933 0 0 1 .275.688c0 .274-.092.512-.275.712-.2.2-.437.3-.712.3a.973.973 0 0 1-.713-.3L13 7.825V19c0 .283-.096.52-.287.712A.968.968 0 0 1 12 20a.968.968 0 0 1-.712-.288A.968.968 0 0 1 11 19V7.825Z'
      />
    </g>
  </svg>
)
export default SvgArrowUpBig
