import React, { CSSProperties, ChangeEvent, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Element } from '@/types'
import { UI_IconSvg, iconType } from '@/ui'

type TUI_SwitchProps = {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  defaultValue?: boolean
  value?: boolean
  className?: string
  startAdornment?: {
    icon: iconType
    className?: string
    fill?: string
    style?: CSSProperties
  }
  endAdornment?: {
    icon: iconType
    className?: string
    fill?: string
    style?: CSSProperties
  }
}

export const UI_Switch = (props: TUI_SwitchProps): Element => {
  const {
    onChange,
    defaultValue = false,
    value = false,
    className,
    startAdornment,
    endAdornment,
  } = props

  const [isChecked, setIsChecked] = useState(defaultValue)
  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(!isChecked)
    onChange?.(e)
  }
  useEffect(() => {
    setIsChecked(value)
  }, [value])

  return (
    <label
      className={twMerge(
        'flex cursor-pointer select-none items-center',
        className,
      )}
    >
      <div className='relative'>
        <input
          type='checkbox'
          checked={isChecked}
          onChange={handleCheckboxChange}
          className='sr-only'
        />
        <div
          className={twMerge(
            'box block h-7 w-12 rounded-full bg-border-border-tertiary border-[1px] border-solid border-border-border-primary',
            isChecked ? 'bg-border-border-tertiary' : 'bg-dark',
          )}
        />

        {startAdornment?.icon && endAdornment?.icon ? (
          <UI_IconSvg
            component={!isChecked ? startAdornment.icon : endAdornment.icon}
            fill={!isChecked ? startAdornment?.fill : endAdornment?.fill}
            className={twMerge(
              !isChecked ? startAdornment?.className : endAdornment?.className,
              'absolute left-1 top-1 flex h-5 w-5 items-center justify-center rounded-full bg-white transition p-[0.1rem]',
              isChecked ? 'translate-x-full' : '',
            )}
            width={20}
            height={20}
          />
        ) : (
          <div
            className={twMerge(
              'absolute left-1 top-1 flex h-5 w-5 items-center justify-center rounded-full bg-white transition',
              isChecked ? 'translate-x-full' : '',
            )}
          />
        )}
      </div>
    </label>
  )
}
