import { m } from 'framer-motion'
import { MouseEvent, ReactNode, useState } from 'react'
import { createPortal } from 'react-dom'
import { twMerge } from 'tailwind-merge'
import { CardAd, MobileView, Col, Row } from '@/components'
import { UI_IconSvg, iconType, UI_Typography, UI_IconButton, UI_HR } from '@/ui'
import { useClickOutside, useMobile } from '@/hooks'
import { isSSR } from '@/variables'

export type TActionMenuItem = {
  id: number
  name: string
  icon?: iconType
  action?: (e: MouseEvent<HTMLLIElement> | undefined) => void
  disabled?: boolean
  renderer?: (item: TActionMenuItem) => ReactNode
}

export type TActionMenuProps = {
  items: Array<TActionMenuItem[]>
  position: 'left-top' | 'left-bottom' | 'right-top' | 'right-bottom'
  item?: {
    id: number
    image_path: string
    subTitle: string
    title: string
  }
}

export const UI_ActionMenu = (props: TActionMenuProps): JSX.Element | null => {
  const { items, position, item } = props
  const [isOpen, setIsOpen] = useState(false)
  const handleCloseMenu = () => setIsOpen(false)
  const ref = useClickOutside<HTMLDivElement>(handleCloseMenu)
  const isMobile = useMobile()
  const el = isSSR
    ? undefined
    : (document.getElementById('react-portal') as HTMLElement)

  const render = isOpen && (
    <div onMouseLeave={handleCloseMenu}>
      <div
        className='fixed inset-0 lg:-mx-[100dvw] lg:-mt-[250px] lg:-mb-[350px] z-[9]'
        onClick={(e) => {
          e.stopPropagation()
          handleCloseMenu()
        }}
      />
      <m.div
        initial={isMobile && { y: 100 }}
        animate={isMobile && { y: 0 }}
        className={twMerge(
          'lg:absolute fixed z-10 lg:w-56 w-full',
          position === 'left-top' &&
            'rtl:right-auto rtl:left-0 right-0 lg:bottom-full top-auto bottom-0',
          position === 'left-bottom' &&
            'rtl:right-auto rtl:left-0 right-0 lg:top-full top-auto bottom-0',
          position === 'right-top' &&
            'rtl:left-auto rtl:right-0 left-0 lg:bottom-full top-auto bottom-0',
          position === 'right-bottom' &&
            'rtl:left-auto rtl:right-0 left-0 lg:top-full top-auto bottom-0',
          '-my-5 py-5',
        )}
      >
        <div className='rounded-lg text-content-content-secondary border border-cards-fill-primary w-full lg:p-0 p-5 pt-3 bg-cards-glass-secondary2 backdrop-blur-xl'>
          <MobileView>
            <Col className='gap-4'>
              <div className='w-7 h-1 rounded-full m-auto bg-border-border-primary' />
              <Row className='justify-between'>
                <CardAd
                  id={Number(item?.id)}
                  image_path={item?.image_path}
                  subTitle={item?.subTitle}
                  title={item?.title}
                  displaySkipAdButton={false}
                  displayPromotedBadge={false}
                />
                <UI_IconButton
                  borderLess
                  icon={{
                    iconType: 'Close',
                    className: 'w-5 h-5',
                  }}
                  onClick={handleCloseMenu}
                />
              </Row>
              <UI_HR />
            </Col>
          </MobileView>
          <div className='flex flex-col space-y-0.5'>
            {items.map((group, index) => {
              if (group.length <= 0) return null
              return (
                <ul
                  key={index}
                  className={twMerge(
                    'border-t border-solid border-transparent py-2',
                    index > 0 &&
                      'border-t border-solid border-transparent border-t-border-border-tertiary',
                  )}
                >
                  {group.map((item) => {
                    if (item?.renderer) return item.renderer?.(item)
                    return (
                      <li
                        key={`${index}_${item.id}`}
                        className={
                          (twMerge(item.disabled && 'opacity-30'),
                          'flex text-white bg-transparent border-none outline-none justify-start items-center py-2 lg:px-3 cursor-pointer space-x-2')
                        }
                        onClick={(e) => {
                          handleCloseMenu()
                          item.action?.(e)
                        }}
                      >
                        {item.icon && (
                          <UI_IconSvg
                            component={item.icon}
                            className={twMerge(item.disabled && 'opacity-30')}
                          />
                        )}
                        <UI_Typography
                          variant='enLabelRegularLabel1'
                          className={twMerge(
                            'rtl:pr-2',
                            'capitalize',
                            item.disabled && 'opacity-30',
                          )}
                        >
                          {item.name}
                        </UI_Typography>
                      </li>
                    )
                  })}
                </ul>
              )
            })}
          </div>
        </div>
      </m.div>
    </div>
  )

  return (
    <div className='relative' ref={ref}>
      <UI_IconButton
        aria-label='Action Menu'
        borderLess
        icon={{
          iconType: 'Options',
          className: isMobile ? 'w-6 h-6' : '',
        }}
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(true)
        }}
      />
      {isMobile && el ? createPortal(render, el) : render}
    </div>
  )
}
