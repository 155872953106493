import * as React from 'react'
import type { SVGProps } from 'react'
const SvgPrevious = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Previous_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Previous_svg__a)'>
      <path
        fill={props.color}
        d='M6.5 18a.967.967 0 0 1-.713-.288A.968.968 0 0 1 5.5 17V7c0-.283.096-.52.287-.713A.968.968 0 0 1 6.5 6c.283 0 .52.096.713.287.191.192.287.43.287.713v10c0 .283-.096.52-.287.712A.967.967 0 0 1 6.5 18Zm10.45-1.025-6.2-4.15A.941.941 0 0 1 10.3 12c0-.35.15-.625.45-.825l6.2-4.15c.333-.233.675-.25 1.025-.05.35.2.525.5.525.9v8.25c0 .4-.175.7-.525.9-.35.2-.692.183-1.025-.05Zm-.45-2.725v-4.5L13.1 12l3.4 2.25Z'
      />
    </g>
  </svg>
)
export default SvgPrevious
