import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFlag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Flag_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Flag_svg__a)'>
      <path
        fill={props.color}
        d='M5 22V2.975c0-.283.096-.517.287-.7A.99.99 0 0 1 6 2c.283 0 .52.096.713.288.191.191.287.429.287.712v1h12.525c.35 0 .625.146.825.438.2.291.233.604.1.937L19 9l1.45 3.625c.133.333.1.646-.1.938a.948.948 0 0 1-.825.437H7v8H5Zm2-10h11.05l-1.2-3 1.2-3H7v6Z'
      />
    </g>
  </svg>
)
export default SvgFlag
