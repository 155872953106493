import * as React from 'react'
import type { SVGProps } from 'react'
const SvgEditFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 25'
    {...props}
  >
    <mask
      id='Edit-filled_svg__a'
      width={24}
      height={25}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 .5h24v24H0z' />
    </mask>
    <g mask='url(#Edit-filled_svg__a)'>
      <path
        fill={props.color}
        d='M4.2 21.475a.916.916 0 0 1-.912-.263.916.916 0 0 1-.263-.912l.875-4.25 4.55 4.55-4.25.875Zm5.875-2.1-4.95-4.95L15.45 4.1c.384-.383.858-.575 1.425-.575.567 0 1.042.192 1.425.575l2.1 2.1c.383.383.575.858.575 1.425 0 .567-.192 1.042-.575 1.425L10.075 19.375Z'
      />
    </g>
  </svg>
)
export default SvgEditFilled
