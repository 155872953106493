/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  buildCreateApi,
  coreModule,
  reactHooksModule,
} from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'
import { baseQueryWithReAuth } from '../tools'
import { TArtist } from '@/types'
import { ParameterNormalize, TParams, objectToQueryString } from '@/tools'

type TResult = {
  items: TArtist
  total: number
}

type AResult = {}

type TResults = {
  items: Array<TArtist>
  total: number
}

type AResults = {
  results: Array<TArtist>
}

const createApi = buildCreateApi(
  coreModule(),
  reactHooksModule({ unstable__sideEffectsInRender: true }),
)

export const artistApi = createApi({
  reducerPath: 'api/artists',
  baseQuery: baseQueryWithReAuth,
  extractRehydrationInfo(action: any, { reducerPath }: any) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
  tagTypes: ['ArtistById', 'Artists', 'FavoriteArtists'],
  endpoints: (builder) => ({
    getArtistById: builder.query<TArtist, number>({
      query: (artistId) => `/api/artists/${artistId}/`,
      providesTags: ['ArtistById'],
    }),

    getArtistsWithQuery: builder.query<TResults, string>({
      query: (query) => `/api/artists/${query}`,
      providesTags: ['Artists'],
    }),

    getArtists: builder.query<AResults, TParams>({
      query: (params) => ({ url: '/api/artists/', method: 'GET', params }),
      providesTags: ['Artists'],
    }),

    getFavoriteArtists: builder.query<TResults, TParams>({
      query: (params) =>
        `/artists/favorites${objectToQueryString(ParameterNormalize(params))}`,
      providesTags: ['FavoriteArtists'],
    }),

    followArtist: builder.mutation<TResult, number>({
      query: (artistId) => {
        return {
          url: `/follow/artist/${artistId}`,
          method: 'POST',
        }
      },
    }),

    unFollowArtist: builder.mutation<TResult, number>({
      query: (artistId) => {
        return {
          url: `/follow/artist/${artistId}`,
          method: 'DELETE',
        }
      },
    }),

    favoriteArtist: builder.mutation<TResult, number>({
      query: (artistId) => {
        return {
          url: `/favorite/artist/${artistId}`,
          method: 'POST',
        }
      },
      invalidatesTags: ['FavoriteArtists'],
    }),

    unFavoriteArtist: builder.mutation<TResult, number>({
      query: (artistId) => {
        return {
          url: `/favorite/artist/${artistId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['FavoriteArtists'],
    }),
  }),
})

export const {
  useGetArtistsWithQueryQuery,
  useLazyGetArtistsWithQueryQuery,
  useGetArtistsQuery,
  useLazyGetArtistsQuery,
  useGetArtistByIdQuery,
  useLazyGetArtistByIdQuery,
  useGetFavoriteArtistsQuery,
  useLazyGetFavoriteArtistsQuery,
  useFollowArtistMutation,
  useUnFollowArtistMutation,
  useFavoriteArtistMutation,
  useUnFavoriteArtistMutation,
  util: { getRunningQueriesThunk: getRunningArtistsQueriesThunk },
} = artistApi

export const {
  getArtistsWithQuery,
  getArtists,
  getArtistById,
  getFavoriteArtists,
  followArtist,
  unFollowArtist,
  favoriteArtist,
  unFavoriteArtist,
} = artistApi.endpoints
