import {
  RefObject,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Timer } from '@/components'
import { UI_Input, UI_Typography } from '@/ui'
import { Element, changeInput, focusInput, keyDownEvent } from '@/types'
import { useTranslate } from '@/hooks'

const handleFocus = (event: focusInput): void => {
  event.target.value = ''
}

type TOtpInput = {
  isError?: boolean
  onClearOtpCode: () => void
  onChanged: (value: string) => void
  ttl?: number
}

export const OtpInput = forwardRef<HTMLButtonElement, TOtpInput>(
  (props, ref): Element => {
    const { translate } = useTranslate()
    const { onClearOtpCode, onChanged, isError, ttl = 120 } = props
    const [isShowResendOTPButton, setIsShowResendOTPButton] =
      useState<boolean>(false)
    const text1Ref = useRef<HTMLInputElement>(null)
    const text2Ref = useRef<HTMLInputElement>(null)
    const text3Ref = useRef<HTMLInputElement>(null)
    const text4Ref = useRef<HTMLInputElement>(null)

    const handleToggleDisplayTimer = (): void => {
      setIsShowResendOTPButton((previousState) => !previousState)
    }
    const handleResendCode = (): void => {
      if (!isShowResendOTPButton) return
      setIsShowResendOTPButton((previousState) => !previousState)
      onClearOtpCode()
    }
    // TODO: change the any type
    useImperativeHandle(
      ref,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (): any => {
        return {
          reset: () => {
            handleToggleDisplayTimer()
          },
        }
      },
      [],
    )

    const handleChange = (
      event: changeInput,
      ref: RefObject<HTMLInputElement | HTMLButtonElement>,
    ): void => {
      const value = `${text1Ref.current?.value}${text2Ref.current?.value}${text3Ref.current?.value}${text4Ref.current?.value}`
      onChanged(value)
      if (event.target.value.length > 0) {
        try {
          ref.current?.focus()
        } catch (err) {
          text4Ref.current?.blur()
        }
      }
    }
    const handleKeyDown = (event: keyDownEvent) => {
      if (event.key === '-') {
        event.preventDefault()
        return false
      }
    }

    useEffect(() => {
      text1Ref.current?.focus()
    }, [])

    const inputProps = useMemo(
      () => ({
        autoComplete: 'off',
        className: 'w-12',
        inputClassName: 'text-center text-lg input-number',
        type: 'number',
        baseClassName: 'bg-cards-glass-secondary border-border-border-inactive',
        onFocus: handleFocus,
        onKeyDown: handleKeyDown,
      }),
      [],
    )

    return (
      <div className='w-full flex flex-col gap-8'>
        <div className='flex flex-col gap-1'>
          <UI_Typography variant='enBodyRegularText2'>
            {translate('login.otp-code')}
          </UI_Typography>
          <div className='flex flex-row items-start justify-between ltr'>
            <UI_Input
              ref={text1Ref}
              name='text1'
              onChange={(event) => handleChange(event, text2Ref)}
              inputMode='numeric'
              isError={isError}
              {...inputProps}
            />
            <UI_Input
              ref={text2Ref}
              name='text2'
              onChange={(event) => handleChange(event, text3Ref)}
              inputMode='numeric'
              isError={isError}
              {...inputProps}
            />
            <UI_Input
              ref={text3Ref}
              name='text3'
              onChange={(event) => handleChange(event, text4Ref)}
              inputMode='numeric'
              isError={isError}
              {...inputProps}
            />
            <UI_Input
              ref={text4Ref}
              name='text4'
              onChange={(event) =>
                handleChange(event, ref as RefObject<HTMLButtonElement>)
              }
              inputMode='numeric'
              isError={isError}
              {...inputProps}
            />
          </div>
        </div>

        <UI_Typography
          variant='enBodyBoldText2'
          align='center'
          onClick={handleResendCode}
        >
          {!isShowResendOTPButton ? (
            <>
              {translate('login.resend-new-code-later')}
              <span className='ml-2 rtl:mr-2'>
                <Timer duration={ttl} onFinished={handleToggleDisplayTimer} />
              </span>
            </>
          ) : (
            <>
              <span className='text-content-content-secondary font-normal text-sm px-0.5'>
                {translate('login.dont-receive-code')}
              </span>
              {translate('login.resend-new-code')}
            </>
          )}
        </UI_Typography>
      </div>
    )
  },
)

OtpInput.displayName = 'OtpInput'
