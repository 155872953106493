/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  buildCreateApi,
  coreModule,
  reactHooksModule,
} from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'
import { baseQueryWithReAuth } from '../tools'
import { TSettings } from '@/types'
import { ParameterNormalize, TParams, objectToQueryString } from '@/tools'

type TResult = {
  items: TSettings
}

const createApi = buildCreateApi(
  coreModule(),
  reactHooksModule({ unstable__sideEffectsInRender: true }),
)

export const settingsApi = createApi({
  reducerPath: 'api/settings',
  baseQuery: baseQueryWithReAuth,
  extractRehydrationInfo(action: any, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
  endpoints: (builder) => ({
    getSettingsList: builder.query<TResult, { params: TParams; type: string }>({
      query: ({ params, type }) =>
        `/settings/${type}${objectToQueryString(ParameterNormalize(params))}`,
    }),
  }),
})

export const {
  useGetSettingsListQuery,
  useLazyGetSettingsListQuery,
  usePrefetch: useSettingsPrefetch,
  util: { getRunningQueriesThunk: getRunningSettingsQueriesThunk },
} = settingsApi

export const { getSettingsList } = settingsApi.endpoints
