import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSettingsFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Settings_-_filled_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Settings_-_filled_svg__a)'>
      <path
        fill={props.color}
        d='M13.875 22h-3.75a.934.934 0 0 1-.65-.25.997.997 0 0 1-.325-.625l-.3-2.325a3.79 3.79 0 0 1-.613-.3 8.266 8.266 0 0 1-.562-.375l-2.175.9a1.119 1.119 0 0 1-.7.025.93.93 0 0 1-.55-.425L2.4 15.4a.94.94 0 0 1-.125-.7.96.96 0 0 1 .375-.6l1.875-1.425a2.387 2.387 0 0 1-.025-.338v-.675c0-.108.008-.22.025-.337L2.65 9.9a.96.96 0 0 1-.375-.6.94.94 0 0 1 .125-.7l1.85-3.225a.786.786 0 0 1 .537-.438c.242-.058.48-.045.713.038l2.175.9c.183-.133.375-.258.575-.375.2-.117.4-.217.6-.3l.3-2.325a.997.997 0 0 1 .325-.625c.183-.167.4-.25.65-.25h3.75c.25 0 .466.083.65.25a.996.996 0 0 1 .325.625l.3 2.325c.216.083.42.183.612.3.192.117.38.242.563.375l2.175-.9a1.12 1.12 0 0 1 .7-.025.929.929 0 0 1 .55.425L21.6 8.6a.94.94 0 0 1 .125.7.959.959 0 0 1-.375.6l-1.875 1.425c.016.117.025.23.025.338v.675c0 .108-.017.22-.05.337l1.875 1.425c.2.15.325.35.375.6a.94.94 0 0 1-.125.7l-1.85 3.2a.982.982 0 0 1-.563.438c-.241.075-.479.07-.712-.013l-2.125-.9a6.826 6.826 0 0 1-.575.375c-.2.117-.4.217-.6.3l-.3 2.325a.996.996 0 0 1-.325.625.934.934 0 0 1-.65.25Zm-1.825-6.5c.966 0 1.791-.342 2.475-1.025A3.372 3.372 0 0 0 15.55 12c0-.967-.342-1.792-1.025-2.475A3.372 3.372 0 0 0 12.05 8.5c-.984 0-1.813.342-2.488 1.025A3.393 3.393 0 0 0 8.55 12c0 .967.337 1.792 1.012 2.475S11.067 15.5 12.05 15.5Z'
      />
    </g>
  </svg>
)
export default SvgSettingsFilled
