import * as React from 'react'
import type { SVGProps } from 'react'
const SvgPlaylist = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Playlist_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Playlist_svg__a)'>
      <path
        fill={props.color}
        d='M6 16a.967.967 0 0 1-.713-.287A.968.968 0 0 1 5 15c0-.283.096-.52.287-.713A.967.967 0 0 1 6 14h6c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.968.968 0 0 1 12 16H6Zm0-4a.967.967 0 0 1-.713-.287A.968.968 0 0 1 5 11c0-.283.096-.52.287-.713A.967.967 0 0 1 6 10h10c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 16 12H6Zm0-4a.968.968 0 0 1-.713-.287A.968.968 0 0 1 5 7c0-.283.096-.52.287-.713A.968.968 0 0 1 6 6h10c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 16 8H6Zm12.775 12.475a.482.482 0 0 1-.25.075.67.67 0 0 1-.25-.05.425.425 0 0 1-.2-.163.462.462 0 0 1-.075-.262v-6.15c0-.1.025-.188.075-.263a.425.425 0 0 1 .2-.162.67.67 0 0 1 .25-.05c.083 0 .167.025.25.075l4.6 3.05c.083.05.142.113.175.188a.579.579 0 0 1 0 .474.418.418 0 0 1-.175.188l-4.6 3.05Z'
      />
    </g>
  </svg>
)
export default SvgPlaylist
