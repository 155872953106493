/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  buildCreateApi,
  coreModule,
  reactHooksModule,
} from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'
import { baseQueryWithReAuth } from '../tools'
import { TGenre, TMusic } from '@/types'
import { TParams } from '@/tools'

type TResult = {
  results: Array<TGenre>
  total: number
}

type TResultSongs = {
  results: Array<TMusic>
  count: number
}

type GenrePrams = { id: string | string[]; limit?: number; offset?: number }

const createApi = buildCreateApi(
  coreModule(),
  reactHooksModule({ unstable__sideEffectsInRender: true }),
)

export const genreApi = createApi({
  reducerPath: 'api/genres',
  baseQuery: baseQueryWithReAuth,
  extractRehydrationInfo(action: any, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
  endpoints: (builder) => ({
    getGenreList: builder.query<TResult, TParams>({
      query: (params) => ({ url: '/api/genres/', method: 'GET', params }),
    }),

    getGenreSongs: builder.query<TResultSongs, GenrePrams>({
      query: ({ id, limit, offset }) => ({
        url: `/api/genres/${id}/songs/`,
        method: 'GET',
        params: { limit, offset },
      }),
    }),
  }),
})

export const {
  useGetGenreSongsQuery,
  useGetGenreListQuery,
  useLazyGetGenreListQuery,
  useLazyGetGenreSongsQuery,
  usePrefetch: useGenrePrefetch,
  util: { getRunningQueriesThunk: getRunningGenreQueriesThunk },
} = genreApi

export const { getGenreList, getGenreSongs } = genreApi.endpoints
