import * as React from 'react'
import type { SVGProps } from 'react'
const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 25 24'
    {...props}
  >
    <mask
      id='Edit_svg__a'
      width={25}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M.5 0h24v24H.5z' />
    </mask>
    <g mask='url(#Edit_svg__a)'>
      <path
        fill={props.color}
        d='M4.675 21a.916.916 0 0 1-.912-.262.916.916 0 0 1-.263-.913l1-4.775L9.45 20l-4.775 1Zm4.775-1L4.5 15.05 15.95 3.6c.384-.383.858-.575 1.425-.575.567 0 1.042.192 1.425.575l2.1 2.1c.384.383.575.858.575 1.425 0 .567-.192 1.042-.575 1.425L9.45 20Zm7.925-15L7.025 15.35l2.125 2.125L19.5 7.125 17.375 5Z'
      />
    </g>
  </svg>
)
export default SvgEdit
