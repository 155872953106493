import * as React from 'react'
import type { SVGProps } from 'react'
const SvgAddSong = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={props.color}
      d='M5.72 2c.558 0 .93.372.93.93v1.86h1.86c.558 0 .93.372.93.93s-.372.93-.93.93H6.65v1.86c0 .465-.372.93-.93.93s-.93-.372-.93-.93V6.65H2.93c-.558 0-.93-.372-.93-.93s.372-.93.93-.93h1.86V2.93c0-.558.372-.93.93-.93Z'
    />
    <path
      fill={props.color}
      fillRule='evenodd'
      d='M8.51 10.65c-.558 0-.93.372-.93.93V15.114H6.464c-1.953 0-3.534 1.58-3.441 3.441a3.228 3.228 0 0 0 3.07 3.07c1.86.092 3.44-1.582 3.44-3.442v-6.696a1.012 1.012 0 0 0-1.023-.838Zm-.93 7.812c0 .744-.65 1.395-1.395 1.395-.744 0-1.395-.65-1.395-1.395 0-.744.651-1.395 1.395-1.395H7.58v1.395ZM19.578 4.697h-6.417c-.558 0-1.023.465-1.023 1.023 0 .558.465.93.93.93h5.487c.558 0 1.023.465 1.023 1.023v7.813h-1.116c-1.953 0-3.534 1.58-3.441 3.441a3.228 3.228 0 0 0 3.07 3.07c1.86.092 3.44-1.582 3.44-3.442V6.65c0-1.116-.837-1.953-1.953-1.953Zm.093 14.137c0 .558-.279 1.023-.744 1.21-.186.092-.372.185-.65.185-.745 0-1.396-.65-1.396-1.395 0-.744.651-1.395 1.395-1.395h.372c.558 0 1.023.465 1.023 1.023v.372Z'
      clipRule='evenodd'
    />
  </svg>
)
export default SvgAddSong
