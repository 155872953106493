import React, { SVGProps, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'
import { Element } from '@/types'
import {
  AddedPeople,
  Artist,
  Check1,
  ExternalLinkFlipped,
  Private16Px,
  PrivateFilled,
  PrivateFilled16Px,
  Public,
  PublicFilled,
  ArrowDown,
  ArrowDownBig,
  ArrowDropDown,
  ArrowForwardBig,
  ArrowLeft,
  ArrowLeftBig,
  ArrowRight,
  ArrowRightBig,
  ArrowRightCircle,
  ArrowRightCircleFilled,
  ArrowUp,
  ArrowUpBig,
  ArtistFilled,
  Artists,
  ArtistsFilled,
  Brightness1,
  Camera,
  CameraFilled,
  CategoryIconMusicNote,
  Check,
  Checkbox,
  CheckboxChecked,
  CheckCircle,
  CheckCircleFilled,
  Circle,
  Close,
  CloseCircle,
  CloseCircleFilled,
  CloseFullscreen,
  Date,
  Delete,
  DeleteFilled,
  Desktop,
  DesktopFilled,
  Device,
  DeviceFilled,
  Download,
  Edit,
  EditFilled,
  ExternalLink,
  EyeHide,
  EyeHideFilled,
  EyeView,
  EyeViewFilled,
  Fire,
  FireFilled,
  Flag,
  FlagFilled,
  Fullscreen,
  FullscreenClose,
  GridView,
  GridViewFilled,
  Headphones,
  HeadphonesFilled,
  Heart,
  HeartFilled,
  Help,
  HelpFilled,
  Home,
  HomeFilled,
  Instagram,
  Library,
  LibraryFilled,
  Linkedin,
  ListView,
  Logout,
  Lyrics,
  LyricsFilled,
  MAterialSymbolsIcons,
  Microphone,
  MicrophoneFilled,
  Music,
  MusicCircle,
  MusicCircleFilled,
  Next,
  NextFilled,
  Night,
  NightFilled,
  Notifications,
  NotificationsFilled,
  OpenFullscreen,
  Options,
  OptionsCircle,
  OptionsCircleFilled,
  Order,
  PanToolAlt,
  Pause,
  PauseCircle,
  PauseCircleFilled,
  PauseFilled,
  Play,
  PlayCircle,
  PlayCircleFilled,
  PlayFilled,
  PlayFilledS,
  Playlist,
  PlaySpeed,
  PlaySpeedFilled,
  Podcast,
  Previous,
  PreviousFilled,
  Profile,
  ProfileFilled,
  QueueMusic,
  Radio,
  RecentlyPlayed,
  Rectangle1,
  Remove,
  RemoveFilled,
  Repeat,
  RepeatSong,
  Report,
  ReportFilled,
  Search,
  SearchFilled,
  SearchMusic,
  Settings,
  SettingsFilled,
  Share,
  ShareFilled,
  Shuffle,
  SkipBack,
  SkipForward,
  Space,
  Speaker,
  SpeakerFilled,
  Sub,
  Subscriptions,
  SubscriptionsFilled,
  Time,
  TimeFilled,
  Twitter,
  Upload,
  VolumeDown,
  VolumeDownFilled,
  VolumeUp,
  VolumeUpFilled,
  VolumeOff,
  Wallet,
  Add,
  AddCircle,
  AddCircleFilled,
  AddPeople,
  AddPeopleFilled,
  AddSong,
  AddSongFilled,
  AddToPlaylist,
  Album,
  AlbumFilled,
  AllDevices,
  AllDevicesFilled,
  ArrowBackBig,
  Private,
  Horn,
  HornFilled,
} from '@/components/svg-icons'

export const IconsMap = {
  AddedPeople,
  Artist,
  Check1,
  ExternalLinkFlipped,
  Private16Px,
  PrivateFilled,
  PrivateFilled16Px,
  Public,
  PublicFilled,
  ArrowDown,
  ArrowDownBig,
  ArrowDropDown,
  ArrowForwardBig,
  ArrowLeft,
  ArrowLeftBig,
  ArrowRight,
  ArrowRightBig,
  ArrowRightCircle,
  ArrowRightCircleFilled,
  ArrowUp,
  ArrowUpBig,
  ArtistFilled,
  Artists,
  ArtistsFilled,
  Brightness1,
  Camera,
  CameraFilled,
  CategoryIconMusicNote,
  Check,
  Checkbox,
  CheckboxChecked,
  CheckCircle,
  CheckCircleFilled,
  Circle,
  Close,
  CloseCircle,
  CloseCircleFilled,
  CloseFullscreen,
  Date,
  Delete,
  DeleteFilled,
  Desktop,
  DesktopFilled,
  Device,
  DeviceFilled,
  Download,
  Edit,
  EditFilled,
  ExternalLink,
  EyeHide,
  EyeHideFilled,
  EyeView,
  EyeViewFilled,
  Fire,
  FireFilled,
  Flag,
  FlagFilled,
  Fullscreen,
  FullscreenClose,
  GridView,
  GridViewFilled,
  Headphones,
  HeadphonesFilled,
  Heart,
  HeartFilled,
  Help,
  HelpFilled,
  Home,
  HomeFilled,
  Instagram,
  Library,
  LibraryFilled,
  Linkedin,
  ListView,
  Logout,
  Lyrics,
  LyricsFilled,
  MAterialSymbolsIcons,
  Microphone,
  MicrophoneFilled,
  Music,
  MusicCircle,
  MusicCircleFilled,
  Next,
  NextFilled,
  Night,
  NightFilled,
  Notifications,
  NotificationsFilled,
  OpenFullscreen,
  Options,
  OptionsCircle,
  OptionsCircleFilled,
  Order,
  PanToolAlt,
  Pause,
  PauseCircle,
  PauseCircleFilled,
  PauseFilled,
  Play,
  PlayCircle,
  PlayCircleFilled,
  PlayFilled,
  PlayFilledS,
  Playlist,
  PlaySpeed,
  PlaySpeedFilled,
  Podcast,
  Previous,
  PreviousFilled,
  Profile,
  ProfileFilled,
  QueueMusic,
  Radio,
  RecentlyPlayed,
  Rectangle1,
  Remove,
  RemoveFilled,
  Repeat,
  RepeatSong,
  Report,
  ReportFilled,
  Search,
  SearchFilled,
  SearchMusic,
  Settings,
  SettingsFilled,
  Share,
  ShareFilled,
  Shuffle,
  SkipBack,
  SkipForward,
  Space,
  Speaker,
  SpeakerFilled,
  Sub,
  Subscriptions,
  SubscriptionsFilled,
  Time,
  TimeFilled,
  Twitter,
  Upload,
  VolumeDown,
  VolumeDownFilled,
  VolumeUp,
  VolumeUpFilled,
  VolumeOff,
  Wallet,
  Add,
  AddCircle,
  AddCircleFilled,
  AddPeople,
  AddPeopleFilled,
  AddSong,
  AddSongFilled,
  AddToPlaylist,
  Album,
  AlbumFilled,
  AllDevices,
  AllDevicesFilled,
  ArrowBackBig,
  Private,
  Horn,
  HornFilled,
}

export type iconType = keyof typeof IconsMap

export const UI_IconSvg = ({
  component,
  className = '',
  fill = 'fill-white',
  ...props
}: {
  component: iconType
  className?: string
  fill?: string
} & SVGProps<SVGSVGElement>): Element => {
  const Component = useMemo(() => IconsMap[component], [component])

  return (
    <Component
      width={24}
      height={24}
      className={twMerge('icon stroke-gray-200', fill, className)}
      {...props}
    />
  )
}
