import { UI_Typography } from '@/ui'
import { Element } from '@/types'

type TTitleProps = { title: string }

export const Title = (props: TTitleProps): Element => {
  const { title } = props
  return (
    <UI_Typography variant='enDesktopTitle2' className='py-16'>
      {title}
    </UI_Typography>
  )
}
