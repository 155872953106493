/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import { REHYDRATE } from 'redux-persist'

type TInitial = {
  isMobile: boolean
}

const initialState: TInitial = {
  isMobile: false,
}

let oldHydrate = {}

export const ssrSlice = createSlice({
  name: 'ssr',
  initialState,
  reducers: {
    setIsMobile(state, action: PayloadAction<boolean>) {
      state.isMobile = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: any) => {
      oldHydrate = action.payload.ssr
      return {
        ...state,
        ...oldHydrate,
      }
    })
    builder.addCase(REHYDRATE, (state) => {
      return {
        ...state,
        ...oldHydrate,
      }
    })
  },
})

export const { setIsMobile } = ssrSlice.actions
