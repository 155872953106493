/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useRouter as useNextRouter } from 'next/router'
import { useState, useEffect } from 'react'

export const useCustomRouter = () => {
  const nextRouter = useNextRouter()
  const [history, setHistory] = useState<string[]>([])

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      setHistory((prevHistory) => [...prevHistory, url])
    }

    nextRouter.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      nextRouter.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [nextRouter])

  const back = () => {
    if (history.length > 0 && history[history.length - 1] !== '/') {
      nextRouter.back()
      setHistory((prevHistory) => prevHistory.slice(0, -1))
    } else {
      nextRouter.push('/')
      setHistory([])
    }
  }
  const backAfterLogin = () => {
    if (
      history.length > 0 &&
      history[history.length - 1] !== '/' &&
      history[history.length - 1] !== '/register'
    ) {
      nextRouter.back()
      setHistory((prevHistory) => prevHistory.slice(0, -1))
    } else {
      nextRouter.push('/')
      setHistory([])
    }
  }

  return { ...nextRouter, history, back, backAfterLogin }
}
