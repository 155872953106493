import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTwitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={props.color}
      fillRule='evenodd'
      d='M15.021 3.343c.51-.087 1.078-.116 1.614-.025a4.85 4.85 0 0 1 2.54 1.273c.456.01.905-.08 1.302-.208a5.363 5.363 0 0 0 1.098-.501l.01-.006a.75.75 0 0 1 1.041 1.037c-.207.315-.496.877-.819 1.507l-.155.301c-.185.36-.375.724-.552 1.036-.11.196-.23.395-.35.567v.274A12.34 12.34 0 0 1 8.287 21.03a12.32 12.32 0 0 1-6.694-1.97.75.75 0 0 1 .5-1.374 7.471 7.471 0 0 0 4.033-.642 4.858 4.858 0 0 1-2.61-2.922.75.75 0 0 1 .147-.722l.01-.01A4.848 4.848 0 0 1 2.05 9.793v-.052a.75.75 0 0 1 .553-.724 4.9 4.9 0 0 1 .137-4.619.75.75 0 0 1 1.232-.1 10.89 10.89 0 0 0 7.006 3.93 4.85 4.85 0 0 1 2.562-4.406c.402-.214.934-.385 1.482-.479ZM3.743 10.891a3.35 3.35 0 0 0 2.503 2.164.75.75 0 0 1 .072 1.453c-.272.083-.55.14-.834.173a3.357 3.357 0 0 0 2.59 1.3.75.75 0 0 1 .45 1.339 8.972 8.972 0 0 1-3.548 1.695c1.071.343 2.189.517 3.313.515h.01A10.838 10.838 0 0 0 19.25 8.607v-.535a.75.75 0 0 1 .186-.495c.07-.079.19-.261.36-.56.16-.282.338-.622.523-.981l.033-.066c-.522.118-1.06.15-1.593.097a.75.75 0 0 1-.47-.237 3.35 3.35 0 0 0-1.904-1.032 3.42 3.42 0 0 0-1.11.025 3.603 3.603 0 0 0-1.028.323 3.35 3.35 0 0 0-1.678 3.74.75.75 0 0 1-.767.925 12.39 12.39 0 0 1-8.149-3.627 3.409 3.409 0 0 0-.063.657v.002a3.34 3.34 0 0 0 1.486 2.785A.75.75 0 0 1 4.64 11a4.8 4.8 0 0 1-.897-.109Z'
      clipRule='evenodd'
    />
  </svg>
)
export default SvgTwitter
