import { MutableRefObject, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import style from './player.module.css'
import { usePlayer } from './usePlayer'
import { UI_IconSvg, UI_Tooltip } from '@/ui'
import { Element } from '@/types'
import {
  isPlayingSelector,
  selectedSong,
  useAppContext,
  useAppSelector,
} from '@/store'
import { useTranslate } from '@/hooks'

type TOtherPlayerActionsProps = {
  isMouseOver?: boolean
}

export const OtherPlayerActions = forwardRef<
  HTMLVideoElement,
  TOtherPlayerActionsProps
>((props, ref): Element => {
  const { isMouseOver } = props
  const {
    isMuted,
    handleMutedChange,
    handleVolumeChange,
    handleFullScreen,
    handleLyrics,
  } = usePlayer(ref as MutableRefObject<HTMLVideoElement | null>)
  const { translate } = useTranslate()
  const { globalState } = useAppContext()
  const isPlayerFullScreen = Boolean(globalState?.isPlayerFullScreen)
  const selectedTrack = useAppSelector(selectedSong)
  const isMusicVideo = selectedTrack?.medias?.video
  const haveLyrics = selectedTrack?.lyrics

  const isPlaying = useAppSelector(isPlayingSelector)
  const transitionClass = 'transition-all duration-300 ease-in-out'

  return (
    <div
      className={twMerge(
        'flex-1 flex flex-row items-center justify-end gap-4 text-content-content-primary',
        transitionClass,
        isMusicVideo &&
          !isMouseOver &&
          isPlaying &&
          isPlayerFullScreen &&
          'opacity-0',
      )}
    >
      <UI_Tooltip
        className='bg-background-background-tertiary'
        message={translate(
          isPlayerFullScreen
            ? 'global.exit-fullscreen'
            : 'global.open-fullscreen',
        )}
      >
        <UI_IconSvg
          className='w-6 h-6 cursor-pointer'
          component={isPlayerFullScreen ? 'CloseFullscreen' : 'OpenFullscreen'}
          onClick={handleFullScreen}
        />
      </UI_Tooltip>

      {!isMusicVideo && haveLyrics && (
        <UI_Tooltip
          className='bg-background-background-tertiary'
          message={translate('global.lyrics')}
        >
          <UI_IconSvg
            className={twMerge(
              'w-6 h-6 cursor-pointer',
              globalState?.isLyrics && 'stroke-gradient-primary',
            )}
            component='Lyrics'
            onClick={handleLyrics}
          />
        </UI_Tooltip>
      )}
      <div className='flex flex-row items-center justify-end gap-1'>
        <UI_Tooltip
          className='bg-background-background-tertiary'
          message={translate(isMuted ? 'global.unmute' : 'global.mute')}
        >
          <UI_IconSvg
            className='w-6 h-6 cursor-pointer'
            component={isMuted ? 'VolumeOff' : 'VolumeUp'}
            onClick={() => handleMutedChange(!isMuted)}
          />
        </UI_Tooltip>
        <div className='rounded-space-100 w-[120px] flex flex-col items-start justify-start gap-1'>
          <label htmlFor='volume-range' aria-label='Volume Range'>
            <input
              id='volume-range'
              type='range'
              min='0'
              max='1'
              disabled={isMuted}
              step='0.01'
              className={style.volume}
              style={{ width: '100%' }}
              onChange={handleVolumeChange}
            />
          </label>
        </div>
      </div>
    </div>
  )
})

OtherPlayerActions.displayName = 'OtherPlayerActions'
