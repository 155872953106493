import * as React from 'react'
import type { SVGProps } from 'react'
const SvgRadio = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Radio_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Radio_svg__a)'>
      <path
        fill={props.color}
        d='M12 14c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0 1 10 12c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 12 10c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412 0 .55-.196 1.02-.588 1.412A1.926 1.926 0 0 1 12 14Zm-7.75 4.325c-.7-.867-1.25-1.837-1.65-2.912A9.705 9.705 0 0 1 2 12c0-1.217.2-2.358.6-3.425.4-1.067.95-2.033 1.65-2.9a.864.864 0 0 1 .688-.325c.275 0 .512.1.712.3.183.183.283.412.3.687.017.275-.067.53-.25.763a8.217 8.217 0 0 0-1.25 2.262C4.15 10.187 4 11.067 4 12a7.855 7.855 0 0 0 1.675 4.9c.183.233.27.488.263.763a1.02 1.02 0 0 1-.313.712.916.916 0 0 1-1.375-.05Zm2.85-2.85c-.35-.5-.62-1.042-.813-1.625A5.89 5.89 0 0 1 6 12c0-.65.096-1.275.287-1.875.192-.6.463-1.142.813-1.625a.76.76 0 0 1 .638-.338c.274-.008.52.096.737.313.183.183.28.412.287.687.009.275-.062.538-.212.788-.183.3-.32.62-.413.963A4.18 4.18 0 0 0 8 12c0 .383.046.746.137 1.088.092.341.23.662.413.962.15.25.217.517.2.8a1.04 1.04 0 0 1-.3.7c-.2.2-.438.292-.712.275a.793.793 0 0 1-.638-.35Zm8.425.05a.977.977 0 0 1-.287-.688 1.421 1.421 0 0 1 .212-.787c.183-.3.32-.62.413-.963A4.18 4.18 0 0 0 16 12c0-.383-.046-.746-.137-1.088a3.652 3.652 0 0 0-.413-.962 1.367 1.367 0 0 1-.2-.8 1.04 1.04 0 0 1 .3-.7.93.93 0 0 1 .712-.288.76.76 0 0 1 .638.338c.35.483.62 1.025.813 1.625.191.6.287 1.225.287 1.875 0 .65-.096 1.267-.288 1.85a6.186 6.186 0 0 1-.812 1.625c-.15.217-.367.33-.65.338a.946.946 0 0 1-.725-.288Zm2.85 2.85a1.091 1.091 0 0 1-.313-.7 1.064 1.064 0 0 1 .238-.775 8.219 8.219 0 0 0 1.25-2.262c.3-.825.45-1.705.45-2.638a7.854 7.854 0 0 0-1.675-4.9 1.15 1.15 0 0 1-.262-.763 1.02 1.02 0 0 1 .312-.712.916.916 0 0 1 1.375.05c.7.867 1.25 1.833 1.65 2.9.4 1.067.6 2.208.6 3.425 0 1.2-.2 2.338-.6 3.412-.4 1.075-.95 2.046-1.65 2.913a.916.916 0 0 1-1.375.05Z'
      />
    </g>
  </svg>
)
export default SvgRadio
