import { ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

type TSkeletonProps = {
  width?: number
  height?: number
  rounded?: 'none' | 'lg' | 'md' | 'sm' | 'xs' | 'full'
  className?: string
  unit?: 'rem' | 'em' | 'px' | '%'
}

const skeletonStyles = {
  rounded: {
    none: 'rounded-none',
    lg: 'rounded-lg',
    md: 'rounded-md',
    sm: 'rounded-sm',
    xs: 'rounded-xs',
    full: 'rounded-full',
  },
}

export const UI_Skeleton = (props: TSkeletonProps): ReactElement => {
  const {
    width = 5,
    height = 5,
    rounded = 'none',
    unit = 'rem',
    className,
  } = props

  return (
    <div
      style={{ width: `${width}${unit}`, height: `${height}${unit}` }}
      className={twMerge(
        'animate-pulse duration-75 bg-slate-200',
        skeletonStyles.rounded[rounded],
        className,
      )}
    />
  )
}
