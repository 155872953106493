import { libraryToApiEndpointMapper } from '../containers/libraries/comp/CardItem'
import { imageUrl } from '@/tools'
import { TApiImageEndpoint, TCategoryType } from '@/types'

type TMusicImageArgs = {
  type: TCategoryType
  uuid: string | undefined
  id: number | undefined
}

export const musicImage = (args: TMusicImageArgs): string => {
  const { type, uuid, id } = args
  if (!id && !uuid) return ''
  const _id = String(type === 'track' ? uuid : id)
  const _imageType =
    type === 'track'
      ? 'songs'
      : (libraryToApiEndpointMapper[
          type as keyof typeof libraryToApiEndpointMapper
        ] as TApiImageEndpoint)
  return imageUrl(String(_id), _imageType)
}
