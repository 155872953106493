/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  buildCreateApi,
  coreModule,
  reactHooksModule,
} from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'
import { baseQueryWithReAuth } from '../tools'
import { TArtist, TAlbum, TMusic, TPlaylist } from '@/types'
import { ParameterNormalize, TParams, objectToQueryString } from '@/tools'

export type TRecentSearch = {
  phrase: string
  user_id: number
  uuid: string
}
export type TTrending = {
  key: string
  doc_count: number
}

export type TSearch = {
  artists?: Array<TArtist>
  albums?: Array<TAlbum>
  songs?: Array<TMusic>
  playlists?: Array<TPlaylist>
  // recent_searches?: Array<TRecentSearch>
  // trending?: Array<TTrending>
}

type TResult = TSearch

type TBody = {
  body: {
    q: string
    type?: string
  }
  params: TParams
}

export type TExternalSearchResult = {
  items: Array<{
    media: Array<{
      text: string
      href: string
    }>
    image: string
    text: string
  }>
}

export type TLibraryResult = {
  items: {
    last_favorite_song: TMusic
    last_modified_playlist: TPlaylist
    last_favorite_album: TAlbum
    last_played_song: TMusic
    last_followed_artist: TArtist
  }
  total: number
}

type TExternalSearchParams = {
  q?: string
}

const createApi = buildCreateApi(
  coreModule(),
  reactHooksModule({ unstable__sideEffectsInRender: true }),
)

export const searchApi = createApi({
  reducerPath: 'api/search',
  baseQuery: baseQueryWithReAuth,
  extractRehydrationInfo(action: any, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
  endpoints: (builder) => ({
    library: builder.query<TLibraryResult, TParams>({
      query: (params) =>
        `/library${objectToQueryString(ParameterNormalize(params))}`,
    }),
    search: builder.mutation<TResult, TBody>({
      query: ({ body }) => ({
        url: 'api/search/',
        method: 'GET',
        params: { q: body.q },
      }),
      extraOptions: {
        delay: 1500,
      },
    }),
    externalSearch: builder.query<TExternalSearchResult, TExternalSearchParams>(
      {
        query: (params) =>
          `${window.location.origin}/api/search${objectToQueryString(
            ParameterNormalize(params),
          )}`,
      },
    ),
  }),
})

export const {
  useLibraryQuery,
  useLazyLibraryQuery,
  useSearchMutation,
  useExternalSearchQuery,
  useLazyExternalSearchQuery,
  util: { getRunningQueriesThunk: getRunningSearchQueriesThunk },
} = searchApi

export const { search, externalSearch, library } = searchApi.endpoints
