import { twMerge } from 'tailwind-merge'
import { Element } from '@/types'

type TUI_HRProps = {
  className?: string
}

export const UI_HR = (props: TUI_HRProps): Element => {
  const { className } = props
  return (
    <div
      className={twMerge(
        'opacity-60 bg-border-border-inactive relative w-full h-[1px]',
        className,
      )}
    />
  )
}
