import { ChangeEvent, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import toast from 'react-hot-toast'
import { UI_Image, UI_Typography, iconType, UI_IconButton } from '@/ui'
import { Element } from '@/types'
import { getImageAsFile, toBase64 } from '@/tools'
import defaultImage from '@/public/images/Art30.png'
import { useTranslate } from '@/hooks'

export type TUploadImageOutput = {
  src?: string
  file?: File
  files?: FileList
  base64?: string
}

type TUploadImage = {
  className?: string
  onChange: ({ src, file, base64 }: TUploadImageOutput) => void
  defaultSrc?: string
  isBackgroundImage?: boolean
  placeholder?: string
  uploadIcon?: iconType
  iconBorderLess?: boolean
  uploadSize?: number
  isMultiple?: boolean
}

export const UploadImage = (props: TUploadImage): Element => {
  const {
    className,
    onChange,
    defaultSrc = '',
    isBackgroundImage = true,
    placeholder,
    uploadIcon = 'Camera',
    iconBorderLess = false,
    uploadSize = 500000,
    isMultiple = false,
  } = props
  const { translate } = useTranslate()
  const [src, setSrc] = useState<string>(defaultSrc)

  useEffect(() => {
    if (!defaultImage) {
      getImageAsFile(defaultImage).then((res) => {
        toBase64(res).then((res) => {
          setSrc(String(res))
        })
      })
    }
  }, [])

  const handleSelectedFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0] as File
    const files = e?.target?.files as FileList
    if (!file) return
    if (!isMultiple && file?.size > uploadSize) {
      toast.error(
        translate('playlist.image-size-error', { size: uploadSize / 1000 }),
      )
      return
    }
    if (!isMultiple) {
      toBase64(file).then((res) => {
        setSrc(String(res))
        onChange({ src, file, base64: String(res) })
      })
    } else {
      onChange({ files })
    }
  }

  const commonClass = 'absolute right-1.5 bottom-1.5 p-1.5 cursor-pointer'

  return (
    <div className={twMerge('relative', !isBackgroundImage && 'w-full')}>
      {isBackgroundImage ? (
        <UI_Image
          className={twMerge(className, ' object-cover')}
          src={src}
          width={100}
          height={100}
        />
      ) : (
        <div className={twMerge('flex items-center', className)}>
          <UI_Typography
            variant='enLabelRegularLabel1'
            className='ps-2 text-content-content-tertiary'
          >
            {placeholder}
          </UI_Typography>
        </div>
      )}
      <UI_IconButton
        borderLess={iconBorderLess}
        className={twMerge(
          commonClass,
          ' bg-cards-glass-secondary2 border-border-border-primary z-9',
        )}
        icon={{
          iconType: uploadIcon,
          className: 'w-4 h-4',
        }}
      />
      <input
        type='file'
        multiple={isMultiple}
        className={twMerge(
          'absolute right-0 bottom-0 w-full h-full opacity-0 z-10',
        )}
        onChange={handleSelectedFile}
      />
    </div>
  )
}
