import { useEffect, useMemo, useState } from 'react'
import { useIndexedDB } from '.'
import { TIndexedDB, TMusic } from '@/types'
import { selectedSong, useAppSelector } from '@/store'

type TUseMyMusicOutput = {
  isMyMusic: boolean
  imagePath: string
  myMusic: TMusic['my_music']
}

export const useMyMusic = (
  my_music?: TMusic['my_music'],
): TUseMyMusicOutput => {
  const [imagePath, setImagePath] = useState<string>('')
  const { getIndexedDB } = useIndexedDB()
  const selectedTrack = useAppSelector(selectedSong)
  const myMusic =
    my_music ?? selectedTrack?.my_music ?? ({} as TMusic['my_music'])
  const isMyMusic = Boolean(myMusic?.id)

  useEffect(() => {
    if (isMyMusic) {
      getIndexedDB(String(myMusic?.id)).then((img) => {
        setImagePath(
          String(
            (img as TIndexedDB)?.artistImage || '/images/avatar/myAvatar.svg',
          ),
        )
      })
    }
  }, [selectedTrack])

  const _isMyMusic = useMemo(() => isMyMusic, [selectedTrack])

  const _imagePath = useMemo(() => imagePath, [imagePath])

  const _myMusic = useMemo(() => myMusic, [selectedTrack])

  return { isMyMusic: _isMyMusic, imagePath: _imagePath, myMusic: _myMusic }
}
