import * as React from 'react'
import type { SVGProps } from 'react'
const SvgEyeViewFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Eye_View-filled_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Eye_View-filled_svg__a)'>
      <path
        fill={props.color}
        d='M12 16c1.25 0 2.312-.438 3.187-1.313.875-.874 1.313-1.937 1.313-3.187 0-1.25-.438-2.313-1.313-3.188C14.312 7.439 13.25 7 12 7c-1.25 0-2.313.438-3.188 1.313C7.937 9.187 7.5 10.25 7.5 11.5c0 1.25.437 2.313 1.312 3.188C9.687 15.562 10.75 16 12 16Zm0-1.8c-.75 0-1.388-.262-1.913-.787A2.604 2.604 0 0 1 9.3 11.5c0-.75.262-1.387.787-1.912A2.604 2.604 0 0 1 12 8.8c.75 0 1.387.262 1.912.787.525.526.788 1.163.788 1.913s-.263 1.387-.788 1.912A2.604 2.604 0 0 1 12 14.2Zm0 4.8c-2.317 0-4.434-.613-6.35-1.837-1.917-1.226-3.367-2.88-4.35-4.963a.812.812 0 0 1-.1-.313 2.932 2.932 0 0 1 0-.774.812.812 0 0 1 .1-.313c.983-2.083 2.433-3.738 4.35-4.963C7.566 4.614 9.683 4 12 4c2.316 0 4.433.612 6.35 1.838 1.916 1.224 3.366 2.879 4.35 4.962.05.083.083.188.1.313a2.925 2.925 0 0 1 0 .774.812.812 0 0 1-.1.313c-.983 2.083-2.433 3.738-4.35 4.963C16.433 18.387 14.316 19 12 19Z'
      />
    </g>
  </svg>
)
export default SvgEyeViewFilled
