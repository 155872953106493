import { ReactNode, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Element } from '@/types'
import { UI_Modal, UI_Typography } from '@/ui'
import { useMobile, useSetting, useTranslate, useUser } from '@/hooks'
import { Login } from '@/containers/login/Login'

type TLoginDialogProps = {
  children: (handleClick: (callback?: () => void) => void) => ReactNode
}

export const LoginDialog = (props: TLoginDialogProps): Element | null => {
  const { children } = props
  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const { isLogin } = useUser()
  const { isForceLogin } = useSetting()
  const isMobile = useMobile()
  const { translate } = useTranslate()

  const handleToggleDialog = (callback?: () => void) => {
    if (isForceLogin && !isLogin) {
      setIsOpenDialog((x) => !x)
    } else {
      callback?.()
    }
  }

  return (
    <>
      {children(handleToggleDialog)}
      {isOpenDialog && (
        <UI_Modal
          title={
            <UI_Typography variant='enDesktopTitle4'>
              {translate('login.sign-in-for-streaming')}
            </UI_Typography>
          }
          isOpen
          onClose={handleToggleDialog}
          className={twMerge(!isMobile && '!min-w-fit')}
        >
          <Login inModal onCloseModal={handleToggleDialog} />
        </UI_Modal>
      )}
    </>
  )
}
