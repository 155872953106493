import { Element } from '@/types'
import { UI_Typography } from '@/ui'

type TTimeOrCounterProps = {
  value: number | null | string
}

export const TimeOrCounter = (props: TTimeOrCounterProps): Element | null => {
  const { value } = props
  if (!value) return null
  return (
    <UI_Typography
      component='time'
      variant='enBodyRegularText2'
      className='text-content-content-tertiary'
    >
      {value}
    </UI_Typography>
  )
}
