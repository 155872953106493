import * as React from 'react'
import type { SVGProps } from 'react'
const SvgPodcast = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Podcast_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Podcast_svg__a)'>
      <path
        fill={props.color}
        d='M4.425 18.525a10.469 10.469 0 0 1-1.775-2.987A9.627 9.627 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12c0 1.25-.217 2.433-.65 3.55a10.42 10.42 0 0 1-1.775 3 .88.88 0 0 1-.675.3.96.96 0 0 1-.7-.3.977.977 0 0 1-.287-.688c-.009-.274.079-.52.262-.737a8.386 8.386 0 0 0 1.337-2.35A7.838 7.838 0 0 0 20 12c0-2.233-.775-4.125-2.325-5.675C16.125 4.775 14.233 4 12 4c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 .983.162 1.904.487 2.762.325.859.78 1.638 1.363 2.338.183.217.27.462.263.737a1.02 1.02 0 0 1-.313.713c-.2.2-.433.296-.7.287a.922.922 0 0 1-.675-.312Zm2.825-2.85a6.678 6.678 0 0 1-.912-1.7A5.717 5.717 0 0 1 6 12c0-1.667.583-3.083 1.75-4.25C8.917 6.583 10.333 6 12 6c1.667 0 3.083.583 4.25 1.75C17.417 8.917 18 10.333 18 12c0 .7-.113 1.367-.337 2-.226.633-.53 1.2-.913 1.7a.795.795 0 0 1-.663.325c-.274 0-.52-.108-.737-.325a.963.963 0 0 1-.287-.675c-.009-.267.07-.525.237-.775A3.829 3.829 0 0 0 16 12c0-1.1-.392-2.042-1.175-2.825C14.042 8.392 13.1 8 12 8s-2.042.392-2.825 1.175C8.392 9.958 8 10.9 8 12c0 .433.063.833.188 1.2.124.367.295.717.512 1.05.167.25.242.512.225.787a1.025 1.025 0 0 1-.3.688.93.93 0 0 1-.713.287.829.829 0 0 1-.662-.337ZM12 22a.968.968 0 0 1-.713-.288A.968.968 0 0 1 11 21v-7.275c-.3-.183-.542-.42-.725-.713A1.863 1.863 0 0 1 10 12c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 12 10c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412 0 .383-.092.725-.275 1.025-.183.3-.425.533-.725.7V21c0 .283-.096.52-.287.712A.968.968 0 0 1 12 22Z'
      />
    </g>
  </svg>
)
export default SvgPodcast
