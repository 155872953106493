import * as React from 'react'
import type { SVGProps } from 'react'
const SvgHomeFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Home_-_filled_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Home_-_filled_svg__a)'>
      <path
        fill={props.color}
        d='M4 19v-9a1.986 1.986 0 0 1 .8-1.6l6-4.5c.35-.267.75-.4 1.2-.4.45 0 .85.133 1.2.4l6 4.5A1.985 1.985 0 0 1 20 10v9c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 18 21h-3a.968.968 0 0 1-.713-.288A.968.968 0 0 1 14 20v-5a.968.968 0 0 0-.287-.713A.968.968 0 0 0 13 14h-2a.968.968 0 0 0-.713.287A.968.968 0 0 0 10 15v5c0 .283-.096.52-.287.712A.967.967 0 0 1 9 21H6c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 4 19Z'
      />
    </g>
  </svg>
)
export default SvgHomeFilled
