import { ReactNode } from 'react'
import { useInfiniteScroll } from '@/hooks'
import { Element } from '@/types'

type TInfiniteScrollProps = {
  onLoadMore: () => void
  children: ReactNode
  className?: string
}

export const InfiniteScroll = (props: TInfiniteScrollProps): Element => {
  const { children, onLoadMore, className } = props

  const { observerTarget } = useInfiniteScroll({
    callback: () => onLoadMore(),
  })

  return (
    <section className={className}>
      {children}
      <div ref={observerTarget} />
    </section>
  )
}
