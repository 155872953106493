/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  buildCreateApi,
  coreModule,
  reactHooksModule,
} from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'
import { baseQueryWithReAuth } from '../tools'
import { TAlbum } from '@/types'
import { ParameterNormalize, TParams, objectToQueryString } from '@/tools'

type TResult = TAlbum

type TResults = {
  results: Array<TAlbum>
  total: number
}

const createApi = buildCreateApi(
  coreModule(),
  reactHooksModule({ unstable__sideEffectsInRender: true }),
)

export const albumApi = createApi({
  reducerPath: 'api/albums',
  baseQuery: baseQueryWithReAuth,
  extractRehydrationInfo(action: any, { reducerPath }: any) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
  tagTypes: [
    'AlbumById',
    'Albums',
    'FavoriteAlbums',
    'AlbumsByArtistId',
    'AlbumSongs',
  ],
  endpoints: (builder) => ({
    getAlbumById: builder.query<TResult, number>({
      query: (albumId) => `/api/albums/${albumId}/`,
      providesTags: ['AlbumById'],
    }),

    getAlbumSongs: builder.query({
      query: (id) => `/api/albums/${id}/songs/`,
      providesTags: ['AlbumSongs'],
    }),

    getAlbums: builder.query<TResults, TParams>({
      query: (params) => `/albums`,
      providesTags: ['Albums'],
    }),
    getAlbumsWithQuery: builder.query<TResults, string>({
      query: (query) => `/albums${query}`,
      providesTags: ['Albums'],
    }),

    getFavoriteAlbums: builder.query<TResults, TParams>({
      query: (params) => `/albums/favorites`,
      providesTags: ['FavoriteAlbums'],
    }),

    getAlbumsByArtistId: builder.query<TResults, number>({
      query: (artistId) =>
        `/albums?$limit=50&$sortby=-id&artists.id=${artistId}`,
      providesTags: ['AlbumsByArtistId'],
    }),

    followAlbum: builder.mutation<TResult, number>({
      query: (albumId) => {
        return {
          url: `/follow/album/${albumId}`,
          method: 'POST',
        }
      },
    }),

    unFollowAlbum: builder.mutation<TResult, number>({
      query: (albumId) => {
        return {
          url: `/follow/album/${albumId}`,
          method: 'DELETE',
        }
      },
    }),

    favoriteAlbum: builder.mutation<TResult, number>({
      query: (albumId) => {
        return {
          url: `/favorite/album/${albumId}`,
          method: 'POST',
        }
      },
      invalidatesTags: ['FavoriteAlbums'],
    }),

    unFavoriteAlbum: builder.mutation<TResult, number>({
      query: (albumId) => {
        return {
          url: `/favorite/album/${albumId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['FavoriteAlbums'],
    }),
  }),
})

export const {
  useGetAlbumSongsQuery,
  useGetAlbumsQuery,
  useGetAlbumsWithQueryQuery,
  useLazyGetAlbumsQuery,
  useGetAlbumByIdQuery,
  useLazyGetAlbumByIdQuery,
  useGetFavoriteAlbumsQuery,
  useLazyGetFavoriteAlbumsQuery,
  useGetAlbumsByArtistIdQuery,
  useLazyGetAlbumsByArtistIdQuery,
  useFollowAlbumMutation,
  useUnFollowAlbumMutation,
  useFavoriteAlbumMutation,
  useUnFavoriteAlbumMutation,
  util: { getRunningQueriesThunk: getRunningAlbumsQueriesThunk },
} = albumApi

export const {
  getAlbums,
  getAlbumById,
  getFavoriteAlbums,
  getAlbumsByArtistId,
  followAlbum,
  unFollowAlbum,
  favoriteAlbum,
  unFavoriteAlbum,
  getAlbumSongs,
} = albumApi.endpoints
