'use client'
import { useRef, useEffect, useState } from 'react'

type TUseScrollOutput = {
  isBottom: boolean
  isTop: boolean
}

export const useScroll = (): TUseScrollOutput => {
  const [isBottom, setIsBottom] = useState(false)
  const [isTop, setIsTop] = useState<boolean>(true)

  const node = useRef<HTMLDivElement>(
    document.getElementById('page-body') as HTMLDivElement,
  )

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = node.current
    if (scrollTop + clientHeight === scrollHeight) {
      setIsBottom(true)
    } else {
      setIsBottom(false)
    }
    setIsTop(scrollTop === 0)
  }

  useEffect(() => {
    if (node.current) {
      node.current.addEventListener('scroll', handleScroll)
    }
    return () => node.current.removeEventListener('scroll', handleScroll)
  }, [handleScroll])
  return { isBottom, isTop }
}
