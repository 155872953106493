import { twMerge } from 'tailwind-merge'
import { Library } from './Library'
// import { RecentSearches } from './RecentSearches'
import { Result } from './Result'
// import { Trending } from './Trending'
import { useSearch } from './useSearch'
// import { RecentSearches } from './RecentSearches'
import { UI_IconSvg } from '@/ui'
import { useTranslate } from '@/hooks'
import { Element } from '@/types'

type TSearchProps = {
  fullWidth?: boolean
}

export const Search = (props: TSearchProps): Element => {
  const { fullWidth = false } = props
  // const { isLogin } = useUser()
  const { translate } = useTranslate()

  const {
    searchData,
    searchLoading,
    globalState,
    handleSearch,
    handleKeyDown,
    toggleSubMenu,
    current,
    isOpenSubMenu,
    toggleOpenSubMenu,
  } = useSearch()

  return (
    <div
      ref={current}
      className={twMerge(
        'relative rounded-31xl lg:w-[460px] md:w-[300px] w-full flex items-center py-2 px-4 gap-3 border-[1px] border-solid border-content-content-primary1',
        fullWidth && 'md:w-full w-full',
      )}
    >
      <UI_IconSvg component='Search' onClick={toggleSubMenu} />
      <div
        className='flex items-center w-full h-full'
        onClick={toggleOpenSubMenu}
      >
        <div className='w-full h-full'>
          <input
            value={globalState?.searchInHeader?.query ?? ''}
            type='text'
            placeholder={translate('global.search')}
            className='font-peyda w-full h-full text-white placeholder-content-content-primary1 border-none outline-none bg-inherit'
            onChange={handleSearch}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
      {isOpenSubMenu && (
        <div className='absolute flex flex-col justify-center px-4 gap-6 top-10 z-10 left-0 rounded-radius-300 bg-cards-glass-secondary2 backdrop-blur-2xl w-full h-auto max-h-[650px] overflow-auto py-6 text-lg text-content-content-primary border-[1px] border-solid'>
          <Library />
          <div className='h-64 overflow-y-auto'>
            <Result {...{ searchData, searchLoading }} />
          </div>
          {/* {isLogin && (
            <RecentSearches
              data={searchData?.items?.recent_searches ?? []}
              loading={searchLoading}
            />
          )}

          <Trending
            data={searchData?.items?.trending ?? []}
            loading={searchLoading}
          /> */}
        </div>
      )}
    </div>
  )
}
