import {
  MouseEvent,
  MutableRefObject,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react'
import Hls from 'hls.js'
import toast from 'react-hot-toast'
import {
  adStreamUrlSelector,
  isAdPlayingSelector,
  isPlayingSelector,
  listenNextSongs,
  selectAllSongs,
  selectedSong,
  setAdStreamUrl,
  setIsAdPlaying,
  setIsPlaying,
  useAppContext,
  useAppDispatch,
  useAppSelector,
} from '@/store'
import { TIndexedDB, changeInput } from '@/types'
import { useIndexedDB, usePlayerActions } from '@/hooks'

type TUsePlayerOutput = {
  downloaded: number
  playVideo: () => void
  handlePause: () => void
  handleLoop: () => void
  isLooped: boolean
  currentTime: number | undefined
  durationTime: number | undefined
  percentage: number
  seekWidth: number
  seekBarRef: RefObject<HTMLDivElement>
  playerRef: MutableRefObject<HTMLMediaElement | null>
  isMuted: boolean
  handleMutedChange: (value: boolean) => void
  handleVolumeChange: (event: changeInput) => void
  handleSeekBarClick: (event: MouseEvent<HTMLDivElement>) => void
  handleFullScreen: () => void
  handleNextSong: () => void
  handleBackSong: () => void
  handleShuffle: () => void
  handleLyrics: () => void
}

let player: Promise<void> | null

export const usePlayer = (
  playerRef: MutableRefObject<HTMLMediaElement | null>,
): TUsePlayerOutput => {
  const seekBarRef = useRef<HTMLDivElement>(null)
  const { handleNextSong, handleBackSong, handleFullScreen } = usePlayerActions(
    {},
  )
  const [, setUpdate] = useState(0)
  const [downloaded, setDownloaded] = useState(0)
  const { globalState, changeGlobalState } = useAppContext()
  const isLooped = Boolean(
    !playerRef.current || playerRef.current?.loop || globalState?.isLoop,
  )
  const currentTime = playerRef.current?.currentTime
  const durationTime = playerRef.current?.duration
  const percentage =
    Number(playerRef.current?.currentTime) / Number(playerRef.current?.duration)
  const seekWidth = Number(seekBarRef.current?.getBoundingClientRect().width)
  const intervalRef = useRef<NodeJS.Timeout>()
  const [isMuted, setIsMuted] = useState(false)
  const latestVolume = useRef<number>(1)
  const { getIndexedDB } = useIndexedDB()
  const allSongs = useAppSelector(selectAllSongs)
  const selectedTrack = useAppSelector(selectedSong)
  const listenNext = useAppSelector(listenNextSongs)
  const adStreamUrl = useAppSelector(adStreamUrlSelector)
  const isPlaying = useAppSelector(isPlayingSelector)
  const isAdPlaying = useAppSelector(isAdPlayingSelector)
  const dispatch = useAppDispatch()
  const isFirstLoaded = useRef(true)

  const setCurrentTime = () => {
    if (playerRef.current) {
      localStorage.setItem('currentTime', String(playerRef.current.currentTime))
    }
  }

  const getCurrentTime = () => {
    if (playerRef.current) {
      const _currentTime = localStorage.getItem('currentTime')
      if (Number(_currentTime) > 0) {
        playerRef.current.currentTime = Number(_currentTime)
        dispatch(setIsPlaying(true))
      }
    }
    if (isFirstLoaded.current) {
      setTimeout(() => {
        dispatch(setIsPlaying(false))
        isFirstLoaded.current = false
      }, 500)
    }
  }

  const handleShuffle = () => {
    changeGlobalState?.({ key: 'isShuffle', value: !globalState?.isShuffle })
  }

  const handleLyrics = () => {
    changeGlobalState?.({ key: 'isLyrics', value: !globalState?.isLyrics })
  }

  const play = (
    afterPlay?: (player: HTMLMediaElement) => void,
    beforePlay?: (player: HTMLMediaElement) => void,
  ) => {
    console.log('play called')
    if (playerRef.current && playerRef.current !== null) {
      const p = playerRef.current as HTMLMediaElement
      if (beforePlay) beforePlay(p)
      dispatch(setIsPlaying(true))
      player = playerRef.current.play()
    }
  }

  const playVideo = () => {
    play(() => {
      setUpdate((x) => x + 1)
    })
  }

  const handlePause = () => {
    player?.then(() => {
      if (playerRef.current) playerRef.current?.pause()
      setUpdate((x) => x + 1)
      dispatch(setIsPlaying(false))
    })
  }

  const handleLoop = () => {
    if (playerRef.current) {
      playerRef.current.loop = !playerRef.current.loop
      changeGlobalState?.({ key: 'isLoop', value: playerRef.current.loop })
      setUpdate((x) => x + 1)
    }
  }

  const handleMutedChange = (value: boolean) => {
    if (playerRef.current) {
      playerRef.current.muted = value
      setIsMuted(value)

      if (value) {
        playerRef.current.volume = latestVolume.current
      }
    }
  }

  const handleVolumeChange = (event: changeInput) => {
    if (playerRef.current) {
      playerRef.current.volume = Number(event.target.value)
      latestVolume.current = Number(event.target.value)
      if (Number(event.target.value) === 0) {
        handleMutedChange(true)
      }
      if (Number(event.target.value) > 0) {
        handleMutedChange(false)
      }
    }
  }

  const playMediaWithRef = (media_stream: string) => {
    play(
      () => {
        setTimeout(() => {
          play()
        }, 1000)
      },
      (player) => {
        player.src = media_stream
      },
    )
  }

  useEffect(() => {
    const MediaElement = playerRef.current

    if (MediaElement && globalState?.isLoop) {
      MediaElement.loop = globalState?.isLoop
    }
    const handleProgress = () => {
      if (MediaElement) {
        if (!Hls.isSupported()) {
          const percent = Math.ceil(
            (MediaElement.currentTime / MediaElement.duration) * 100,
          )
          setDownloaded(percent + 5)
        } else {
          const buffered = MediaElement?.buffered?.length
          if (buffered > 0) {
            const end = MediaElement.buffered.end(buffered - 1)
            const duration = MediaElement.duration
            const percent = Math.ceil((end / duration) * 100)
            setDownloaded(percent)
          }
        }
      }
    }
    if (MediaElement) {
      MediaElement.addEventListener('ended', handleNextSong)
      MediaElement.addEventListener('progress', handleProgress)
    }
    return () => {
      if (MediaElement) {
        MediaElement.removeEventListener('ended', handleNextSong)
        MediaElement.removeEventListener('progress', handleProgress)
      }
    }
  }, [playerRef, listenNext, allSongs, selectedTrack, adStreamUrl])

  useEffect(() => {
    const hls = new Hls({
      maxBufferLength: process.env.NODE_ENV === 'production' ? 15 : 1,
      maxBufferSize: process.env.NODE_ENV === 'production' ? 15 : 1,
      maxMaxBufferLength: process.env.NODE_ENV === 'production' ? 15 : 1,
    })
    if (selectedTrack) {
      getCurrentTime()
      try {
        const isMusicVideo = selectedTrack?.medias?.video
        let media_stream = isMusicVideo
          ? selectedTrack?.hls
          : selectedTrack?.hls

        // if (selectedTrack?.id) {
        //   getIndexedDB(String(selectedTrack?.id)).then((music) => {
        //     media_stream = String((music as TIndexedDB).value)
        //     playMediaWithRef(media_stream)
        //   })
        // } else {
        if (playerRef?.current && playerRef.current) {
          playerRef.current
          if (
            !Hls.isSupported() &&
            playerRef?.current?.canPlayType('application/vnd.apple.mpegurl')
          ) {
            console.log('here!')
            playMediaWithRef(media_stream)
          } else {
            if (selectedTrack?.id) {
              hls.loadSource(media_stream)
              hls.attachMedia(playerRef.current)
              play()
            }
          }
        }
        // }
      } catch (error) {
        toast.error('This music is broken')
        handleNextSong()
      }
    }
    return () => {
      try {
        hls.stopLoad()
      } catch {}
    }
  }, [selectedTrack?.id])

  useEffect(() => {
    if (adStreamUrl && !isAdPlaying) {
      const isMusicVideo = selectedTrack?.medias?.video
      let media_stream = isMusicVideo
        ? `${process.env.NEXT_CONTENT_URL}/${selectedTrack?.video_stream}`
        : `${process.env.NEXT_CONTENT_URL}/${selectedTrack?.song_stream}`

      fetch(`${process.env.NEXT_CONTENT_URL}${adStreamUrl}`, {
        cache: 'force-cache',
      })
        .then((res) => res.blob())
        .then((blob) => {
          const objectURL = URL.createObjectURL(blob)
          media_stream = objectURL
          playMediaWithRef(media_stream)
        })
        .finally(() => {
          dispatch(setIsAdPlaying(true))
          dispatch(setAdStreamUrl(null))
        })
    }
  }, [adStreamUrl])

  useEffect(() => {
    if (isPlaying) {
      if (playerRef.current) {
        play()

        intervalRef.current = setInterval(() => {
          if (!playerRef.current?.paused) {
            setUpdate((x) => x + 1)
            const _currentTime = localStorage.getItem('currentTime')
            if (_currentTime === 'null') {
              setTimeout(() => {
                setCurrentTime()
              }, 3000)
            } else {
              setCurrentTime()
            }
          }
        }, 1000)
      }
    } else {
      if (playerRef.current) {
        player?.then(() => {
          playerRef.current?.pause()
          setUpdate((x) => x + 1)
        })
      }
      clearInterval(intervalRef.current)
    }
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [isPlaying])

  const handleSeekBarClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    if (playerRef.current && playerRef.current.duration) {
      try {
        const rectangleElement = event.target as HTMLDivElement
        const clickX = Math.round(
          event.clientX - rectangleElement.getBoundingClientRect().left,
        )
        playerRef.current.currentTime =
          (Number(durationTime) * clickX) / seekWidth

        setCurrentTime()
      } catch (error) {}
    }
  }

  return {
    downloaded,
    playVideo,
    handlePause,
    handleLoop,
    isLooped,
    currentTime,
    durationTime,
    percentage,
    seekWidth,
    seekBarRef,
    playerRef,
    isMuted,
    handleMutedChange,
    handleVolumeChange,
    handleSeekBarClick,
    handleFullScreen,
    handleNextSong,
    handleBackSong,
    handleShuffle,
    handleLyrics,
  }
}
