import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSearchFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={props.color}
      fillRule='evenodd'
      d='M19.178 10.59c0 1.983-.672 3.81-1.802 5.264l4.36 4.358a.904.904 0 1 1-1.28 1.279l-4.333-4.333a8.59 8.59 0 1 1 3.055-6.569Zm-15.46 0a6.871 6.871 0 1 0 13.743-.001 6.871 6.871 0 0 0-13.743 0Zm6.909 4.457a4.52 4.52 0 1 0 0-9.041 4.52 4.52 0 0 0 0 9.04Z'
      clipRule='evenodd'
    />
  </svg>
)
export default SvgSearchFilled
