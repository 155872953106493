import { useEffect, useState } from 'react'

type TTimerProps = {
  duration: number
  onFinished?: () => void
}

export const Timer = (props: TTimerProps): string => {
  const { duration = 60 * 1, onFinished } = props
  const [timer, setTimer] = useState('00:00')

  useEffect(() => {
    let newTimer: number = duration
    let minutes
    let seconds
    const _timer = setInterval(() => {
      minutes = Number.parseInt(String(newTimer / 60), 10)
      seconds = Number.parseInt(String(newTimer % 60), 10)
      minutes = minutes < 10 ? '0' + minutes : minutes
      seconds = seconds < 10 ? '0' + seconds : seconds
      if (--newTimer < 0) {
        clearInterval(_timer)
        onFinished?.()
      }
      setTimer(`${minutes}:${seconds}`)
    }, 1000)
    return () => {
      clearInterval(_timer)
      newTimer = 0
      minutes = undefined
      seconds = undefined
    }
  }, [])

  return timer
}
