import { UI_Button, UI_Image, UI_Typography, UI_IconButton } from '@/ui'
import { errorHandler, imageUrl } from '@/tools'
import { Element, TError, TMusic, TPlaylist } from '@/types'
import { useHover, useLocale, useMobile, useTranslate } from '@/hooks'
import {
  selectAllPlaylists,
  useAddSongToPlaylistMutation,
  useAppSelector,
  useRemoveSongFromPlaylistMutation,
} from '@/store'

type TSongsItemProps = TMusic & {
  latestPlaylist: Partial<TPlaylist>
}

export const SongsItem = (props: TSongsItemProps): Element => {
  const {
    id,
    name_en: english_name,
    name: persian_name,
    artists,
    latestPlaylist,
  } = props
  const { translate } = useTranslate()
  const isMobile = useMobile()
  const [hoverRef, isHovered] = useHover<HTMLButtonElement>()
  const { isPersian } = useLocale()
  const [executeAddSongToPlaylist, { isLoading: loadingAddSongToPlaylist }] =
    useAddSongToPlaylistMutation()
  const [
    executeDeleteSongFromMyPlaylist,
    { isLoading: loadingDeleteSongFromMyPlaylist },
  ] = useRemoveSongFromPlaylistMutation()
  const playlists = useAppSelector(selectAllPlaylists)

  const isAdded = playlists?.[Number(latestPlaylist.id)]?.includes(id)

  const handleAddOrRemoveToPlaylist = () => {
    if (isAdded) {
      executeDeleteSongFromMyPlaylist({
        id: Number(latestPlaylist.id),
        body: {
          song_id: id,
        },
      }).catch((error: TError) => {
        errorHandler(error)
      })
    } else {
      executeAddSongToPlaylist({
        id: Number(latestPlaylist.id),
        body: {
          song_id: id,
        },
      }).catch((error: TError) => {
        errorHandler(error)
      })
    }
  }

  return (
    <div className='flex flex-row items-center gap-4'>
      <div className='flex flex-row items-center justify-start'>
        <UI_Image
          className='rounded-lg object-cover'
          src={imageUrl(id)}
          width={72}
          height={72}
        />
      </div>
      <div className='flex-1 flex flex-col gap-3'>
        <UI_Typography
          variant='enDesktopTitle5'
          component='b'
          className='ellipsis lg:max-w-[250px] max-w-[160px]'
        >
          {isPersian ? persian_name : english_name}
        </UI_Typography>
        <UI_Typography
          variant='enLabelRegularLabel1'
          className='ellipsis lg:max-w-[250px] max-w-[160px]'
        >
          {artists?.[0]?.[isPersian ? 'persian_name' : 'english_name']}
        </UI_Typography>
      </div>
      <div className='flex items-center gap-[18px] justify-end'>
        {!isAdded ? (
          isMobile ? (
            <UI_IconButton
              icon={{ iconType: 'Add' }}
              onClick={handleAddOrRemoveToPlaylist}
              loading={loadingAddSongToPlaylist}
            />
          ) : (
            <UI_Button
              variant='secondary'
              onClick={handleAddOrRemoveToPlaylist}
              loading={loadingAddSongToPlaylist}
            >
              {translate('playlist.add')}
            </UI_Button>
          )
        ) : isMobile ? (
          <UI_IconButton icon={{ iconType: 'Check' }} />
        ) : (
          <UI_Button
            variant='secondary'
            onClick={handleAddOrRemoveToPlaylist}
            startAdornment={{ icon: isHovered ? 'Delete' : 'Check' }}
            ref={hoverRef}
            loading={loadingDeleteSongFromMyPlaylist}
          >
            {translate(isHovered ? 'playlist.remove' : 'playlist.added')}
          </UI_Button>
        )}
      </div>
    </div>
  )
}
