'use client'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { twMerge } from 'tailwind-merge'
import 'swiper/css'
import 'swiper/css/pagination'
import Link from 'next/link'
import style from './ui_carousel.module.css'
import { UI_Image } from '@/ui'
import { Element } from '@/types'

export type TSlider = {
  id: number
  image_url: string
  link: string
  title: string
}

type TUI_CarouselProps = {
  carouselClassName?: string
  slides: Array<TSlider>
  width: number
  height: number
}

export const UI_Carousel = (props: TUI_CarouselProps): Element => {
  const { carouselClassName, slides, width, height } = props
  const pagination = {
    clickable: true,
    bulletActiveClass: twMerge(
      'swiper-pagination-bullet-active',
      style.swiperPaginationBulletActive,
    ),
    bulletClass: twMerge(
      'swiper-pagination-bullet',
      style.swiperPaginationBullet,
    ),
    renderBullet(_index: number, className: string) {
      return `<span class="${className}"></span>`
    },
  }
  return (
    slides && (
      <Swiper
        spaceBetween={20}
        pagination={pagination}
        modules={[Pagination]}
        className={twMerge('', [carouselClassName])}
      >
        {slides?.map((slide) => (
          <SwiperSlide key={slide.id}>
            <Link href={slide.link ?? '#'}>
              <UI_Image
                className='rounded-lg object-cover aspect-auto h-full w-full'
                alt={slide.title}
                src={slide.image_url}
                width={width}
                height={height}
              />
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    )
  )
}
