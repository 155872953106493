/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError } from 'axios'
import { deleteCookie, getCookie, setCookie } from 'cookies-next'
import { configure, makeUseAxios } from 'axios-hooks'

const axiosInstance = axios.create({
  baseURL: process.env.API_URL,
})

export const refreshToken = async (): Promise<any> => {
  const API = `${process.env.API_URL}/api/auth/token/refresh/`
  const refresh_token = getCookie('refresh_token') ?? ''
  const res = await axios
    .post(API, null, {
      headers: {
        Authorization: `Bearer ${refresh_token}`,
      },
    })
    .catch((error) => {
      if (error.response.status === 401) {
        deleteCookie('access_token')
        deleteCookie('refresh_token')
      }
      return error
    })

  const newToken = res?.data?.items?.access_token ?? ''
  if (typeof window !== 'undefined' && newToken) {
    setCookie('access_token', newToken)
    setCookie('refresh_token', res.data.items?.refresh_token)
  }
  return newToken
}

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    const originalRequest = error.config
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const access_token = await refreshToken()
      originalRequest.headers.Authorization = `Bearer ${access_token}`
      return axiosInstance(originalRequest)
    }
    return Promise.reject(error)
  },
)

configure({ axios: axiosInstance })

const handleRequestFulfilled = (config: any) => {
  const token =
    (typeof window !== 'undefined' && getCookie('access_token')) ?? ''
  if (config.headers) {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
  }

  return config
}

const handleRequestRejected = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error)
}

axiosInstance.interceptors.request.use(
  handleRequestFulfilled,
  handleRequestRejected,
)

export const useAxios = makeUseAxios({
  axios: axiosInstance,
})
