import * as React from 'react'
import type { SVGProps } from 'react'
const SvgArrowLeftBig = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Arrow_left_Big_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Arrow_left_Big_svg__a)'>
      <path
        fill={props.color}
        d='m7.825 13 4.9 4.9c.2.2.296.433.287.7-.008.267-.112.5-.312.7-.2.183-.433.28-.7.287a.916.916 0 0 1-.7-.287l-6.6-6.6a.877.877 0 0 1-.213-.325A1.107 1.107 0 0 1 4.425 12c0-.133.02-.258.062-.375A.877.877 0 0 1 4.7 11.3l6.6-6.6a.933.933 0 0 1 .687-.275c.275 0 .513.092.713.275.2.2.3.437.3.712 0 .275-.1.513-.3.713L7.825 11H19c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 19 13H7.825Z'
      />
    </g>
  </svg>
)
export default SvgArrowLeftBig
