import Image from 'next/image'
import { Element } from '@/types'

type TImageProps = {
  width?: number
  height?: number
  src: string
  alt?: string
  className?: string
  onClick?: () => void
}

export const UI_Image = (props: TImageProps): Element => {
  const {
    width = 24,
    height = 24,
    src,
    className,
    onClick,
    alt = '',
    ...otherProps
  } = props
  const classNames = `w-[${width}px] h-[${height}px] ${className}`
  return (
    <Image
      onClick={onClick}
      className={classNames}
      alt={alt}
      src={src}
      width={width}
      height={height}
      loading='lazy'
      blurDataURL='/images/background.webp'
      {...otherProps}
    />
  )
}
