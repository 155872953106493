import { Inter } from 'next/font/google'
import { twMerge } from 'tailwind-merge'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useUserAgent } from 'next-useragent'
import { Body } from './body/Body'
import style from './layout.module.css'
import { Children, Element } from '@/types'
import { useMobile, useUser } from '@/hooks'
import { Navigation } from '@/pages/global'
import { setIsMobile, useAppDispatch } from '@/store'

const inter = Inter({ subsets: ['latin'] })

type TLayoutProps = Children & {
  header?: Element | null
  mobileHeader?: Element | null
  footer?: Element | null
  isNavigation?: boolean
}

const LayoutSecondary = (props: TLayoutProps): Element => {
  const { children, footer, header, mobileHeader, isNavigation = true } = props
  const isMobile = useMobile()
  const { isLogin } = useUser()
  const { push, pathname } = useRouter()
  const dispatch = useAppDispatch()

  useEffect(() => {
    document.getElementById('page-body')?.scrollTo({
      top: 0,
    })

    if (pathname?.toLowerCase() === '/login' && isLogin) {
      push('/')
    }
  }, [pathname])

  useEffect(() => {
    window.addEventListener('resize', () => {
      setTimeout(() => {
        const ua = useUserAgent(window.navigator.userAgent)
        dispatch(setIsMobile(ua?.isMobile))
      }, 10)
    })

    return () => {
      window.removeEventListener('resize', () => {
        //
      })
    }
  }, [])

  return (
    <div
      className={twMerge(
        'font-peyda bg-base-purple bg-cover relative w-full h-[100dvh] overflow-hidden text-left text-xl text-gray-50 font-leading-none-text-sm-font-medium lg:p-4',
        'bg-[url("/images/background.png")]',
        'flex flex-col justify-between',
        inter.className,
      )}
    >
      <div
        className={twMerge(
          'lg:rounded-t-21xl lg:rounded-b-13xl bg-cards-glass-secondary w-full lg:overflow-hidden flex flex-col items-start justify-start lg:p-0 box-border lg:gap-4',
          'h-[100dvh]',
          !isMobile && 'backdrop-blur-3xl',
        )}
      >
        <div className='lg:self-stretch flex-1 flex flex-row items-start justify-start gap-2 w-full'>
          <div
            id='page-body'
            className={twMerge(
              !isMobile && 'scroll',
              style.mainLayoutSecondary,
              isMobile ? style.mainMobileScroll : 'scroll-smooth',
              'lg:h-[calc(100dvh_-_56px)] ',
              isNavigation ? 'h-[calc(100dvh_-_64px)]' : 'h-[100dvh]',
            )}
          >
            {header && (
              <div className='w-full lg:px-6 px-3 lg:pt-6 pt-3'>
                {isMobile ? mobileHeader : header}
              </div>
            )}
            <Body className='lg:px-6 px-3'>{children}</Body>
            {!isMobile && footer && (
              <div className='w-full lg:px-6 px-3'>{footer}</div>
            )}
          </div>
        </div>
      </div>
      {isMobile && isNavigation && <Navigation />}
      <div id='react-portal' className='absolute' />
    </div>
  )
}

export default LayoutSecondary
