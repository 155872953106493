import { twMerge } from 'tailwind-merge'
import { LoginType } from '../useLogin'
import { UI_Typography } from '@/ui'
import { Element } from '@/types'
import { useTranslate } from '@/hooks'

type TSwitchLoginTypeProps = {
  onChange: (type: LoginType) => void
  value: string
}

export const SwitchLoginType = (props: TSwitchLoginTypeProps): Element => {
  const { onChange, value } = props
  const { translate } = useTranslate()

  const activeClass = twMerge(
    'border-[1px] border-solid',
    'bg-content-content-tertiary border-content-content-tertiary text-cards-fill-primary',
  )

  return (
    <div
      className={twMerge(
        'flex items-center justify-between h-12 cursor-pointer w-full border-solid border-[1px] rounded',
        'border-content-content-tertiary',
      )}
    >
      <div
        className={twMerge(
          'flex-1 rounded-tl rounded-bl box-border flex items-center justify-center h-full',
          value === 'password' && activeClass,
        )}
        onClick={() => onChange('password')}
      >
        <UI_Typography variant='enBodyBoldText2'>
          {translate('global.password')}
        </UI_Typography>
      </div>
      <div
        className={twMerge(
          'flex-1 rounded-tr rounded-br box-border flex items-center justify-center h-full',
          value === 'otp' && activeClass,
        )}
        onClick={() => onChange('otp')}
      >
        <UI_Typography variant='enBodyBoldText2'>
          {translate('login.otp')}
        </UI_Typography>
      </div>
    </div>
  )
}
