import { twMerge } from 'tailwind-merge'
import { ReactNode } from 'react'
import { Children, Element } from '@/types'

type TUI_ButtonGradientProps = Children & {
  rounded?: string
  border?: string
  className?: string
  space?: string
  background?: string
  gradient?: string
  isGradient?: boolean
  onClick?: () => void
}

export const UI_ButtonGradient = (
  props: TUI_ButtonGradientProps,
): Element | ReactNode => {
  const {
    rounded = 'rounded-2xl',
    border = 'p-[1px]',
    className,
    background,
    space,
    children,
    gradient = 'background-conic-gradient',
    isGradient = true,
    onClick,
  } = props

  if (!isGradient) return children

  return (
    <div
      className={twMerge(
        'flex items-center justify-center box-border',
        gradient,
        border,
        rounded,
        className,
      )}
      onClick={onClick}
    >
      <div
        className={twMerge(
          'w-full h-full bg-cards-glass-secondary-opacity-full',
          rounded,
          background,
          space,
        )}
      >
        {children}
      </div>
    </div>
  )
}
