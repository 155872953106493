import Link from 'next/link'
import { twMerge } from 'tailwind-merge'
import { m } from 'framer-motion'
import { UI_Image, UI_Typography } from '@/ui'
import { Like } from '@/components'
import {
  isPlayingSelector,
  selectedSong,
  useAppContext,
  useAppSelector,
} from '@/store'
import { imageUrl } from '@/tools'
import { Element } from '@/types'
import { useLocale, useMobile, useMyMusic } from '@/hooks'

type TCardProps = {
  isMouseOver?: boolean
}

export const Card = (props: TCardProps): Element => {
  const { isMouseOver } = props
  const { globalState } = useAppContext()
  const selectedTrack = useAppSelector(selectedSong)
  const isPlaying = useAppSelector(isPlayingSelector)
  const {
    id,
    name_en: english_name,
    name: persian_name,
    artists,
    uuid,
    is_favorite,
    active,
    image,
  } = selectedTrack ?? {}
  const isFavorite = Boolean(is_favorite)
  const isMobile = useMobile()
  const { isPersian } = useLocale()
  const isPlayerFullScreen = Boolean(globalState?.isPlayerFullScreen)
  const isMusicVideo = selectedTrack?.medias?.video
  const transitionClass = 'transition-all duration-300 ease-in-out'
  const { isMyMusic, imagePath, myMusic } = useMyMusic()

  if (!id) {
    return <div className='flex-1 flex items-center gap-6'></div>
  }

  let imageSrc = '/images/avatar/myAvatar.svg'
  if (isMyMusic) imageSrc = imagePath
  else if (image && id) imageSrc = image

  return (
    <div
      className={twMerge(
        ' flex items-center ',
        !isMobile && isPlayerFullScreen
          ? 'justify-start'
          : 'lg:justify-start justify-between flex-1 gap-6 w-full',
        transitionClass,
        isMusicVideo &&
          !isMouseOver &&
          isPlaying &&
          isPlayerFullScreen &&
          'opacity-0',
      )}
    >
      <Link
        href={isMyMusic ? '#' : `/track/${id}`}
        className='flex items-center justify-start gap-2.5'
      >
        {!isPlayerFullScreen && (
          <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 1,
            }}
          >
            <UI_Image
              className='relative rounded-rounded w-12 h-12 object-cover'
              width={48}
              height={48}
              src={imageSrc}
            />
          </m.div>
        )}
        {(isMobile || !isPlayerFullScreen) && (
          <div className='flex flex-col items-start justify-center gap-1'>
            <UI_Typography variant='enBodyBoldText3'>
              {isMyMusic
                ? myMusic?.title
                : isPersian && persian_name !== '-'
                ? persian_name
                : english_name}
            </UI_Typography>
            <div className='self-stretch relative text-xs leading-[16px] text-gray-300'>
              <Link href={isMyMusic ? '#' : `/artist/${artists?.[0]?.id}`}>
                {isMyMusic ? myMusic?.artist_name : artists?.[0]?.name}
              </Link>
            </div>
          </div>
        )}
      </Link>

      {!isMyMusic && (
        <Like
          id={Number(id)}
          isFavorite={isFavorite}
          className={twMerge(
            'lg:w-6 lg:h-6',
            isPlayerFullScreen && '-mr-4',
            !isMobile && isPlayerFullScreen && 'mr-4',
          )}
        />
      )}
    </div>
  )
}
