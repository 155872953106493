import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSpace = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Space_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Space_svg__a)'>
      <path
        fill={props.color}
        d='M12 16c-1.114 0-2.06-.388-2.836-1.164C8.388 14.059 8 13.114 8 12c0-1.114.388-2.06 1.164-2.836C9.94 8.388 10.886 8 12 8c1.114 0 2.06.388 2.836 1.164C15.612 9.94 16 10.886 16 12c0 1.114-.388 2.06-1.164 2.836C14.059 15.612 13.114 16 12 16Z'
      />
    </g>
  </svg>
)
export default SvgSpace
