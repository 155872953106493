import Link from 'next/link'
import { UI_Image, UI_Typography } from '@/ui'
import { Element } from '@/types'
import { useTranslate } from '@/hooks'
import { variantClasses } from '@/ui/ui_typography/UI_Typography'

export type TLogoProps = {
  width?: number
  height?: number
  textClassName?: string
  textVariant?: keyof typeof variantClasses
}

export const Logo = (props: TLogoProps): Element => {
  const { translate } = useTranslate()
  const {
    width = 32,
    height = 32,
    textClassName = '',
    textVariant = 'enDesktopTitle5',
  } = props

  return (
    <Link href='/' className='flex flex-row items-center justify-start gap-3'>
      <UI_Image
        className='relative overflow-hidden shrink-0'
        src='/images/logo.png'
        width={width}
        height={height}
      />
      <UI_Typography
        className={textClassName}
        variant={textVariant}
        component='b'
      >
        {translate('global.moodic')}
      </UI_Typography>
    </Link>
  )
}
