import { ChangeEvent, InputHTMLAttributes, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { UI_IconSvg, UI_Typography, iconType } from '@/ui'
import { Element } from '@/types'

type TUI_Checkbox = InputHTMLAttributes<HTMLInputElement> & {
  label?: string
  className?: string
  width?: number
  height?: number
  checkIcon?: iconType
  uncheckIcon?: iconType
  labelClassName?: string
  checked?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

export const UI_Checkbox = forwardRef<HTMLInputElement, TUI_Checkbox>(
  (props, ref): Element => {
    const {
      label,
      className = '',
      width = 20,
      height = 20,
      uncheckIcon = 'Checkbox',
      checkIcon = 'CheckboxChecked',
      labelClassName,
      checked = false,
      onChange,
      ...otherProps
    } = props

    return (
      <label
        className={twMerge(
          'relative w-full flex items-center cursor-pointer',
          label && 'gap-2',
        )}
      >
        <input
          type='checkbox'
          ref={ref}
          className={twMerge(
            className,
            'relative peer shrink-0 box-border appearance-none rounded-sm bg-transparent cursor-pointer',
          )}
          style={{ width, height }}
          onChange={onChange}
          {...(onChange ? { checked } : { defaultChecked: checked })}
          {...otherProps}
        />
        <UI_IconSvg
          className='absolute block peer-checked:hidden pointer-events-none'
          width={width}
          height={height}
          component={uncheckIcon}
        />
        <UI_IconSvg
          className='absolute hidden peer-checked:block pointer-events-none'
          width={width}
          height={height}
          component={checkIcon}
        />
        <UI_Typography
          variant='enBodyRegularText2'
          className={twMerge('select-none', labelClassName)}
        >
          {label}
        </UI_Typography>
      </label>
    )
  },
)

UI_Checkbox.displayName = 'UI_Checkbox'
