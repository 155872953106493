import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSub = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 9 5'
    {...props}
  >
    <path
      fill={props.color}
      d='M1.325 4.055c-.187 0-.388-.015-.605-.045a5.867 5.867 0 0 1-.59-.11l.035-.615a7.322 7.322 0 0 0 1.17.11.714.714 0 0 0 .275-.04.21.21 0 0 0 .125-.125.67.67 0 0 0 .035-.245c0-.12-.015-.202-.045-.245-.027-.043-.09-.08-.19-.11l-.62-.195c-.263-.083-.447-.202-.55-.355C.262 1.923.21 1.712.21 1.445c0-.343.093-.602.28-.775.19-.173.498-.26.925-.26.14 0 .288.007.445.02.157.013.363.035.62.065l-.045.615a11.572 11.572 0 0 1-.525-.025c-.247-.013-.4-.02-.46-.02a.749.749 0 0 0-.295.045.25.25 0 0 0-.135.125c-.02.05-.03.12-.03.21a.49.49 0 0 0 .045.235c.033.053.1.095.2.125l.585.19c.187.06.332.132.435.215.107.08.182.182.225.305.047.123.07.28.07.47 0 .39-.098.667-.295.83-.193.16-.503.24-.93.24Zm2.656-.03a.917.917 0 0 1-.93-.4.796.796 0 0 1-.12-.425V1.315h.785l-.005 1.89c0 .057.02.102.06.135.043.03.093.04.15.03l.67-.145-.025.63a.801.801 0 0 0-.08.04.63.63 0 0 1-.185.06c-.1.023-.207.047-.32.07Zm.535-2.71H5.3V4.06h-.785V1.315Zm2.626 2.72c-.183 0-.63-.027-1.34-.08l.445-.6c.237.01.508.015.815.015.07 0 .123-.02.16-.06.04-.04.07-.112.09-.215.02-.103.03-.25.03-.44 0-.267-.023-.45-.07-.55-.047-.103-.117-.155-.21-.155-.07 0-.163.007-.28.02-.117.01-.185.017-.205.02l-.01-.605c.143-.033.253-.057.33-.07.08-.017.162-.025.245-.025.34 0 .588.11.745.33.157.217.235.562.235 1.035 0 .48-.078.83-.235 1.05-.157.22-.405.33-.745.33Zm-1.34-.08V.42h.785v3.585l-.785-.05Z'
    />
  </svg>
)
export default SvgSub
