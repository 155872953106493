import * as React from 'react'
import type { SVGProps } from 'react'
const SvgRecentlyPlayed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={props.color}
      d='M3.038 11.166c-.55-.052-1.042.353-1.038.906A10 10 0 1 0 12.143 2c-.552-.008-.96.482-.913 1.032.047.55.533.952 1.085.973a8 8 0 1 1-8.311 8.252c-.018-.552-.416-1.04-.966-1.091ZM8.579 4.15a1.089 1.089 0 1 1-2.178 0 1.089 1.089 0 0 1 2.178 0ZM5.292 7.508a1.089 1.089 0 1 1-2.177 0 1.089 1.089 0 0 1 2.177 0Z'
    />
    <path
      fill={props.color}
      d='M11.233 6.907a.89.89 0 0 0-.89.89V14.2h4.507a.925.925 0 1 0 0-1.85h-2.727V7.796a.89.89 0 0 0-.89-.89Z'
    />
  </svg>
)
export default SvgRecentlyPlayed
