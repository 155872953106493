// Tooltip.tsx
import React, { InputHTMLAttributes, useState } from 'react'
import { twMerge } from 'tailwind-merge'

interface TUI_TooltipProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  message: string
  children: React.ReactNode
}

export const UI_Tooltip: React.FC<TUI_TooltipProps> = ({
  message,
  children,
  className,
}) => {
  const [visible, setVisible] = useState(false)

  const showTooltip = () => setVisible(true)
  const hideTooltip = () => setVisible(false)

  return (
    <div
      className='relative flex items-center'
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {visible && (
        <span
          className={twMerge(
            'absolute bottom-full left-1/2 transform  whitespace-nowrap -translate-x-1/2 mb-0.5 z-10 px-2 py-1 bg-gray-800 text-white text-sm rounded shadow-lg font-peyda',
            className,
          )}
        >
          {message}
        </span>
      )}
    </div>
  )
}
