import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {
  followAlbum,
  followArtist,
  unFollowAlbum,
  unFollowArtist,
} from '@/store/api'

type TInitial = {
  albums: Array<number>
  artists: Array<number>
}

const initialState: TInitial = {
  albums: [],
  artists: [],
}

// const oldHydrate = {}

export const followSlice = createSlice({
  name: 'follow',
  initialState,
  reducers: {
    setFollowAlbums(state, action: PayloadAction<TInitial['albums']>) {
      state.albums = [...new Set([...state.albums, ...action.payload])]
    },
    removeFollowedAlbum(state, action: PayloadAction<number>) {
      state.albums = state.albums.filter((x) => x !== action.payload)
    },
    setFollowArtists(state, action: PayloadAction<TInitial['artists']>) {
      state.artists = [...new Set([...state.artists, ...action.payload])]
    },
    removeFollowedArtist(state, action: PayloadAction<number>) {
      state.artists = state.artists.filter((x) => x !== action.payload)
    },
  },
  extraReducers: (builder) => {
    // TODO: add after getting is_follow from backend
    // builder.addCase(HYDRATE, (state, action: any) => {
    //   oldHydrate = action.payload.follow
    //   return {
    //     ...state,
    //     ...oldHydrate,
    //   }
    // })
    // builder.addCase(REHYDRATE, (state) => {
    //   return {
    //     ...state,
    //     ...oldHydrate,
    //   }
    // })
    builder.addMatcher(followAlbum.matchFulfilled, (state, action) => {
      state.albums = [
        ...new Set([...state.albums, action.meta.arg.originalArgs]),
      ]
    })
    builder.addMatcher(unFollowAlbum.matchFulfilled, (state, action) => {
      state.albums = state.albums.filter(
        (x) => x !== action.meta.arg.originalArgs,
      )
    })
    builder.addMatcher(followArtist.matchFulfilled, (state, action) => {
      state.artists = [
        ...new Set([...state.artists, action.meta.arg.originalArgs]),
      ]
    })
    builder.addMatcher(unFollowArtist.matchFulfilled, (state, action) => {
      state.artists = state.artists.filter(
        (x) => x !== action.meta.arg.originalArgs,
      )
    })
  },
})

export const {
  setFollowAlbums,
  removeFollowedAlbum,
  setFollowArtists,
  removeFollowedArtist,
} = followSlice.actions
