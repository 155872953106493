import { setCookie } from 'cookies-next'
import { RefObject, useEffect, useMemo, useRef, useState } from 'react'
import {
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormResetField,
  useForm,
} from 'react-hook-form'
import { QueryStatus } from '@reduxjs/toolkit/query'
import { TLoginForm } from './Login'
import { useCustomRouter, useTranslate } from '@/hooks'
import { errorHandler } from '@/tools'
import { TError } from '@/types'
import {
  setIsPlaying,
  setSelectedSong,
  useAppDispatch,
  useLazyGetProfileQuery,
  useLoginMutation,
  useRequestCodeMutation,
  useResendCodeMutation,
  useVerifyCodeMutation,
} from '@/store'
import { toValidMobileNumber } from '@/tools/toValidMobileNumber'

export type LoginType = 'password' | 'otp'

type TUseLogin = {
  isOtpCode: boolean
  loginType: LoginType
  onChangeLoginType: (type: LoginType) => void
  loading: boolean
  handleSignIn: (data: TLoginForm) => void
  buttonText: string
  handleClearOtpCode: () => void
  handleChangePhoneNumber: () => void
  handleChangedOtp: (value: string) => void
  register: UseFormRegister<TLoginForm>
  handleSubmit: UseFormHandleSubmit<TLoginForm, undefined>
  isValid: boolean
  resetField: UseFormResetField<TLoginForm>
  onSubmit: SubmitHandler<TLoginForm>
  buttonRef: RefObject<HTMLButtonElement>
  errors: FieldErrors<TLoginForm>
  ttl: number
}

const useLogin = (inModal?: boolean, onCloseModal?: () => void): TUseLogin => {
  const { translate } = useTranslate()
  const [loginType, setLoginType] = useState<LoginType>('password')
  const [isOtpCode, setIsOtpCode] = useState(false)
  const [ttl, setTTL] = useState(120)
  const [executeLogin, { isLoading: loading }] = useLoginMutation()
  const [executeOtpRequest, { isLoading: requestLoading }] =
    useRequestCodeMutation()
  const otpCodeRef = useRef<string | null>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [executeResendCode, { isLoading: loadingResendCode }] =
    useResendCodeMutation()
  const [executeVerifyCode, { isLoading: verifyLoading }] =
    useVerifyCodeMutation()
  const { backAfterLogin } = useCustomRouter()

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    resetField,
    setError,
    clearErrors,
    setValue,
    getValues,
    trigger,
  } = useForm<TLoginForm>({
    mode: 'all',
  })

  const [executeProfile] = useLazyGetProfileQuery()

  const getUserProfile = () => {
    executeProfile().then((response) => {
      if (response.status === QueryStatus.fulfilled) {
        const data = response.data
        if (data?.id) {
          if (inModal) {
            onCloseModal?.()
          } else {
            backAfterLogin()
          }
        }
      } else if (response.status === QueryStatus.rejected) {
        const error = response.error
        errorHandler((error as { data: { message: string } })?.data?.message)
      }
    })
  }

  const onChangeLoginType = (type: LoginType) => {
    setLoginType(type)
    resetField('password')
    resetField('phone')
  }

  const handleResendCode = () => {
    const phoneValue = getValues('phone')
    executeOtpRequest({
      mobile: phoneValue,
    })
      .unwrap()
      .then((data) => {
        if (data.ttl) {
          setTTL(data?.ttl)
        }
        setIsOtpCode(true)
      })
      .catch((error: TError) => {
        errorHandler(error)
      })
  }

  const handleClearOtpCode = () => {
    setValue('otp_code', '')
    clearErrors('otp_code')
    trigger('otp_code') // Revalidate the form to update isValid
    handleResendCode()
  }

  const handleChangePhoneNumber = () => {
    resetField('phone')
    resetField('password')
    clearErrors('otp_code')
    trigger('otp_code') // Revalidate the form to update isValid
    setIsOtpCode(false)
  }

  const handleSendOtpCode = (data: TLoginForm) => {
    if (isOtpCode) {
      const otpCodeValue = getValues('otp_code')
      if (!(otpCodeRef.current && otpCodeValue?.length === 4)) {
        setError('otp_code', {
          type: 'custom',
          message: translate('login.otp-code-is-required'),
        })
        return
      }
      executeVerifyCode({
        mobile: data.phone,
        otp: String(otpCodeRef.current),
      })
        .unwrap()
        .then((response) => {
          const access_token = response?.access
          const refresh_token = response?.refresh
          if (typeof window !== 'undefined' && access_token && refresh_token) {
            setCookie('access_token', access_token)
            setCookie('refresh_token', refresh_token)
            localStorage.setItem('access_token', access_token)
            localStorage.setItem('refresh_token', refresh_token)
            getUserProfile()
          } else {
            return new Error('Response is not valid!')
          }
        })
        .catch((error: TError) => {
          setError('otp_code', {
            type: 'custom',
            message: translate('login.otp-code-is-wrong'),
          })
          errorHandler(error)
        })
    } else {
      executeOtpRequest({
        mobile: data.phone,
      })
        .unwrap()
        .then((data) => {
          if (data.ttl) {
            setTTL(data?.ttl)
          }
          setIsOtpCode(true)
        })
        .catch((error: TError) => {
          setError('phone', {
            type: 'custom',
            message: translate('login.user-password-are-wrong'),
          })
          errorHandler(error)
        })
    }
  }

  const handleSignInByPassword = (data: TLoginForm) => {
    executeLogin({
      username: toValidMobileNumber(data.phone),
      password: data.password,
    })
      .unwrap()
      .then((response) => {
        const access_token = response?.access
        const refresh_token = response?.refresh
        if (typeof window !== 'undefined' && access_token && refresh_token) {
          setCookie('access_token', access_token)
          setCookie('refresh_token', refresh_token)
          localStorage.setItem('access_token', access_token)
          localStorage.setItem('refresh_token', refresh_token)
          getUserProfile()
        } else {
          return new Error('Response is not valid!')
        }
      })
      .catch((error: TError) => {
        setError('phone', {
          type: 'custom',
          message: translate('login.user-password-are-wrong'),
        })
        errorHandler(error)
      })
  }

  const handleSignIn = (data: TLoginForm) => {
    loginType === 'password'
      ? handleSignInByPassword(data)
      : handleSendOtpCode(data)
  }

  const buttonText = useMemo(() => {
    if (loading || verifyLoading || loadingResendCode)
      return translate('login.wait')
    else if (loginType === 'password') return translate('global.sign-in')
    else if (loginType === 'otp' && isOtpCode)
      return translate('global.verify-code')
    else return translate('global.send-code')
  }, [loading, loginType, verifyLoading, loadingResendCode, isOtpCode])

  const handleChangedOtp = (value: string) => {
    otpCodeRef.current = value
    setValue('otp_code', value)
    clearErrors('otp_code')
    trigger('otp_code') // Revalidate the form to update isValid
  }

  const onSubmit: SubmitHandler<TLoginForm> = (data: TLoginForm) =>
    loading && !isValid ? undefined : handleSignIn(data)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setSelectedSong(null))
    dispatch(setIsPlaying(false))
    dispatch({ type: 'LOGOUT' })
  }, [])

  return {
    isOtpCode,
    loginType,
    onChangeLoginType,
    loading: loading || requestLoading,
    handleSignIn,
    buttonText,
    handleClearOtpCode,
    handleChangePhoneNumber,
    handleChangedOtp,
    register,
    handleSubmit,
    isValid,
    resetField,
    onSubmit,
    buttonRef,
    errors,
    ttl,
  }
}

export default useLogin
