import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Check_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Check_svg__a)'>
      <path
        fill={props.color}
        d='m10.442 15.53 8.917-10.25a.912.912 0 0 1 .686-.28c.277 0 .506.093.686.28.18.185.27.422.27.71 0 .287-.09.524-.27.71l-8.917 10.252-.743.769a.912.912 0 0 1-.686.279c-.277 0-.433-.017-.678-.28a508.515 508.515 0 0 0-5.438-5.742.981.981 0 0 1-.269-.71c0-.288.09-.525.27-.71a.912.912 0 0 1 .685-.28c.278 0 .506.093.686.28l4.801 4.973Z'
      />
    </g>
  </svg>
)
export default SvgCheck
