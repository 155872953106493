import * as React from 'react'
import type { SVGProps } from 'react'
const SvgProfile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Profile_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Profile_svg__a)'>
      <path
        fill={props.color}
        d='M5.85 17.1c.85-.65 1.8-1.163 2.85-1.538A9.737 9.737 0 0 1 12 15c1.15 0 2.25.188 3.3.563 1.05.374 2 .887 2.85 1.537a7.73 7.73 0 0 0 1.363-2.325A7.838 7.838 0 0 0 20 12c0-2.217-.78-4.104-2.337-5.662C16.104 4.779 14.217 4 12 4s-4.104.78-5.662 2.338C4.779 7.896 4 9.783 4 12c0 .983.162 1.908.487 2.775.325.867.78 1.642 1.363 2.325ZM12 13c-.983 0-1.813-.338-2.488-1.012C8.837 11.313 8.5 10.483 8.5 9.5c0-.983.338-1.813 1.012-2.487C10.188 6.338 11.017 6 12 6c.983 0 1.813.338 2.488 1.013.675.675 1.012 1.504 1.012 2.487 0 .983-.338 1.813-1.012 2.488C13.813 12.663 12.983 13 12 13Zm0 9a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0 1 12 22Zm0-2c.883 0 1.717-.13 2.5-.387a7.53 7.53 0 0 0 2.15-1.113 7.53 7.53 0 0 0-2.15-1.113A7.933 7.933 0 0 0 12 17c-.883 0-1.717.13-2.5.387A7.53 7.53 0 0 0 7.35 18.5a7.53 7.53 0 0 0 2.15 1.113A7.933 7.933 0 0 0 12 20Zm0-9c.433 0 .792-.142 1.075-.425.283-.283.425-.642.425-1.075 0-.433-.142-.792-.425-1.075C12.792 8.142 12.433 8 12 8c-.433 0-.792.142-1.075.425-.283.283-.425.642-.425 1.075 0 .433.142.792.425 1.075.283.283.642.425 1.075.425Z'
      />
    </g>
  </svg>
)
export default SvgProfile
