import * as React from 'react'
import type { SVGProps } from 'react'
const SvgHorn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={props.color}
      d='M21 13h-2a.968.968 0 0 1-.712-.288A.967.967 0 0 1 18 12c0-.283.096-.52.288-.713A.968.968 0 0 1 19 11h2c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.968.968 0 0 1 21 13Zm-4.4 3.8a.914.914 0 0 1 .65-.4c.267-.033.517.033.75.2l1.6 1.2a.913.913 0 0 1 .4.65c.033.267-.033.517-.2.75a.913.913 0 0 1-.65.4 1.032 1.032 0 0 1-.75-.2l-1.6-1.2a.913.913 0 0 1-.4-.65 1.033 1.033 0 0 1 .2-.75Zm3-10.6L18 7.4a1.032 1.032 0 0 1-.75.2.913.913 0 0 1-.65-.4 1.033 1.033 0 0 1-.2-.75.913.913 0 0 1 .4-.65l1.6-1.2c.233-.167.483-.233.75-.2a.913.913 0 0 1 .65.4c.167.233.233.483.2.75a.913.913 0 0 1-.4.65ZM5 15H4c-.55 0-1.02-.196-1.413-.588A1.926 1.926 0 0 1 2 13v-2c0-.55.196-1.02.587-1.413A1.926 1.926 0 0 1 4 9h4l3.475-2.1c.333-.2.67-.2 1.013 0 .341.2.512.492.512.875v8.45c0 .383-.17.675-.512.875-.342.2-.68.2-1.013 0L8 15H7v3c0 .283-.096.52-.287.712A.967.967 0 0 1 6 19a.967.967 0 0 1-.713-.288A.967.967 0 0 1 5 18v-3Zm6-.55v-4.9L8.55 11H4v2h4.55L11 14.45Zm3 .9v-6.7c.45.4.813.887 1.088 1.462.275.575.412 1.205.412 1.888 0 .683-.137 1.312-.412 1.887A4.567 4.567 0 0 1 14 15.35Z'
    />
  </svg>
)
export default SvgHorn
