/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import {
  addPlaylist,
  addSongToPlaylist,
  deletePlaylist,
  getMyPlaylists,
  getPlayListSongs,
  removeSongFromPlaylist,
} from '@/store/api'

type TInitial = {
  playlists: Record<number, Array<number | string>>
}

const initialState: TInitial = {
  playlists: {},
}

export const playlistSlice = createSlice({
  name: 'playlist',
  initialState,
  reducers: {
    setPlaylist(
      state,
      action: PayloadAction<{ playlistId: number; songId: number }>,
    ) {
      const _playlistId = action.payload.playlistId
      const _songId = action.payload.songId
      const _playlists = { ...state.playlists }
      const _playlistSongs = _playlists[_playlistId] ?? []
      state.playlists = {
        ..._playlists,
        [_playlistId]: [..._playlistSongs, _songId],
      }
    },
    removePlaylist(
      state,
      action: PayloadAction<{
        playlistId?: number | string
        songId?: number | string
      }>,
    ) {
      const _playlistId = Number(action.payload.playlistId)
      const _songId = Number(action.payload.songId)
      const _playlists = { ...state.playlists }
      const _removedPlaylist = _playlists?.[_playlistId]?.filter(
        (x) => x !== _songId,
      )
      state.playlists = {
        ..._playlists,
        [_playlistId]: [..._removedPlaylist],
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(getMyPlaylists.matchFulfilled, (state, action) => {
      action?.payload?.results?.forEach((item) => {
        const _playlists = { ...state.playlists }
        state.playlists = {
          ..._playlists,
          // [item.id]: item?.songs?.map((x) => x.id) ?? [],
        }
      })
    })

    builder.addMatcher(getPlayListSongs.matchFulfilled, (state, action) => {
      const _playlistId = action.meta.arg.originalArgs.id
      state.playlists = {
        ...state.playlists,
        [_playlistId]: action.payload.results.map((x) => x.id) ?? [],
      }
      action.payload.results
    })

    builder.addMatcher(addSongToPlaylist.matchFulfilled, (state, action) => {
      const _playlistId = Number(action?.payload?.id)
      const _songId = action?.payload?.body?.song_id
      const _playlists = { ...state.playlists }
      const _playlistSongs = _playlists[_playlistId] ?? []
      state.playlists = {
        ..._playlists,
        [_playlistId]: [..._playlistSongs, _songId],
      }
    })
    builder.addMatcher(
      removeSongFromPlaylist.matchFulfilled,
      (state, action) => {
        const _playlistId = Number(action?.payload?.id)
        const _songId = action?.payload?.body?.song_id
        const _playlists = { ...state.playlists }
        const _playlistSongs =
          _playlists[_playlistId].filter((x) => x !== _songId) ?? []
        state.playlists = {
          ..._playlists,
          [_playlistId]: [..._playlistSongs],
        }
      },
    )
    builder.addMatcher(addPlaylist.matchFulfilled, (state, action) => {
      const _playlistId = Number(action.payload.id)
      const _playlists = { ...state.playlists }
      state.playlists = {
        ..._playlists,
        [_playlistId]: [],
      }
    })
    builder.addMatcher(deletePlaylist.matchFulfilled, (state, action) => {
      const _playlistId = Number(action.payload)
      const _playlists = { ...state.playlists }
      delete _playlists[_playlistId]
      state.playlists = _playlists
    })
  },
})

export const { setPlaylist, removePlaylist } = playlistSlice.actions

export const selectAllPlaylists = (
  state: RootState,
): Record<number, Array<string | number>> => state.playlist.playlists
