import { ChangeEvent, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import toast from 'react-hot-toast'
import { useParams } from 'next/navigation'
import {
  UI_IconButton,
  UI_Image,
  UI_Input,
  UI_Switch,
  UI_Typography,
} from '@/ui'
import { UI_Button } from '@/ui/ui_button/UI_Button'
import { Element, TError, TUseParams } from '@/types'
import { errorHandler, musicImage, toBase64 } from '@/tools'
import { useLocale, useTranslate } from '@/hooks'
import { useEditPlaylistMutation, useLazyGetPlaylistQuery } from '@/store'

type TEditProps = {
  onSuccess: () => void
}

export const Edit = (props: TEditProps): Element => {
  const { translate } = useTranslate()
  const { onSuccess } = props
  const [title, setTitle] = useState('')
  const [image, setImage] = useState<File>()
  const [src, setSrc] = useState<string>('')
  const [isPublic, setIsPublic] = useState(true)
  const { id } = useParams<TUseParams>()
  const { isPersian } = useLocale()
  const [executeUpdatePlaylist, { isLoading: loadingUpdatePlaylist }] =
    useEditPlaylistMutation()

  const [executeMyPlaylistById] = useLazyGetPlaylistQuery()

  useEffect(() => {
    executeMyPlaylistById(Number(id)).then((response) => {
      setTitle(
        String(response?.data?.[isPersian ? 'persian_name' : 'english_name']),
      )
      const _src = musicImage({
        id: response?.data?.id,
        uuid: response?.data?.uuid,
        type: 'playlist',
      })
      setSrc(_src)

      setIsPublic(Boolean(response?.data?.is_public))
    })
  }, [])

  const handleSubmit = () => {
    const formData = new FormData()
    formData.append('persian_name', title)
    formData.append('english_name', title)
    image && formData.append('image', image as File)
    formData.append('is_public', String(isPublic))

    executeUpdatePlaylist({
      id: Number(id),
      body: formData,
    })
      .then(() => {
        toast.success(translate('playlist.success-update'))
        onSuccess()
      })
      .catch((error: TError) => {
        errorHandler(error)
      })
  }

  const handleSelectedFile = (e: ChangeEvent<HTMLInputElement>) => {
    const _img = e?.target?.files?.[0] as File
    if (_img?.size > 200 * 1000) {
      toast.error(translate('playlist.image-size-error', { size: 200 }))
      return
    }
    setImage(_img)
    toBase64(_img).then((res) => {
      setSrc(String(res))
    })
  }

  const handleChangePublicMode = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    setIsPublic(!checked)
  }

  const commonClass = 'absolute right-1.5 bottom-1.5 p-1.5 cursor-pointer'

  return (
    <div className='flex lg:flex-row flex-col lg:items-end items-center self-stretch gap-6'>
      <div className='relative w-[100px] h-[100px]'>
        <UI_Image
          className='object-cover rounded-lg'
          src={src}
          width={100}
          height={100}
        />
        <UI_IconButton
          className={twMerge(
            commonClass,
            ' bg-cards-glass-secondary2 border-border-border-primary z-9',
          )}
          icon={{
            iconType: 'Camera',
            className: 'w-4 h-4',
          }}
        />
        <input
          type='file'
          className={twMerge(
            'absolute right-0 bottom-0 w-full h-full opacity-0 z-10',
          )}
          onChange={handleSelectedFile}
        />
      </div>
      <div className='relative flex flex-1 lg:w-auto w-full'>
        <UI_Input
          label={translate('playlist.playlist-title')}
          className='gap-6 text-lg w-full'
          baseClassName='border-border-border-inactive h-10'
          name='title'
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          placeholder={translate('playlist.Soundtracks-example')}
        />
        <div className='absolute top-0 right-0 flex items-center justify-center gap-2'>
          <UI_Typography
            variant='enCaptionBold'
            className={twMerge(!isPublic && 'opacity-50')}
          >
            {translate('global.public')}
          </UI_Typography>
          <UI_Switch
            onChange={handleChangePublicMode}
            startAdornment={{
              icon: 'Public',
              fill: 'fill-black',
            }}
            endAdornment={{
              icon: 'Private',
              fill: 'fill-black',
            }}
            value={!isPublic}
            defaultValue={!isPublic}
          />
          <UI_Typography
            variant='enCaptionBold'
            className={twMerge(isPublic && 'opacity-50')}
          >
            {translate('global.private')}
          </UI_Typography>
        </div>
      </div>
      <div className='flex items-end justify-end lg:w-auto w-full'>
        <UI_Button
          variant='primary'
          typography={{
            variant: 'enLabelBoldLabel3',
          }}
          loading={loadingUpdatePlaylist}
          onClick={handleSubmit}
          className='lg:w-auto w-full'
        >
          {translate('global.save')}
        </UI_Button>
      </div>
    </div>
  )
}
