import Link from 'next/link'
import { Element } from '@/types'
import { UI_IconSvg, UI_Typography, iconType } from '@/ui'

export type TNavItemProps = {
  text: string
  href: string
  icon: iconType
  iconFilled: iconType
  isEnable?: boolean
  label: string
}

export const NavigationItem = (props: TNavItemProps): Element => {
  const { text, href, icon, iconFilled, isEnable = false, label } = props

  return (
    <Link href={href} aria-label={label} className='flex flex-col items-center'>
      <UI_IconSvg
        component={isEnable ? iconFilled : icon}
        fill={
          isEnable
            ? 'fill-content-content-primary'
            : 'fill-content-content-tertiary'
        }
      />
      <UI_Typography
        className={
          isEnable
            ? 'text-content-content-primary'
            : 'text-content-content-tertiary'
        }
        variant={isEnable ? 'enCaptionBold' : 'enLabelCaption'}
      >
        {text}
      </UI_Typography>
    </Link>
  )
}
