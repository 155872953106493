import * as cheerio from 'cheerio'
import axios from 'axios'

const regex = /\s(از|را|تا|به|برای|با|تو|به|در|روی|زیر|کنار|و|یا)\s/g

const linkPatterns = {
  resanejavan: {
    address: 'https://resanejavan.net/search/',
    links: 'article header h2 a',
    description: 'article div.rjtcnt p',
  },
  rozmusic: {
    address: 'https://rozmusic.com/?s=',
    links: "article a[href*='.html']",
    description: 'article div.matnmusic p',
  },
}

type TOutput = {
  text: string
  image: string | undefined
  media: {
    text: string
    href?: string | undefined
    description?: string[] | undefined
  }[]
  description: string[]
}

export const crawler = async (
  newText: string,
  baseSite: 'resanejavan' | 'rozmusic' = 'rozmusic',
): Promise<Array<TOutput>> => {
  const songName = newText.replace(regex, '').replace('++', '+').trim()
  const songNameArr = songName.split('+')
  const siteUrl = `${linkPatterns[baseSite].address}${songName}`
  const songs: Array<{ text: string; href?: string; image?: string }> = []

  await axios.get(siteUrl).then((response) => {
    const html = response.data
    const $ = cheerio.load(html)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const links: any = $(linkPatterns[baseSite].links)
    links.each((index: string, link: string) => {
      const text = $(link).text()
      const href = $(link).attr('href')
      const image = $(link).parents('article').find('img').attr('src')
      if (
        (text.includes('دانلود آهنگ') ||
          text.includes('دانلود ورژن') ||
          text.includes('دانلود مداحی')) &&
        Boolean(songNameArr.find((x: string) => text.includes(x)))
      ) {
        songs.push({ text, href, image })
      }
    })
  })

  const _songs: Array<TOutput> = []
  for (const item of songs) {
    const _media: Array<{
      text: string
      href?: string
      description?: Array<string>
    }> = []
    const _description: Array<string> = []
    await axios.get(String(item?.href)).then((response) => {
      const _html = response.data
      const _$ = cheerio.load(_html)

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const descriptions: any = _$(linkPatterns[baseSite].description)
      descriptions.each((_index: string, _text: string) => {
        const text = _$(_text).text()
        if (baseSite === 'rozmusic' && text.includes('\n')) {
          _description.push(text)
        } else {
          _description.push(text)
        }
      })

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const _links: any = _$("a[href*='.mp3']")
      _links.each((_index: string, _link: string) => {
        const _text = _$(_link).text()
        const _href = _$(_link).attr('href')
        if (_text !== _href) {
          _media.push({
            text: _text
              .replace('دانلود آهنگ با کیفیت', '')
              .replace('دانلود آهنگ کیفیت', '')
              .replace('دانلود این بخش', 'دانلود'),
            href: _href,
          })
        }
      })
    })
    _songs.push({
      text: item.text,
      image: item.image,
      media: _media,
      description: _description,
    })
  }

  return _songs
}
