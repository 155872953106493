import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { InfiniteScroll } from '../infiniteScroll/InfiniteScroll'
import { SongsItem } from './SongsItem'
import { SongsLoading } from './SongsLoading'
import { UI_Input } from '@/ui'
import { errorHandler } from '@/tools'
import { Element, TError, TMusic, TPlaylist } from '@/types'
import { playlistApi, useAppDispatch } from '@/store'
import { useSearchMutation } from '@/store/api/searchApi'

type TSongProps = {
  latestPlaylist: Partial<TPlaylist>
}

export const Songs = (props: TSongProps): Element => {
  const { latestPlaylist } = props
  const dispatch = useAppDispatch()
  const page = useRef(1)
  const [searched, setSearched] = useState('')
  const [loadingMore, setLoadingMore] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const [data, setData] = useState<Array<TMusic>>([])
  const [executeSearch, { isLoading: loadingSearch, data: searchData = {} }] =
    useSearchMutation()

  const hasMore = Number(searchData?.songs?.length) > data.length

  const handleLoadData = (value: string) => {
    setLoadingMore(true)
    executeSearch({
      params: {
        limit: 20,
        page: page.current,
      },
      body: {
        q: value,
      },
    })
      .unwrap()
      .then((response) => {
        const result = response?.songs ?? []
        setData((x) => [...x, ...result])
        page.current += 1
      })
      .catch((error: TError) => {
        errorHandler(error)
      })
      .finally(() => {
        setLoadingMore(false)
      })
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    page.current = 1
    const value = String(e.target.value)
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      setData([])
      setSearched(value)
      handleLoadData(value)
    }, 500)
  }

  const handleLoadMore = () => {
    if (!loadingSearch && hasMore) {
      handleLoadData(searched)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(playlistApi.util.invalidateTags(['Playlist']))
    }
  }, [])

  return (
    <div className='section-8'>
      <UI_Input
        icon='Search'
        placeholder='Search songs ...'
        baseClassName='flex-row-reverse !rounded-full gap-3'
        inputClassName='text-base'
        onChange={handleSearch}
      />
      <div className='flex flex-col gap-4 scroll overflow-auto lg:max-h-[330px] max-h-[300px] -mx-8 px-8'>
        <InfiniteScroll className='section-4' onLoadMore={handleLoadMore}>
          {data.map((item) => (
            <SongsItem
              key={item.id}
              {...item}
              latestPlaylist={latestPlaylist}
            />
          ))}
          {(loadingSearch || loadingMore) &&
            Array.from({ length: 4 }).map((_, index) => (
              <SongsLoading key={index} />
            ))}
        </InfiniteScroll>
      </div>
    </div>
  )
}
