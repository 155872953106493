import {
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useRouter } from 'next/router'
import { Create } from './Create'
import { Success } from './Success'
import { Songs } from './Songs'
import { Playlist } from './Playlist'
import { Edit } from './Edit'
import { UI_Modal } from '@/ui'
import { useLocale, useTranslate } from '@/hooks'
import { Element, TMusic, TPlaylist } from '@/types'

type TPlaylistDialogProps = {
  type: 'create' | 'addToPlaylist'
  children?: (handleClick: () => void) => ReactNode
  song?: TMusic
  playlist?: TPlaylist
  onCloseDialog?: () => void
}

export type TPlaylistDialogFunctions = { open: () => void; edit: () => void }

export const PlaylistDialog = forwardRef(
  (props: TPlaylistDialogProps, ref): Element => {
    const { type, children, song, playlist, onCloseDialog } = props
    const [isOpenDialog, setIsOpenDialog] = useState(false)
    const [isOpenCreateDialog, setIsOpenCreateDialog] = useState(false)
    const [isOpenSuccessDialog, setIsOpenSuccessDialog] = useState(false)
    const [isOpenEditDialog, setIsOpenEditDialog] = useState(false)
    const [latestPlaylist, setLatestPlaylist] = useState<Partial<TPlaylist>>(
      playlist ?? {},
    )
    const { isPersian } = useLocale()
    const [isOpenAddToPlaylistDialog, setIsOpenAddToPlaylistDialog] =
      useState(false)
    const { translate } = useTranslate()
    const isCreate = type === 'create'
    const { replace } = useRouter()

    useEffect(() => {
      setLatestPlaylist(playlist as TPlaylist)
    }, [playlist])

    const handleToggleDialog = () => {
      onCloseDialog?.()
      setIsOpenDialog((x) => !x)
    }
    const handleToggleCreateDialog = () => {
      onCloseDialog?.()
      setIsOpenCreateDialog((x) => !x)
    }
    const handleToggleEditDialog = () => {
      onCloseDialog?.()
      setIsOpenEditDialog((x) => !x)
    }
    const handleToggleSuccessDialog = () => {
      onCloseDialog?.()
      setIsOpenSuccessDialog((x) => !x)
    }
    const handleToggleAddToPlaylistDialog = () => {
      onCloseDialog?.()
      setIsOpenAddToPlaylistDialog((x) => !x)
    }

    const handleClick = () => {
      if (isCreate) {
        handleToggleCreateDialog()
      } else if (playlist) {
        setIsOpenAddToPlaylistDialog(true)
      } else {
        handleToggleDialog()
      }
    }

    const handleClickBack = () => {
      setIsOpenDialog(true)
      setIsOpenCreateDialog(false)
    }

    const handleClickCreateNew = () => {
      setIsOpenDialog(false)
      setIsOpenCreateDialog(true)
    }

    const handleSuccessCreate = (playlist: Partial<TPlaylist>) => {
      setLatestPlaylist(playlist)
      setIsOpenCreateDialog(false)
      setIsOpenSuccessDialog(true)
    }

    const handleSuccessEdit = () => {
      setIsOpenEditDialog(false)
      replace(window.location.href)
    }

    const handleAddSong = () => {
      setIsOpenAddToPlaylistDialog(true)
      setIsOpenSuccessDialog(false)
    }

    const handleLater = () => {
      setIsOpenSuccessDialog(false)
    }

    useImperativeHandle(
      ref,
      (): TPlaylistDialogFunctions => {
        return {
          open: () => {
            handleClick()
          },
          edit: () => {
            handleToggleEditDialog()
          },
        }
      },
      [],
    )

    return (
      <>
        {children?.(handleClick)}
        {isOpenDialog && (
          <UI_Modal
            title={translate('playlist.add')}
            isOpen
            onClose={handleToggleDialog}
          >
            <Playlist onCreateNew={handleClickCreateNew} song={song} />
          </UI_Modal>
        )}
        {isOpenCreateDialog && (
          <UI_Modal
            isOpen
            title={translate('playlist.create')}
            onClose={handleToggleCreateDialog}
            onClickBack={isCreate ? undefined : handleClickBack}
          >
            <Create onSuccess={handleSuccessCreate} />
          </UI_Modal>
        )}
        {isOpenEditDialog && (
          <UI_Modal
            isOpen
            title={translate('playlist.edit')}
            onClose={handleToggleEditDialog}
          >
            <Edit onSuccess={handleSuccessEdit} />
          </UI_Modal>
        )}
        {isOpenSuccessDialog && (
          <UI_Modal
            title={translate('playlist.playlist-created')}
            isOpen
            onClose={handleToggleSuccessDialog}
            titleOptions={{
              alignment: 'center',
            }}
          >
            <Success onSuccess={handleAddSong} onCancel={handleLater} />
          </UI_Modal>
        )}
        {isOpenAddToPlaylistDialog && (
          <UI_Modal
            title={`Add Songs To “${
              latestPlaylist?.[isPersian ? 'persian_name' : 'english_name']
            }”`}
            isOpen
            onClose={handleToggleAddToPlaylistDialog}
          >
            <Songs latestPlaylist={latestPlaylist} />
          </UI_Modal>
        )}
      </>
    )
  },
)

PlaylistDialog.displayName = 'PlaylistDialog'

export default PlaylistDialog
