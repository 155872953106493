import * as React from 'react'
import type { SVGProps } from 'react'
const SvgPlaySpeed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Play_Speed_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Play_Speed_svg__a)'>
      <path
        fill={props.color}
        d='M3 21c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 1 19V5c0-.55.196-1.02.587-1.413A1.926 1.926 0 0 1 3 3h18c.55 0 1.02.196 1.413.587C22.803 3.98 23 4.45 23 5v14c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 21 21H3Zm0-2h18V5H3v14ZM7 9v7c0 .283.096.52.287.712.192.192.43.288.713.288s.52-.096.713-.288A.968.968 0 0 0 9 16V8a.968.968 0 0 0-.287-.713A.968.968 0 0 0 8 7H6a.968.968 0 0 0-.713.287A.968.968 0 0 0 5 8c0 .283.096.52.287.713.192.191.43.287.713.287h1Zm8.25 4.825 1.5 2.725c.083.133.192.242.325.325a.832.832 0 0 0 .45.125.811.811 0 0 0 .75-.438.806.806 0 0 0 0-.862L16.25 12l2.05-3.7c.167-.283.162-.57-.012-.863A.839.839 0 0 0 17.524 7a.831.831 0 0 0-.45.125 1.01 1.01 0 0 0-.325.325l-1.5 2.725-1.5-2.725a1.01 1.01 0 0 0-.325-.325.831.831 0 0 0-.45-.125.811.811 0 0 0-.75.438.806.806 0 0 0 0 .862L14.25 12l-2.05 3.7c-.167.283-.162.57.013.863.175.291.429.437.762.437a.832.832 0 0 0 .45-.125 1.01 1.01 0 0 0 .325-.325l1.5-2.725Z'
      />
    </g>
  </svg>
)
export default SvgPlaySpeed
