import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCheck1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={props.color}
      d='m9.675 15.423 8.475-8.475c.2-.2.438-.3.713-.3.275 0 .512.1.712.3.2.2.3.438.3.713 0 .275-.1.512-.3.712l-9.2 9.2c-.2.2-.433.3-.7.3a.96.96 0 0 1-.7-.3l-4.3-4.3a.93.93 0 0 1-.287-.712 1.02 1.02 0 0 1 .312-.713c.2-.2.438-.3.713-.3.275 0 .512.1.712.3l3.55 3.575Z'
    />
  </svg>
)
export default SvgCheck1
