import * as React from 'react'
import type { SVGProps } from 'react'
const SvgNightFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Night_-_filled_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Night_-_filled_svg__a)'>
      <path
        fill={props.color}
        d='M13.1 23c-1.4 0-2.713-.267-3.938-.8a10.279 10.279 0 0 1-3.2-2.162 10.279 10.279 0 0 1-2.162-3.2A9.756 9.756 0 0 1 3 12.9c0-2.433.775-4.58 2.325-6.438C6.875 4.604 8.85 3.45 11.25 3a10.1 10.1 0 0 0 .275 4.838 9.867 9.867 0 0 0 2.5 4.137 9.867 9.867 0 0 0 4.138 2.5A10.1 10.1 0 0 0 23 14.75c-.433 2.4-1.583 4.375-3.45 5.925C17.683 22.225 15.533 23 13.1 23Z'
      />
    </g>
  </svg>
)
export default SvgNightFilled
