import React, { ReactNode, useMemo } from 'react'
import { m } from 'framer-motion'
import { twMerge } from 'tailwind-merge'
import { Header } from './Header'
import { Row } from './Row'
import { Cell } from './Cell'
import { Col } from '@/components'
import { UI_IconSvg } from '@/ui'
import { Element } from '@/types'

export type TColumnDefs<T> = {
  field: string
  headerName: string
  cellRenderer?: (data: T) => React.JSX.Element | ReactNode
  width?: string
}

export type TUI_TableProps<T> = {
  columnDefs: TColumnDefs<T>[]
  data: Array<T> | null | undefined
  onDoubleClickRow?: (item?: T) => void
  currentPage?: number
  totalItems?: number
  perPage?: number
  onPageChange?: (page: number, perPage?: number) => void
  loading?: boolean
}

export const UI_Table = <T,>(props: TUI_TableProps<T>): Element => {
  const {
    columnDefs,
    data,
    onDoubleClickRow,
    currentPage = 1,
    totalItems = 100,
    perPage = 10,
    loading,
  } = props

  const render = useMemo(
    () => (
      <m.div
        initial={{ y: -30, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        className='relative w-full h-auto text-left font-peyda'
      >
        <Col className='relative self-stretch gap-4'>
          <Header>
            {columnDefs.map((header) => (
              <Cell
                key={header.field}
                value={header.headerName}
                width={header.width}
              />
            ))}
          </Header>
          {loading && (
            <m.div
              initial={{ y: -100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className='z-10 [backdrop-filter:blur(8px)] absolute inset-0 items-center justify-center flex flex-col'
            >
              <span className='animate-spin'>
                <UI_IconSvg component='Time' width={100} height={100} />
              </span>
            </m.div>
          )}
          {!loading &&
            data?.map((row: T, index) => (
              <Row<T>
                key={index}
                onDoubleClickRow={onDoubleClickRow}
                row={row}
                index={index}
              >
                {columnDefs.map((header, i) => {
                  const field = header?.field
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const newRow: any = row
                  const value = String(newRow[field])
                  if (header?.cellRenderer) {
                    return (
                      <div
                        key={`${index}_${i}`}
                        className={twMerge('flex-1 p-3 ellipsis', header.width)}
                      >
                        {header?.cellRenderer(row)}
                      </div>
                    )
                  }
                  return <Cell key={field} value={value} width={header.width} />
                })}
              </Row>
            ))}
        </Col>
      </m.div>
    ),
    [currentPage, totalItems, perPage, columnDefs, data],
  )

  return render
}
