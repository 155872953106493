import { MouseEvent, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { UI_ButtonGradient, UI_IconButton } from '@/ui'
import { Element, TError } from '@/types'
import { useUser } from '@/hooks'
import { errorHandler } from '@/tools'
import {
  useAddToFavoriteMutation,
  useAppSelector,
  useRemoveFromFavoriteMutation,
} from '@/store'

type TFollowArtistProps = {
  isFollow: boolean
  haveGradient?: boolean
  size?: 'lg' | 'md'
  id: number
  className?: string
  children?: (
    handleFollow: (e: MouseEvent<HTMLElement>) => void,
    follow: boolean,
    loading: boolean,
  ) => Element
}

export const FollowArtist = (props: TFollowArtistProps): Element | null => {
  const {
    isFollow,
    id,
    className,
    children,
    size = 'lg',
    haveGradient = true,
  } = props
  const [follow, setFollow] = useState(isFollow)
  const followedArtists = useAppSelector((state) => state.follow.artists)
  const { isLogin } = useUser()

  const [executeAddFavorite, { isLoading: loadingFollow }] =
    useAddToFavoriteMutation()

  const [executeRemoveFromFavorite, { isLoading: loadingUnFollow }] =
    useRemoveFromFavoriteMutation()

  const loading = loadingFollow || loadingUnFollow

  useEffect(() => {
    setFollow(Boolean(followedArtists?.includes(id)))
  }, [followedArtists, id])

  const handleFollow = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (follow) {
      executeRemoveFromFavorite({ id, model: 'artist' })
        .then(() => {
          setFollow(false)
        })
        .catch((error: TError) => {
          errorHandler(error)
        })
    } else {
      executeAddFavorite({ id, model: 'artist' })
        .then(() => {
          setFollow(true)
        })
        .catch((error: TError) => {
          errorHandler(error)
        })
    }
  }

  if (!isLogin) return null

  if (children) return children(handleFollow, follow, loading)

  return (
    <UI_ButtonGradient
      rounded='rounded-full'
      className='w-fit h-fit'
      gradient='background-conic-gradient2'
      isGradient={haveGradient}
    >
      <UI_IconButton
        borderLess={haveGradient}
        icon={{
          iconType: follow ? 'AddedPeople' : 'AddPeople',
          className: twMerge(
            'w-5 h-5 transition-all',
            loading && 'beating',
            className,
          ),
        }}
        className={twMerge(
          'border-border-border-primary',
          size === 'md' && 'w-8 h-8 p-0',
        )}
        onClick={handleFollow}
      />
    </UI_ButtonGradient>
  )
}
