import { useRouter } from 'next/navigation'
import { ChangeEvent } from 'react'
import { twMerge } from 'tailwind-merge'
import { Title } from './comp/Title'
import { TimeOrCounter } from './comp/TimeOrCounter'
import { ArtistName } from './comp/ArtistName'
import { BadgeAd, PlaylistDialog } from '@/components'
import {
  UI_ActionMenu,
  UI_Checkbox,
  UI_IconSvg,
  UI_Image,
  UI_PlayButton,
  UI_RoundedCheckbox,
  UI_Typography,
} from '@/ui'
import {
  useActions,
  useLocale,
  useMobile,
  useSetting,
  useTranslate,
  useUser,
} from '@/hooks'
import { categoryToIcon, formattedTime } from '@/tools'
import { Element, TCategoryType, TArtist, TMusic, TPlaylist } from '@/types'
import { Like } from '@/pages/global'
import { useAppSelector } from '@/store'

type TShelfItemProps<T> = {
  data: T
  allSongs?: Array<T>
  hasCheckbox?: boolean
  isChecked?: boolean
  type?: TCategoryType
  isPromoted?: boolean
  onSelect?: ({ item }: { item: T }) => void
}

export const ShelfItem = <T,>(props: TShelfItemProps<T>): Element => {
  const {
    data,
    hasCheckbox,
    isChecked,
    type = 'song',
    isPromoted,
    allSongs = [],
    onSelect,
  } = props

  const {
    id,
    name_en,
    name: persian_name,
    artists,
    uuid,
    is_favorite,
    image,
    duration,
  } = data as TMusic
  const { isPersian } = useLocale()
  const { translate } = useTranslate()
  const { songs_count } = data as TArtist
  const { songs } = data as TPlaylist
  const songsCount = songs_count || songs?.length || 0
  const isTrack = type === 'song'
  const isAlbum = type === 'album'
  const isArtist = type === 'artist'
  const isPlaylist = type === 'playlist'
  const isFavorite = Boolean(is_favorite)
  // const duration = duration
  const { push } = useRouter()
  const link = `/${type == 'song' ? 'track' : type}/${id}`
  let artistLink = isArtist ? `/artist/${id}` : `/artist/${artists?.[0]?.id}`
  if (isPlaylist || isAlbum) {
    artistLink = ''
  }
  const isMobile = useMobile()
  const { isLogin } = useUser()
  const { isForceLogin } = useSetting()
  const followedArtists = useAppSelector((state) => state.follow.artists)

  const handleClickOnCard = () => {
    if (hasCheckbox) {
      onSelect &&
        onSelect({
          item: data,
        })
    } else {
      isMobile && push(link)
    }
  }
  const handleSelect = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    onSelect &&
      onSelect({
        item: data,
      })
  }

  const artistId = Number(artists?.[0]?.id)

  const isFollowedArtist = Boolean(followedArtists?.includes(artistId))

  const { actionMenu, addToPlaylistRef } = useActions({
    data: data as TMusic,
    type,
    artistId,
    isFollowedArtist,
    isFollowArtist: true,
    isAddToPlaylist: true,
    isGoToAlbum: true,
    isGoToArtist: true,
  })

  const trackText =
    songsCount && songsCount > 1
      ? translate('global.songs')
      : translate('global.song')

  return (
    <div
      className='flex flex-col gap-3 max-w-[280px] cursor-pointer'
      onClick={handleClickOnCard}
    >
      <div className='group relative rounded-space-100 w-full h-auto flex items-center justify-center'>
        <UI_Image
          className={twMerge(
            'object-cover w-full max-w-[280px]  h-full aspect-square',
            isArtist ? 'rounded-full' : 'rounded-space-100',
            isChecked && isArtist && 'background-conic-gradient p-0.5',
          )}
          src={image !== null ? image : ''}
          width={isMobile ? 120 : 280}
          height={isMobile ? 120 : 280}
        />
        {isPromoted && (
          <BadgeAd
            type={'promote'}
            className='absolute lg:top-2 top-auto lg:bottom-auto bottom-2 lg:left-2 left-2'
          />
        )}
        {!isArtist && (
          <div className='absolute lg:top-2 top-1 lg:right-2 right-1 badge'>
            <UI_IconSvg
              component={categoryToIcon(type)}
              className='lg:w-4 lg:h-4 w-3 h-3'
              width={16}
              height={16}
            />
            <UI_Typography
              variant='enLabelCaption'
              className='-mt-1 capitalize'
            >
              {translate(`global.${type}`)}
            </UI_Typography>
          </div>
        )}

        {hasCheckbox && !isArtist && (
          <div className='z-10 absolute flex items-center justify-center'>
            <UI_Checkbox width={36} height={36} onChange={handleSelect} />
          </div>
        )}

        {hasCheckbox && isArtist && (
          <div
            className={twMerge(
              !isChecked && 'hidden group-hover:flex',
              'z-10   absolute  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
            )}
          >
            <UI_RoundedCheckbox
              width={60}
              checkIcon='CheckCircleFilled'
              uncheckIcon='CheckCircle'
              height={60}
              checked={isChecked}
            />
          </div>
        )}

        {!isMobile && !hasCheckbox && (
          <div
            onClick={handleClickOnCard}
            className={twMerge(
              'transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100 z-9 absolute [background:linear-gradient(180deg,_rgba(50,_48,_58,_0),_#32303a)] -bottom-[1px] inset-0 rounded-space-100',
              !isTrack && 'bg-none group-hover:opacity-0',
            )}
          />
        )}
        {!isMobile && !hasCheckbox && isTrack && (
          <div className='transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100 flex z-10 items-center justify-between self-stretch absolute bottom-0 left-0 right-0 p-4'>
            <UI_PlayButton
              song={data as TMusic}
              isEnable={!isForceLogin || isLogin}
              type='secondary'
              allSongs={allSongs as Array<TMusic>}
            />

            <div className='flex items-center justify-between gap-1'>
              <div className='rounded-radius-1000 w-10 h-10 flex flex-row items-center justify-center p-3 box-border'>
                <div className='flex flex-row items-center justify-center gap-[8px]'>
                  <Like
                    id={id}
                    isFavorite={isFavorite}
                    className='lg:w-6 lg:h-6'
                  />
                </div>
              </div>
              <div className='rounded-radius-1000 w-10 h-10 flex flex-row items-center justify-center p-3 box-border'>
                <div className='flex flex-row items-center justify-center gap-[8px]'>
                  {actionMenu.length > 0 && (
                    <UI_ActionMenu items={actionMenu} position='left-top' />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='self-stretch flex flex-col gap-2'>
        <div
          className={twMerge(
            'self-stretch flex flex-row items-center justify-between',
          )}
        >
          <Title
            link={link}
            name={isPersian && persian_name !== '-' ? persian_name : name_en}
            isCenter={isArtist}
          />
          <TimeOrCounter
            value={
              isAlbum || isPlaylist
                ? `${songsCount} ${trackText}`
                : isArtist
                ? null
                : formattedTime(Number(duration))
            }
          />
        </div>
        <ArtistName
          link={isArtist ? null : artistLink}
          name={
            isArtist
              ? null
              : (artists?.[0]?.[isPersian ? 'name' : 'name_en'] as string)
          }
        />
      </div>
      <PlaylistDialog
        type='addToPlaylist'
        ref={addToPlaylistRef}
        song={data as TMusic & TArtist}
      />
    </div>
  )
}
