import { useParams } from 'next/navigation'
import {
  HorizontalCardItem,
  THorizontalCardItemProps,
} from './HorizontalCardItem'
import { UI_Typography } from '@/ui'
import { Element, TCategoryType, TUseParams } from '@/types'

export type THorizontalCardListProps = {
  data: THorizontalCardItemProps[]
  title: string
  type?: TCategoryType
}

export const HorizontalCardList = (
  props: THorizontalCardListProps,
): Element => {
  const { data = [], title = '', type } = props
  const { musicType } = useParams<TUseParams>()

  return (
    <div className='self-stretch section-4'>
      <UI_Typography variant='enDesktopTitle4' className='flex-1' component='b'>
        {title}
      </UI_Typography>
      {data?.map((item) => (
        <HorizontalCardItem
          key={item.id}
          {...item}
          type={type ?? (musicType as TCategoryType)}
        />
      ))}
    </div>
  )
}
