import { Row } from '@/components'
import { Children, Element } from '@/types'

export const Header = (props: Children): Element => {
  const { children } = props
  return (
    <Row className='self-stretch justify-between border border-solid border-transparent border-b-border-primary-reversed'>
      {children}
    </Row>
  )
}
