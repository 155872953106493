import { twMerge } from 'tailwind-merge'
import { Row } from '@/components'
import { UI_IconSvg, UI_Typography } from '@/ui'
import { Element } from '@/types'

type TBadgeType = 'promote'

type TBadgeAdProps = {
  className?: string
  type: TBadgeType
}

export const BadgeAd = (props: TBadgeAdProps): Element => {
  const { className, type = 'promote' } = props

  const text: Record<TBadgeType, string> = {
    promote: 'Promoted',
  }

  return (
    <Row
      className={twMerge(
        'bg-gradient-ad rounded-md items-center w-fit ps-1 pe-2 py-0.5 gap-1 h-5 lg:backdrop-blur-lg',
        className,
      )}
    >
      <UI_IconSvg component='Horn' width={16} height={16} />
      <UI_Typography variant='enLabelCaption' className='lg:-mt-0.5 -mt-1'>
        {text[type]}
      </UI_Typography>
    </Row>
  )
}
