import { twMerge } from 'tailwind-merge'
import { LibraryItem } from './LibraryItem'
import style from './library.module.css'
import { Element } from '@/types'

export enum LibraryData {
  All = 'all',
  Playlist = 'playlist',
  Track = 'track',
  Artist = 'artist',
  Album = 'album',
}

export type TLibraryData = `${LibraryData}`

const fakeLibraryData = Object.values(LibraryData)

export const Library = (): Element => {
  return (
    <div
      className={twMerge(
        style.scrollHidden,
        'self-stretch h-[39px] flex flex-row items-center justify-between gap-2 text-sm overflow-y-hidden overflow-auto',
      )}
    >
      {fakeLibraryData.map((item) => (
        <LibraryItem key={item} item={item} />
      ))}
    </div>
  )
}
