import * as React from 'react'
import type { SVGProps } from 'react'
const SvgRectangle1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 22 16'
    {...props}
  >
    <path
      stroke={props.color}
      strokeWidth={2}
      d='M17 15h1a3 3 0 0 0 3-3V4a3 3 0 0 0-3-3H4a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h1'
    />
  </svg>
)
export default SvgRectangle1
