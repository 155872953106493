import * as React from 'react'
import type { SVGProps } from 'react'
const SvgExternalLink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='External_link_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#External_link_svg__a)'>
      <path
        fill={props.color}
        d='M5 21c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 3 19V5c0-.55.196-1.02.587-1.413A1.926 1.926 0 0 1 5 3h6c.283 0 .52.096.713.288.191.191.287.429.287.712s-.096.52-.287.713A.968.968 0 0 1 11 5H5v14h14v-6c0-.283.096-.52.288-.713A.968.968 0 0 1 20 12c.283 0 .52.096.712.287.192.192.288.43.288.713v6c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 19 21H5ZM19 6.4 10.4 15a.948.948 0 0 1-.7.275A.948.948 0 0 1 9 15a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7L17.6 5H15a.968.968 0 0 1-.713-.287A.967.967 0 0 1 14 4c0-.283.096-.52.287-.712A.968.968 0 0 1 15 3h6v6c0 .283-.096.52-.288.713A.968.968 0 0 1 20 10a.968.968 0 0 1-.712-.287A.967.967 0 0 1 19 9V6.4Z'
      />
    </g>
  </svg>
)
export default SvgExternalLink
