/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  BaseQueryApi,
} from '@reduxjs/toolkit/query'
import { getCookie, setCookie } from 'cookies-next'
import { NextRequest, NextResponse } from 'next/server'
import { isSSR } from '@/variables'

// const _baseUrl = String(process.env.NEXT_CONTENT_URL)
const _newUrl = String(process.env.API_URL)
const _baseOauthUrl = String(process.env.NEXT_OAUTH_URL)
const _baseWalletUrl = String(process.env.NEXT_WALLET_URL)

type TApi = BaseQueryApi & {
  extra: {
    cookies: {
      refresh_token: string
    }
    context: {
      req: NextRequest
      res: NextResponse
    }
  }
}

const baseQuery = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers: Headers, { extra }: any) => {
      const { req, res } = extra.context ?? {}
      const token = getCookie('access_token', { req, res })
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  })

function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
const commonRunner = async (
  baseUrl: string,
  args: string | FetchArgs,
  api: TApi,
  extraOptions: FetchBaseQueryError & { delay?: number },
) => {
  extraOptions?.delay && (await timeout(extraOptions?.delay ?? 0))
  const result = await baseQuery(baseUrl)(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    const { req, res } = api.extra.context ?? {}
    const refreshToken = getCookie('refresh_token', { req, res })

    if (!isSSR && !refreshToken) {
      setCookie('access_token', localStorage.getItem('access_token'), {
        req,
        res,
      })
      setCookie('refresh_token', localStorage.getItem('refresh_token'), {
        req,
        res,
      })
    }

    if (!refreshToken) {
      return {
        error: result?.error,
      }
    }

    const refreshTokenResult = await fetch(
      `${_baseOauthUrl}/api/auth/token/refresh/`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      },
    )

    const newToken = await refreshTokenResult.json()

    if (newToken) {
      setCookie('access_token', newToken?.access, { req, res })
      setCookie('refresh_token', newToken?.refresh, { req, res })

      const _result = await baseQuery(baseUrl)(args, api, extraOptions)

      if (newToken?.access && !_result.error) {
        return _result
      } else {
        if (!isSSR) {
          window.location.href = '/login'
        }
      }
    }
  }
  return result
}

type TBaseQueryWithReAuth = BaseQueryFn<
  string | FetchArgs,
  TApi,
  FetchBaseQueryError
>

export const baseQueryWithReAuth: TBaseQueryWithReAuth = async (
  args,
  api,
  extraOptions,
): Promise<any> => {
  const result = await commonRunner(
    _newUrl,
    args,
    api as TApi,
    extraOptions as FetchBaseQueryError,
  )
  return result
}

export const baseAuthQueryWithReAuth: TBaseQueryWithReAuth = async (
  args,
  api,
  extraOptions,
): Promise<any> => {
  const result = await commonRunner(
    _baseOauthUrl,
    args,
    api as TApi,
    extraOptions as FetchBaseQueryError,
  )
  return result
}

export const baseWalletQueryWithReAuth: TBaseQueryWithReAuth = async (
  args,
  api,
  extraOptions,
): Promise<any> => {
  const result = await commonRunner(
    _baseWalletUrl,
    args,
    api as TApi,
    extraOptions as FetchBaseQueryError,
  )
  return result
}
