import { iconType } from '@/ui'
import { TCategoryType } from '@/types'

type TMap = {
  [key in TCategoryType]: iconType
}

export const categoryToIcon = (icon: TCategoryType): iconType => {
  const map: TMap = {
    album: 'Album',
    song: 'Music',
    playlist: 'Playlist',
    episode: 'Podcast',
    artist: 'ArtistFilled',
    podcast: 'Headphones',
  }

  return map[icon]
}
