import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import { REHYDRATE } from 'redux-persist'
import {
  addToFavorite,
  favoriteAlbum,
  favoriteArtist,
  favoriteSong,
  getAlbumById,
  getAlbums,
  getAlbumsByArtistId,
  getArtistById,
  getArtists,
  getFavoriteAlbums,
  getFavoriteArtists,
  getFavoriteSongs,
  getFavorites,
  getRecommendations,
  getSongById,
  getSongLastPlayedList,
  getSongs,
  getSongsByAlbumId,
  getSongsByArtistId,
  playSongByUUID,
  removeFromFavorite,
  unFavoriteAlbum,
  unFavoriteArtist,
  unFavoriteSong,
} from '@/store/api'
import { TAlbum, TArtist, TMusic } from '@/types'

type TInitial = {
  songs: Array<TMusic>
  albums: Array<TAlbum>
  artists: Array<TArtist>
}

const initialState: TInitial = {
  songs: [],
  albums: [],
  artists: [],
}

type TGetFav = TMusic | TAlbum | TArtist

const getFavIds = (items: Array<TGetFav>) =>
  items?.filter((x: TGetFav) => x.is_favorite).map((x: TGetFav) => x.id)

let oldHydrate = {}

const commonFunc = (
  state: TInitial,
  action: Array<any>,
  _state: keyof typeof initialState,
) => {
  const ids = getFavIds(action) ?? []
  const temp = [...new Set([...[...state[_state]], ...ids])]
  // state[_state] = temp
}

const commonFuncById = (
  state: TInitial,
  action: { payload: { items: TGetFav } },
  _state: keyof typeof initialState,
) => {
  const ids = getFavIds([action?.payload?.items])
  const temp = [...new Set([...[...state[_state]], ...ids])]
  // state[_state] = temp
}

export const favoriteSlice = createSlice({
  name: 'favorite',
  initialState,
  reducers: {
    setFavoriteSongs(state, action: PayloadAction<TInitial['songs']>) {
      state.songs = [...new Set([...state.songs, ...action.payload])]
    },
    removeFavoriteSong(state, action: PayloadAction<number>) {
      state.songs = state.songs.filter((x) => x.id !== action.payload)
    },
    setFavoriteAlbums(state, action: PayloadAction<TInitial['albums']>) {
      state.albums = [...new Set([...state.albums, ...action.payload])]
    },
    removeFavoriteAlbum(state, action: PayloadAction<number>) {
      state.albums = state.albums.filter((x) => x.id !== action.payload)
    },
    setFavoriteArtists(state, action: PayloadAction<TInitial['artists']>) {
      state.artists = [...new Set([...state.artists, ...action.payload])]
    },
    removeFavoriteArtist(state, action: PayloadAction<number>) {
      state.artists = state.artists.filter((x) => x.id !== action.payload)
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    builder.addMatcher(getSongsByAlbumId.matchFulfilled, (state, action) => {
      commonFunc(state, action.payload.results, 'albums')
    })

    builder.addMatcher(getRecommendations.matchFulfilled, (state, action) => {
      action.payload.map((s) => {
        commonFunc(state, s.songs, 'songs')
      })
    })

    builder.addMatcher(addToFavorite.matchFulfilled, (state, action) => {
      const _name = (action.meta.arg.originalArgs['model'] +
        's') as keyof TInitial
      const id = action.meta.arg.originalArgs.id
      if (state[_name].filter((x) => x.id !== id).length < 0)
        state[_name].push(action.payload)
    })
    builder.addMatcher(removeFromFavorite.matchFulfilled, (state, action) => {
      const id = action.meta.arg.originalArgs.id
      const model = (action.meta.arg.originalArgs.model + 's') as keyof TInitial
      // @ts-ignore
      state[model] = state[model].filter((x) => x.id != id)
      // state[model] = state[model].filter((x) => {
      //   return x.id != id
      // })
      // console.log(id, action.meta.arg.originalArgs)
      // console.log('remove from favorites')
      // const _name = (action.meta.arg.originalArgs.model + 's') as keyof TInitial
      // console.log(_name, action.meta.arg.originalArgs.id, state[_name])
      // state[_name] = state[_name].filter(
      //   (x) => x !== action.meta.arg.originalArgs.id,
      // )
    })
    builder.addMatcher(getFavorites.matchFulfilled, (state, action) => {
      const songs: Array<TMusic> = []
      action?.payload?.results.map((s) => s.song && songs.push(s.song))
      state.songs = songs
    })
    builder.addMatcher(getRecommendations.matchFulfilled, (state, action) => {
      const songs: any = []
      action?.payload?.map((s) => songs.push(...s.songs))
      console.log(songs)
    })
    // builder.addCase(HYDRATE, (state, action: any) => {
    //   oldHydrate = action.payload.favorite
    //   return {
    //     ...state,
    //     ...oldHydrate,
    //   }
    // })
    // builder.addCase(REHYDRATE, (state) => {
    //   return {
    //     ...state,
    //     ...oldHydrate,
    //   }
    // })
    // builder.addMatcher(getSongs.matchFulfilled, (state, action) =>
    //   commonFunc(state, action, 'songs'),
    // )
    // builder.addMatcher(getSongsByArtistId.matchFulfilled, (state, action) =>
    //   commonFunc(state, action, 'songs'),
    // )
    // builder.addMatcher(getSongsByAlbumId.matchFulfilled, (state, action) =>
    //   commonFunc(state, action, 'songs'),
    // )
    // builder.addMatcher(getSongLastPlayedList.matchFulfilled, (state, action) =>
    //   commonFunc(state, action, 'songs'),
    // )
    // builder.addMatcher(getFavoriteSongs.matchFulfilled, (state, action) =>
    //   commonFunc(state, action, 'songs'),
    // )
    // builder.addMatcher(getSongById.matchFulfilled, (state, action) =>
    //   commonFuncById(state, action, 'songs'),
    // )
    // builder.addMatcher(playSongByUUID.matchFulfilled, (state, action) =>
    //   commonFuncById(state, action, 'songs'),
    // )
    // builder.addMatcher(getAlbums.matchFulfilled, (state, action) =>
    //   commonFunc(state, action, 'albums'),
    // )
    // builder.addMatcher(getAlbumById.matchFulfilled, (state, action) =>
    //   commonFuncById(state, action, 'albums'),
    // )
    // builder.addMatcher(getFavoriteAlbums.matchFulfilled, (state, action) =>
    //   commonFunc(state, action, 'albums'),
    // )
    // builder.addMatcher(getAlbumsByArtistId.matchFulfilled, (state, action) =>
    //   commonFunc(state, action, 'albums'),
    // )
    // builder.addMatcher(getArtistById.matchFulfilled, (state, action) =>
    //   commonFuncById(state, action, 'artists'),
    // )
    // builder.addMatcher(getArtists.matchFulfilled, (state, action) =>
    //   commonFunc(state, action, 'artists'),
    // )
    // builder.addMatcher(getFavoriteArtists.matchFulfilled, (state, action) =>
    //   commonFunc(state, action, 'artists'),
    // )
    // builder.addMatcher(favoriteSong.matchFulfilled, (state, action) => {
    //   state.songs = [...new Set([...state.songs, action.meta.arg.originalArgs])]
    // })
    // builder.addMatcher(unFavoriteSong.matchFulfilled, (state, action) => {
    //   state.songs = state.songs.filter(
    //     (x) => x !== action.meta.arg.originalArgs,
    //   )
    // })
    // builder.addMatcher(favoriteAlbum.matchFulfilled, (state, action) => {
    //   state.albums = [
    //     ...new Set([...state.albums, action.meta.arg.originalArgs]),
    //   ]
    // })
    // builder.addMatcher(unFavoriteAlbum.matchFulfilled, (state, action) => {
    //   state.albums = state.albums.filter(
    //     (x) => x !== action.meta.arg.originalArgs,
    //   )
    // })
    // builder.addMatcher(favoriteArtist.matchFulfilled, (state, action) => {
    //   state.artists = [
    //     ...new Set([...state.artists, action.meta.arg.originalArgs]),
    //   ]
    // })
    // builder.addMatcher(unFavoriteArtist.matchFulfilled, (state, action) => {
    //   state.artists = state.artists.filter(
    //     (x) => x !== action.meta.arg.originalArgs,
    //   )
    // })
  },
})

export const {
  setFavoriteSongs,
  removeFavoriteSong,
  setFavoriteAlbums,
  removeFavoriteAlbum,
  setFavoriteArtists,
  removeFavoriteArtist,
} = favoriteSlice.actions
