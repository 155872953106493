/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import { REHYDRATE } from 'redux-persist'
import { getAdList } from '../api/advertisementApi'
import { RootState } from '..'
import { TAdvertisement } from '@/types'

type TInitial = {
  playerAd: TAdvertisement
  homeAd: Array<TAdvertisement>
  searchAd: Array<TAdvertisement>
  homeSliderAd: Array<TAdvertisement>
}

const initialState: TInitial = {
  playerAd: {} as TAdvertisement,
  homeAd: [] as Array<TAdvertisement>,
  searchAd: [] as Array<TAdvertisement>,
  homeSliderAd: [] as Array<TAdvertisement>,
}

function adIsInDateRange(from_time: string, to_time: string): boolean {
  const fromDate: Date = new Date(from_time)
  const toDate: Date = new Date(to_time)
  const checkDate: Date = new Date()
  return checkDate >= fromDate && checkDate <= toDate
}

let oldHydrate = {}

export const advertisementSlice = createSlice({
  name: 'advertisement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: any) => {
      oldHydrate = action.payload.advertisement
      return {
        ...state,
        ...oldHydrate,
      }
    })
    builder.addCase(REHYDRATE, (state) => {
      return {
        ...state,
        ...oldHydrate,
      }
    })
    builder.addMatcher(getAdList.matchFulfilled, (state, action) => {
      state.playerAd =
        action?.payload?.items
          ?.filter((item) => adIsInDateRange(item.from_time, item.to_time))
          ?.filter((x) => x.extra.section === 'player')?.[0] || {}
      state.homeAd =
        action?.payload?.items
          ?.filter((item) => adIsInDateRange(item.from_time, item.to_time))
          ?.filter((x) => x.extra.page === 'home') || []
      state.searchAd =
        action?.payload?.items
          ?.filter((item) => adIsInDateRange(item.from_time, item.to_time))
          ?.filter((x) => x.extra.page === 'search') || []
      state.homeSliderAd =
        action?.payload?.items
          ?.filter((item) => adIsInDateRange(item.from_time, item.to_time))
          ?.filter((x) => x.extra.page === 'home_slider') || []
    })
  },
})

export const selectPlayerAd = (state: RootState): TAdvertisement =>
  state?.advertisement?.playerAd || {}
export const selectAllHomeAd = (state: RootState): Array<TAdvertisement> => {
  if (!state.advertisement) return []
  return state?.advertisement?.homeAd
}
export const selectAllHomeSliderAd = (
  state: RootState,
): Array<TAdvertisement> => state?.advertisement?.homeSliderAd
export const selectAllSearchAd = (state: RootState): Array<TAdvertisement> =>
  state?.advertisement?.searchAd
