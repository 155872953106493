import * as React from 'react'
import type { SVGProps } from 'react'
const SvgRepeatSong = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={props.color}
      d='M13.594 10.633h-2.47v-5.75L9.79 5.03V3.46a6.26 6.26 0 0 0 .693-.133 3.08 3.08 0 0 0 .59-.229c.207-.103.383-.245.53-.427.148-.182.269-.406.362-.671h1.63v8.633ZM7.227 2.456h-1.54A3.686 3.686 0 0 0 2 6.142v8.83a3.686 3.686 0 0 0 3.686 3.685H8.52v-1.843H5.686a1.843 1.843 0 0 1-1.843-1.843V6.142c0-1.018.825-1.843 1.843-1.843h1.54V2.456Z'
    />
    <path
      fill={props.color}
      d='M16.773 4.3h1.54c1.019 0 1.844.824 1.844 1.842v8.83a1.843 1.843 0 0 1-1.843 1.842H13.8l1.78-1.78a.937.937 0 0 0-.132-1.17.937.937 0 0 0-1.172-.132l-3.352 3.352a.922.922 0 0 0 0 1.303l3.319 3.32a.932.932 0 0 0 1.204-.104.932.932 0 0 0 .123-1.176l-1.77-1.77h4.514A3.686 3.686 0 0 0 22 14.971V6.142a3.686 3.686 0 0 0-3.686-3.686h-1.54V4.3Z'
    />
  </svg>
)
export default SvgRepeatSong
