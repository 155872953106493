/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { deleteCookie } from 'cookies-next'
import { createWrapper } from 'next-redux-wrapper'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {
  advertisementApi,
  albumApi,
  artistApi,
  genreApi,
  oauthApi,
  playlistApi,
  profileApi,
  searchApi,
  songApi,
  walletApi,
  settingsApi,
} from './api'
import {
  advertisementSlice,
  favoriteSlice,
  followSlice,
  playlistSlice,
  playerSlice,
  ssrSlice,
  userProfileSlice,
  myPlaylistSlice,
  settingsSlice,
} from './slice'
import { categoryApi } from './api/categoryApi'
import { favoriteApi } from './api/favoriteApi'

const rootReducer = combineReducers({
  [advertisementApi.reducerPath]: advertisementApi.reducer,
  [artistApi.reducerPath]: artistApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [playlistApi.reducerPath]: playlistApi.reducer,
  [songApi.reducerPath]: songApi.reducer,
  [genreApi.reducerPath]: genreApi.reducer,
  [albumApi.reducerPath]: albumApi.reducer,
  [oauthApi.reducerPath]: oauthApi.reducer,
  [searchApi.reducerPath]: searchApi.reducer,
  [walletApi.reducerPath]: walletApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [favoriteApi.reducerPath]: favoriteApi.reducer,
  advertisement: advertisementSlice.reducer,
  ssr: ssrSlice.reducer,
  userProfile: userProfileSlice.reducer,
  favorite: favoriteSlice.reducer,
  follow: followSlice.reducer,
  playlist: playlistSlice.reducer,
  player: playerSlice.reducer,
  myPlaylist: myPlaylistSlice.reducer,
  settings: settingsSlice.reducer,
})

const blacklist = [
  'profile',
  'advertisements',
  'artists',
  'api/advertisements',
  'api/artists',
  'api/playlists',
  'api/profile',
  'api/songs',
  'api/genres',
  'api/albums',
  'api/oauth',
  'api/search',
  'api/wallet',
  'api/settings',
  'api/category',
  'api/favorites',
]

const version = 19

const concatMiddleWares = [
  advertisementApi.middleware,
  artistApi.middleware,
  profileApi.middleware,
  playlistApi.middleware,
  songApi.middleware,
  genreApi.middleware,
  albumApi.middleware,
  oauthApi.middleware,
  searchApi.middleware,
  walletApi.middleware,
  settingsApi.middleware,
  categoryApi.middleware,
  favoriteApi.middleware,
]

const reducerProxy = (state: any, action: any) => {
  const _state = { ...state }
  if (action.type === 'LOGOUT') {
    console.log('logout working ?')
    deleteCookie('access_token')
    deleteCookie('refresh_token')
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    delete _state['userProfile']
  }
  return rootReducer(_state, action)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const makeConfiguredStore = (reducer: any, context: any): any =>
  configureStore({
    reducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (gDM) =>
      gDM({
        serializableCheck: false,
        thunk: {
          extraArgument: {
            cookies: context.ctx?.req?.cookies,
            context: context.ctx,
          },
        },
      }).concat(concatMiddleWares),
  })

const makeStore = (context: any): ReturnType<typeof makeConfiguredStore> => {
  const isServer = typeof window === 'undefined'
  if (isServer) {
    const store = makeConfiguredStore(reducerProxy, context)
    return store
  } else {
    const persistConfig = {
      key: 'root',
      version,
      storage,
      blacklist,
      migrate: (state: any) => {
        return Promise.resolve(state)
      },
    }
    const persistedReducer = persistReducer(persistConfig, reducerProxy)
    const store = makeConfiguredStore(persistedReducer, context)
    store.__persistor = persistStore(store)
    return store
  }
}

export type AppStore = ReturnType<typeof makeStore>
export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = AppStore['dispatch']

export const wrapper = createWrapper<AppStore>(makeStore)
