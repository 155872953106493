import Link from 'next/link'
import { twMerge } from 'tailwind-merge'
import { UI_Image, UI_Typography } from '@/ui'
import { BadgeAd, Col, Row, SkipAd } from '@/components'
import { Element } from '@/types'

type TCardProps = {
  id: number
  title?: string
  subTitle?: string
  image_path?: string
  link?: string
  isOnlySkipAdButton?: boolean
  displaySkipAdButton?: boolean
  isImage?: boolean
  displayPromotedBadge?: boolean
  variant?: 'primary' | 'secondary' | 'tertiary'
  className?: string
  skipAdClassName?: string
}

export const CardAd = (props: TCardProps): Element | null => {
  const {
    id,
    title,
    subTitle,
    image_path,
    link,
    isOnlySkipAdButton,
    displaySkipAdButton = true,
    isImage = true,
    displayPromotedBadge = true,
    variant = 'primary',
    className,
    skipAdClassName,
  } = props

  if (!id) {
    return <div className='flex-1 flex items-center gap-6'></div>
  }

  if (isOnlySkipAdButton && !displaySkipAdButton) return null
  if (isOnlySkipAdButton)
    return <SkipAd isIcon variant={variant} className={skipAdClassName} />

  return (
    <Row className={twMerge('items-center gap-20 flex-1', className)}>
      <Link href={link ? String(link) : '#'}>
        <Row className='gap-2.5 items-center'>
          {isImage && (
            <UI_Image
              className='relative rounded-rounded w-12 h-12 object-cover'
              width={48}
              height={48}
              src={String(image_path)}
            />
          )}
          <Col className='gap-2'>
            <UI_Typography variant='enBodyBoldText3'>{title}</UI_Typography>
            <UI_Typography
              variant='enCaptionRegular'
              className='flex items-center gap-2'
            >
              {subTitle} {displayPromotedBadge && <BadgeAd type='promote' />}
            </UI_Typography>
          </Col>
        </Row>
      </Link>
      {displaySkipAdButton && (
        <SkipAd isIcon variant={variant} className={skipAdClassName} />
      )}
    </Row>
  )
}
