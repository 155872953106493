/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  buildCreateApi,
  coreModule,
  reactHooksModule,
} from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'
import { baseAuthQueryWithReAuth } from '../tools'

type TLogin = {
  access?: string
  refresh?: string
  ttl?: number
}

type TResult = TLogin

type TPassword = {
  password?: string
}

type TBody = TPassword & {
  username: string
}

type TMobile = {
  mobile?: string
}

type TBodyVerify = TMobile & {
  otp?: string
}

type TBodyRequest = TMobile

type TBodyForgetPassword = {
  otp?: string
  mobile: string
  step: number
  password?: string
}
type TOnboarding = {
  config: {
    artist_uuids: string[]
    genre_uuids: string[]
  }
}
type TResultOnboarding = {
  items: {
    artist_uuids: string[]
    genre_uuids: string[]
  }
}

const createApi = buildCreateApi(
  coreModule(),
  reactHooksModule({ unstable__sideEffectsInRender: true }),
)

export const oauthApi = createApi({
  // reducerPath: 'api/oauth',
  baseQuery: baseAuthQueryWithReAuth,
  extractRehydrationInfo(action: any, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
  tagTypes: ['Oauth', 'Onboarding'],
  endpoints: (builder) => ({
    login: builder.mutation<TResult, TBody>({
      query: (body) => ({
        url: '/api/auth/token/',
        method: 'POST',
        body,
      }),
    }),
    requestCode: builder.mutation<TResult, TBodyRequest>({
      query: (body) => ({
        url: '/api/auth/otp/request/',
        method: 'POST',
        body,
      }),
    }),
    verifyCode: builder.mutation<TResult, TBodyVerify>({
      query: (body) => ({
        url: '/api/auth/otp/login/',
        method: 'POST',
        body,
      }),
    }),
    register: builder.mutation<TResult, TBodyRequest>({
      query: (body) => ({
        url: '/register',
        method: 'POST',
        body,
      }),
    }),
    forgetPassword: builder.mutation<TResult, TBodyForgetPassword>({
      query: (body) => ({
        url: '/forget_password',
        method: 'POST',
        body,
      }),
    }),
    changePassword: builder.mutation<TResult, FormData>({
      query: (body) => ({
        url: '/change_password',
        method: 'POST',
        body,
      }),
    }),
    resendCode: builder.mutation<TResult, TMobile>({
      query: (body) => ({
        url: '/resend_code',
        method: 'POST',
        body,
      }),
    }),

    onboarding: builder.mutation<TResult, TOnboarding>({
      query: (body) => ({
        url: '/onboarding',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Onboarding'],
    }),
    getOnboarding: builder.query<TResultOnboarding, void>({
      query: () => '/onboarding',
      providesTags: ['Onboarding'],
    }),
  }),
})

export const {
  useLoginMutation,
  useVerifyCodeMutation,
  useRegisterMutation,
  useChangePasswordMutation,
  useResendCodeMutation,
  useForgetPasswordMutation,
  useOnboardingMutation,
  useGetOnboardingQuery,
  useLazyGetOnboardingQuery,
  useRequestCodeMutation,
  util: { getRunningQueriesThunk: getRunningOauthQueriesThunk },
} = oauthApi

export const {
  login,
  verifyCode,
  register,
  changePassword,
  resendCode,
  requestCode,
} = oauthApi.endpoints
