import * as React from 'react'
import type { SVGProps } from 'react'
const SvgNext = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Next_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Next_svg__a)'>
      <path
        fill={props.color}
        d='M17.5 18a.968.968 0 0 1-.712-.288A.968.968 0 0 1 16.5 17V7c0-.283.096-.52.288-.713A.968.968 0 0 1 17.5 6c.283 0 .52.096.712.287.192.192.288.43.288.713v10c0 .283-.096.52-.288.712A.968.968 0 0 1 17.5 18ZM7.05 16.975c-.333.233-.675.25-1.025.05a.973.973 0 0 1-.525-.9v-8.25c0-.4.175-.7.525-.9.35-.2.692-.183 1.025.05l6.2 4.15c.3.2.45.475.45.825s-.15.625-.45.825l-6.2 4.15Zm.45-2.725L10.9 12 7.5 9.75v4.5Z'
      />
    </g>
  </svg>
)
export default SvgNext
