import * as React from 'react'
import type { SVGProps } from 'react'
const SvgPlayFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Play_-_filled_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Play_-_filled_svg__a)'>
      <path
        fill={props.color}
        d='M9.525 18.025c-.333.217-.67.23-1.013.038-.341-.192-.512-.488-.512-.888V6.825c0-.4.17-.696.512-.888.342-.191.68-.179 1.013.038l8.15 5.175c.3.2.45.483.45.85s-.15.65-.45.85l-8.15 5.175Z'
      />
    </g>
  </svg>
)
export default SvgPlayFilled
