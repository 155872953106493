import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Circle_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Circle_svg__a)'>
      <path
        fill={props.color}
        d='M12 20a7.79 7.79 0 0 1-3.12-.63 8.079 8.079 0 0 1-2.54-1.71 8.08 8.08 0 0 1-1.71-2.54A7.79 7.79 0 0 1 4 12c0-1.107.21-2.147.63-3.12.42-.973.99-1.82 1.71-2.54a8.079 8.079 0 0 1 2.54-1.71A7.79 7.79 0 0 1 12 4a7.79 7.79 0 0 1 3.12.63c.973.42 1.82.99 2.54 1.71s1.29 1.567 1.71 2.54c.42.973.63 2.013.63 3.12a7.79 7.79 0 0 1-.63 3.12 8.08 8.08 0 0 1-1.71 2.54 8.08 8.08 0 0 1-2.54 1.71A7.79 7.79 0 0 1 12 20Z'
      />
    </g>
  </svg>
)
export default SvgCircle
