import * as React from 'react'
import type { SVGProps } from 'react'
const SvgPause = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Pause_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Pause_svg__a)'>
      <path
        fill={props.color}
        d='M15 19c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 13 17V7c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 15 5h2c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v10c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 17 19h-2Zm-8 0c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 5 17V7c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 7 5h2c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412v10c0 .55-.196 1.02-.588 1.413A1.926 1.926 0 0 1 9 19H7Zm8-2h2V7h-2v10Zm-8 0h2V7H7v10Z'
      />
    </g>
  </svg>
)
export default SvgPause
