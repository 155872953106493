import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import { Children } from '@/types'

type TBody = Children & {
  className?: string
}

export const Body = (props: TBody): ReactNode => {
  const { children, className } = props
  return <main className={twMerge('w-full', className)}>{children}</main>
}
