import * as React from 'react'
import type { SVGProps } from 'react'
const SvgArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Arrow_left_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Arrow_left_svg__a)'>
      <path
        fill={props.color}
        d='m10.8 12 3.9 3.9a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275l-4.6-4.6a.877.877 0 0 1-.213-.325A1.107 1.107 0 0 1 8.425 12c0-.133.02-.258.062-.375A.877.877 0 0 1 8.7 11.3l4.6-4.6a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7L10.8 12Z'
      />
    </g>
  </svg>
)
export default SvgArrowLeft
