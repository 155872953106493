import { twMerge } from 'tailwind-merge'
import { UI_Skeleton } from '@/ui'
import { Col } from '@/components'
import { useMobile } from '@/hooks'
import { Element } from '@/types'
import 'swiper/css'
import 'swiper/css/pagination'

type TCardItemLoading = {
  count?: number
  mobileCount?: number
  displayTitle?: boolean
  fit?: boolean
}

export const CardItemLoading = (props: TCardItemLoading): Element => {
  const { count = 5, mobileCount = 3, displayTitle = true, fit } = props
  const isMobile = useMobile()

  return (
    <Col
      className={twMerge(
        'lg:gap-6 gap-7 lg:w-full',
        fit ? 'w-full' : 'w-[120%]',
      )}
    >
      {displayTitle && (
        <div className='w-full flex items-baseline justify-between'>
          <div>
            <UI_Skeleton
              width={isMobile ? 100 : 200}
              height={15}
              rounded='full'
              unit='px'
            />
          </div>
          {!isMobile && (
            <div>
              <UI_Skeleton
                width={isMobile ? 40 : 60}
                height={6}
                rounded='full'
                unit='px'
              />
            </div>
          )}
        </div>
      )}
      <div className='lg:-m-4 -m-2 flex flex-wrap'>
        {Array.from({ length: isMobile ? mobileCount : count }).map(
          (_, index) => (
            <div
              key={index}
              className={twMerge(
                'flex flex-col gap-6 xl:w-1/5 lg:w-1/4 lg:p-4 p-2',
                fit ? 'w-1/2' : 'w-1/3',
              )}
            >
              <UI_Skeleton
                width={isMobile ? 190 : 280}
                height={isMobile ? 190 : 280}
                rounded='lg'
                className='aspect-square'
                unit='px'
              />
              <div className='flex items-center justify-between'>
                <UI_Skeleton
                  width={isMobile ? 70 : 100}
                  height={10}
                  rounded='full'
                  unit='px'
                />
                <UI_Skeleton width={20} height={6} rounded='full' unit='px' />
              </div>
              <UI_Skeleton
                width={isMobile ? 50 : 100}
                height={6}
                rounded='full'
                unit='px'
              />
            </div>
          ),
        )}
      </div>
    </Col>
  )
}
