import '@/styles/globals.css'
import { useUserAgent } from 'next-useragent'
import { domAnimation, LazyMotion } from 'framer-motion'
import type { AppInitialProps, AppProps } from 'next/app'
import Head from 'next/head'
import { useEffect, useMemo } from 'react'
import { Context as ResponsiveContext } from 'react-responsive'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { useRouter } from 'next/router'
import { shelfOrderList } from '.'
import { Layout as DefaultLayout } from '@/layout/Layout'
import LayoutSecondary from '@/layout/LayoutSecondary'
import {
  AppProvider,
  GlobalStateType,
  initContext,
  wrapper,
  setIsMobile,
  getAdList,
  getRunningAdvertisementsQueriesThunk,
  getSettingsList,
  getRunningSettingsQueriesThunk,
} from '@/store'
import { Element, TArtistsOrder, TShelfOrder } from '@/types'
import { useLocale } from '@/hooks'
import { UI_Toast } from '@/ui'
import { isSSR } from '@/variables'

export type TAppProps = AppProps & {
  Component: {
    layout?: 'secondary'
    header?: Element | null
    mobileHeader?: Element | null
    footer?: Element | null
    isNavigation?: boolean
  }
  userAgent?: string
  messages: Record<string, string>
}

const App = ({ Component, pageProps: otherProps }: TAppProps): Element => {
  const { header, mobileHeader, footer, layout, isNavigation } = Component
  const { store } = wrapper.useWrappedStore(otherProps)

  const router = useRouter()

  useEffect(() => {
    if (router.locale) {
      const dir = router.locale === 'fa' ? 'rtl' : 'ltr'
      document.querySelector('html')?.setAttribute('dir', dir)
      document.querySelector('body')?.setAttribute('dir', dir)
    }
  }, [router.locale])

  const Layout = useMemo(
    () => (layout === 'secondary' ? LayoutSecondary : DefaultLayout),
    [layout, header, mobileHeader, footer],
  )
  const props = useMemo(
    () =>
      layout === 'secondary'
        ? {
            header,
            mobileHeader,
            footer,
            isNavigation,
          }
        : {
            header,
            mobileHeader,
          },
    [layout, header, mobileHeader, footer],
  )
  const ua = useMemo(() => useUserAgent(otherProps.userAgent), [])

  const width = useMemo(() => {
    const device = ua.deviceType
    let width = 2559
    switch (device) {
      case 'mobile':
        width = 767
        break
      case 'tablet':
        width = 1023
        break
      case 'laptop':
        width = 1439
        break
      case 'tv':
        width = 2560
        break
    }
    return width
  }, [])

  const appStore =
    typeof window !== 'undefined' && localStorage.getItem('appStore')
  const parsedAppStore = appStore
    ? JSON.parse(appStore || '{}')
    : initContext.globalState
  const { locale, messages } = useLocale()

  const globalStates: GlobalStateType = useMemo(
    () => ({
      ...parsedAppStore,
      isMobile: Boolean(ua.isMobile),
      isTablet: Boolean(ua.isTablet),
      isPlayerFullScreen: false,
    }),
    [],
  )
  if (!appStore && typeof window !== 'undefined')
    localStorage.setItem('appStore', JSON.stringify(globalStates))

  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <link rel='manifest' href='/manifest.json' />
        <title>Moodic</title>
      </Head>
      <Provider store={store}>
        <IntlProvider locale={locale as string} messages={messages}>
          <ResponsiveContext.Provider
            value={{ width: isSSR ? undefined : width }}
          >
            <AppProvider values={globalStates}>
              <LazyMotion features={domAnimation}>
                <Layout {...props}>
                  <Component {...otherProps} />
                </Layout>
              </LazyMotion>
              <UI_Toast />
            </AppProvider>
          </ResponsiveContext.Provider>
        </IntlProvider>
      </Provider>
    </>
  )
}

App.getInitialProps = wrapper.getInitialAppProps(
  (store) =>
    async ({ ctx }): Promise<AppInitialProps> => {
      const userAgent = ctx?.req?.headers['user-agent']

      const ua = useUserAgent(String(userAgent))
      store.dispatch(setIsMobile(ua.isMobile))

      // store.dispatch(getAdList.initiate({ limit: 100 }))
      // await Promise.all(store.dispatch(getRunningAdvertisementsQueriesThunk()))

      // store.dispatch(
      //   getSettingsList.initiate({
      //     params: {},
      //     type: 'global',
      //   }),
      // )

      // store.dispatch(
      //   getSettingsList.initiate({
      //     params: {},
      //     type: 'home-page-' + ctx.locale,
      //   }),
      // )

      // await Promise.all(store.dispatch(getRunningSettingsQueriesThunk()))

      // const shelfOrder =
      //   (store?.getState()?.settings?.config
      //     ?.shelfOrder as Array<TShelfOrder>) || shelfOrderList

      // const searchShelfOrder =
      //   (store?.getState()?.settings?.config
      //     ?.searchShelfOrder as Array<TShelfOrder>) || shelfOrderList

      // const artistsOrder = store?.getState()?.settings?.config
      //   ?.artistsOrder as TArtistsOrder

      return {
        pageProps: {
          userAgent,
          shelfOrder: {},
          artistsOrder: {},
          searchShelfOrder: {},
        },
      }
    },
)

export default App
