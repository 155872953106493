import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import {} from '@/store/api'
import { TMusic } from '@/types'
import { uniqueArray } from '@/tools'

type TInitial = {
  selectedTrack: TMusic | null
  listenNext: Array<TMusic>
  allData: Array<TMusic> | null
  tempAllData: Array<TMusic> | null
  adStreamUrl: string | null
  isPlaying: boolean
  isAdPlaying: boolean
  isAdOnTrack: TMusic | null
  playerLoadingId: number
  listenedAds: Array<number> | null
}

const initialState: TInitial = {
  selectedTrack: null,
  listenNext: [],
  allData: null,
  tempAllData: null,
  adStreamUrl: null,
  isPlaying: false,
  isAdPlaying: false,
  isAdOnTrack: null,
  playerLoadingId: 0,
  listenedAds: null,
}

export const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    setMultiSongState(state, action: PayloadAction<Partial<TInitial>>) {
      if (action.payload?.selectedTrack)
        state.selectedTrack = action.payload?.selectedTrack
      if (action.payload?.listenNext)
        state.listenNext = action.payload?.listenNext
      if (action.payload?.allData) state.allData = action.payload?.allData
      if (action.payload?.adStreamUrl)
        state.adStreamUrl = action.payload?.adStreamUrl
      if (action.payload?.isPlaying) state.isPlaying = action.payload?.isPlaying
      if (action.payload?.isAdPlaying)
        state.isAdPlaying = action.payload?.isAdPlaying
      if (action.payload?.isAdOnTrack)
        state.isAdOnTrack = action.payload?.isAdOnTrack
      if (action.payload?.playerLoadingId)
        state.playerLoadingId = action.payload?.playerLoadingId
      if (action.payload?.listenedAds)
        state.listenedAds = action.payload?.listenedAds
    },
    setListenedAds(state, action: PayloadAction<number>) {
      const listenedAds = state.listenedAds ?? []
      state.listenedAds = [...listenedAds, action.payload]
    },
    setPlayerLoadingId(
      state,
      action: PayloadAction<TInitial['playerLoadingId']>,
    ) {
      state.playerLoadingId = action.payload
    },
    setIsAdOnTrack(state, action: PayloadAction<TInitial['isAdOnTrack']>) {
      state.isAdOnTrack = action.payload
    },
    setIsAdPlaying(state, action: PayloadAction<TInitial['isAdPlaying']>) {
      state.isAdPlaying = action.payload
    },
    setIsPlaying(state, action: PayloadAction<TInitial['isPlaying']>) {
      state.isPlaying = action.payload
    },
    setAdStreamUrl(state, action: PayloadAction<TInitial['adStreamUrl']>) {
      state.adStreamUrl = action.payload
    },
    setSelectedSong(state, action: PayloadAction<TInitial['selectedTrack']>) {
      state.selectedTrack = action.payload
    },
    setListenNext(state, action: PayloadAction<TInitial['listenNext']>) {
      state.listenNext = action.payload
    },
    setSongToAllData(state, action: PayloadAction<TInitial['allData']>) {
      let allData = [...(state?.allData ?? [])]
      allData = uniqueArray([
        ...allData,
        ...(action.payload as Array<TMusic>),
      ]) as Array<TMusic>
      state.allData = allData
    },
    removeMySongFromAllData(
      state,
      action: PayloadAction<TInitial['selectedTrack']>,
    ) {
      const allData = [...(state?.allData ?? [])]
      const filteredAllData = allData.filter(
        (x) => x?.my_music?.id !== action.payload?.my_music?.id,
      ) as Array<TMusic>
      state.allData = filteredAllData
    },
    removeMyPlaylistsSongs(state) {
      state.allData = []
    },
    setAllSong(state, action: PayloadAction<TInitial['allData']>) {
      const allData = [...(action.payload ?? [])]
      state.allData = allData
    },
    setTempAllSong(state, action: PayloadAction<TInitial['tempAllData']>) {
      const tempAllData = [...(action.payload ?? [])]
      state.tempAllData = tempAllData
    },
  },
})

export const {
  setSongToAllData,
  removeMySongFromAllData,
  removeMyPlaylistsSongs,
  setSelectedSong,
  setListenNext,
  setAdStreamUrl,
  setIsPlaying,
  setIsAdPlaying,
  setIsAdOnTrack,
  setPlayerLoadingId,
  setListenedAds,
  setMultiSongState,
  setAllSong,
  setTempAllSong,
} = playerSlice.actions

export const selectTempAllSongs = (state: RootState): TInitial['tempAllData'] =>
  state.player.tempAllData

export const selectAllSongs = (state: RootState): TInitial['allData'] =>
  state.player.allData

export const selectedSong = (state: RootState): TInitial['selectedTrack'] =>
  state.player.selectedTrack

export const listenNextSongs = (state: RootState): TInitial['listenNext'] =>
  state.player.listenNext

export const adStreamUrlSelector = (
  state: RootState,
): TInitial['adStreamUrl'] => state.player.adStreamUrl

export const isPlayingSelector = (state: RootState): TInitial['isPlaying'] =>
  state.player.isPlaying

export const isAdPlayingSelector = (
  state: RootState,
): TInitial['isAdPlaying'] => state.player.isAdPlaying

export const isAdOnTrackSelector = (
  state: RootState,
): TInitial['isAdOnTrack'] => state.player.isAdOnTrack

export const playerLoadingIdSelector = (
  state: RootState,
): TInitial['playerLoadingId'] => state.player.playerLoadingId

export const listenedAdsSelector = (
  state: RootState,
): TInitial['listenedAds'] => state.player.listenedAds
