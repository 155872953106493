import { useIntl } from 'react-intl'
import { useCallback } from 'react'
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat'
import { TranslationKey } from '@/tools/flattenMessages'

type TUseTranslate = {
  translate: (
    key: TranslationKey,
    options?:
      | Record<string, PrimitiveType | FormatXMLElementFn<string, string>>
      | undefined,
  ) => string
}

export const useTranslate = (): TUseTranslate => {
  const { formatMessage } = useIntl()
  const translate = useCallback(
    (
      key: TranslationKey,
      options?:
        | Record<string, PrimitiveType | FormatXMLElementFn<string, string>>
        | undefined,
    ) => formatMessage({ id: key }, options),
    [formatMessage],
  )

  return { translate }
}
