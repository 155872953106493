import { useCallback, useMemo } from 'react'
import {
  isPlayingSelector,
  listenNextSongs,
  playerLoadingIdSelector,
  selectAllSongs,
  selectPlayerAd,
  selectedSong,
  setMultiSongState,
  setIsAdOnTrack,
  setIsAdPlaying,
  setIsPlaying,
  setListenNext,
  setPlayerLoadingId,
  setSelectedSong,
  useAppContext,
  useAppDispatch,
  useAppSelector,
  useLazyPlaySongByUUIDQuery,
} from '@/store'
import { TMusic, TError } from '@/types'
import { errorHandler, getRandomNumber } from '@/tools'

export type TUsePlayInput = {
  item?: TMusic
}

type TUsePlayOutput = {
  isPlaying: boolean | number | undefined
  isSelected: boolean | number | undefined
  handleClickPlay: (item: TMusic) => void
  playerLoading: number | undefined
  handleNextSong: () => void
  handleBackSong: () => void
  handleListenNext: (_item: TMusic) => void
  handleTogglePlay: () => void
  handleFullScreen: () => void
}

export const usePlayerActions = (args: TUsePlayInput): TUsePlayOutput => {
  const { item } = args
  const { globalState, changeGlobalState } = useAppContext()
  const [executePlaySong] = useLazyPlaySongByUUIDQuery()
  const playerAd = useAppSelector(selectPlayerAd)
  const allSongs = useAppSelector(selectAllSongs)
  const selectedTrack = useAppSelector(selectedSong)
  const listenNext = useAppSelector(listenNextSongs)
  const dispatch = useAppDispatch()
  const isShuffle = globalState?.isShuffle
  const id = Number(item?.id)
  const _isPlaying = useAppSelector(isPlayingSelector)
  const playerLoadingId = useAppSelector(playerLoadingIdSelector)
  const playerLoading = useMemo(() => playerLoadingId, [playerLoadingId])
  // const [execRecommendations] = useLazyGetRecommendationsQuery()

  const isPlaying = useMemo(
    () =>
      selectedTrack?.id &&
      (selectedTrack?.id === id || selectedTrack?.album?.id === id) &&
      _isPlaying,
    [selectedTrack?.id, _isPlaying, id],
  )

  const isSelected = useMemo(
    () => selectedTrack?.id && selectedTrack.id === id,
    [selectedTrack?.id, id],
  )

  const checkHaveAd = (_item: TMusic) => {
    if (
      playerAd?.extra?.before_song_ids?.includes(Number(_item?.id)) &&
      Number(listenNext?.[0]?.id) !== Number(_item?.id)
    ) {
      const _listenNext = listenNext?.filter((x) => x.id !== _item?.id) || []
      dispatch(
        setMultiSongState({
          selectedTrack: null,
          listenNext: [_item, ..._listenNext],
          adStreamUrl: playerAd?.file_path,
          isPlaying: true,
          isAdOnTrack: _item,
        }),
      )
      return false
    }
    return true
  }

  const handleClickPlay = (music: TMusic, ignoreAd = false) => {
    localStorage.setItem('currentTime', 'null')
    if (!ignoreAd && !checkHaveAd(music)) return

    if (_isPlaying && selectedTrack?.id === music?.id) {
      dispatch(setIsPlaying(false))
    } else if (selectedTrack?.id === music?.id) {
      dispatch(setIsPlaying(true))
    } else {
      if (music?.id) {
        dispatch(setSelectedSong(music))
        dispatch(setIsPlaying(true))
        dispatch(setIsAdOnTrack(null))
      } else {
        dispatch(setPlayerLoadingId(music?.id))
        executePlaySong(String(music?.id))
          .then((response) => {
            dispatch(setSelectedSong(response?.data as TMusic))
            dispatch(setIsPlaying(true))
            dispatch(setIsAdOnTrack(null))
          })
          .catch((error: TError) => {
            errorHandler(error)
          })
          .finally(() => {
            dispatch(setIsAdPlaying(false))
            dispatch(setPlayerLoadingId(0))
          })
      }
    }
  }

  // Recommendations function
  const recommendations = useCallback(
    (
      nextUuid: string | undefined,
      currentUuid: string | undefined,
      currentItemIndex: number,
    ) => {
      if (
        Number(currentItemIndex) >= Number(allSongs?.length) - 2 &&
        currentItemIndex
      ) {
        // execRecommendations(String(nextUuid ?? currentUuid)).then(
        //   (response) => {
        //     dispatch(
        //       setAllSong([
        //         ...(allSongs as TMusic[]),
        //         ...((response.data?.items as TMusic[]) ?? []),
        //       ]),
        //     )
        //     if (!nextUuid && Number(response.data?.items?.length) > 0) {
        //       handleClickPlay(response.data?.items?.[0] as TMusic)
        //     }
        //   },
        // )
      }
    },
    [allSongs],
  )

  const handleNextSong = useCallback(() => {
    // if have next music
    if (listenNext?.length > 0) {
      handleClickPlay(listenNext[0], true)
      const newList = listenNext.slice(1, listenNext.length)
      dispatch(setListenNext(newList))
      return
    }

    // find current music index
    const currentItemIndex = allSongs?.findIndex(
      (x) => x.id === selectedTrack?.id,
    )
    const index = isShuffle
      ? getRandomNumber(0, Number(allSongs?.length) - 1)
      : Number(currentItemIndex) + 1
    const _nextSong = allSongs?.[index]

    // call Recommendations
    if (currentItemIndex) {
      const _currentSong = allSongs?.[currentItemIndex]
      recommendations(_nextSong?.uuid, _currentSong?.uuid, currentItemIndex)
    }

    if (_nextSong?.id) {
      handleClickPlay(_nextSong)
    }
  }, [listenNext, selectedTrack, allSongs])

  const handleBackSong = () => {
    const currentItemIndex = allSongs?.findIndex(
      (x) => x.id === selectedTrack?.id,
    )
    const index = isShuffle
      ? getRandomNumber(0, Number(allSongs?.length) - 1)
      : Number(currentItemIndex) - 1
    const _backSong = allSongs?.[index]
    if (_backSong?.id) {
      handleClickPlay(_backSong)
    }
  }

  const handleListenNext = (_nextMusic: TMusic) => {
    const _AllListenNext = listenNext || []
    dispatch(setListenNext([..._AllListenNext, _nextMusic]))
  }

  const handleTogglePlay = () => {
    dispatch(setIsPlaying(!_isPlaying))
  }

  const handleFullScreen = () => {
    changeGlobalState?.({
      key: 'isPlayerFullScreen',
      value: !globalState?.isPlayerFullScreen,
    })
  }

  return {
    isPlaying,
    isSelected,
    handleClickPlay,
    playerLoading,
    handleNextSong,
    handleBackSong,
    handleListenNext,
    handleTogglePlay,
    handleFullScreen,
  }
}
