import * as React from 'react'
import type { SVGProps } from 'react'
const SvgAddedPeople = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 26 24'
    {...props}
  >
    <mask
      id='Added_people_svg__a'
      width={26}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h26v24H0z' />
    </mask>
    <g fill={props.color} mask='url(#Added_people_svg__a)'>
      <path d='M10 12c-1.1 0-2.042-.392-2.825-1.175C6.392 10.042 6 9.1 6 8s.392-2.042 1.175-2.825C7.958 4.392 8.9 4 10 4s2.042.392 2.825 1.175C13.608 5.958 14 6.9 14 8s-.392 2.042-1.175 2.825C12.042 11.608 11.1 12 10 12Zm-7 8a.967.967 0 0 1-.712-.288A.968.968 0 0 1 2 19v-1.8c0-.567.146-1.087.438-1.563.291-.475.679-.837 1.162-1.087a14.843 14.843 0 0 1 3.15-1.163A13.76 13.76 0 0 1 10 13c1.1 0 2.183.13 3.25.387 1.067.259 2.117.646 3.15 1.163.483.25.87.612 1.163 1.087.291.476.437.996.437 1.563V19c0 .283-.096.52-.288.712A.968.968 0 0 1 17 20H3Zm1-2h12v-.8a.973.973 0 0 0-.5-.85c-.9-.45-1.808-.787-2.725-1.012a11.6 11.6 0 0 0-5.55 0c-.917.225-1.825.562-2.725 1.012a.973.973 0 0 0-.5.85v.8Zm6-8c.55 0 1.02-.196 1.412-.588C11.804 9.021 12 8.55 12 8c0-.55-.196-1.02-.588-1.412A1.926 1.926 0 0 0 10 6c-.55 0-1.02.196-1.412.588A1.926 1.926 0 0 0 8 8c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588Z' />
      <path
        stroke={props.color}
        strokeWidth={0.2}
        d='m24.628 7.43-.003.004-4.87 5.561-2.726-2.823a.632.632 0 0 0-.472-.193.632.632 0 0 0-.472.193.672.672 0 0 0-.185.484c0 .19.06.355.185.484a297.965 297.965 0 0 1 3.171 3.349.583.583 0 0 0 .23.164.69.69 0 0 0 .239.03.632.632 0 0 0 .472-.193l.433-.449.003-.003 4.94-5.641a.672.672 0 0 0 .184-.483c0-.19-.06-.354-.185-.483a.632.632 0 0 0-.472-.194.632.632 0 0 0-.472.194Z'
      />
    </g>
  </svg>
)
export default SvgAddedPeople
