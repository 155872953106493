import { NextPage } from 'next'
import { twMerge } from 'tailwind-merge'
import Link from 'next/link'
import { ExtraLinks } from './comp/ExtraLinks'
import { Title } from './comp/Title'
import useLogin from './useLogin'
import { OtpInput } from './comp/OtpInput'
import { SwitchLoginType } from './comp/SwitchLoginType'
import { Element } from '@/types'
import { Logo } from '@/layout/sidebar/Logo'
import { useTranslate } from '@/hooks'
import { UI_Input, UI_Input_Password, UI_Typography } from '@/ui'
import { UI_Button } from '@/ui/ui_button/UI_Button'
import { mobileValidation } from '@/variables'
import { preventInvalidMobileInputKeyDown } from '@/tools/preventInvalidMobileInputKeyDown'
import { preventInvalidPasswordInput } from '@/tools'

export type TLoginForm = {
  phone: string
  password?: string
  otp_code?: string
}

type TLoginProps = {
  inModal?: boolean
  onCloseModal?: () => void
}

export const Login: NextPage<TLoginProps> = (props: TLoginProps): Element => {
  const { inModal, onCloseModal } = props
  const { translate } = useTranslate()
  const {
    isOtpCode,
    loginType,
    onChangeLoginType,
    buttonText,
    ttl,
    handleClearOtpCode,
    handleChangePhoneNumber,
    handleChangedOtp,
    register,
    handleSubmit,
    isValid,
    onSubmit,
    buttonRef,
    errors,
  } = useLogin(inModal, onCloseModal)

  return (
    <div
      className={twMerge(
        'w-full flex items-center justify-center',
        !inModal && 'h-screen lg:h-[92vh]',
      )}
    >
      <div className='flex flex-col items-center w-full'>
        {!inModal && (
          <>
            <Logo width={60} height={60} textVariant='enDesktopTitle3' />
            <Title
              title={
                !isOtpCode
                  ? translate('login.sign-with-phone')
                  : translate('login.we-sent-code')
              }
            />
          </>
        )}
        <form
          className='w-[335px] flex flex-col gap-12'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='flex flex-col items-start gap-8 relative'>
            {(!!errors?.otp_code || !!errors?.phone) && (
              <UI_Typography
                variant='enBodyRegularText1'
                className='text-content-negative flex justify-center w-full absolute -top-9'
              >
                {errors?.otp_code?.message}
                {errors?.phone?.message}
              </UI_Typography>
            )}

            {!isOtpCode && (
              <SwitchLoginType onChange={onChangeLoginType} value={loginType} />
            )}
            {!isOtpCode && (
              <UI_Input
                autoComplete='off'
                label={translate('global.phone-number')}
                baseClassName='border-border-border-inactive bg-cards-glass-secondary'
                type='tel'
                placeholder='09XXXXXXXX'
                validation={mobileValidation}
                {...register('phone', {
                  required: true,
                  pattern: {
                    value: mobileValidation,
                    message: 'شماره موبایل وارد شده صحیح نیست',
                  },
                })}
                onKeyDown={preventInvalidMobileInputKeyDown}
                name='phone'
              />
            )}
            {loginType === 'password' && (
              <UI_Input_Password
                autoComplete='off'
                label={translate('global.password')}
                baseClassName='border-border-border-inactive bg-cards-glass-secondary'
                placeholder='**************'
                // validation={passwordValidation}
                {...register('password', {
                  required:
                    loginType === 'password' &&
                    translate('errors.password-required'),
                  // minLength: {
                  //   value: 6,
                  //   message: translate('errors.password-minLength'),
                  // },
                  // pattern: {
                  //   value: passwordValidation,
                  //   message: translate('errors.password-pattern'),
                  // },
                })}
                name='password'
                onKeyDown={preventInvalidPasswordInput}
                onPaste={preventInvalidPasswordInput}
                errorMessage={errors?.password?.message}
                isError={Boolean(errors.password)}
              />
            )}

            {isOtpCode && (
              <OtpInput
                ref={buttonRef}
                onClearOtpCode={handleClearOtpCode}
                onChanged={handleChangedOtp}
                ttl={ttl}
                isError={!!errors?.otp_code}
              />
            )}
          </div>
          <UI_Typography
            variant='enBodyBoldText2'
            align='center'
            className='-mt-3'
          >
            <Link href='/forget-password' className=' cursor-pointer'>
              {translate('login.forget-password')}
            </Link>
          </UI_Typography>

          <UI_Button
            fullWidth
            typography={{
              variant: 'enLabelBoldLabel3',
            }}
            type='submit'
            disabled={!isValid}
            ref={buttonRef}
            className={`!h-12 ${!isValid && 'pointer-events-none'} `}
          >
            {buttonText}
          </UI_Button>

          <ExtraLinks
            isOtpCode={isOtpCode}
            onChangePhoneNumber={handleChangePhoneNumber}
          />
        </form>
      </div>
    </div>
  )
}
