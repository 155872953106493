import { m } from 'framer-motion'
import { usePathname } from 'next/navigation'
import { NavigationItem } from './NavigationItem'
import { iconType } from '@/ui'
import { Element } from '@/types'
import { useTranslate } from '@/hooks'

type TNavItemProps = {
  text: string
  href: string
  icon: iconType
  iconFilled: iconType
  isEnable?: boolean
  label: string
}

export const Navigation = (): Element => {
  const path = usePathname()
  const { translate } = useTranslate()
  const menuItems: TNavItemProps[] = [
    {
      href: '/',
      icon: 'Home',
      iconFilled: 'HomeFilled',
      text: translate('global.home'),
      label: 'Home Page',
    },
    {
      href: '/my-playlist',
      icon: 'RecentlyPlayed',
      iconFilled: 'RecentlyPlayed',
      text: translate('global.playlist'),
      label: 'My Playlist Page',
    },
    {
      href: '/search',
      icon: 'Search',
      iconFilled: 'SearchFilled',
      text: translate('global.search'),
      label: 'Search Page',
    },
    {
      href: '/libraries',
      icon: 'Library',
      iconFilled: 'LibraryFilled',
      text: translate('global.library'),
      label: 'Library Page',
    },
  ]

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.5,
      }}
      className='w-full flex flex-row justify-around bg-cards-fill-primary p-3 items-center text-white'
    >
      {(menuItems as TNavItemProps[])?.map((menu, index) => (
        <NavigationItem
          isEnable={path === menu.href}
          key={index}
          href={menu.href}
          icon={menu.icon}
          iconFilled={menu.iconFilled}
          text={menu.text}
          label={menu.label}
        />
      ))}
    </m.div>
  )
}
