import * as React from 'react'
import type { SVGProps } from 'react'
const SvgShare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={props.color}
      d='M18 22a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 15 19c0-.117.008-.238.025-.363s.042-.237.075-.337l-7.05-4.1c-.283.25-.6.446-.95.588-.35.141-.717.212-1.1.212a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 3 12c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 6 9c.383 0 .75.07 1.1.213.35.141.667.337.95.587l7.05-4.1a1.843 1.843 0 0 1-.075-.338A2.738 2.738 0 0 1 15 5c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 18 2c.833 0 1.542.292 2.125.875S21 4.167 21 5s-.292 1.542-.875 2.125A2.893 2.893 0 0 1 18 8c-.383 0-.75-.07-1.1-.213a3.293 3.293 0 0 1-.95-.587L8.9 11.3c.033.1.058.212.075.337a2.742 2.742 0 0 1 0 .726 1.838 1.838 0 0 1-.075.337l7.05 4.1c.283-.25.6-.446.95-.588.35-.141.717-.212 1.1-.212.833 0 1.542.292 2.125.875S21 18.167 21 19s-.292 1.542-.875 2.125A2.893 2.893 0 0 1 18 22Zm0-16c.283 0 .52-.096.712-.287A.967.967 0 0 0 19 5a.967.967 0 0 0-.288-.713A.968.968 0 0 0 18 4a.968.968 0 0 0-.712.287A.967.967 0 0 0 17 5c0 .283.096.52.288.713.191.191.429.287.712.287ZM6 13c.283 0 .52-.096.713-.287A.968.968 0 0 0 7 12a.968.968 0 0 0-.287-.713A.967.967 0 0 0 6 11a.967.967 0 0 0-.713.287A.968.968 0 0 0 5 12c0 .283.096.52.287.713.192.191.43.287.713.287Zm12 7c.283 0 .52-.096.712-.288A.968.968 0 0 0 19 19a.968.968 0 0 0-.288-.712A.968.968 0 0 0 18 18a.968.968 0 0 0-.712.288A.968.968 0 0 0 17 19c0 .283.096.52.288.712.191.192.429.288.712.288Z'
    />
  </svg>
)
export default SvgShare
