import Link from 'next/link'
import { twMerge } from 'tailwind-merge'
import { UI_IconSvg, UI_Image } from '@/ui'
import { musicImage } from '@/tools/musicImage'
import { Element, TCategoryType, TArtist, TMusic, TPlaylist } from '@/types'
import { categoryToIcon } from '@/tools'
import { useLocale } from '@/hooks'
import { useSearch } from './useSearch'

type TResultItem = Partial<TMusic> &
  Partial<TPlaylist> &
  Partial<TArtist> & {
    type: TCategoryType
  }

export const ResultItem = (props: TResultItem): Element => {
  const { name_en, name, name: nickname, id, type, image } = props
  const { isPersian } = useLocale()
  const { toggleOpenSubMenu } = useSearch()

  const _name = isPersian && name !== '-' ? name : name_en
  const _artist = nickname
  const isArtist = type === 'artist'

  return (
    <div className='card-item-2'>
      <div className='flex-1 flex items-center justify-between'>
        <Link
          href={`/${type.toLowerCase()}/${id}`}
          onClick={() => toggleOpenSubMenu()}
          className='flex items-center justify-center pr-1 gap-3'
        >
          <div className='rounded-md w-10 h-10 overflow-hidden flex items-center justify-center'>
            <UI_Image
              className={twMerge(
                'object-cover',
                isArtist ? 'rounded-full' : 'rounded-md',
              )}
              src={image ? image : '/images/artist-default.jpg'}
              width={40}
              height={40}
            />
          </div>
          <div className='h-10 flex flex-col justify-center gap-1.5'>
            {_name && (
              <b className='self-stretch leading-[16px] h-3 ellipsis lg:max-w-[200px] max-w-[150px]'>
                {_name}
              </b>
            )}
            {_artist && (
              <div className='self-stretch leading-[14px] flex items-center h-3 ellipsis lg:max-w-[200px] max-w-[150px]'>
                {_artist}
              </div>
            )}
          </div>
        </Link>
        <div className='flex items-center justify-end gap-2 text-center'>
          <div className='rounded-radius-1000 bg-cards-glass-primary flex items-center py-0.5 pr-2 pl-1.5 gap-1'>
            <UI_IconSvg
              component={categoryToIcon(type)}
              width={16}
              height={16}
            />
            <div className='leading-[14px] capitalize'>{type}</div>
          </div>
          {/* <UI_IconSvg component='AddToPlaylist' width={20} height={20} /> */}
        </div>
      </div>
    </div>
  )
}
