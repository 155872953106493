import * as React from 'react'
import type { SVGProps } from 'react'
const SvgPublicFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={props.color}
      d='M6 22c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 4 20V10c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 6 8h9V6c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0 0 12 3c-.7 0-1.313.212-1.838.638-.525.425-.879.954-1.062 1.587a.994.994 0 0 1-.412.563A1.197 1.197 0 0 1 8 6a.99.99 0 0 1-.713-.275.67.67 0 0 1-.212-.65c.183-1.133.737-2.096 1.663-2.888C9.663 1.397 10.75 1 12 1c1.383 0 2.563.488 3.537 1.462C16.512 3.438 17 4.617 17 6v2h1c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v10c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 18 22H6Zm6-5c.55 0 1.02-.196 1.412-.587.392-.392.588-.863.588-1.413s-.196-1.02-.588-1.412A1.926 1.926 0 0 0 12 13c-.55 0-1.02.196-1.412.588A1.926 1.926 0 0 0 10 15c0 .55.196 1.02.588 1.413.391.391.862.587 1.412.587Z'
    />
  </svg>
)
export default SvgPublicFilled
