import Link from 'next/link'
import { twMerge } from 'tailwind-merge'
import { UI_Typography } from '@/ui'
import { Element } from '@/types'

type TTitleProps = {
  name: string | null
  link: string | null
  isCenter: boolean
}

export const Title = (props: TTitleProps): Element | null => {
  const { name, link, isCenter } = props
  if (!name) return null
  return (
    <Link
      href={String(link)}
      className={twMerge(
        isCenter
          ? 'w-full justify-center flex'
          : 'lg:max-w-[160px] max-w-[120px] flex',
      )}
    >
      <UI_Typography
        variant='enBodyBoldText2'
        className={twMerge(isCenter ? '' : 'ellipsis')}
        align='center'
      >
        {name}
      </UI_Typography>
    </Link>
  )
}
