import { ReactNode } from 'react'
import { UI_Typography, UI_Button, UI_Image } from '@/ui'
import { Element } from '@/types'
import { Col, Row } from '@/components'

type TUI_EmptyProps = {
  height?: number
  href?: string
  title?: string
  children?: ReactNode
  buttonTitle?: string
  hasImage?: boolean
}

export const UI_Empty = (props: TUI_EmptyProps): Element => {
  const { height, href, title, children, buttonTitle, hasImage = false } = props
  const imageUrl = '/images/error/empty-states.svg'

  return (
    <div
      className='w-full h-full flex items-center justify-center'
      style={height ? { height: `${height}px` } : {}}
    >
      <Col className='items-center justify-center'>
        {hasImage && (
          <Row className='w-[400px] h-[250px] overflow-hidden items-center justify-center'>
            <UI_Image
              src={imageUrl}
              width={700}
              height={700}
              className='object-cover rounded-2xl -mt-12'
            />
          </Row>
        )}
        <div className='section-8 items-center'>
          <UI_Typography variant='enDesktopTitle4'>
            {title ?? 'There is nothing to show!'}
          </UI_Typography>
          {children ?? (
            <UI_Button {...{ href }}>{buttonTitle ?? 'Explore Now'}</UI_Button>
          )}
        </div>
      </Col>
    </div>
  )
}
