import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFireFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Fire_-_filled_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Fire_-_filled_svg__a)'>
      <path
        fill={props.color}
        d='M4 14c0-1.883.558-3.692 1.675-5.425 1.117-1.733 2.65-3.25 4.6-4.55.367-.25.746-.262 1.137-.037.392.225.588.562.588 1.012v1.3c0 .567.196 1.042.588 1.425.391.383.87.575 1.437.575.283 0 .554-.062.813-.187.258-.125.487-.305.687-.538a.851.851 0 0 1 .513-.312.722.722 0 0 1 .587.137 7.942 7.942 0 0 1 2.475 2.875c.6 1.167.9 2.408.9 3.725 0 1.467-.358 2.804-1.075 4.012a8.086 8.086 0 0 1-2.825 2.863c.283-.4.504-.837.662-1.313.159-.475.238-.979.238-1.512a4.8 4.8 0 0 0-.375-1.887 4.957 4.957 0 0 0-1.075-1.588L12 11.1l-3.525 3.475a5.093 5.093 0 0 0-1.1 1.6A4.709 4.709 0 0 0 7 18.05c0 .533.08 1.037.237 1.512.159.476.38.913.663 1.313a8.086 8.086 0 0 1-2.825-2.863C4.358 16.805 4 15.467 4 14Zm8-.1 2.125 2.075c.283.283.5.6.65.95.15.35.225.725.225 1.125 0 .817-.292 1.512-.875 2.087-.583.575-1.292.863-2.125.863s-1.542-.288-2.125-.863A2.823 2.823 0 0 1 9 18.05c0-.383.075-.754.225-1.113.15-.358.367-.679.65-.962L12 13.9Z'
      />
    </g>
  </svg>
)
export default SvgFireFilled
