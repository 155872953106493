/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  buildCreateApi,
  coreModule,
  reactHooksModule,
} from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'
import { baseQueryWithReAuth } from '../tools'
import { TAlbum, TArtist, TMusic, TPlaylist } from '@/types'

type TResults = {
  model_name: 'album' | 'song' | 'artist' | 'playlist'
  song?: TMusic
  album?: TAlbum
  artist?: TArtist
  playlist?: TPlaylist
}

type Paginate<T> = {
  count: number
  results: T[]
}

const createApi = buildCreateApi(
  coreModule(),
  reactHooksModule({ unstable__sideEffectsInRender: true }),
)

export const favoriteApi = createApi({
  reducerPath: 'api/favorites',
  baseQuery: baseQueryWithReAuth,
  extractRehydrationInfo(action: any, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
  endpoints: (builder) => ({
    addToFavorite: builder.mutation({
      query: ({ model, id }) => ({
        url: `/api/favorites/${model}/${id}/`,
        method: 'POST',
      }),
    }),
    removeFromFavorite: builder.mutation({
      query: ({ model, id }) => ({
        url: `/api/favorites/${model}/${id}/`,
        method: 'DELETE',
      }),
    }),

    getFavorites: builder.query<{ results: TResults[] }, any>({
      query: () => '/api/favorites/',
    }),

    getFavoritesModel: builder.query<
      Paginate<TResults>,
      { model?: string; params?: any }
    >({
      query: ({ model, params }) => ({
        url: `/api/favorites/${model}/`,
        params,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useAddToFavoriteMutation,
  useRemoveFromFavoriteMutation,
  usePrefetch: useFavoritePrefetch,
  useGetFavoritesQuery,
  useLazyGetFavoritesQuery,
  useGetFavoritesModelQuery,
  useLazyGetFavoritesModelQuery,
  util: { getRunningQueriesThunk: getRunningFavoriteQueriesThunk },
} = favoriteApi

export const { addToFavorite, removeFromFavorite, getFavorites } =
  favoriteApi.endpoints
