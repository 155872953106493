/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  buildCreateApi,
  coreModule,
  reactHooksModule,
} from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'
import { baseQueryWithReAuth } from '../tools'
import { TMusic } from '@/types'
import { ParameterNormalize, TParams, objectToQueryString } from '@/tools'

type TResult = TMusic

type TResults = {
  results: Array<TMusic>
  count: number
}

type AResult = {
  category: any
  songs: Array<TMusic>
}

type AResults = Array<AResult>

const createApi = buildCreateApi(
  coreModule(),
  reactHooksModule({ unstable__sideEffectsInRender: true }),
)

export const songApi = createApi({
  reducerPath: 'api/songs',
  baseQuery: baseQueryWithReAuth,
  extractRehydrationInfo(action: any, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
  tagTypes: [
    'Recommendations',
    'SongById',
    'Songs',
    'SongsByArtistId',
    'SongsByAlbumId',
    'LastPlayed',
    'FavoriteSongs',
  ],
  endpoints: (builder) => ({
    getRecommendations: builder.query<AResults, string>({
      query: () => '/api/recommendations/',
      providesTags: ['Recommendations'],
    }),

    getSongById: builder.query<TResult, string>({
      query: (id) => `/api/songs/${id}/`,
      providesTags: ['SongById'],
    }),

    playSongByUUID: builder.query<TResult, string>({
      query: (uuid) => `/songs/${uuid}/play`,
    }),

    getSongsWithQuery: builder.query<TResults, string>({
      query: (query) => `/api/songs/${query}`,
      providesTags: ['Songs'],
    }),

    getSongs: builder.query<TResults, TParams>({
      query: (params) =>
        `/songs${objectToQueryString(ParameterNormalize(params))}`,
      providesTags: ['Songs'],
    }),

    getSongsByArtistId: builder.query<TResults, number>({
      query: (artistId) => `/api/artists/${artistId}/songs`,
      providesTags: ['SongsByArtistId'],
    }),

    getSongsByAlbumId: builder.query<TResults, number>({
      query: (albumId) => `/songs?$limit=50&$sortby=-id&album.id=${albumId}`,
      providesTags: ['SongsByAlbumId'],
    }),

    getSongLastPlayedList: builder.query<TResults, TParams>({
      query: (params) =>
        `/songs/last_played${objectToQueryString(ParameterNormalize(params))}`,
      providesTags: ['LastPlayed'],
    }),

    getFavoriteSongs: builder.query<TResults, TParams>({
      query: (params) =>
        `/songs/favorites${objectToQueryString(ParameterNormalize(params))}`,
      providesTags: ['FavoriteSongs'],
    }),

    favoriteSong: builder.mutation<TResult, number>({
      query: (songId) => {
        return {
          url: `/favorite/song/${songId}`,
          method: 'POST',
        }
      },
      invalidatesTags: ['FavoriteSongs'],
    }),

    unFavoriteSong: builder.mutation<TResult, number>({
      query: (songId) => {
        return {
          url: `/favorite/song/${songId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['FavoriteSongs'],
    }),
  }),
})

export const {
  useGetRecommendationsQuery,
  useLazyGetRecommendationsQuery,
  useGetSongsWithQueryQuery,
  useLazyGetSongsWithQueryQuery,
  usePlaySongByUUIDQuery,
  useLazyPlaySongByUUIDQuery,
  useGetSongsQuery,
  useLazyGetSongsQuery,
  usePrefetch: useSongsPrefetch,
  useGetSongByIdQuery,
  useLazyGetSongByIdQuery,
  useGetSongsByArtistIdQuery,
  useLazyGetSongsByArtistIdQuery,
  useGetSongsByAlbumIdQuery,
  useLazyGetSongsByAlbumIdQuery,
  useGetFavoriteSongsQuery,
  useGetSongLastPlayedListQuery,
  useLazyGetSongLastPlayedListQuery,
  useLazyGetFavoriteSongsQuery,
  useFavoriteSongMutation,
  useUnFavoriteSongMutation,
  util: { getRunningQueriesThunk: getRunningSongQueriesThunk },
} = songApi

export const {
  getRecommendations,
  getSongsWithQuery,
  getSongs,
  playSongByUUID,
  getSongById,
  getSongsByArtistId,
  getSongLastPlayedList,
  getSongsByAlbumId,
  getFavoriteSongs,
  favoriteSong,
  unFavoriteSong,
} = songApi.endpoints
