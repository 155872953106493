/* eslint-disable no-prototype-builtins */
const mapKeys = {
  fromDate: '$fromdate.create_at',
  toDate: '$todate.create_at',
  limit: '$limit',
  sortBy: '$sortby',
  categoryId: '$category.id',
  page: '$page',
  name: 'name',
  fullName: '$ct.name',
  englishName: '$ct.english_name',
  persianName: '$ct.persian_name',
  artistsId: 'artists.id',
  active: 'active',
  description: 'description',
  genresId: 'genres.id',
  moodsId: 'moods.id',
  categoriesId: 'categories.id',
  tagsId: 'tags.id',
  albumId: 'album.id',
  createdAt: 'created_at',
  q: 'q',
  uuids: 'uuid',
} as const

type TKeys = keyof typeof mapKeys

type MapValues = (typeof mapKeys)[keyof typeof mapKeys]

export type TParams = Partial<Record<TKeys, unknown>>

export type TParameterNormalizeOutput = Partial<Record<MapValues, unknown>>

export const ParameterNormalize = (obj: TParams): TParameterNormalizeOutput => {
  const result: TParameterNormalizeOutput = {}
  for (const key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      obj[key as TKeys] !== undefined &&
      obj[key as TKeys] !== null
    ) {
      result[mapKeys[key as TKeys]] = obj[key as TKeys]
    }
  }
  return result
}

export const objectToQueryString = (obj: TParams): string => {
  const str = []
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${p}=${obj[p as TKeys]}`)
    }
  return `?${str.join('&')}`
}

export const objectToQueryStringNormal = (
  obj: Record<string, string>,
): string => {
  const str = []
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${p}=${obj[p as TKeys]}`)
    }
  return `?${str.join('&')}`
}
