import * as React from 'react'
import type { SVGProps } from 'react'
const SvgAddPeopleFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Add_people_-_filled_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Add_people_-_filled_svg__a)'>
      <path
        fill={props.color}
        d='M20 14a.968.968 0 0 1-.712-.287A.968.968 0 0 1 19 13v-2h-2a.968.968 0 0 1-.712-.287A.968.968 0 0 1 16 10c0-.283.096-.52.288-.713A.968.968 0 0 1 17 9h2V7c0-.283.096-.52.288-.713A.968.968 0 0 1 20 6c.283 0 .52.096.712.287.192.192.288.43.288.713v2h2c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 23 11h-2v2c0 .283-.096.52-.288.713A.968.968 0 0 1 20 14Zm-10-2c-1.1 0-2.042-.392-2.825-1.175C6.392 10.042 6 9.1 6 8s.392-2.042 1.175-2.825C7.958 4.392 8.9 4 10 4s2.042.392 2.825 1.175C13.608 5.958 14 6.9 14 8s-.392 2.042-1.175 2.825C12.042 11.608 11.1 12 10 12Zm-7 8a.967.967 0 0 1-.712-.288A.968.968 0 0 1 2 19v-1.8c0-.567.146-1.087.438-1.563.291-.475.679-.837 1.162-1.087a14.843 14.843 0 0 1 3.15-1.163A13.76 13.76 0 0 1 10 13c1.1 0 2.183.13 3.25.387 1.067.259 2.117.646 3.15 1.163.483.25.87.612 1.163 1.087.291.476.437.996.437 1.563V19c0 .283-.096.52-.288.712A.968.968 0 0 1 17 20H3Z'
      />
    </g>
  </svg>
)
export default SvgAddPeopleFilled
