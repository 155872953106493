/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  buildCreateApi,
  coreModule,
  reactHooksModule,
} from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'
import { baseWalletQueryWithReAuth } from '../tools'
import { ParameterNormalize, TParams, objectToQueryString } from '@/tools'
import { TSubscription, TWallet } from '@/types'

type TBody = {
  amount?: number
  discountCode?: number
  selectPaymentMethod?: string
}

export type TSubscriptionResult = {
  items: Array<TSubscription>
  total: number
}

type TResult = {
  items: Array<TWallet>
  total: number
}

const createApi = buildCreateApi(
  coreModule(),
  reactHooksModule({ unstable__sideEffectsInRender: true }),
)

export const walletApi = createApi({
  reducerPath: 'api/wallet',
  baseQuery: baseWalletQueryWithReAuth,
  extractRehydrationInfo(action: any, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
  tagTypes: ['Payments', 'Subscriptions', 'Accounting'],
  endpoints: (builder) => ({
    payments: builder.query<TResult, TParams>({
      query: (params) =>
        `/payments${objectToQueryString(ParameterNormalize(params))}`,
      providesTags: ['Payments'],
    }),

    subscriptions: builder.query<TSubscriptionResult, TParams>({
      query: (params) =>
        `/subscriptions${objectToQueryString(ParameterNormalize(params))}`,
      providesTags: ['Subscriptions'],
    }),

    accounting: builder.query<TSubscriptionResult, TParams>({
      query: (params) =>
        `/accountings${objectToQueryString(ParameterNormalize(params))}`,
      providesTags: ['Accounting'],
    }),

    paymentCharge: builder.mutation<TResult, TBody>({
      query: (body) => ({
        url: '/payment/charge',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Payments'],
    }),
  }),
})

export const {
  usePaymentsQuery,
  useLazyPaymentsQuery,
  usePaymentChargeMutation,
  useSubscriptionsQuery,
  useLazySubscriptionsQuery,
  useAccountingQuery,
  useLazyAccountingQuery,
  util: { getRunningQueriesThunk: getRunningWalletQueriesThunk },
} = walletApi

export const { payments, paymentCharge, subscriptions, accounting } =
  walletApi.endpoints
