import Link from 'next/link'
import { twMerge } from 'tailwind-merge'
import { UI_Typography } from '@/ui'
import { Element } from '@/types'

type TArtistNameProps = {
  link: string | null
  name: string | null
}

export const ArtistName = (props: TArtistNameProps): Element | null => {
  const { link, name } = props
  if (!name || !link) return null
  return (
    <div className={twMerge('self-stretch')}>
      <Link href={link}>
        <UI_Typography
          variant='enBodyRegularText3'
          component='div'
          className={twMerge('flex items-center w-56')}
        >
          {name}
        </UI_Typography>
      </Link>
    </div>
  )
}
