import { ChangeEvent, InputHTMLAttributes, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { UI_IconSvg, iconType } from '@/ui'
import { Element } from '@/types'

type TUI_RoundedCheckbox = InputHTMLAttributes<HTMLInputElement> & {
  className?: string
  width?: number
  height?: number
  checkIcon?: iconType
  uncheckIcon?: iconType
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

export const UI_RoundedCheckbox = forwardRef<
  HTMLInputElement,
  TUI_RoundedCheckbox
>((props, ref): Element => {
  const {
    className = '',
    width = 20,
    height = 20,
    uncheckIcon = 'CheckCircle',
    checkIcon = 'CheckCircleFilled',
    onChange,
    ...otherProps
  } = props

  return (
    <div className='flex items-center justify-center'>
      <input
        type='checkbox'
        ref={ref}
        className={twMerge(
          'relative peer shrink-0 box-border appearance-none bg-transparent cursor-pointer rounded-full border border-solid border-white p-4',
          className,
        )}
        style={{ width, height }}
        onChange={onChange}
        {...otherProps}
      />
      <UI_IconSvg
        className='absolute block peer-checked:hidden pointer-events-none'
        width={width}
        height={height}
        component={uncheckIcon}
      ></UI_IconSvg>
      <UI_IconSvg
        className='absolute hidden peer-checked:block pointer-events-none'
        width={width}
        height={height}
        component={checkIcon}
      ></UI_IconSvg>
    </div>
  )
})

UI_RoundedCheckbox.displayName = 'UI_RoundedCheckbox'
