export type TGreeting = 'morning' | 'afternoon' | 'night'

export const greeting = (): TGreeting => {
  const currentHour = new Date().getHours()

  if (currentHour >= 0 && currentHour < 12) {
    return 'morning'
  } else if (currentHour >= 12 && currentHour < 17) {
    return 'afternoon'
  } else {
    return 'night'
  }
}
