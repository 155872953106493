import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFullscreenClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Fullscreen_close_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Fullscreen_close_svg__a)'>
      <path
        fill={props.color}
        d='M8 16H6a.967.967 0 0 1-.713-.287A.968.968 0 0 1 5 15c0-.283.096-.52.287-.713A.967.967 0 0 1 6 14h3c.283 0 .52.096.713.287.191.192.287.43.287.713v3c0 .283-.096.52-.287.712A.967.967 0 0 1 9 19a.967.967 0 0 1-.713-.288A.968.968 0 0 1 8 18v-2Zm8 0v2c0 .283-.096.52-.287.712A.968.968 0 0 1 15 19a.968.968 0 0 1-.713-.288A.968.968 0 0 1 14 18v-3c0-.283.096-.52.287-.713A.968.968 0 0 1 15 14h3c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 18 16h-2ZM8 8V6c0-.283.096-.52.287-.713A.968.968 0 0 1 9 5c.283 0 .52.096.713.287.191.192.287.43.287.713v3c0 .283-.096.52-.287.713A.968.968 0 0 1 9 10H6a.968.968 0 0 1-.713-.287A.968.968 0 0 1 5 9c0-.283.096-.52.287-.713A.968.968 0 0 1 6 8h2Zm8 0h2c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 18 10h-3a.968.968 0 0 1-.713-.287A.967.967 0 0 1 14 9V6c0-.283.096-.52.287-.713A.968.968 0 0 1 15 5c.283 0 .52.096.713.287.191.192.287.43.287.713v2Z'
      />
    </g>
  </svg>
)
export default SvgFullscreenClose
