import Link from 'next/link'
import { twMerge } from 'tailwind-merge'
import { UI_IconSvg, UI_Image, UI_Typography } from '@/ui'
import { useLocale, useMobile, useTranslate } from '@/hooks'
import { categoryToIcon, formattedTime, musicImage } from '@/tools'
import { Element, TCategoryType, TArtist } from '@/types'

type TCardItemProps = {
  type: 'playlist' | 'artist' | 'album' | 'song'
  name?: string
  songs_count?: number
  id: number
  uuid: string
  duration?: string
  artist?: TArtist
  image?: string
}

export const libraryToApiEndpointMapper = {
  playlist: 'playlists',
  track: 'songs',
  artist: 'artists',
  album: 'albums',
  episode: 'episodes',
}

export const CardItem = (props: TCardItemProps): Element => {
  const {
    name,
    id,
    uuid,
    songs_count = 0,
    type,
    duration,
    artist,
    image,
  } = props
  const { translate } = useTranslate()
  const { isPersian } = useLocale()

  const renderSection = () => {
    switch (type) {
      case 'playlist':
      case 'album':
        return (
          <span>
            {translate(songs_count > 1 ? 'detail.songs' : 'detail.songs', {
              count: songs_count,
            })}
          </span>
        )

      case 'song':
        return (
          <UI_Typography variant='enLabelRegularLabel3' component='time'>
            {formattedTime(Number(duration))}
          </UI_Typography>
        )

      default:
        break
    }
  }
  const isMobile = useMobile()

  return (
    <div className='flex flex-col gap-3 xl:w-1/5 lg:w-1/4 md:w-1/3 sm:w-1/2 w-1/2 lg:p-4 p-2 font-peyda'>
      <Link
        href={`/${type}/${id}`}
        className='self-stretch relative rounded-space-100 overflow-hidden flex items-center justify-center w-full h-full'
      >
        <UI_Image
          className={`object-cover w-full h-full`}
          src={image ? image : '/images/artist-default.jpg'}
          width={isMobile ? 150 : 224}
          height={isMobile ? 150 : 224}
        />

        <div className='absolute top-2 right-2 badge'>
          {/* {categoryToIcon(type as keyof typeof categoryToIcon)} */}
          <UI_IconSvg
            className='w-4 h-4'
            component={categoryToIcon(type as keyof typeof categoryToIcon)}
          />
          <span className='capitalize'>{translate(`global.${type}`)}</span>
        </div>
      </Link>
      <div className='section-2'>
        <Link
          href={`/${type?.toLowerCase()}/${id}`}
          className='flex items-center justify-between gap-2'
        >
          <UI_Typography
            variant='enBodyRegularText1'
            className={twMerge(isMobile && 'ellipsis max-w-[100px]')}
          >
            {name}
          </UI_Typography>
          {renderSection()}
        </Link>
        {artist && (
          <Link
            href={`/artist/${artist?.id}`}
            className='flex items-center justify-between gap-2'
          >
            <UI_Typography
              variant='enBodyRegularText1'
              className={twMerge(isMobile && 'ellipsis max-w-[100px]')}
            >
              {artist?.[isPersian ? 'name' : 'name_en']}
            </UI_Typography>
          </Link>
        )}
      </div>
    </div>
  )
}
