import * as React from 'react'
import type { SVGProps } from 'react'
const SvgEyeHideFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Eye_Hide-filled_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Eye_Hide-filled_svg__a)'>
      <path
        fill={props.color}
        d='m19.3 16.5-3.2-3.2c.133-.283.233-.57.3-.862.067-.292.1-.605.1-.938 0-1.25-.438-2.312-1.313-3.187S13.25 7 12 7a4.2 4.2 0 0 0-.938.1 4.24 4.24 0 0 0-.862.3L7.65 4.85A11.08 11.08 0 0 1 12 4c2.383 0 4.525.63 6.425 1.888 1.9 1.258 3.325 2.895 4.275 4.912.05.083.083.188.1.313s.025.254.025.387a1.972 1.972 0 0 1-.125.7 11.49 11.49 0 0 1-1.438 2.375A10.467 10.467 0 0 1 19.3 16.5Zm-.2 5.4-3.5-3.45c-.583.183-1.171.32-1.763.413-.591.091-1.204.137-1.837.137-2.384 0-4.525-.63-6.425-1.887-1.9-1.259-3.325-2.896-4.275-4.913a.812.812 0 0 1-.1-.312 2.932 2.932 0 0 1 0-.763.796.796 0 0 1 .1-.3c.35-.75.766-1.442 1.25-2.075A13.292 13.292 0 0 1 4.15 7L2.075 4.9a.933.933 0 0 1-.275-.687c0-.275.1-.513.3-.713a.948.948 0 0 1 .7-.275c.283 0 .516.092.7.275l17 17c.183.183.279.413.287.688a.93.93 0 0 1-.287.712.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275ZM12 16a4.9 4.9 0 0 0 .512-.025c.159-.017.33-.05.513-.1l-5.4-5.4c-.05.183-.084.354-.1.513-.017.158-.025.329-.025.512 0 1.25.437 2.313 1.312 3.188S10.75 16 12 16Zm2.65-4.15-3-3c.95-.15 1.725.117 2.325.8.6.683.825 1.417.675 2.2Z'
      />
    </g>
  </svg>
)
export default SvgEyeHideFilled
