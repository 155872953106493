import * as React from 'react'
import type { SVGProps } from 'react'
const SvgHelpFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Help_-_filled_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Help_-_filled_svg__a)'>
      <path
        fill={props.color}
        d='M11.95 18c.35 0 .646-.12.888-.363.241-.241.362-.537.362-.887s-.12-.646-.362-.887a1.207 1.207 0 0 0-.888-.363c-.35 0-.646.12-.887.363a1.207 1.207 0 0 0-.363.887c0 .35.12.646.363.887.241.242.537.363.887.363Zm.05 4a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0 1 12 22Zm.1-14.3c.417 0 .78.133 1.088.4.308.267.462.6.462 1 0 .367-.113.692-.338.975-.224.283-.479.55-.762.8-.383.333-.72.7-1.013 1.1-.291.4-.437.85-.437 1.35 0 .233.088.43.263.588.175.158.379.237.612.237a.89.89 0 0 0 .638-.25c.175-.167.287-.375.337-.625.067-.35.217-.662.45-.938.233-.274.483-.537.75-.787.383-.367.713-.767.987-1.2a2.65 2.65 0 0 0 .413-1.45c0-.85-.346-1.546-1.038-2.088C13.821 6.271 13.017 6 12.1 6c-.633 0-1.237.133-1.813.4a2.796 2.796 0 0 0-1.312 1.225.902.902 0 0 0-.112.637.739.739 0 0 0 .337.513c.233.133.475.175.725.125a.996.996 0 0 0 .625-.425c.183-.25.412-.442.688-.575.274-.133.562-.2.862-.2Z'
      />
    </g>
  </svg>
)
export default SvgHelpFilled
