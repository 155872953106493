import * as React from 'react'
import type { SVGProps } from 'react'
const SvgArtistFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={props.color}
      d='M12.04 12c-1.106 0-2.053-.392-2.84-1.175C8.413 10.042 8.02 9.1 8.02 8s.393-2.042 1.18-2.825C9.987 4.392 10.934 4 12.04 4c1.105 0 2.051.392 2.838 1.175.787.783 1.18 1.725 1.181 2.825 0 1.1-.394 2.041-1.18 2.825-.788.784-1.734 1.175-2.84 1.175Zm-7.036 8a.972.972 0 0 1-.716-.29A.966.966 0 0 1 4 19v-1.8a2.97 2.97 0 0 1 .431-1.55 2.99 2.99 0 0 1 1.177-1.1 14.963 14.963 0 0 1 3.165-1.163 13.96 13.96 0 0 1 6.533 0c1.096.267 2.158.657 3.165 1.162a2.971 2.971 0 0 1 1.608 2.65V19c0 .284-.097.521-.29.713a.983.983 0 0 1-.716.289l-14.069-.002Zm1.005-2h12.06v-.8a.974.974 0 0 0-.503-.85 13.146 13.146 0 0 0-2.738-1.012 11.693 11.693 0 0 0-5.578 0c-.947.234-1.866.574-2.738 1.011a.976.976 0 0 0-.502.85v.8Zm6.03-8.001a1.94 1.94 0 0 0 1.42-.587 1.92 1.92 0 0 0 .59-1.413c0-.55-.197-1.02-.59-1.413A1.937 1.937 0 0 0 12.039 6a1.94 1.94 0 0 0-1.42.587c-.393.392-.59.863-.59 1.413s.197 1.021.59 1.413c.394.391.868.587 1.42.587Z'
    />
    <path
      fill={props.color}
      d='M12.06 10.433a2.44 2.44 0 0 0 2.445-2.434 2.44 2.44 0 0 0-2.446-2.434 2.44 2.44 0 0 0-2.446 2.434 2.44 2.44 0 0 0 2.446 2.434ZM5.505 16.186v2.465h13.233v-2.714l-6.616-1.904-6.617 2.153ZM19.513 13.622c-.484 0-.896-.169-1.235-.506a1.666 1.666 0 0 1-.508-1.23c0-.48.169-.89.508-1.228.339-.338.75-.506 1.235-.506.102 0 .204.007.305.022.097.014.19.046.276.094V6.106a.565.565 0 0 1 .357-.537.573.573 0 0 1 .224-.041h1.742a.582.582 0 0 1 .58.578.576.576 0 0 1-.58.578h-1.162v5.203c0 .481-.17.891-.508 1.229-.34.337-.75.506-1.234.506Z'
    />
  </svg>
)
export default SvgArtistFilled
