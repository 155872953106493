import { useState, useRef, useEffect, RefObject } from 'react'

type TUseScrollWithClickOutput = {
  scrollX: number
  scrollEnd: boolean
  slide: (shift: number) => void
  scrollCheck: () => void
  scrollElement: RefObject<HTMLDivElement>
}

export const useScrollWithClick = (): TUseScrollWithClickOutput => {
  const [scrollX, setScrollX] = useState(0)
  const [scrollEnd, setScrollEnd] = useState(false)
  const scrollElement = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (
      scrollElement.current &&
      scrollElement?.current?.scrollWidth ===
        scrollElement?.current?.offsetWidth
    ) {
      setScrollEnd(true)
    } else {
      setScrollEnd(false)
    }
  }, [scrollElement?.current?.scrollWidth, scrollElement?.current?.offsetWidth])

  const slide = (shift: number) => {
    if (scrollElement.current) {
      scrollElement.current.scrollLeft += shift
      if (
        Math.floor(
          scrollElement.current.scrollWidth - scrollElement.current.scrollLeft,
        ) <= scrollElement.current.offsetWidth
      ) {
        setScrollEnd(true)
      } else {
        setScrollEnd(false)
      }
    }
  }

  const scrollCheck = () => {
    if (scrollElement.current) {
      setScrollX(scrollElement.current.scrollLeft)
      if (
        Math.floor(
          scrollElement.current.scrollWidth - scrollElement.current.scrollLeft,
        ) <= scrollElement.current.offsetWidth
      ) {
        setScrollEnd(true)
      } else {
        setScrollEnd(false)
      }
    }
  }

  return { scrollX, scrollEnd, slide, scrollCheck, scrollElement }
}
