import { ChangeEvent, InputHTMLAttributes, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { TVariantClasses, UI_Typography } from '@/ui'
import { Element } from '@/types'

type TUI_Radio = InputHTMLAttributes<HTMLInputElement> & {
  label?: string
  className?: string
  width?: number
  height?: number
  checked?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  typography?: {
    variant?: TVariantClasses
    className?: string
  }
}

export const UI_Radio = forwardRef<HTMLInputElement, TUI_Radio>(
  (props, ref): Element => {
    const {
      label,
      className = '',
      width = 24,
      height = 24,
      checked = false,
      onChange,
      typography,
      ...otherProps
    } = props

    return (
      <div
        className={twMerge('relative flex items-center cursor-pointer', label)}
      >
        <input
          type='radio'
          ref={ref}
          className={twMerge(
            'relative peer bg-transparent border-border-inactive border-solid border-[2px] rounded-full cursor-pointer',
            className,
          )}
          style={{ width, height }}
          onChange={onChange}
          defaultChecked={checked}
          {...otherProps}
        />
        {typography && (
          <UI_Typography
            variant={typography?.variant}
            className={twMerge('select-none', typography?.className)}
          >
            {label}
          </UI_Typography>
        )}
      </div>
    )
  },
)

UI_Radio.displayName = 'UI_Radio'
