import * as React from 'react'
import type { SVGProps } from 'react'
const SvgArrowForwardBig = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Arrow_Forward_big_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Arrow_Forward_big_svg__a)'>
      <path
        fill={props.color}
        d='m14.475 12-7.35-7.35a1.17 1.17 0 0 1-.362-.888 1.26 1.26 0 0 1 .387-.887c.25-.25.546-.375.888-.375.341 0 .637.125.887.375l7.675 7.7c.2.2.35.425.45.675.1.25.15.5.15.75s-.05.5-.15.75c-.1.25-.25.475-.45.675l-7.7 7.7c-.25.25-.542.37-.875.363a1.246 1.246 0 0 1-.875-.388 1.213 1.213 0 0 1-.375-.888c0-.341.125-.637.375-.887L14.475 12Z'
      />
    </g>
  </svg>
)
export default SvgArrowForwardBig
