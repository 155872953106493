import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFire = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Fire_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Fire_svg__a)'>
      <path
        fill={props.color}
        d='M12 22c-2.233 0-4.125-.775-5.675-2.325C4.775 18.125 4 16.233 4 14c0-1.883.558-3.692 1.675-5.425 1.117-1.733 2.65-3.25 4.6-4.55.367-.25.746-.262 1.137-.037.392.225.588.562.588 1.012v1.3c0 .567.196 1.042.588 1.425.391.383.87.575 1.437.575.283 0 .554-.062.813-.187.258-.125.487-.305.687-.538a.851.851 0 0 1 .513-.312.722.722 0 0 1 .587.137 7.942 7.942 0 0 1 2.475 2.875c.6 1.167.9 2.408.9 3.725 0 2.233-.775 4.125-2.325 5.675C16.125 21.225 14.233 22 12 22Zm-6-8c0 .867.175 1.688.525 2.462.35.776.85 1.455 1.5 2.038A1.158 1.158 0 0 1 8 18.275v-.225c0-.533.1-1.033.3-1.5.2-.467.492-.892.875-1.275L12 12.5l2.825 2.775c.383.383.675.808.875 1.275.2.467.3.967.3 1.5v.225c0 .067-.008.142-.025.225a6.073 6.073 0 0 0 1.5-2.038c.35-.774.525-1.595.525-2.462 0-.833-.154-1.62-.462-2.363A6.102 6.102 0 0 0 16.2 9.65a3.951 3.951 0 0 1-2.175.65c-1.033 0-1.93-.342-2.688-1.025a3.908 3.908 0 0 1-1.312-2.525c-1.3 1.1-2.296 2.27-2.988 3.512C6.346 11.505 6 12.75 6 14Zm6 1.3-1.425 1.4a1.981 1.981 0 0 0-.425.625c-.1.233-.15.475-.15.725 0 .533.196.992.588 1.375.391.383.862.575 1.412.575.55 0 1.02-.192 1.412-.575.392-.383.588-.842.588-1.375 0-.267-.05-.512-.15-.738-.1-.225-.242-.429-.425-.612L12 15.3Z'
      />
    </g>
  </svg>
)
export default SvgFire
