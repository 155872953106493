import * as React from 'react'
import type { SVGProps } from 'react'
const SvgHome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Home_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Home_svg__a)'>
      <path
        fill={props.color}
        d='M6 19h3v-5c0-.283.096-.52.287-.713A.967.967 0 0 1 10 13h4c.283 0 .52.096.713.287.191.192.287.43.287.713v5h3v-9l-6-4.5L6 10v9Zm-2 0v-9a1.986 1.986 0 0 1 .8-1.6l6-4.5c.35-.267.75-.4 1.2-.4.45 0 .85.133 1.2.4l6 4.5A1.985 1.985 0 0 1 20 10v9c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 18 21h-4a.968.968 0 0 1-.713-.288A.968.968 0 0 1 13 20v-5h-2v5c0 .283-.096.52-.287.712A.968.968 0 0 1 10 21H6c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 4 19Z'
      />
    </g>
  </svg>
)
export default SvgHome
