import * as React from 'react'
import type { SVGProps } from 'react'
const SvgOpenFullscreen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Open_Fullscreen_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Open_Fullscreen_svg__a)'>
      <path
        fill={props.color}
        d='M4 21a.967.967 0 0 1-.712-.288A.968.968 0 0 1 3 20v-6c0-.283.096-.52.288-.713A.967.967 0 0 1 4 13c.283 0 .52.096.713.287.191.192.287.43.287.713v3.6L17.6 5H14a.968.968 0 0 1-.713-.287A.967.967 0 0 1 13 4c0-.283.096-.52.287-.712A.968.968 0 0 1 14 3h6c.283 0 .52.096.712.288.192.191.288.429.288.712v6c0 .283-.096.52-.288.713A.968.968 0 0 1 20 11a.968.968 0 0 1-.712-.287A.968.968 0 0 1 19 10V6.4L6.4 19H10c.283 0 .52.096.713.288.191.191.287.429.287.712s-.096.52-.287.712A.968.968 0 0 1 10 21H4Z'
      />
    </g>
  </svg>
)
export default SvgOpenFullscreen
