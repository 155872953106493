import * as React from 'react'
import type { SVGProps } from 'react'
const SvgArrowDownBig = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Arrow_Down_Big_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Arrow_Down_Big_svg__a)'>
      <path
        fill={props.color}
        d='M11 16.175V5c0-.283.096-.52.287-.713A.968.968 0 0 1 12 4c.283 0 .52.096.712.287.192.192.288.43.288.713v11.175l4.9-4.9c.2-.2.433-.296.7-.287.266.008.5.112.7.312.183.2.279.433.287.7a.916.916 0 0 1-.287.7l-6.6 6.6c-.1.1-.208.17-.325.212a1.106 1.106 0 0 1-.375.063c-.133 0-.258-.02-.375-.063a.877.877 0 0 1-.325-.212l-6.6-6.6a.933.933 0 0 1-.275-.688c0-.274.091-.512.275-.712.2-.2.437-.3.712-.3.275 0 .513.1.713.3L11 16.175Z'
      />
    </g>
  </svg>
)
export default SvgArrowDownBig
