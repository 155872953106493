/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import { REHYDRATE } from 'redux-persist'
import { RootState } from '..'
import { getSettingsList } from '@/store/api'
import { TSettings, THomePage } from '@/types'

type TInitial = TSettings

const initialState: TInitial = {
  config: null,
}

let oldHydrate = {}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: any) => {
      oldHydrate = action.payload.settings
      return {
        ...state,
        ...oldHydrate,
      }
    })
    builder.addCase(REHYDRATE, (state) => {
      return {
        ...state,
        ...oldHydrate,
      }
    })
    builder.addMatcher(getSettingsList.matchFulfilled, (state, action) => {
      state.config = action?.payload?.items?.config
    })
  },
})

export const selectHomePageSettings = (state: RootState): THomePage =>
  state.settings.config
