import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react'
import { twMerge } from 'tailwind-merge'
import { UI_Typography } from '@/ui'
import { Element } from '@/types'

interface TUI_Button
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: React.ReactNode
  className?: string
}

export const Ui_ButtonBorderGradient = (props: TUI_Button): Element => {
  const { children, className, ...rest } = props
  return (
    <button
      className={twMerge(
        'flex items-center justify-center border-none border-0 bg-transparent  cursor-pointer w-full h-full',
        className,
      )}
      {...rest}
    >
      <div className=' w-full rounded-lg background-conic-gradient p-[1px]'>
        <div className='flex h-full w-full items-center justify-center bg-black back px-3 rounded-lg overflow-hidden'>
          <UI_Typography className='text-white'>{children}</UI_Typography>
        </div>
      </div>
    </button>
  )
}
