import * as React from 'react'
import type { SVGProps } from 'react'
const SvgLyrics = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={props.color}
      d='M1.86 20V6a1.914 1.914 0 0 1 .592-1.412A1.944 1.944 0 0 1 3.872 4h11.07c.554 0 1.028.196 1.422.588A1.924 1.924 0 0 1 16.956 6v.425a5.306 5.306 0 0 0-1.108.675 5.758 5.758 0 0 0-.905.899V6H3.873v12h11.07v-4c.267.332.57.633.905.899.34.27.712.496 1.107.675V18c0 .55-.197 1.02-.591 1.412a1.947 1.947 0 0 1-1.421.588H1.86Zm4.026-4h4.025v-2H5.886v2Zm13.083-2a2.921 2.921 0 0 1-2.139-.875A2.885 2.885 0 0 1 15.95 11c0-.833.293-1.542.88-2.125A2.921 2.921 0 0 1 18.968 8c.177 0 .354.016.528.05.162.032.322.074.479.125V3H24v2h-2.013v6c0 .833-.293 1.542-.88 2.125s-1.3.875-2.139.875ZM5.886 13h7.044v-2H5.886v2Zm0-3h7.044V8H5.886v2Z'
    />
  </svg>
)
export default SvgLyrics
