import { MouseEvent, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { UI_IconButton, UI_Tooltip } from '@/ui'
import { Element, TError } from '@/types'
import { useTranslate, useUser } from '@/hooks'
import { delay, errorHandler } from '@/tools'
import {
  useAddToFavoriteMutation,
  useRemoveFromFavoriteMutation,
} from '@/store/api/favoriteApi'
import { useAppSelector } from '@/store'

type TLikeProps = {
  isFavorite: boolean
  id: number
  className?: string
  children?: (
    handleLike: (e: MouseEvent<HTMLElement>) => void,
    favorite: boolean,
    loading: boolean,
  ) => Element
}

export const Like = (props: TLikeProps): Element | null => {
  const { isFavorite = false, id, className, children } = props
  const { translate } = useTranslate()
  const [favorite, setFavorite] = useState(isFavorite)
  const [showAnimation, setShowAnimation] = useState(false)
  const favoriteSongs = useAppSelector((state) => state.favorite.songs)
  const { isLogin } = useUser()

  useEffect(() => {
    setFavorite(favoriteSongs.filter((f) => f.id == id).length > 0)
  }, [favoriteSongs])

  const [executeFavoriteSong, { isLoading: loadingFollow }] =
    useAddToFavoriteMutation()

  const [executeUnFavoriteSong, { isLoading: loadingUnFollow }] =
    useRemoveFromFavoriteMutation()

  const loading = loadingFollow || loadingUnFollow

  const handleLike = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (favorite) {
      setFavorite(false)
      executeUnFavoriteSong({ id, model: 'song' }).catch((error: TError) => {
        errorHandler(error)
        setFavorite(true)
      })
    } else {
      setFavorite(true)
      executeFavoriteSong({ id, model: 'song' })
        .unwrap()
        .then(() => {
          setShowAnimation(true)
          delay(2000).then(() => {
            setShowAnimation(false)
          })
        })
        .catch((error: TError) => {
          setFavorite(false)
          errorHandler(error)
        })
    }
  }

  if (!isLogin) return <span />

  if (children) return children(handleLike, favorite, loading)

  return (
    <span className={twMerge('heart-btn', favorite ? 'liked' : 'unLiked')}>
      <UI_Tooltip
        className='bg-background-background-tertiary -mb-1.5'
        message={translate(
          favorite ? 'global.remove-favorite' : 'global.add-favorite',
        )}
      >
        <UI_IconButton
          borderLess
          icon={{
            iconType: favorite ? 'HeartFilled' : 'Heart',
            className: twMerge(
              'lg:w-8 w-6 lg:h-8 h-6 transition-all z-[1]',
              loading && 'beating',
              className,
            ),
          }}
          onClick={handleLike}
        />

        {showAnimation &&
          [
            'one',
            'two',
            'three',
            'four',
            'five',
            'six',
            'seven',
            'eight',
            'nine',
          ].map((item) => (
            <UI_IconButton
              key={item}
              borderLess
              icon={{
                iconType: 'HeartFilled',
                fill: 'fill-background-background-alert',
              }}
              className={twMerge(
                '!absolute -mt-2 -ms-1 heart-like heart-pop -z[1]',
                item,
              )}
            />
          ))}
      </UI_Tooltip>
    </span>
  )
}
