import * as React from 'react'
import type { SVGProps } from 'react'
const SvgVolumeUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Volume_Up_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Volume_Up_svg__a)'>
      <path
        fill={props.color}
        d='M19 11.975a6.864 6.864 0 0 0-1.1-3.787 6.822 6.822 0 0 0-2.95-2.563 1.114 1.114 0 0 1-.55-.537.948.948 0 0 1-.05-.738c.1-.267.28-.458.537-.575a.93.93 0 0 1 .788 0 8.806 8.806 0 0 1 3.875 3.288c.967 1.474 1.45 3.112 1.45 4.912 0 1.8-.483 3.438-1.45 4.912a8.806 8.806 0 0 1-3.875 3.288.93.93 0 0 1-.788 0 .988.988 0 0 1-.537-.575.948.948 0 0 1 .05-.738c.117-.241.3-.42.55-.537a6.822 6.822 0 0 0 2.95-2.563 6.864 6.864 0 0 0 1.1-3.787ZM7 15H4a.967.967 0 0 1-.712-.287A.968.968 0 0 1 3 14v-4c0-.283.096-.52.288-.713A.968.968 0 0 1 4 9h3l3.3-3.3c.317-.317.68-.388 1.087-.213.409.175.613.488.613.938v11.15c0 .45-.204.762-.613.938-.408.174-.77.104-1.087-.213L7 15Zm9.5-3c0 .7-.158 1.363-.475 1.988a4.46 4.46 0 0 1-1.25 1.537.493.493 0 0 1-.513.012A.46.46 0 0 1 14 15.1V8.85c0-.2.088-.346.262-.438a.493.493 0 0 1 .513.013A4.68 4.68 0 0 1 16.025 10c.317.633.475 1.3.475 2ZM10 8.85 7.85 11H5v2h2.85L10 15.15v-6.3Z'
      />
    </g>
  </svg>
)
export default SvgVolumeUp
