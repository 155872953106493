import { twMerge } from 'tailwind-merge'
import { InputHTMLAttributes } from 'react'
import { Element } from '@/types'

interface I_TextareaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  className?: string
  inputClassName?: string
  rows?: number
}

export const UI_Textarea = (props: I_TextareaProps): Element => {
  const { label, rows, className, inputClassName, ...otherProps } = props
  return (
    <label className={twMerge('flex flex-col text-base gap-2', className)}>
      {label}
      <textarea
        rows={rows}
        className={twMerge(
          'font-peyda rounded-radius-200 border text-content-tertiary border-solid outline-none border-content-secondary bg-transparent p-3',
          inputClassName,
        )}
        {...otherProps}
      />
    </label>
  )
}
