import * as React from 'react'
import type { SVGProps } from 'react'
const SvgEyeHide = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Eye_Hide_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Eye_Hide_svg__a)'>
      <path
        fill={props.color}
        d='m16.1 13.3-1.45-1.45c.15-.783-.075-1.517-.675-2.2-.6-.683-1.375-.95-2.325-.8L10.2 7.4a4.24 4.24 0 0 1 .862-.3A4.2 4.2 0 0 1 12 7c1.25 0 2.312.438 3.187 1.313S16.5 10.25 16.5 11.5c0 .333-.034.646-.1.938-.067.291-.167.579-.3.862Zm3.2 3.15-1.45-1.4a10.951 10.951 0 0 0 1.687-1.587A8.898 8.898 0 0 0 20.8 11.5c-.834-1.683-2.03-3.02-3.588-4.012C15.654 6.496 13.916 6 12 6c-.483 0-.959.033-1.425.1a9.622 9.622 0 0 0-1.375.3L7.65 4.85A11.08 11.08 0 0 1 12 4c2.383 0 4.525.63 6.425 1.888 1.9 1.258 3.325 2.895 4.275 4.912.05.083.083.188.1.313s.025.254.025.387a1.972 1.972 0 0 1-.125.7 10.896 10.896 0 0 1-3.4 4.25Zm-.2 5.45-3.5-3.45c-.583.183-1.171.32-1.763.413-.591.091-1.204.137-1.837.137-2.384 0-4.525-.63-6.425-1.887-1.9-1.259-3.325-2.896-4.275-4.913a.812.812 0 0 1-.1-.312 2.932 2.932 0 0 1 0-.763.796.796 0 0 1 .1-.3c.35-.75.766-1.442 1.25-2.075A13.292 13.292 0 0 1 4.15 7L2.075 4.9a.933.933 0 0 1-.275-.687c0-.275.1-.513.3-.713a.948.948 0 0 1 .7-.275c.283 0 .516.092.7.275l17 17c.183.183.279.413.287.688a.93.93 0 0 1-.287.712.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275ZM5.55 8.4c-.484.433-.925.908-1.325 1.425A9.016 9.016 0 0 0 3.2 11.5c.833 1.683 2.029 3.02 3.587 4.013C8.346 16.504 10.083 17 12 17c.333 0 .658-.02.975-.062.316-.042.642-.088.975-.138l-.9-.95c-.184.05-.359.088-.525.113A3.539 3.539 0 0 1 12 16c-1.25 0-2.313-.437-3.188-1.312S7.5 12.75 7.5 11.5c0-.183.012-.358.037-.525.025-.167.063-.342.113-.525L5.55 8.4Z'
      />
    </g>
  </svg>
)
export default SvgEyeHide
