import { InputHTMLAttributes, forwardRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { UI_IconSvg, UI_Typography, iconType } from '@/ui'
import { Element, changeInput, onInput } from '@/types'
import { handleKeyDown } from '@/tools'

export type TUI_InputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string
  icon?: iconType
  className?: string
  baseClassName?: string
  inputClassName?: string
  onClickIcon?: () => void
  validation?: RegExp
  isError?: boolean
  errorMessage?: string
  required?: boolean
  iconPosition?: 'start' | 'end'
}

export const UI_Input = forwardRef<HTMLInputElement, TUI_InputProps>(
  (props, ref): Element => {
    const {
      label,
      icon,
      onClickIcon,
      className = '',
      baseClassName = '',
      inputClassName = '',
      validation,
      isError,
      errorMessage,
      required,
      iconPosition = 'end',
      ...otherProps
    } = props

    const [isInValid, setIsInValid] = useState<boolean>()

    const handleValidation = (event: onInput) => {
      if (validation) {
        handleKeyDown(event as changeInput, validation)
          .then(() => {
            setIsInValid(false)
          })
          .catch(() => {
            setIsInValid(true)
          })
      }
    }

    return (
      <label
        className={twMerge(
          'flex flex-col justify-center text-base gap-2 w-full',
          className,
        )}
      >
        {label && (
          <UI_Typography variant='enBodyRegularText2'>{label}</UI_Typography>
        )}
        <div
          className={twMerge(
            'w-full flex items-center justify-between rounded-radius-200 p-[11px] border border-solid border-content-content-secondary box-border h-12',
            baseClassName,
            (isInValid || isError) && 'border-content-negative',
          )}
        >
          {icon && iconPosition === 'start' && (
            <UI_IconSvg
              onClick={onClickIcon}
              component={icon}
              className={twMerge(
                typeof onClickIcon === 'function' && 'cursor-pointer',
                'mx-1',
              )}
            />
          )}
          <input
            ref={ref}
            required={required}
            className={twMerge(
              'text-content-content-tertiary outline-none border-none bg-transparent w-full font-peyda',
              inputClassName,
              isInValid && 'text-content-negative',
            )}
            onInput={handleValidation}
            {...otherProps}
          />
          {icon && iconPosition === 'end' && (
            <UI_IconSvg
              onClick={onClickIcon}
              component={icon}
              className={twMerge(
                typeof onClickIcon === 'function' && 'cursor-pointer',
              )}
            />
          )}
        </div>
        {isError && errorMessage && (
          <UI_Typography
            variant='enLabelBoldLabel3'
            className='text-content-negative leading-3 relative -top-1 left-2 text-sm'
          >
            {errorMessage}
          </UI_Typography>
        )}
      </label>
    )
  },
)

UI_Input.displayName = 'UI_Input'
