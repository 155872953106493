import * as React from 'react'
import type { SVGProps } from 'react'
const SvgQueueMusic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='queue_music_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#queue_music_svg__a)'>
      <path
        fill={props.color}
        d='M18 20a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 15 17c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 18 14c.183 0 .358.012.525.037.167.025.325.08.475.163V7c0-.283.096-.52.288-.713A.968.968 0 0 1 20 6h3c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 23 8h-2v9c0 .833-.292 1.542-.875 2.125A2.893 2.893 0 0 1 18 20ZM6 16a.967.967 0 0 1-.713-.287A.968.968 0 0 1 5 15c0-.283.096-.52.287-.713A.967.967 0 0 1 6 14h6c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.968.968 0 0 1 12 16H6Zm0-4a.967.967 0 0 1-.713-.287A.968.968 0 0 1 5 11c0-.283.096-.52.287-.713A.967.967 0 0 1 6 10h10c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 16 12H6Zm0-4a.968.968 0 0 1-.713-.287A.968.968 0 0 1 5 7c0-.283.096-.52.287-.713A.968.968 0 0 1 6 6h10c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 16 8H6Z'
      />
    </g>
  </svg>
)
export default SvgQueueMusic
