import * as React from 'react'
import type { SVGProps } from 'react'
const SvgArtist = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={props.color}
      d='M12 12c-1.1 0-2.042-.392-2.825-1.175C8.39 10.04 8 9.1 7.999 8c0-1.1.392-2.042 1.176-2.825C9.958 4.392 10.9 4 12 4s2.041.392 2.825 1.175C15.608 5.958 16 6.9 16 8s-.392 2.041-1.175 2.825C14.04 11.608 13.1 12 12 12Zm-7 7.999a.965.965 0 0 1-.713-.29A.968.968 0 0 1 4 19v-1.8a2.981 2.981 0 0 1 1.6-2.65 14.843 14.843 0 0 1 3.15-1.163 13.826 13.826 0 0 1 6.5 0c1.09.268 2.147.657 3.15 1.163a2.982 2.982 0 0 1 1.6 2.65v1.8c0 .283-.096.52-.289.712A.976.976 0 0 1 19 20l-14-.001Zm1-2h12v-.8a.973.973 0 0 0-.5-.85 13.045 13.045 0 0 0-2.725-1.012 11.58 11.58 0 0 0-5.55 0c-.944.235-1.858.574-2.725 1.012a.974.974 0 0 0-.5.85v.8Zm6-8c.55 0 1.02-.196 1.412-.588C13.804 9.02 14 8.55 14 8c0-.55-.196-1.02-.588-1.413A1.923 1.923 0 0 0 12 6c-.55 0-1.021.196-1.413.587A1.927 1.927 0 0 0 10 8c0 .55.196 1.02.588 1.412.392.392.863.588 1.413.588ZM19.559 13.74c-.473 0-.875-.166-1.207-.497a1.64 1.64 0 0 1-.496-1.206c0-.473.165-.875.496-1.206a1.64 1.64 0 0 1 1.207-.497c.1 0 .199.007.297.021a.79.79 0 0 1 .27.092V6.362a.555.555 0 0 1 .567-.567h1.702a.567.567 0 1 1 0 1.135H21.26v5.107c0 .472-.165.874-.496 1.206a1.638 1.638 0 0 1-1.205.496Z'
    />
  </svg>
)
export default SvgArtist
