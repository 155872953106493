import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={props.color}
      fillRule='evenodd'
      stroke={props.color}
      strokeWidth={0.5}
      d='M17.376 15.854a8.59 8.59 0 1 0-1.253 1.305l4.334 4.332a.904.904 0 1 0 1.278-1.279l-4.359-4.358ZM10.59 17.46a6.871 6.871 0 1 1 0-13.743 6.871 6.871 0 0 1 0 13.743Z'
      clipRule='evenodd'
    />
  </svg>
)
export default SvgSearch
