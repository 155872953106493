import * as React from 'react'
import type { SVGProps } from 'react'
const SvgShuffle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={props.color}
      d='m7.122 15.313-.052.056c-.228.25-.652.713-1.387 1.14-.791.46-1.972.892-3.67.88L2 19.178c2.027.014 3.518-.507 4.575-1.12a7.379 7.379 0 0 0 1.851-1.527l.52-.56c1.222-1.306 2.547-2.726 4.88-5.767 1.69-2.201 4.01-2.7 4.54-2.714h.593l-1.38 1.386a.928.928 0 0 0 .12 1.158.918.918 0 0 0 1.084.162L21.74 7.23a.897.897 0 0 0 0-1.265l-2.838-2.85a.918.918 0 0 0-1.095.157.928.928 0 0 0-.13 1.142l1.283 1.289h-.618c-.972.017-3.85.705-5.926 3.41-2.28 2.973-3.559 4.342-4.769 5.638l-.001.001c-.175.188-.349.373-.523.562ZM7.07 8.63l.052.057.523.562v.001a87.9 87.9 0 0 1 1.346 1.463l1.344-1.173a88.124 88.124 0 0 0-1.388-1.511l-.52-.56a10.623 10.623 0 0 1-.051-.055c-.247-.27-.826-.904-1.801-1.471C5.519 5.329 4.027 4.808 2 4.823l.012 1.788c1.699-.012 2.88.42 3.67.88.736.427 1.16.891 1.388 1.14Z'
    />
    <path
      fill={props.color}
      d='m12.416 14.888-.29-.375 1.347-1.175.354.458c1.688 2.201 4.01 2.7 4.54 2.713h.592l-1.38-1.385a.928.928 0 0 1 .121-1.158.918.918 0 0 1 1.083-.162l2.956 2.967a.897.897 0 0 1 0 1.265l-2.838 2.85a.918.918 0 0 1-1.095-.157.927.927 0 0 1-.13-1.142l1.283-1.289h-.618c-.972-.017-3.85-.705-5.925-3.41Z'
    />
  </svg>
)
export default SvgShuffle
