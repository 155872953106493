import { RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { useRouter } from 'next/navigation'
import {
  GlobalStateType,
  TSearch,
  useAppContext,
  useSearchMutation,
} from '@/store'
import { useClickOutside } from '@/hooks'
import { TError, changeInput, keyDownEvent } from '@/types'
import { errorHandler } from '@/tools'

export type TCommonDataLoading = {
  searchData: TSearch
  searchLoading: boolean
}

type TUseSearchOutput = TCommonDataLoading & {
  globalState: GlobalStateType | undefined
  handleSearch: (e: changeInput) => void | undefined
  handleKeyDown: (e: keyDownEvent) => void
  toggleSubMenu: () => void
  toggleOpenSubMenu: () => void
  current: RefObject<HTMLDivElement>
  isOpenSubMenu: boolean
}

export const useSearch = (): TUseSearchOutput => {
  const router = useRouter()
  const { globalState, changeGlobalState } = useAppContext()
  const query = globalState?.searchInHeader?.query ?? ''
  const selectedLibrary = globalState?.searchInHeader?.selectedLibrary
  const [isOpenSubMenu, setIsOpenSubMenu] = useState(false)
  const toggleSubMenu = () => setIsOpenSubMenu((x) => !x)
  const toggleOpenSubMenu = () => setIsOpenSubMenu(true)
  const current = useClickOutside<HTMLDivElement>(() => setIsOpenSubMenu(false))

  const timeoutRef = useRef<NodeJS.Timeout>()

  const [executeSearch, { isLoading: searchLoading, data }] =
    useSearchMutation()

  const searchData = useMemo(() => {
    let _searchData = data ?? {}
    if (data?.songs) {
      if (selectedLibrary === 'playlist') {
        _searchData = {
          ..._searchData,
          playlists: _searchData['playlists']?.slice(0, 1),
        }
      } else if (selectedLibrary === 'artist') {
        _searchData = {
          ..._searchData,
          artists: _searchData['artists']?.slice(0, 1),
        }
      } else if (selectedLibrary === 'album') {
        _searchData = {
          ..._searchData,
          albums: _searchData['albums']?.slice(0, 1),
        }
      } else if (selectedLibrary === 'track') {
        _searchData = {
          ..._searchData,
          songs: _searchData['songs']?.slice(0, 1),
        }
      }
    }
    return _searchData
  }, [data, selectedLibrary])

  useEffect(() => {
    if (query && query.length >= 1 && isOpenSubMenu) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => {
        executeSearch({
          params: {
            limit: 4,
          },
          body: {
            q: query,
          },
        }).catch((error: TError) => {
          errorHandler(error)
        })
      }, 500)
    }
  }, [query, isOpenSubMenu])

  const handleSearch = (e: changeInput) =>
    changeGlobalState?.({
      key: 'searchInHeader',
      value: { ...globalState?.searchInHeader, query: e.target.value },
    })

  const handleKeyDown = (e: keyDownEvent) => {
    if (e.key === 'Enter' && globalState?.searchInHeader?.query) {
      setIsOpenSubMenu(false)
      router.push(`/search?q=${globalState?.searchInHeader?.query}`)
    }
  }

  return {
    searchData,
    searchLoading,
    globalState,
    handleSearch,
    handleKeyDown,
    toggleSubMenu,
    current,
    isOpenSubMenu,
    toggleOpenSubMenu,
  }
}
