import * as React from 'react'
import type { SVGProps } from 'react'
const SvgAddToPlaylist = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Add_to_Playlist_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Add_to_Playlist_svg__a)'>
      <path
        fill={props.color}
        d='M6 16a.967.967 0 0 1-.713-.287A.968.968 0 0 1 5 15c0-.283.096-.52.287-.713A.967.967 0 0 1 6 14h5c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.968.968 0 0 1 11 16H6Zm0-4a.967.967 0 0 1-.713-.287A.968.968 0 0 1 5 11c0-.283.096-.52.287-.713A.967.967 0 0 1 6 10h9c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.968.968 0 0 1 15 12H6Zm0-4a.968.968 0 0 1-.713-.287A.968.968 0 0 1 5 7c0-.283.096-.52.287-.713A.968.968 0 0 1 6 6h9c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.968.968 0 0 1 15 8H6Zm13 12a.968.968 0 0 1-.712-.288A.968.968 0 0 1 18 19v-3h-3a.968.968 0 0 1-.713-.287A.968.968 0 0 1 14 15c0-.283.096-.52.287-.713A.968.968 0 0 1 15 14h3v-3c0-.283.096-.52.288-.713A.968.968 0 0 1 19 10c.283 0 .52.096.712.287.192.192.288.43.288.713v3h3c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 23 16h-3v3c0 .283-.096.52-.288.712A.968.968 0 0 1 19 20Z'
      />
    </g>
  </svg>
)
export default SvgAddToPlaylist
