import { changeInput } from '@/types'

export const handleKeyDown = (
  event: changeInput,
  validation: RegExp,
): Promise<changeInput> => {
  return new Promise((resolve, reject) => {
    if (validation.test(event.target.value)) {
      resolve(event)
    } else {
      reject(event)
    }
  })
}
