import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { UI_IconSvg, UI_Typography, iconType } from '@/ui'
import { Element } from '@/types'
import { useClickOutside } from '@/hooks/useClickOutside'

export type TSelectItem = {
  title: string
  value: string
}
export type TUI_SelectProps = {
  items: Array<TSelectItem>
  selected: TSelectItem | undefined
  onChange: (item: TSelectItem) => void
  className?: string
  icon?: iconType
}

export const UI_Select = (props: TUI_SelectProps): Element => {
  const { items, selected, onChange, className, icon } = props
  const [selectedItem, setSelectedItem] = useState<TSelectItem>()
  const [showPopover, setShowPopover] = useState(false)
  const ref = useClickOutside<HTMLDivElement>(() => setShowPopover(false))
  const handleSelectItem = (item: TSelectItem) => {
    onChange(item)
    setSelectedItem(item)
    setShowPopover(false)
  }
  return (
    <div className='relative' ref={ref}>
      <button
        className={twMerge(
          'relative flex items-center gap-1 justify-between w-full cursor-pointer rounded-lg border-none bg-cards-glass-secondary text-content-content-tertiary py-2 px-2 focus:outline-none sm:text-sm select-none',
          className,
        )}
        onClick={() => setShowPopover(!showPopover)}
      >
        <UI_Typography variant='enBodyRegularText3'>
          {selectedItem ? selectedItem.title : selected?.title}
        </UI_Typography>
        <UI_IconSvg component={icon ? icon : 'ArrowDropDown'} />
      </button>
      {showPopover ? (
        <ul
          className='absolute right-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-slate-800 z-10 text-base focus:outline-none sm:text-sm min-w-[7rem]'
          aria-orientation='vertical'
          id='listbox-options-2'
          role='listbox'
          tabIndex={0}
        >
          {items.map((item) => (
            <li
              key={item.title}
              className='flex max-h-[2.3rem] items-center justify-between cursor-pointer select-none py-2 px-2 text-gray-900 hover:bg-slate-900 border-b border-b-slate-700 border-solid border-0 last:border-b-0'
              role='option'
              tabIndex={-1}
              aria-selected={showPopover}
              onClick={() => handleSelectItem(item)}
            >
              <UI_Typography variant='enBodyRegularText3'>
                {item.title}
              </UI_Typography>
              {item.value === selected?.value ? (
                <UI_IconSvg component='Check' />
              ) : null}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  )
}
