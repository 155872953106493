import * as React from 'react'
import type { SVGProps } from 'react'
const SvgPlayFilledS = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 16 16'
    {...props}
  >
    <mask
      id='Play_-_filledS_svg__a'
      width={16}
      height={16}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h16v16H0z' />
    </mask>
    <g mask='url(#Play_-_filledS_svg__a)'>
      <path
        fill={props.color}
        d='M6.35 12.017a.61.61 0 0 1-.675.025.633.633 0 0 1-.341-.592v-6.9c0-.267.113-.464.341-.592a.61.61 0 0 1 .675.025l5.434 3.45c.2.134.3.323.3.567 0 .244-.1.433-.3.567l-5.434 3.45Z'
      />
    </g>
  </svg>
)
export default SvgPlayFilledS
