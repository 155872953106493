import * as React from 'react'
import type { SVGProps } from 'react'
const SvgAllDevicesFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='All_Devices_-_filled_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#All_Devices_-_filled_svg__a)'>
      <path
        fill={props.color}
        d='M4 20c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 2 18V6c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 4 4h15c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 19 6H4v12h2c.283 0 .52.096.713.288.191.191.287.429.287.712s-.096.52-.287.712A.967.967 0 0 1 6 20H4Zm7-2.5c.417 0 .77-.146 1.063-.438.291-.291.437-.645.437-1.062 0-.417-.146-.77-.438-1.063A1.446 1.446 0 0 0 11 14.5c-.417 0-.77.146-1.063.438A1.447 1.447 0 0 0 9.5 16c0 .417.146.77.438 1.063.291.291.645.437 1.062.437ZM21 20h-5a.968.968 0 0 1-.713-.288A.968.968 0 0 1 15 19v-9c0-.283.096-.52.287-.713A.968.968 0 0 1 16 9h5c.283 0 .52.096.712.287.192.192.288.43.288.713v9c0 .283-.096.52-.288.712A.968.968 0 0 1 21 20ZM9 19v-.775a3 3 0 0 1-.738-1A2.915 2.915 0 0 1 8 16c0-.433.088-.842.262-1.225a3 3 0 0 1 .738-1V13c0-.283.096-.52.287-.713A.967.967 0 0 1 10 12h2c.283 0 .52.096.713.287.191.192.287.43.287.713v.775a3 3 0 0 1 .738 1c.175.383.262.792.262 1.225 0 .433-.088.842-.262 1.225a3 3 0 0 1-.738 1V19c0 .283-.096.52-.287.712A.968.968 0 0 1 12 20h-2a.967.967 0 0 1-.713-.288A.968.968 0 0 1 9 19Z'
      />
    </g>
  </svg>
)
export default SvgAllDevicesFilled
