import { useRouter } from 'next/router'
import { ReactNode } from 'react'
import { NextSeo } from 'next-seo'
import { OpenGraphMedia } from 'next-seo/lib/types'
import { useLocale } from '@/hooks'

export type TSeoProps = {
  title?: string
  titleWithBrandName?: boolean
  description?: string
  children?: ReactNode
  canonicalUrl?: string
  openGraph?: {
    images?: Array<OpenGraphMedia>
  }
  noIndex?: boolean
  nofollow?: boolean
}

const BASE_URL = new URL(String(process.env.BASE_URL))

export const Seo = (props: TSeoProps): JSX.Element => {
  const {
    title,
    description = '',
    canonicalUrl,
    openGraph,
    titleWithBrandName = true,
    children,
    noIndex = true,
    nofollow = true,
  } = props
  const { locale, isPersian } = useLocale()
  const lang = locale === 'fa' ? 'fa_IR' : 'en_US'
  const { asPath } = useRouter()
  const PROJECT_NAME = isPersian
    ? process.env.PROJECT_NAME_PERSIAN
    : process.env.PROJECT_NAME
  const titleTemplate = `${PROJECT_NAME ?? ''}${
    titleWithBrandName ? `${title ? ` | ${title}` : ''}` : ''
  }`

  return (
    <>
      <NextSeo
        title={titleTemplate}
        description={description}
        canonical={canonicalUrl ?? `${BASE_URL}${asPath.replace('/', '')}`}
        openGraph={{
          type: 'website',
          locale: lang,
          siteName: 'Strytm',
          article: { tags: ['music', 'new music', 'download new music'] },
          ...openGraph,
        }}
        noindex={noIndex}
        nofollow={nofollow}
        twitter={{
          site: PROJECT_NAME,
          cardType: 'summary_large_image',
        }}
      />
      {children}
    </>
  )
}

export default Seo
