import Link from 'next/link'
// import { useRouter } from 'next/navigation'
import { useEffect, useMemo, useState } from 'react'
// import { LimitLocalMusic } from './LimitLocalMusic'
import {
  useClickOutside,
  useLocale,
  useSetting,
  useTranslate,
  useUser,
} from '@/hooks'
import {
  setIsPlaying,
  setSelectedSong,
  useAppDispatch,
  useLazyGetOnboardingQuery,
} from '@/store'
import { Element } from '@/types'
import {
  UI_IconButton,
  UI_IconSvg,
  UI_Image,
  UI_Typography,
  iconType,
} from '@/ui'

export type TMenuItem = {
  title: string
  description?: string
  icon: iconType
  link: string
  withExternal: boolean
  onClick?: (event?: any) => void
  disabled: boolean
  loginRequired?: boolean
}

export const ProfileMenu = (): Element => {
  const { isPersian, switchLocale } = useLocale()
  const [showMenu, setShowMenu] = useState(false)
  const { avatar, first_name, last_name, mobile, isLogin } = useUser()
  const username = first_name + ' ' + last_name
  const { translate } = useTranslate()

  // const [executeGetOnboarding, { data }] = useLazyGetOnboardingQuery()

  const { isNotSubscription } = useSetting()

  const ref = useClickOutside<HTMLDivElement>(() => setShowMenu(false))
  // const { push, replace } = useRouter()
  const dispatch = useAppDispatch()

  const handleOpenMenu = () => {
    setShowMenu(true)
  }

  const handleCloseMenu = (item?: TMenuItem) => {
    if (item) item.onClick && item.onClick()
    setShowMenu(false)
  }

  // useEffect(() => {
  //   isLogin && executeGetOnboarding()
  // }, [isLogin])

  // const handleLogin = () => push('/login')

  const changeLocale = (e: any) => {
    e.stopPropagation()
    isPersian ? switchLocale('en') : switchLocale('fa')
    handleCloseMenu()
  }

  const handleLogOut = () => {
    dispatch(setSelectedSong(null))
    dispatch(setIsPlaying(false))
    dispatch({ type: 'LOGOUT' })
  }

  const menuItems: Array<TMenuItem> = useMemo(
    () => [
      {
        title: translate('login.log-in'),
        icon: 'Profile',
        link: '/login',
        withExternal: true,
        disabled: isLogin,
        loginRequired: false,
      },
      {
        title: translate('global.profile'),
        icon: 'Profile',
        link: '/profile',
        withExternal: false,
        disabled: false,
        loginRequired: true,
      },
      {
        title: translate('global.profile-onboarding'),
        icon: 'AddedPeople',
        link: '/onboarding',
        withExternal: false,
        // disabled: (data?.items?.genre_uuids?.length || 0) > 0 || !isLogin,
        disabled: false,
        description: translate('global.profile-onboarding-description'),
        loginRequired: true,
      },
      {
        title: translate('subscription.subscription'),
        icon: 'Subscriptions',
        link: '/subscription',
        withExternal: true,
        disabled: isNotSubscription,
        loginRequired: true,
      },
      {
        title: translate('global.wallet'),
        icon: 'Wallet',
        link: '/wallet',
        withExternal: true,
        disabled: isNotSubscription,
        loginRequired: true,
      },
      {
        title: translate('global.sign-out'),
        icon: 'Logout',
        link: '/',
        withExternal: false,
        onClick: handleLogOut,
        disabled: false,
        loginRequired: true,
      },
    ],
    [isPersian, isLogin],
  )

  return (
    <div
      ref={ref}
      className='flex flex-col relative items-end justify-start gap-3 cursor-pointer'
    >
      {isLogin ? (
        <UI_Image
          className='object-cover w-[32px] h-[32px] lg:w-[48px] lg:h-[48px] rounded-full'
          src={avatar}
          width={48}
          height={48}
          onClick={handleOpenMenu}
        />
      ) : (
        <UI_IconButton
          aria-label='Profile Menu'
          onClick={handleOpenMenu}
          icon={{
            iconType: 'Profile',
          }}
          className='bg-cards-glass-primary border-border-border-primary'
        />
      )}

      {showMenu ? (
        <div className='z-10 absolute ltr:right-0 rtl:left-0 top-[55px] bg-cards-glass-secondary backdrop-blur-xl rounded-lg'>
          <ul className='py-2 text-sm text-gray-700 list-none w-[250px]'>
            {isLogin && (
              <li className='w-full flex gap-3 items-center px-4 py-2 text-content-content-secondary'>
                <UI_Image
                  className='object-cover relative w-[32px] h-[32px] lg:w-[48px] lg:h-[48px] border-border-border-tertiary border-solid rounded-full'
                  width={48}
                  height={48}
                  src={avatar}
                />
                <span className='flex flex-col'>
                  <UI_Typography variant='enLabelRegularLabel2'>
                    {mobile}
                  </UI_Typography>
                  <UI_Typography variant='enLabelRegularLabel1'>
                    {username}
                  </UI_Typography>
                  <span></span>
                </span>
              </li>
            )}
            {menuItems
              .filter((x) => !x.disabled)
              .filter((x) => (x.loginRequired ? isLogin && x : x))
              .map((item: TMenuItem) => (
                <li
                  key={item.title}
                  className='mx-3 border-b border-border-border-primary border-solid border-0 last:border-b-0'
                >
                  <Link
                    href={item.link}
                    onClick={() => {
                      handleCloseMenu(item)
                    }}
                    className='flex w-full items-center justify-between px-2 py-2 border-l-transparent hover:bg-cards-glass-secondary  transition-all delay-50 ease-in-out text-content-content-secondary'
                  >
                    <span className='flex flex-row gap-2 items-center'>
                      <UI_IconSvg component={item.icon} />
                      <span className='flex flex-col  '>
                        <UI_Typography variant='enLabelRegularLabel2'>
                          {item.title}
                        </UI_Typography>
                        {item.description && (
                          <UI_Typography
                            variant='enLabelRegularLabel4'
                            className='  text-content-content-tertiary'
                          >
                            {item.description}
                          </UI_Typography>
                        )}
                      </span>
                    </span>
                    {item.withExternal ? (
                      <UI_IconSvg component='ExternalLink' />
                    ) : null}
                  </Link>
                </li>
              ))}
            <li className='mx-3 border-b border-border-border-primary border-solid border-0 last:border-b-0'>
              <a
                onClick={changeLocale}
                className='flex w-full items-center justify-between px-2 py-2 border-l-transparent hover:bg-cards-glass-secondary  transition-all delay-50 ease-in-out text-content-content-secondary'
              >
                <span className='flex flex-row gap-2 items-center'>
                  <UI_IconSvg component={'Flag'} />
                  <span className='flex flex-col  '>
                    <UI_Typography variant='enLabelRegularLabel2'>
                      {translate('global.lang')}
                    </UI_Typography>
                    {/* {item.description && (
                          <UI_Typography
                            variant='enLabelRegularLabel4'
                            className='  text-content-content-tertiary'
                          >
                            {item.description}
                          </UI_Typography>
                        )} */}
                  </span>
                </span>
              </a>
            </li>
            {/* TODO: */}
            {/* <li className='w-full px-4 ps-6 py-2 flex flex-col gap-3'>
              <LimitLocalMusic />
            </li> */}
          </ul>
        </div>
      ) : null}
    </div>
  )
}
