import { twMerge } from 'tailwind-merge'
import { UI_Button, UI_IconSvg, UI_Typography } from '@/ui'
import { BadgeAd, Col } from '@/components'
import { useLocale, useMobile } from '@/hooks'
import { Element, TAd } from '@/types'

type TInlineAdProps = {
  data: TAd
}

export const InlineAd = (props: TInlineAdProps): Element => {
  const { data } = props
  const isMobile = useMobile()
  const {
    fa_title,
    en_title,
    fa_sub_title,
    en_sub_title,
    fa_button_text,
    en_button_text,
    link,
    image_url,
    is_promoted,
    title_color,
    sub_title_color,
    button_color,
    button_background_color,
  } = data

  const { isPersian } = useLocale()

  return (
    <div
      className='flex items-center justify-center min-h-[178px] p-8 rounded-lg bg-center bg-no-repeat bg-cover'
      style={{ backgroundImage: `url(${image_url})` }}
    >
      <Col className='gap-4'>
        {is_promoted && <BadgeAd type='promote' />}
        <Col className='gap-2'>
          {link ? (
            <UI_Typography
              variant={isMobile ? 'enLabelBoldLabel3' : 'enDesktopTitle4'}
              className='text-content-content-ad-primary'
              style={{
                color: title_color,
              }}
            >
              {isPersian ? fa_title : en_title}
            </UI_Typography>
          ) : (
            <UI_Typography
              style={{ color: title_color }}
              variant={isMobile ? 'enLabelBoldLabel3' : 'enDesktopTitle4'}
              className='p-0 border-none text-content-content-ad-primary  '
            >
              {isPersian ? fa_title : en_title}
              <UI_IconSvg
                component='ArrowRight'
                fill={`fill-content-content-ad-primary ${
                  isMobile ? 'w-4 h-4' : 'w-10 h-10'
                } `}
                className={`rtl:rotate-180 ${isMobile ? '-mb-0.5' : '-mb-2.5'}`}
                style={{ fill: title_color }}
              />
            </UI_Typography>
          )}

          <UI_Typography
            variant={isMobile ? 'enLabelRegularLabel4' : 'enBodyRegularText1'}
            className='text-content-content-ad-secondary'
            style={{
              color: sub_title_color,
            }}
          >
            {isPersian ? fa_sub_title : en_sub_title}
          </UI_Typography>
        </Col>
        {link && (
          <UI_Button
            href={link}
            variant='secondary'
            className={twMerge(
              'border-none pe-3',
              !button_background_color && '!bg-content-content-ad-primary',
            )}
            endAdornment={{
              icon: 'ArrowRight',
              className: 'rtl:rotate-180',
            }}
            style={{
              color: button_color,
              backgroundColor: button_background_color,
            }}
          >
            {isPersian ? fa_button_text : en_button_text}
          </UI_Button>
        )}
      </Col>
    </div>
  )
}
