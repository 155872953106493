import * as React from 'react'
import type { SVGProps } from 'react'
const SvgRepeat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={props.color}
      d='M17.631 4.653c.913 0 1.653.74 1.653 1.653v7.918c0 .913-.74 1.653-1.653 1.653h-4.048l1.597-1.597a.84.84 0 0 0-.12-1.05.84.84 0 0 0-1.051-.117l-3.006 3.006a.826.826 0 0 0 0 1.169l2.977 2.977a.836.836 0 0 0 1.08-.093.836.836 0 0 0 .11-1.055l-1.587-1.587h4.048a3.306 3.306 0 0 0 3.306-3.306V6.306A3.306 3.306 0 0 0 17.63 3H6.306A3.306 3.306 0 0 0 3 6.306v7.918a3.306 3.306 0 0 0 3.306 3.306h2.542v-1.653H6.306c-.913 0-1.653-.74-1.653-1.653V6.306c0-.913.74-1.653 1.653-1.653H17.63Z'
    />
  </svg>
)
export default SvgRepeat
