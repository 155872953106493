import * as React from 'react'
import type { SVGProps } from 'react'
const SvgAddSongFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={props.color}
      d='M5.72 2c.558 0 .93.372.93.93v1.86h1.86c.558 0 .93.372.93.93s-.372.93-.93.93H6.65v1.86c0 .465-.372.93-.93.93s-.93-.372-.93-.93V6.65H2.93c-.558 0-.93-.372-.93-.93s.372-.93.93-.93h1.86V2.93c0-.558.372-.93.93-.93ZM7.58 11.58c0-.558.372-.93.93-.93s.93.372 1.023.837v6.696c0 1.86-1.58 3.534-3.44 3.441a3.228 3.228 0 0 1-3.07-3.069c-.093-1.86 1.488-3.441 3.441-3.441H7.58V11.58ZM14.37 4.697h5.208c1.116 0 1.953.837 1.953 1.953v11.905c0 1.86-1.58 3.534-3.44 3.441a3.228 3.228 0 0 1-3.07-3.069c-.093-1.86 1.488-3.441 3.441-3.441h1.116V7.673c0-.558-.465-1.023-1.023-1.023H13.068a.932.932 0 0 1-.93-.93c0-.558.465-1.023 1.023-1.023h1.209Z'
    />
  </svg>
)
export default SvgAddSongFilled
