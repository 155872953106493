import * as React from 'react'
import type { SVGProps } from 'react'
const SvgMicrophoneFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Microphone_-_filled_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Microphone_-_filled_svg__a)'>
      <path
        fill={props.color}
        d='M12 14a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 9 11V5c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 12 2c.833 0 1.542.292 2.125.875S15 4.167 15 5v6c0 .833-.292 1.542-.875 2.125A2.893 2.893 0 0 1 12 14Zm0 7a.967.967 0 0 1-.712-.288A.968.968 0 0 1 11 20v-2.1c-1.55-.2-2.867-.842-3.95-1.925a6.855 6.855 0 0 1-1.975-3.9.839.839 0 0 1 .225-.75c.2-.217.467-.325.8-.325.233 0 .442.088.625.262.183.175.3.388.35.638.217 1.167.783 2.142 1.7 2.925C9.692 15.608 10.767 16 12 16s2.308-.392 3.225-1.175c.917-.783 1.483-1.758 1.7-2.925.05-.25.17-.463.363-.638a.924.924 0 0 1 .637-.262c.317 0 .575.108.775.325.2.217.275.467.225.75a6.856 6.856 0 0 1-1.975 3.9C15.867 17.058 14.55 17.7 13 17.9V20c0 .283-.096.52-.287.712A.968.968 0 0 1 12 21Z'
      />
    </g>
  </svg>
)
export default SvgMicrophoneFilled
