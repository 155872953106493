/* eslint-disable @typescript-eslint/no-explicit-any */
import { m } from 'framer-motion'
import Link from 'next/link'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode } from 'swiper/modules'
import { ShelfItem } from './ShelfItem'
import { ShelfLoading } from './ShelfLoading'
import { Element, TCategory, TCategoryType } from '@/types'
import 'swiper/css'
import 'swiper/css/pagination'
import { UI_Typography } from '@/ui'
import { useLocale, useMobile, useTranslate } from '@/hooks'
import { replaceAll } from '@/tools'

export type TShelfProps<T> = {
  data: Array<T>
  title: string
  enTitle: string
  loading: boolean
  type?: TCategoryType
  displayShowAll?: boolean
  linkToMoreList?: string
  query?: string
  hasCheckbox?: boolean
  category?: TCategory
  checkedIds?: string[]
  onSelect?: ({ item }: { item: T }) => void
}

export const Shelf = <T,>(props: TShelfProps<T>): Element => {
  const {
    data = [],
    title,
    enTitle,
    loading,
    type,
    displayShowAll = true,
    linkToMoreList,
    query,
    hasCheckbox = false,
    checkedIds = [],
    onSelect,
    category,
  } = props
  const isMobile = useMobile()
  const { isPersian } = useLocale()
  const { translate } = useTranslate()

  if (loading) return <ShelfLoading />

  const link =
    linkToMoreList ??
    `/category/${category?.id}/${
      isPersian && category?.name !== '-' ? category?.name : category?.name_en
    }`

  return (
    <m.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.5,
      }}
      className='section-4 lg:w-full w-[calc(100%_+_10px)] pb-5'
    >
      <div className='w-full flex items-baseline justify-between lg:pe-0 pe-2.5'>
        <Link href={data?.length <= 5 ? '#' : !displayShowAll ? '#' : link}>
          <UI_Typography
            variant='enDesktopTitle4'
            component='h4'
            className='capitalize'
          >
            {isPersian ? title : enTitle}
          </UI_Typography>
        </Link>
        {displayShowAll && data?.length >= 5 && (
          <Link href={link}>
            <UI_Typography
              variant='enLabelBoldLabel2'
              component='b'
              className='text-content-content-tertiary'
            >
              {translate('global.show-all')}
            </UI_Typography>
          </Link>
        )}
      </div>
      <div>
        {data?.length > 0 && (
          <Swiper
            freeMode={true}
            className='max-w-full'
            spaceBetween={isMobile ? 20 : 5}
            slidesPerView={isMobile ? 2.5 : data.length}
            modules={[FreeMode]}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1440: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              1920: {
                slidesPerView: 5,
                spaceBetween: 30,
              },
            }}
          >
            {data.map((item: any) => (
              <SwiperSlide key={item.id}>
                <ShelfItem<T>
                  data={item}
                  type={type}
                  isPromoted={false}
                  allSongs={data}
                  hasCheckbox={hasCheckbox}
                  isChecked={checkedIds?.includes(item.id)}
                  onSelect={onSelect}
                />
              </SwiperSlide>
            ))}
            {Array.from({
              length: isMobile ? 3 - data.length : 5 - data.length,
            }).map((_, index) => (
              <SwiperSlide key={index}></SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </m.section>
  )
}
