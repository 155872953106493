import { ReactNode } from 'react'
import { useMobile, useDesktop, useFulHD, useLaptop, useTablet } from '@/hooks'

type TCommonProps = { children: ReactNode }

export const UI_Mobile = ({ children }: TCommonProps): ReactNode | null => {
  const isMobile = useMobile()
  return isMobile ? children : null
}

export const UI_Tablet = ({ children }: TCommonProps): ReactNode | null => {
  const isTablet = useTablet()
  return isTablet ? children : null
}

export const UI_Laptop = ({ children }: TCommonProps): ReactNode | null => {
  const isLaptop = useLaptop()
  return isLaptop ? children : null
}

export const UI_Desktop = ({ children }: TCommonProps): ReactNode | null => {
  const isDesktop = useDesktop()
  return isDesktop ? children : null
}

export const UI_FulHD = ({ children }: TCommonProps): ReactNode | null => {
  const isFulHD = useFulHD()
  return isFulHD ? children : null
}
