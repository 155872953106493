import * as React from 'react'
import type { SVGProps } from 'react'
const SvgLibrary = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={props.color}
      d='M2.982 2A.982.982 0 0 0 2 2.982v18.003a.982.982 0 1 0 1.963 0V2.982A.982.982 0 0 0 2.982 2ZM7.917 2.982a.982.982 0 1 1 1.963 0v18.003a.982.982 0 1 1-1.963 0V2.982Z'
    />
    <path
      fill={props.color}
      fillRule='evenodd'
      d='M13.792 3.197c0-.84.92-1.357 1.638-.918L21.1 5.74a1.08 1.08 0 0 1 .515.92v14.263c0 .595-.482 1.077-1.077 1.077h-5.669a1.077 1.077 0 0 1-1.077-1.077V3.197Zm1.958 1.571v15.274h3.907V7.153L15.75 4.768Z'
      clipRule='evenodd'
    />
  </svg>
)
export default SvgLibrary
