import { twMerge } from 'tailwind-merge'
import { useLocale, useScrollWithClick } from '@/hooks'
import { Children, Element } from '@/types'
import { UI_IconButton } from '@/ui'

type THorizontalScrollProps = Children & {
  isClear?: boolean
}

export const HorizontalScroll = (props: THorizontalScrollProps): Element => {
  const { children, isClear } = props
  const { scrollCheck, scrollEnd, scrollX, slide, scrollElement } =
    useScrollWithClick()
  const { isPersian } = useLocale()

  return (
    <div
      className={twMerge(
        'w-full relative select-none',
        isClear && 'px-3 -mx-3',
      )}
    >
      <div
        ref={scrollElement}
        onScroll={scrollCheck}
        className='overflow-hidden overflow-x-auto scroll-hidden w-full scroll-smooth'
      >
        {children}
      </div>
      {scrollX !== 0 && (
        <UI_IconButton
          icon={{
            iconType: 'ArrowLeft',
            className: 'rtl:rotate-180',
          }}
          className='w-8 h-8 absolute top-[calc(50%_-_16px)] start-0 bg-cards-fill-primary border-[1px] border-solid border-border-border-primary'
          onClick={() => slide(isPersian ? +300 : -300)}
        />
      )}
      {!scrollEnd && (
        <UI_IconButton
          icon={{
            iconType: 'ArrowRight',
            className: 'rtl:rotate-180',
          }}
          className='w-8 h-8 absolute top-[calc(50%_-_16px)] end-0 bg-cards-fill-primary border-[1px] border-solid border-border-border-primary'
          onClick={() => slide(isPersian ? -300 : +300)}
        />
      )}
    </div>
  )
}
