import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '..'

import { TMusic } from '@/types'

type TInitial = {
  mySongs: Array<TMusic>
}

const initialState: TInitial = {
  mySongs: [],
}

export const myPlaylistSlice = createSlice({
  name: 'myPlaylist',
  initialState,
  reducers: {
    removeMySong(state, action) {
      state.mySongs = [...state.mySongs].filter(
        (x) => x.my_music?.id !== action.payload.my_music?.id,
      )
    },
    removeMySongs(state) {
      state.mySongs = []
    },
    setMySongs(state, action) {
      state.mySongs = action.payload
    },
    setMySong(state, action) {
      state.mySongs = [...state.mySongs, action.payload]
    },
  },
})

export const { removeMySong, removeMySongs, setMySongs, setMySong } =
  myPlaylistSlice.actions

export const selectMySongs = (state: RootState): Array<TMusic> =>
  state.myPlaylist.mySongs
