import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCameraFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Camera_-_filled_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Camera_-_filled_svg__a)'>
      <path
        fill={props.color}
        d='M12 17.5c1.25 0 2.313-.438 3.188-1.313.874-.875 1.312-1.937 1.312-3.187 0-1.25-.438-2.313-1.313-3.188C14.313 8.938 13.25 8.5 12 8.5c-1.25 0-2.313.438-3.188 1.313C7.939 10.687 7.5 11.75 7.5 13c0 1.25.438 2.313 1.313 3.188.874.875 1.937 1.312 3.187 1.312Zm0-2c-.7 0-1.292-.242-1.775-.725C9.742 14.292 9.5 13.7 9.5 13s.242-1.292.725-1.775c.483-.483 1.075-.725 1.775-.725s1.292.242 1.775.725c.483.483.725 1.075.725 1.775s-.242 1.292-.725 1.775c-.483.483-1.075.725-1.775.725ZM4 21c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 2 19V7c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 4 5h3.15L8.4 3.65c.183-.2.404-.358.662-.475.259-.117.53-.175.813-.175h4.25c.283 0 .554.058.813.175.258.117.479.275.662.475L16.85 5H20c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v12c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 20 21H4Z'
      />
    </g>
  </svg>
)
export default SvgCameraFilled
