import { UI_Skeleton } from '@/ui'
import { Element } from '@/types'

export const ResultLoading = (): Element => {
  return (
    <div className='flex flex-col gap-2 text-xs'>
      {Array.from({ length: 4 }).map((_, index) => (
        <div key={index} className='card-item-2'>
          <div className='flex-1 flex items-center justify-between'>
            <div className='flex items-center justify-center pr-1 gap-3'>
              <div className='rounded-radius-100 w-10 h-10 overflow-hidden flex items-center justify-center'>
                <UI_Skeleton
                  className='rounded-radius-100 object-cover'
                  width={40}
                  height={40}
                  unit='px'
                />
              </div>
              <div className='h-10 flex flex-col justify-center gap-1.5'>
                <UI_Skeleton
                  className='self-stretch'
                  width={100}
                  height={12}
                  unit='px'
                  rounded='full'
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
