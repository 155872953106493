import moment from 'moment'

export const passwordValidation =
  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,20}$/
export const displaySkipAdTimeSecond = 5
export const mobileValidation = /^09\d{9}$/
export const isSSR = typeof window === 'undefined'

const limit = 6
const sortBy = '-id'

export const filters = {
  artists: {
    sortBy,
    limit,
  },
  newMusics: {
    sortBy,
    limit,
  },
  moharram: {
    sortBy,
    limit,
    moodsId: 1,
  },
  sonati: {
    sortBy,
    limit,
    categoryId: 1,
  },
  navahi: {
    sortBy,
    limit,
    categoryId: 2,
  },
  emamReza: {
    sortBy,
    limit,
    categoryId: 3,
  },
  lastPlayed: {
    sortBy: '-played_at',
    limit,
  },
  genreBySongId: {
    sortBy: '-id',
    limit,
    page: 1,
  },
  monthlyTopSongs: {
    sortBy: 'play_count',
    limit,
    page: 1,
    fromDate: moment().add(-1, 'month').toISOString(),
    toDate: moment().toISOString(),
  },
  weeklyTopSongs: {
    sortBy: 'play_count',
    limit,
    page: 1,
    fromDate: moment().add(-1, 'week').toISOString(),
    toDate: moment().toISOString(),
  },
  topSongs: {
    sortBy: 'play_count',
    limit,
    page: 1,
    fromDate: moment().add(-3, 'months').toISOString(),
  },
}
