import * as React from 'react'
import type { SVGProps } from 'react'
const SvgArrowRightBig = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Arrow_right_big_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Arrow_right_big_svg__a)'>
      <path
        fill={props.color}
        d='M16.175 13H5a.967.967 0 0 1-.713-.287A.968.968 0 0 1 4 12c0-.283.096-.52.287-.713A.967.967 0 0 1 5 11h11.175l-4.9-4.9a.916.916 0 0 1-.287-.7c.008-.267.112-.5.312-.7.2-.183.433-.28.7-.288.267-.008.5.088.7.288l6.6 6.6c.1.1.17.208.212.325.042.117.063.242.063.375s-.02.258-.063.375a.877.877 0 0 1-.212.325l-6.6 6.6a.933.933 0 0 1-.688.275c-.274 0-.512-.092-.712-.275-.2-.2-.3-.438-.3-.713 0-.274.1-.512.3-.712L16.175 13Z'
      />
    </g>
  </svg>
)
export default SvgArrowRightBig
