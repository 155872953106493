import Link from 'next/link'
import {
  UI_Image,
  UI_Typography,
  UI_Button,
  UI_IconSvg,
  UI_IconButton,
} from '@/ui'
import { errorHandler, musicImage } from '@/tools'
import { Element, TError, TPlaylist, TMusic } from '@/types'
import { useLocale, useMobile, useTranslate } from '@/hooks'
import {
  selectAllPlaylists,
  useAddSongToPlaylistMutation,
  useAppSelector,
} from '@/store'

type TPlaylistItemProps = TPlaylist & {
  song?: TMusic
}

export const PlaylistItem = (props: TPlaylistItemProps): Element => {
  const { id, uuid, name, songs, song, is_public } = props
  const { translate } = useTranslate()
  const { isPersian } = useLocale()
  const isMobile = useMobile()
  const playlists = useAppSelector(selectAllPlaylists)
  const [executeAddSongToPlaylist, { isLoading: loadingAddSongToPlaylist }] =
    useAddSongToPlaylistMutation()

  const handleAddToPlaylist = () => {
    executeAddSongToPlaylist({
      id: Number(id),
      body: {
        song_id: String(song?.id),
      },
    }).catch((error: TError) => {
      errorHandler(error)
    })
  }

  const isAdded = playlists?.[id]?.includes(String(song?.uuid))

  return (
    <div className='flex flex-row items-center gap-4'>
      <div className='flex flex-row items-center justify-start'>
        <Link href={`/playlist/${id}`} className='flex'>
          <UI_Image
            className='rounded-lg object-cover'
            src={musicImage({ id, uuid, type: 'playlist' })}
            width={72}
            height={72}
          />
        </Link>
      </div>
      <div className='flex-1 flex flex-col gap-3'>
        <Link href={`/playlist/${id}`}>
          <UI_Typography
            variant='enDesktopTitle5'
            component='b'
            className='whitespace-nowrap flex items-center gap-1'
          >
            {name}
            {!is_public && (
              <UI_IconSvg component='Private' className='w-5 h-5 -mt-1.5' />
            )}
          </UI_Typography>
        </Link>
        {songs?.length > 0 && (
          <UI_Typography
            variant='enLabelRegularLabel1'
            className='whitespace-nowrap'
          >
            {songs.length} Track
          </UI_Typography>
        )}
      </div>
      <div className='flex items-center gap-[18px] justify-end'>
        {!isAdded ? (
          isMobile ? (
            <UI_IconButton
              icon={{ iconType: 'Add' }}
              onClick={handleAddToPlaylist}
              loading={loadingAddSongToPlaylist}
            />
          ) : (
            <UI_Button
              variant='secondary'
              typography={{
                variant: 'enLabelBoldLabel1',
              }}
              onClick={handleAddToPlaylist}
              loading={loadingAddSongToPlaylist}
            >
              {translate('playlist.add')}
            </UI_Button>
          )
        ) : isMobile ? (
          <UI_IconButton icon={{ iconType: 'Check' }} />
        ) : (
          <UI_Button
            typography={{
              variant: 'enLabelBoldLabel1',
            }}
            variant='secondary'
            startAdornment={{ icon: 'Check' }}
          >
            {translate('playlist.added')}
          </UI_Button>
        )}
      </div>
    </div>
  )
}
