import { UI_IconSvg, UI_Typography, UI_Button } from '@/ui'
import { Element } from '@/types'
import { useTranslate } from '@/hooks'

type TSuccessProps = {
  onSuccess?: () => void
  onCancel?: () => void
}

export const Success = (props: TSuccessProps): Element => {
  const { onSuccess, onCancel } = props
  const { translate } = useTranslate()
  return (
    <div className='section-8'>
      <div className='flex gap-1 items-center justify-center'>
        <UI_IconSvg
          component='CheckCircle'
          className='stroke-content-positive'
        />
        <UI_Typography
          variant='enBodyRegularText3'
          className='text-content-positive'
        >
          {translate('playlist-success.add-some-songs')}
        </UI_Typography>
      </div>
      <div className='flex gap-6 justify-center'>
        <UI_Button className='!w-fit' onClick={onSuccess}>
          {translate('playlist-success.add-songs')}
        </UI_Button>
        <UI_Button
          variant='secondary'
          className='cursor-pointer'
          onClick={onCancel}
        >
          {translate('playlist-success.later')}
        </UI_Button>
      </div>
    </div>
  )
}
