import { twMerge } from 'tailwind-merge'
import { Element } from '@/types'

type CellProps = {
  value?: string | number | null
  width?: string
}

export const Cell = (props: CellProps): Element => {
  const { value, width } = props

  return (
    <div className={twMerge('flex-1 p-3 ellipsis', width)}>
      <span>{value}</span>
    </div>
  )
}
