import { ChangeEvent, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import toast from 'react-hot-toast'
import {
  UI_IconButton,
  UI_Image,
  UI_Input,
  UI_Switch,
  UI_Typography,
} from '@/ui'
import { UI_Button } from '@/ui/ui_button/UI_Button'
import { Element, TError, TPlaylist } from '@/types'
import { errorHandler, getImageAsFile, toBase64 } from '@/tools'
import defaultImage from '@/public/images/Art30.png'
import { useAddPlaylistMutation } from '@/store'
import { useLocale, useTranslate } from '@/hooks'

type TCreateProps = {
  onSuccess: (playlist: Partial<TPlaylist>) => void
}

export const Create = (props: TCreateProps): Element => {
  const { translate } = useTranslate()
  const { isPersian } = useLocale()
  const { onSuccess } = props
  const [title, setTitle] = useState('')
  const [image, setImage] = useState<File>()
  const [src, setSrc] = useState<string>('')
  const [isPublic, setIsPublic] = useState(true)
  useEffect(() => {
    getImageAsFile(defaultImage).then((res) => {
      setImage(res)
      toBase64(res).then((res) => {
        setSrc(String(res))
      })
    })
  }, [])

  const [executeAddPlaylist, { isLoading: loadingPlaylist }] =
    useAddPlaylistMutation()

  const handleSubmit = () => {
    if (!title) {
      toast.error(translate('playlist.enter-title-error'))
      return
    }

    // const formData = new FormData()
    // formData.append('persian_name', title)
    // formData.append('english_name', title)
    // formData.append('image', image as File)
    // formData.append('is_public', String(isPublic))

    const data = {
      name: title,
      is_public: isPublic,
    }

    const formData = new FormData()
    Object.keys(data).map((i: string) => {
      // @ts-ignore
      formData.append(i, data[i])
    })
    if (image) {
      formData.append('image', image as File)
    }

    executeAddPlaylist(formData)
      .unwrap()
      .then((response) => {
        onSuccess(response)
      })
      .catch((error: TError) => {
        errorHandler(error)
      })
  }

  const handleSelectedFile = (e: ChangeEvent<HTMLInputElement>) => {
    const _img = e?.target?.files?.[0] as File
    if (_img?.size > 200 * 1000) {
      toast.error(translate('playlist.image-size-error', { size: 200 }))
      return
    }
    setImage(_img)
    toBase64(_img).then((res) => {
      setSrc(String(res))
    })
  }

  const handleChangePublicMode = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    setIsPublic(!checked)
  }

  const commonClass = 'absolute right-1.5 bottom-1.5 p-1.5 cursor-pointer'

  return (
    <div className='flex lg:flex-row flex-col lg:items-end items-center self-stretch gap-6'>
      <div className='relative w-[100px] h-[100px]'>
        <UI_Image
          className='object-cover rounded-lg'
          src={src}
          width={100}
          height={100}
        />
        <UI_IconButton
          className={twMerge(
            commonClass,
            ' bg-cards-glass-secondary2 border-border-border-primary z-9',
          )}
          icon={{
            iconType: 'Camera',
            className: 'w-4 h-4',
          }}
        />
        <input
          type='file'
          className={twMerge(
            'absolute right-0 bottom-0 w-full h-full opacity-0 z-10',
          )}
          onChange={handleSelectedFile}
        />
      </div>
      <div className='relative flex flex-1 lg:w-auto w-full'>
        <UI_Input
          label={translate('playlist.playlist-title')}
          className='gap-6 text-lg w-full'
          baseClassName='border-border-border-inactive h-10'
          name='title'
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          placeholder={translate('playlist.Soundtracks-example')}
        />
        <div
          className={twMerge(
            'absolute top-0 flex items-center justify-center gap-2',
            isPersian ? 'left-0 flex-row-reverse' : 'right-0',
          )}
        >
          <UI_Typography
            variant='enCaptionBold'
            className={twMerge(!isPublic && 'opacity-50')}
          >
            {translate('global.public')}
          </UI_Typography>
          <UI_Switch
            onChange={handleChangePublicMode}
            startAdornment={{
              icon: 'Public',
              fill: 'fill-black',
            }}
            endAdornment={{
              icon: 'Private',
              fill: 'fill-black',
            }}
          />
          <UI_Typography
            variant='enCaptionBold'
            className={twMerge(isPublic && 'opacity-50')}
          >
            {translate('global.private')}
          </UI_Typography>
        </div>
      </div>
      <div className='flex items-end justify-end lg:w-auto w-full'>
        <UI_Button
          variant='primary'
          typography={{
            variant: 'enLabelBoldLabel3',
          }}
          loading={loadingPlaylist}
          onClick={handleSubmit}
          className='lg:w-auto w-full'
        >
          {translate('playlist.create')}
        </UI_Button>
      </div>
    </div>
  )
}
