import { UI_Skeleton } from '@/ui'
import { Element } from '@/types'

export const SongsLoading = (): Element => {
  return (
    <div className='flex flex-row items-center gap-4'>
      <div className='flex flex-row items-center justify-start'>
        <UI_Skeleton
          className='rounded-lg object-cover'
          width={72}
          height={72}
          unit='px'
        />
      </div>
      <div className='flex-1 flex flex-col gap-3'>
        <UI_Skeleton width={150} height={10} unit='px' rounded='full' />
        <UI_Skeleton width={60} height={7} unit='px' rounded='full' />
      </div>
      <div className='flex items-center gap-[18px] justify-end'>
        <UI_Skeleton width={130} height={40} unit='px' rounded='lg' />
      </div>
    </div>
  )
}
