import * as React from 'react'
import type { SVGProps } from 'react'
const SvgPanToolAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 12 12'
    {...props}
  >
    <mask
      id='pan_tool_alt_svg__a'
      width={12}
      height={12}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill={props.color} d='M0 0h12v12H0z' />
    </mask>
    <g mask='url(#pan_tool_alt_svg__a)'>
      <path
        fill={props.color}
        d='M4.975 10.5a1.496 1.496 0 0 1-1.162-.55L.575 5.963.9 5.65a.926.926 0 0 1 .563-.275.817.817 0 0 1 .587.15L3.5 6.537V1.5c0-.142.048-.26.144-.356A.484.484 0 0 1 4 1c.142 0 .26.048.356.144A.484.484 0 0 1 4.5 1.5v6c0 .2-.09.348-.269.444a.463.463 0 0 1-.518-.032l-.6-.425 1.475 1.825c.05.059.108.105.175.138.066.033.137.05.212.05H8.5c.275 0 .51-.098.706-.294A.963.963 0 0 0 9.5 8.5V5c0-.142.048-.26.144-.356A.484.484 0 0 1 10 4.5c.142 0 .26.048.356.144A.484.484 0 0 1 10.5 5v3.5c0 .55-.196 1.02-.587 1.412A1.926 1.926 0 0 1 8.5 10.5H4.975Zm.525-4v-3c0-.142.048-.26.144-.356A.484.484 0 0 1 6 3c.142 0 .26.048.356.144A.484.484 0 0 1 6.5 3.5v3h-1Zm2 0V4c0-.142.048-.26.144-.356A.484.484 0 0 1 8 3.5c.142 0 .26.048.356.144A.484.484 0 0 1 8.5 4v2.5h-1Z'
      />
    </g>
  </svg>
)
export default SvgPanToolAlt
