export { useAxios } from './useAxios'
export { useFetcher } from './useFetcher'
export { useClickOutside } from './useClickOutside'
export { usePlayerActions } from './usePlayerActions'
export { useMobileDetect } from './useMobileDetect'
export {
  useMobile,
  useTablet,
  useLaptop,
  useDesktop,
  useFulHD,
} from './useResponsive'
export { useLocale } from './useLocale'
export { useTranslate } from './useTranslate'
export { useUser } from './useUser'
export { useScroll } from './useScroll'
export { useActions } from './useActions'
export { useScrollWithClick } from './useScrollWithClick'
export { useToggleMouseover } from './useToggleMouseover'
export { useRouterCustom } from './useRouterCustom'
export { useInfiniteScroll } from './useInfiniteScroll'
export { useIndexedDB } from './useIndexedDB'
export { useMyMusic } from './useMyMusic'
export { useAdvertisement } from './useAdvertisement'
export { useCustomRouter } from './useCustomRouter'
export { useSetting } from './useSetting'
export { useDebounce } from './useDebounce'
export { useHover } from './useHover'
