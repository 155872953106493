import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSubscriptions = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='subscriptions_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#subscriptions_svg__a)'>
      <path
        fill={props.color}
        d='M4 22c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 2 20V10c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 4 8h16c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v10c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 20 22H4Zm0-2h16V10H4v10Zm6.775-1.525 4.6-3.05c.15-.1.225-.242.225-.425a.482.482 0 0 0-.225-.425l-4.6-3.05a.461.461 0 0 0-.513-.037.46.46 0 0 0-.262.437v6.15c0 .2.088.346.262.438.175.091.346.079.513-.038ZM5 7a.968.968 0 0 1-.713-.287A.968.968 0 0 1 4 6c0-.283.096-.52.287-.713A.968.968 0 0 1 5 5h14c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 19 7H5Zm3-3a.968.968 0 0 1-.713-.288A.968.968 0 0 1 7 3c0-.283.096-.52.287-.712A.968.968 0 0 1 8 2h8c.283 0 .52.096.712.288.192.191.288.429.288.712s-.096.52-.288.712A.968.968 0 0 1 16 4H8Z'
      />
    </g>
  </svg>
)
export default SvgSubscriptions
