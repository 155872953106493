import * as React from 'react'
import type { SVGProps } from 'react'
const SvgHeadphones = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Headphones_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Headphones_svg__a)'>
      <path
        fill={props.color}
        d='M5 21c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 3 19v-7c0-1.25.237-2.42.712-3.512a9.148 9.148 0 0 1 1.925-2.85 9.148 9.148 0 0 1 2.85-1.925A8.707 8.707 0 0 1 12 3c1.25 0 2.42.237 3.512.712a9.147 9.147 0 0 1 2.85 1.925 9.149 9.149 0 0 1 1.926 2.85A8.707 8.707 0 0 1 21 12v7c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 19 21h-2c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 15 19v-4c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 17 13h2v-1c0-1.95-.68-3.604-2.038-4.963C15.604 5.68 13.95 5 12 5c-1.95 0-3.604.68-4.963 2.037C5.68 8.396 5 10.05 5 12v1h2c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412v4c0 .55-.196 1.02-.588 1.413A1.926 1.926 0 0 1 7 21H5Zm0-2h2v-4H5v4Zm12 0h2v-4h-2v4Z'
      />
    </g>
  </svg>
)
export default SvgHeadphones
