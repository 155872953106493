/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { getProfile } from '../api/profileApi'
import { login, verifyCode } from '../api/oauthApi'
import { TProfile } from '@/types'

type TInitial = {
  profile: Partial<TProfile>
}

const initialState: TInitial = {
  profile: {} as Partial<TProfile>,
}

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    setProfile(state, action: PayloadAction<Partial<TProfile>>) {
      state.profile = { ...state.profile, ...action.payload }
    },
    clearProfile(state) {
      state.profile = {} as TProfile
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(getProfile.matchFulfilled, (state, action) => {
      state.profile = {
        ...state.profile,
        ...action.payload,
        latest_update: new Date().getTime().toString(),
      }
    })

    builder.addMatcher(login.matchFulfilled, (state, action) => {
      state.profile.loggedIn = action.payload.access ? true : false
    })

    builder.addMatcher(verifyCode.matchFulfilled, (state, action) => {
      state.profile.loggedIn = action.payload.access ? true : false
    })
  },
})

export const { setProfile, clearProfile } = userProfileSlice.actions
