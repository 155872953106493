import { twMerge } from 'tailwind-merge'
import { MouseEvent, useMemo } from 'react'
import { UI_IconSvg, iconType } from '@/ui'
import { Element } from '@/types'

type TUI_IconButton = {
  icon: {
    iconType: iconType
    className?: string
    fill?: string
  }
  className?: string
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  borderLess?: boolean
  loading?: boolean
  disabled?: boolean
}

export const UI_IconButton = (props: TUI_IconButton): Element => {
  const {
    className,
    icon,
    onClick,
    borderLess,
    loading,
    disabled,
    ...otherProps
  } = props
  const disabledClass = useMemo(
    () => (disabled ? 'button-disabled' : ''),
    [disabled],
  )

  return (
    <button
      className={twMerge(
        'cursor-pointer rounded-full flex items-center justify-center',
        'border-none outline-none bg-transparent w-10 h-10',
        !borderLess && 'border-border-inactive border-[1px] border-solid',
        disabledClass,
        className,
      )}
      onClick={onClick}
      {...otherProps}
    >
      <UI_IconSvg
        component={icon.iconType}
        className={twMerge(
          borderLess && 'w-8 h-8',
          loading && 'animate-spin',
          icon.className,
        )}
        fill={icon?.fill}
      />
    </button>
  )
}
