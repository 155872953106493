import * as React from 'react'
import type { SVGProps } from 'react'
const SvgLyricsFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={props.color}
      d='M5 16h4v-2H5v2Zm12.999-2a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 15 11c0-.834.292-1.542.875-2.125A2.893 2.893 0 0 1 18 8a2.48 2.48 0 0 1 .512.05c.159.033.321.075.488.125V3H23v2h-2.002v6c0 .833-.291 1.542-.874 2.125a2.892 2.892 0 0 1-2.125.875Zm-13-1h7v-2H5v2Zm0-3h7V8H5v2Zm0 10H1V6a1.927 1.927 0 0 1 .587-1.413A1.925 1.925 0 0 1 3 4h10.998a2.004 2.004 0 0 1 2 2v.425a4.996 4.996 0 0 0-2.187 7.312 4.908 4.908 0 0 0 2.188 1.837V18a2.004 2.004 0 0 1-2 2h-9Z'
    />
  </svg>
)
export default SvgLyricsFilled
