import { useEffect, useState } from 'react'

type TUseToggleMouseoverOutput = {
  isMouseOver: boolean
}

export const useToggleMouseover = (
  timeout?: number,
): TUseToggleMouseoverOutput => {
  const [isMouseOver, setIsMouseOver] = useState(true)

  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    const handleMouseMove = () => {
      setIsMouseOver(true)
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => setIsMouseOver(false), timeout || 1000)
    }

    window.addEventListener('mousemove', handleMouseMove)

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      clearTimeout(timeoutId)
    }
  }, [])

  return { isMouseOver }
}
