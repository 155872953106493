import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { UI_Button } from '../../ui/ui_button/UI_Button'
import { PlaylistItem } from './PlaylistItem'
import { SongsLoading } from './SongsLoading'
import { UI_Input } from '@/ui'
import { useTranslate } from '@/hooks'
import { Element, TError, TMusic, TPlaylist } from '@/types'
import { errorHandler } from '@/tools'
import {
  playlistApi,
  useAppDispatch,
  useLazyGetMyPlaylistsQuery,
} from '@/store'

type TPlaylistProps = {
  onCreateNew: () => void
  song?: TMusic
}

export const Playlist = (props: TPlaylistProps): Element => {
  const { onCreateNew, song } = props
  const { translate } = useTranslate()
  const dispatch = useAppDispatch()
  const page = useRef(1)
  const [searched, setSearched] = useState('')
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const [data, setData] = useState<Array<TPlaylist>>([])
  const [
    executeMyPlaylists,
    {
      // data: { total = 0 } = {},
      isLoading: loadingMyPlaylists,
      isFetching: isFetchingMyPlaylist,
    },
  ] = useLazyGetMyPlaylistsQuery()

  // const hasMore = Number(total) > data?.length

  const handleLoadData = (value: string) => {
    executeMyPlaylists({})
      .then((response) => {
        const result = response?.data?.results ?? []
        setData((x) => [...x, ...result])
        page.current += 1
      })
      .catch((error: TError) => {
        errorHandler(error)
      })
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    page.current = 1
    const value = String(e.target.value)
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      setData([])
      setSearched(value)
      handleLoadData(value)
    }, 500)
  }

  const handleLoadMore = () => {
    handleLoadData(searched)
  }

  useEffect(() => {
    handleLoadData('')
    return () => {
      dispatch(playlistApi.util.invalidateTags(['Playlist']))
    }
  }, [])

  return (
    <div className='section-8'>
      <div className='flex gap-4 lg:flex-row flex-col'>
        <UI_Input
          name='search'
          baseClassName='!rounded-full flex-row-reverse gap-3 h-10 border-content-content-inactive'
          icon='Search'
          placeholder={translate('playlist.filter-by-name')}
          onChange={handleSearch}
        />
        <UI_Button
          className='whitespace-nowrap lg:w-auto w-full'
          onClick={onCreateNew}
        >
          {translate('playlist.create-new')}
        </UI_Button>
      </div>
      <div className='section-4 scroll overflow-auto lg:max-h-[340px] max-h-[300px] -mx-8 px-8'>
        {data?.map((item) => (
          <PlaylistItem key={item.id} {...item} song={song} />
        ))}
        {(loadingMyPlaylists || isFetchingMyPlaylist) &&
          Array.from({ length: 4 }).map((_, index) => (
            <SongsLoading key={index} />
          ))}
      </div>
      {/* {hasMore && (
        <UI_Button
          fullWidth
          variant='secondary'
          onClick={handleLoadMore}
          endAdornment={{
            icon: 'ArrowRight',
          }}
          typography={{
            variant: 'enLabelBoldLabel3',
          }}
          loading={isFetchingMyPlaylist}
        >
          {translate('global.see-more')}
        </UI_Button>
      )} */}
    </div>
  )
}
