import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCloseFullscreen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Close_Fullscreen_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Close_Fullscreen_svg__a)'>
      <path
        fill={props.color}
        d='m10 15.4-5.9 5.9a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275.948.948 0 0 1-.275-.7c0-.283.091-.517.275-.7L8.6 14H5a.967.967 0 0 1-.713-.287A.968.968 0 0 1 4 13c0-.283.096-.52.287-.713A.967.967 0 0 1 5 12h6c.283 0 .52.096.712.287.192.192.288.43.288.713v6c0 .283-.096.52-.288.712A.968.968 0 0 1 11 20a.968.968 0 0 1-.713-.288A.968.968 0 0 1 10 19v-3.6Zm5.4-5.4H19c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 19 12h-6a.968.968 0 0 1-.713-.287A.968.968 0 0 1 12 11V5c0-.283.096-.52.287-.713A.968.968 0 0 1 13 4c.283 0 .52.096.712.287.192.192.288.43.288.713v3.6l5.9-5.9a.948.948 0 0 1 .7-.275c.283 0 .516.092.7.275a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7L15.4 10Z'
      />
    </g>
  </svg>
)
export default SvgCloseFullscreen
