'use client'

import {
  createContext,
  ReactNode,
  useMemo,
  useState,
  JSX,
  useContext,
} from 'react'
import { TCategories, TLibraries } from '@/types'

export type GlobalStateType = {
  searchInHeader?: {
    query?: string
    selectedLibrary?: string
  }
  searchInSidebar?: {
    query?: string
    selectedLibrary?: TLibraries | null
    sort?: string
  }
  isTablet?: boolean
  isPlayerFullScreen?: boolean
  isShuffle?: boolean
  isLyrics?: boolean
  isLoop?: boolean
  categories?: Array<TCategories>
}

export const initContext = {
  globalState: {
    searchInHeader: {
      query: '',
      selectedLibrary: 'all',
    },
    searchInSidebar: {
      query: '',
      selectedLibrary: 'all' as TLibraries,
      sort: '-added_at',
    },
    isTablet: false,
    isPlayerFullScreen: false,
    isShuffle: false,
    isLyrics: false,
    isLoop: false,
    categories: [
      {
        name: 'all' as TLibraries,
        title: 'All',
        faTitle: 'همه',
      },
      {
        name: 'favorite' as TLibraries,
        title: 'Favorites',
        faTitle: 'علاقه‌مندی‌ها',
      },
      {
        name: 'playlist' as TLibraries,
        title: 'Play lists',
        faTitle: 'لیست پخش',
      },
      {
        name: 'artist' as TLibraries,
        title: 'Artists',
        faTitle: 'خواننده‌ها',
      },
      {
        name: 'album' as TLibraries,
        title: 'Albums',
        faTitle: 'آلبوم‌ها',
      },
    ],
  },
}

type changeGlobalStateInput = {
  key: keyof typeof initContext.globalState
  value: unknown
}

type ContextType = {
  changeGlobalState?: ({ key, value }: changeGlobalStateInput) => void
  globalState?: GlobalStateType
  changeGlobalStateBulk?: (items: changeGlobalStateInput[]) => void
}

export const AppContext = createContext<ContextType>(initContext)

export const AppProvider = ({
  values,
  children,
}: {
  values?: GlobalStateType
  children: ReactNode
}): JSX.Element => {
  const [globalState, setGlobalState] = useState({ ...values })

  function changeGlobalState({ key, value }: changeGlobalStateInput) {
    setGlobalState((x: GlobalStateType) => {
      const temp = {
        ...x,
        [key]: value as unknown as undefined,
      }
      try {
        localStorage.setItem('appStore', JSON.stringify(temp))
      } catch {}
      return temp
    })
  }

  function changeGlobalStateBulk(items: changeGlobalStateInput[]) {
    setGlobalState((x: GlobalStateType) => {
      const temp: GlobalStateType = {
        ...x,
      }
      items.forEach((x: changeGlobalStateInput) => {
        temp[x.key] = x.value as unknown as undefined
      })
      try {
        localStorage.setItem('appStore', JSON.stringify(temp))
      } catch {}
      return temp
    })
  }

  const contextValue = useMemo(
    () => ({ globalState, changeGlobalState, changeGlobalStateBulk }),
    [globalState, changeGlobalState, changeGlobalStateBulk],
  )

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  )
}

export const useAppContext = (): ContextType => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error('the context not found')
  }
  return context
}

export * from './api'
export * from './slice'
export * from './store'
export * from './hooks'
