import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSearchMusic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Search_music_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Search_music_svg__a)'>
      <path
        fill={props.color}
        d='M3 19a.967.967 0 0 1-.712-.288A.968.968 0 0 1 2 18c0-.283.096-.52.288-.712A.967.967 0 0 1 3 17h8c.283 0 .52.096.713.288.191.191.287.429.287.712s-.096.52-.287.712A.968.968 0 0 1 11 19H3Zm0-5a.967.967 0 0 1-.712-.287A.968.968 0 0 1 2 13c0-.283.096-.52.288-.713A.967.967 0 0 1 3 12h3c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.967.967 0 0 1 6 14H3Zm0-5a.968.968 0 0 1-.712-.287A.968.968 0 0 1 2 8c0-.283.096-.52.288-.713A.968.968 0 0 1 3 7h3c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.968.968 0 0 1 6 9H3Zm11 7c-1.383 0-2.563-.488-3.537-1.463C9.488 13.563 9 12.383 9 11s.488-2.563 1.463-3.537C11.438 6.487 12.617 6 14 6s2.563.487 3.538 1.463C18.512 8.437 19 9.617 19 11c0 .483-.07.963-.212 1.438a4.462 4.462 0 0 1-.638 1.312l3.15 3.15a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275l-3.15-3.15c-.4.283-.838.496-1.313.637A5.004 5.004 0 0 1 14 16Zm0-2c.833 0 1.542-.292 2.125-.875A2.893 2.893 0 0 0 17 11c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0 0 14 8c-.833 0-1.542.292-2.125.875A2.893 2.893 0 0 0 11 11c0 .833.292 1.542.875 2.125A2.893 2.893 0 0 0 14 14Z'
      />
    </g>
  </svg>
)
export default SvgSearchMusic
