import React from 'react'

export const preventInvalidPasswordInput: React.KeyboardEventHandler<HTMLInputElement> &
  React.ClipboardEventHandler<HTMLInputElement> = (event) => {
  // Define a regex that matches Farsi characters
  const farsiCharRegex = /[\u0600-\u06FF]/

  // Check if the event is from pasting
  if (event.type === 'paste') {
    const clipboardEvent = event as React.ClipboardEvent<HTMLInputElement>
    const inputElement = clipboardEvent.target as HTMLInputElement

    // Prevent pasting in the confirmPassword field
    if (inputElement.name === 'confirmPassword') {
      event.preventDefault()
      return
    }

    // Get the text from clipboard data
    const paste = clipboardEvent.clipboardData.getData('text')

    // Prevent the paste if Farsi characters are found
    if (farsiCharRegex.test(paste)) {
      event.preventDefault()
    }
  }

  // For keyboard inputs
  else if (event.type === 'keydown') {
    const keyboardEvent = event as React.KeyboardEvent<HTMLInputElement>
    // Prevent the key press if Farsi characters are found
    if (farsiCharRegex.test(keyboardEvent.key)) {
      event.preventDefault()
    }
  }
}
