/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  buildCreateApi,
  coreModule,
  reactHooksModule,
} from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'
import { baseQueryWithReAuth } from '../tools'
import { TAdvertisement } from '@/types'
import { ParameterNormalize, TParams, objectToQueryString } from '@/tools'

type TResult = {
  items: Array<TAdvertisement>
  total: number
}

const createApi = buildCreateApi(
  coreModule(),
  reactHooksModule({ unstable__sideEffectsInRender: true }),
)

export const advertisementApi = createApi({
  reducerPath: 'api/advertisements',
  baseQuery: baseQueryWithReAuth,
  extractRehydrationInfo(action: any, { reducerPath }: any) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
  tagTypes: ['Advertisements'],
  endpoints: (builder) => ({
    getAdList: builder.query<TResult, TParams>({
      query: (params) =>
        `/advertisements${objectToQueryString(ParameterNormalize(params))}`,
    }),
  }),
})

export const {
  useGetAdListQuery,
  useLazyGetAdListQuery,
  util: { getRunningQueriesThunk: getRunningAdvertisementsQueriesThunk },
} = advertisementApi

export const { getAdList } = advertisementApi.endpoints
