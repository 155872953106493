import * as React from 'react'
import type { SVGProps } from 'react'
const SvgDate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={props.color}
      d='m10.95 15.45 3.475-3.475c.2-.2.442-.3.725-.3s.525.1.725.3c.2.2.3.442.3.725s-.1.525-.3.725L11.65 17.65c-.2.2-.433.3-.7.3a.96.96 0 0 1-.7-.3l-2.125-2.125c-.2-.2-.3-.442-.3-.725s.1-.525.3-.725c.2-.2.442-.3.725-.3s.525.1.725.3l1.375 1.375ZM5 22c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 3 20V6c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 5 4h1V3c0-.283.096-.52.287-.712A.968.968 0 0 1 7 2c.283 0 .52.096.713.288.191.191.287.429.287.712v1h8V3c0-.283.096-.52.288-.712A.968.968 0 0 1 17 2c.283 0 .52.096.712.288.192.191.288.429.288.712v1h1c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v14c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 19 22H5Zm0-2h14V10H5v10ZM5 8h14V6H5v2Z'
    />
  </svg>
)
export default SvgDate
