/* eslint-disable @typescript-eslint/no-explicit-any */
import toast from 'react-hot-toast'
import { TIndexedDB } from '@/types'
import { removeMyPlaylistsSongs, useAppDispatch, removeMySongs } from '@/store'

const storeName = 'MyDB'

type TUseIndexedDBOutput = {
  saveIndexedDB: (id: string, value: string, artistImage: string) => void
  getIndexedDB: (id: string) => Promise<unknown>
  removeIndexedDB: (id: string) => Promise<unknown>
  removeDatabase: () => void
}

export const useIndexedDB = (): TUseIndexedDBOutput => {
  const dispatch = useAppDispatch()
  const saveIndexedDB = (id: string, value: string, artistImage: string) => {
    const request = indexedDB.open(storeName)
    let db
    request.onupgradeneeded = function (event: any) {
      db = event.target.result
      db.createObjectStore(storeName, { keyPath: 'id' })
    }

    request.onsuccess = function (event: any) {
      try {
        db = event.target.result
        const transaction = db.transaction(storeName, 'readwrite')
        const store = transaction.objectStore(storeName)
        const data = { id, value, artistImage }

        const _request = store.add(data)

        _request.onerror = function () {
          toast.error('Error saving data')
        }
      } catch (error) {
        toast.error('Error saving data')
      }
    }

    request.onerror = function () {
      toast.error('Error opening database')
    }
  }

  const getIndexedDB = (id: string) => {
    const request = window.indexedDB.open(storeName)
    let db

    return new Promise((resolve, reject) => {
      request.onsuccess = function (event: any) {
        try {
          db = event.target.result
          const transaction = db.transaction(storeName, 'readonly')
          const store = transaction.objectStore(storeName)
          const _request = store.get(id)

          _request.onsuccess = function (_event: any) {
            const value = _event.target.result
            resolve(value as TIndexedDB)
          }

          _request.onerror = function (_event: any) {
            reject(_event)
          }

          db = request.result
          db.close()
        } catch (error) {
          reject(error)
        }
      }

      request.onerror = function (_event) {
        reject(_event)
      }
    })
  }

  const removeIndexedDB = (id: string) => {
    const request = indexedDB.open(storeName)
    let db
    return new Promise((resolve, reject) => {
      request.onsuccess = function (event: any) {
        db = event.target.result
        const transaction = db.transaction(storeName, 'readwrite')
        const store = transaction.objectStore(storeName)
        const request = store.delete(id)

        request.onsuccess = function () {
          resolve('')
        }

        request.onerror = function () {
          reject()
        }
      }

      request.onerror = function (event) {
        reject(event)
      }
    })
  }

  const removeDatabase = () => {
    dispatch(removeMySongs())
    dispatch(removeMyPlaylistsSongs())
    const req = indexedDB.deleteDatabase(storeName)
    req.onsuccess = function () {
      toast.success('All music has been deleted successfully')
    }
    req.onerror = function () {
      toast.error('Error deleting all music at once')
    }
  }

  return { saveIndexedDB, getIndexedDB, removeIndexedDB, removeDatabase }
}
