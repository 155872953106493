import * as React from 'react'
import type { SVGProps } from 'react'
const SvgLinkedin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={props.color}
      d='M17.01 22H6.99A4.997 4.997 0 0 1 2 17.01V6.99A4.997 4.997 0 0 1 6.99 2H17.01A4.997 4.997 0 0 1 22 6.99V17.01A4.997 4.997 0 0 1 17.01 22ZM6.99 4A2.994 2.994 0 0 0 4 6.99V17.01A2.995 2.995 0 0 0 6.99 20H17.01A2.995 2.995 0 0 0 20 17.01V6.99A2.995 2.995 0 0 0 17.01 4H6.99Z'
    />
    <path
      fill={props.color}
      d='M7.5 8.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6.5 10h2v7.5h-2V10Zm11 7.5h-2v-3.736c0-1.243-.476-1.822-1.5-1.822l-2 .038v5.52h-2v-7.48l3.98-.078c1.712 0 3.52 1.004 3.52 3.822V17.5Z'
    />
  </svg>
)
export default SvgLinkedin
