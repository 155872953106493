import * as React from 'react'
import type { SVGProps } from 'react'
const SvgUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Upload_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Upload_svg__a)'>
      <path
        fill={props.color}
        d='M6 20c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 4 18v-2c0-.283.096-.52.287-.713A.967.967 0 0 1 5 15c.283 0 .52.096.713.287.191.192.287.43.287.713v2h12v-2c0-.283.096-.52.288-.713A.968.968 0 0 1 19 15c.283 0 .52.096.712.287.192.192.288.43.288.713v2c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 18 20H6Zm5-12.15L9.125 9.725a.93.93 0 0 1-.713.287A1.02 1.02 0 0 1 7.7 9.7c-.183-.2-.28-.433-.288-.7a.916.916 0 0 1 .288-.7l3.6-3.6c.1-.1.208-.17.325-.213.117-.041.242-.062.375-.062s.258.02.375.062a.877.877 0 0 1 .325.213l3.6 3.6c.2.2.296.433.287.7a1.02 1.02 0 0 1-1 1.013.93.93 0 0 1-.712-.288L13 7.85V15c0 .283-.096.52-.287.713A.968.968 0 0 1 12 16a.968.968 0 0 1-.713-.287A.968.968 0 0 1 11 15V7.85Z'
      />
    </g>
  </svg>
)
export default SvgUpload
