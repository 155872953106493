import * as React from 'react'
import type { SVGProps } from 'react'
const SvgVolumeDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Volume_Down_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Volume_Down_svg__a)'>
      <path
        fill={props.color}
        d='M9 15H6a.968.968 0 0 1-.713-.287A.968.968 0 0 1 5 14v-4c0-.283.096-.52.287-.713A.968.968 0 0 1 6 9h3l3.3-3.3c.317-.317.68-.388 1.087-.213.409.175.613.488.613.938v11.15c0 .45-.204.762-.613.938-.408.174-.77.104-1.087-.213L9 15Zm9.5-3c0 .7-.158 1.363-.475 1.988a4.46 4.46 0 0 1-1.25 1.537.493.493 0 0 1-.512.012A.46.46 0 0 1 16 15.1V8.85c0-.2.087-.346.262-.438a.493.493 0 0 1 .513.013c.517.417.933.942 1.25 1.575.317.633.475 1.3.475 2ZM12 8.85 9.85 11H7v2h2.85L12 15.15v-6.3Z'
      />
    </g>
  </svg>
)
export default SvgVolumeDown
