import { twMerge } from 'tailwind-merge'
import { UI_Button, UI_IconSvg } from '@/ui'
import { Element, TMusic } from '@/types'
import { usePlayerActions } from '@/hooks'
import {
  isAdPlayingSelector,
  // selectTempAllSongs,
  // setAllSong,
  // useAppDispatch,
  useAppSelector,
} from '@/store'
import { LoginDialog } from '@/components'

type TUI_PlayButtonProps = {
  song: TMusic
  isEnable: boolean
  type?: 'secondary'
  size?: 'small'
  allSongs?: Array<TMusic>
}

export const UI_PlayButton = (props: TUI_PlayButtonProps): Element => {
  const {
    song,
    isEnable,
    type,
    size,
    // allSongs
  } = props
  const { isPlaying, handleClickPlay, playerLoading } = usePlayerActions({
    item: song,
  })

  // const tempAllSongs = useAppSelector(selectTempAllSongs)
  // const dispatch = useAppDispatch()

  const isAdPlaying = useAppSelector(isAdPlayingSelector)

  const handleClick = () => {
    handleClickPlay(song)
    // dispatch(setAllSong(allSongs ?? tempAllSongs))
  }

  return (
    <LoginDialog>
      {(handleToggleDialog) => (
        <UI_Button
          aria-label='Play Button'
          type='button'
          onClick={() =>
            handleToggleDialog(
              isEnable && !isAdPlaying ? () => handleClick() : undefined,
            )
          }
          disabled={isAdPlaying}
          variant={
            isEnable
              ? type === 'secondary'
                ? 'secondary'
                : 'primary'
              : 'secondary'
          }
          className={twMerge(
            type === 'secondary'
              ? 'lg:!w-10 lg:!h-10 !border-border-border-inactive border-solid caret-cards-glass-primary backdrop-blur-2xl'
              : 'lg:!w-[64px] lg:!h-[64px]',
            '!rounded-full !w-10 !h-10 !p-0',
            playerLoading === song?.id && 'animate-spin',
            size === 'small' && 'lg:!w-10 lg:!h-10',
            !isEnable && '!bg-content-content-inactive !border-none',
          )}
        >
          <UI_IconSvg
            width={35}
            height={35}
            component={isPlaying ? 'Pause' : 'PlayFilled'}
            className={twMerge(
              'mt-1 w-[24px] h-[24px]',
              type === 'secondary'
                ? 'lg:w-6 lg:h-6'
                : 'lg:w-[35px] lg:h-[35px]',
              size === 'small' && 'lg:w-6 lg:h-6',
            )}
          />
        </UI_Button>
      )}
    </LoginDialog>
  )
}
