import { useState } from 'react'
import Link from 'next/link'
import { UI_Button } from '../../ui/ui_button/UI_Button'
import { Element } from '@/types'
import { useClickOutside, useTranslate } from '@/hooks'
import { UI_IconSvg, UI_Typography } from '@/ui'

export type TNotificationMenuItem = {
  id: number
  title: string
  link: string
}

const menuItems: Array<TNotificationMenuItem> = [
  {
    id: 1,
    title: 'Upgrade to Premium for Family.You and Up to 5 People Just $14.99.',
    link: '/notifications',
  },
  {
    id: 2,
    title: 'PARTYNEXTDOOR and Rihanna are back, Stream ‘Believe It’ Now!',
    link: '/notifications',
  },
  {
    id: 3,
    title: ' Stream the Latest Song of Imagine Dragons ‘Thunder’ on Moodic.',
    link: '/notifications',
  },
]

export const NotificationMenu = (): Element => {
  const { translate } = useTranslate()

  const [showNotificationMenu, setShowNotificationMenu] = useState(false)

  const handleToggleMenu = () => setShowNotificationMenu((prev) => !prev)

  const ref = useClickOutside<HTMLDivElement>(() =>
    setShowNotificationMenu(false),
  )

  return (
    <div
      onClick={handleToggleMenu}
      ref={ref}
      className='flex flex-col relative items-center justify-center cursor-pointer'
    >
      <UI_IconSvg component='Notifications' />
      {showNotificationMenu && (
        <div className='z-10 absolute ltr:right-0 rtl:left-0 top-[40px] bg-cards-glass-secondary backdrop-blur-xl rounded-lg'>
          <ul className='py-6 px-4 text-sm text-gray-700 w-[260px]'>
            <li className='w-full flex gap-2 items-center pb-3 text-content-content-secondary'>
              <UI_IconSvg component='NotificationsFilled' />
              <UI_Typography variant='enLabelBoldLabel3'>
                {translate('notification.notifications')}
              </UI_Typography>
            </li>
            {menuItems.map((item: TNotificationMenuItem) => (
              <li
                key={item.id}
                className='border-t list-none py-3 border-border-border-primary border-solid border-0 hover:bg-cards-glass-secondary transition-all delay-50 ease-in-out'
              >
                <Link
                  href={item.link}
                  className='flex w-full h-full gap-3 items-start text-content-content-secondary'
                >
                  <span className='background-conic-gradient w-1 h-1 rounded-full p-1 mt-1' />
                  <span>{item.title}</span>
                </Link>
              </li>
            ))}
            <UI_Button
              fullWidth
              typography={{
                variant: 'enLabelBoldLabel1',
              }}
              href='/notifications'
              className='!h-8 mt-5'
              variant='primary'
            >
              {translate('notification.show-all-notifications')}
            </UI_Button>
          </ul>
        </div>
      )}
    </div>
  )
}
