import * as React from 'react'
import type { SVGProps } from 'react'
const SvgListView = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='List_view_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#List_view_svg__a)'>
      <path
        fill={props.color}
        d='M10 20c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 8 18c0-.55.196-1.02.588-1.413A1.926 1.926 0 0 1 10 16h10c.55 0 1.02.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.413A1.926 1.926 0 0 1 20 20H10Zm0-6c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0 1 8 12c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 10 10h10c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412 0 .55-.196 1.02-.587 1.412A1.926 1.926 0 0 1 20 14H10Zm0-6c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0 1 8 6c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 10 4h10c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412 0 .55-.196 1.02-.587 1.412A1.926 1.926 0 0 1 20 8H10ZM4 8c-.55 0-1.02-.196-1.413-.588A1.926 1.926 0 0 1 2 6c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 4 4c.55 0 1.02.196 1.412.588C5.804 4.979 6 5.45 6 6c0 .55-.196 1.02-.588 1.412A1.926 1.926 0 0 1 4 8Zm0 6c-.55 0-1.02-.196-1.413-.588A1.926 1.926 0 0 1 2 12c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 4 10c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412 0 .55-.196 1.02-.588 1.412A1.926 1.926 0 0 1 4 14Zm0 6c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 2 18c0-.55.196-1.02.587-1.413A1.926 1.926 0 0 1 4 16c.55 0 1.02.196 1.412.587C5.804 16.98 6 17.45 6 18s-.196 1.02-.588 1.413A1.926 1.926 0 0 1 4 20Z'
      />
    </g>
  </svg>
)
export default SvgListView
