import { useRouter } from 'next/router'

type TUseRouterCustomOutput = {
  replaceRouter: (id: number | undefined, uuid: string | undefined) => void
  back: () => void
}

export const useRouterCustom = (): TUseRouterCustomOutput => {
  const router = useRouter()

  const replaceRouter = (id: number | undefined, uuid: string | undefined) => {
    router.replace({
      pathname: router.pathname,
      query: {
        ...router?.query,
        ep_id: id,
        ep_uuid: uuid,
      },
    })
  }

  const back = () => {
    router.back()
  }

  return { replaceRouter, back }
}
