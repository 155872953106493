import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFullscreen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Fullscreen_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Fullscreen_svg__a)'>
      <path
        fill={props.color}
        d='M7 17h2c.283 0 .52.096.713.288.191.191.287.429.287.712s-.096.52-.287.712A.967.967 0 0 1 9 19H6a.967.967 0 0 1-.713-.288A.968.968 0 0 1 5 18v-3c0-.283.096-.52.287-.713A.967.967 0 0 1 6 14c.283 0 .52.096.713.287.191.192.287.43.287.713v2ZM7 7v2c0 .283-.096.52-.287.713A.968.968 0 0 1 6 10a.968.968 0 0 1-.713-.287A.968.968 0 0 1 5 9V6c0-.283.096-.52.287-.713A.968.968 0 0 1 6 5h3c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.968.968 0 0 1 9 7H7Zm10 10v-2c0-.283.096-.52.288-.713A.968.968 0 0 1 18 14c.283 0 .52.096.712.287.192.192.288.43.288.713v3c0 .283-.096.52-.288.712A.968.968 0 0 1 18 19h-3a.968.968 0 0 1-.713-.288A.968.968 0 0 1 14 18c0-.283.096-.52.287-.712A.968.968 0 0 1 15 17h2Zm0-10h-2a.968.968 0 0 1-.713-.287A.967.967 0 0 1 14 6c0-.283.096-.52.287-.713A.968.968 0 0 1 15 5h3c.283 0 .52.096.712.287.192.192.288.43.288.713v3c0 .283-.096.52-.288.713A.968.968 0 0 1 18 10a.968.968 0 0 1-.712-.287A.967.967 0 0 1 17 9V7Z'
      />
    </g>
  </svg>
)
export default SvgFullscreen
