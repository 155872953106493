import * as React from 'react'
import type { SVGProps } from 'react'
const SvgReportFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Report_-_filled_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Report_-_filled_svg__a)'>
      <path
        fill={props.color}
        d='M12 17c.283 0 .52-.096.713-.288A.968.968 0 0 0 13 16a.968.968 0 0 0-.287-.713A.968.968 0 0 0 12 15a.968.968 0 0 0-.713.287A.968.968 0 0 0 11 16c0 .283.096.52.287.712.192.192.43.288.713.288Zm0-4c.283 0 .52-.096.713-.287A.968.968 0 0 0 13 12V8a.967.967 0 0 0-.287-.713A.968.968 0 0 0 12 7a.968.968 0 0 0-.713.287A.967.967 0 0 0 11 8v4c0 .283.096.52.287.713.192.191.43.287.713.287Zm-2.925 8a1.975 1.975 0 0 1-1.4-.575l-4.1-4.1a1.975 1.975 0 0 1-.575-1.4v-5.85a1.975 1.975 0 0 1 .575-1.4l4.1-4.1A1.975 1.975 0 0 1 9.075 3h5.85a1.975 1.975 0 0 1 1.4.575l4.1 4.1a1.975 1.975 0 0 1 .575 1.4v5.85a1.975 1.975 0 0 1-.575 1.4l-4.1 4.1a1.975 1.975 0 0 1-1.4.575h-5.85Z'
      />
    </g>
  </svg>
)
export default SvgReportFilled
