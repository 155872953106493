import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSpeaker = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Speaker_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Speaker_svg__a)'>
      <path
        fill={props.color}
        d='M10 17h9V3h-9v14Zm0 2c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 8 17V3c0-.55.196-1.02.588-1.413A1.926 1.926 0 0 1 10 1h9c.55 0 1.02.196 1.413.587C20.803 1.98 21 2.45 21 3v14c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 19 19h-9Zm4.5-11.5c.417 0 .77-.146 1.063-.438C15.854 6.771 16 6.418 16 6c0-.417-.146-.77-.438-1.063A1.447 1.447 0 0 0 14.5 4.5c-.417 0-.77.146-1.063.438A1.446 1.446 0 0 0 13 6c0 .417.146.77.438 1.063.291.291.645.437 1.062.437Zm0 8.5c.967 0 1.792-.342 2.475-1.025A3.372 3.372 0 0 0 18 12.5c0-.967-.342-1.792-1.025-2.475A3.372 3.372 0 0 0 14.5 9c-.967 0-1.792.342-2.475 1.025A3.372 3.372 0 0 0 11 12.5c0 .967.342 1.792 1.025 2.475A3.372 3.372 0 0 0 14.5 16Zm0-2c-.417 0-.77-.146-1.063-.438A1.446 1.446 0 0 1 13 12.5c0-.417.146-.77.438-1.063A1.446 1.446 0 0 1 14.5 11c.417 0 .77.146 1.063.438.291.291.437.645.437 1.062 0 .417-.146.77-.438 1.063A1.446 1.446 0 0 1 14.5 14ZM6 23c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 4 21V6c0-.283.096-.52.287-.713A.968.968 0 0 1 5 5c.283 0 .52.096.713.287.191.192.287.43.287.713v15h9c.283 0 .52.096.713.288.191.191.287.429.287.712s-.096.52-.287.712A.968.968 0 0 1 15 23H6Z'
      />
    </g>
  </svg>
)
export default SvgSpeaker
