import { useRouter } from 'next/router'
import { MutableRefObject, useRef } from 'react'
import toast from 'react-hot-toast'
import { twMerge } from 'tailwind-merge'
import { useDispatch } from 'react-redux'
import {
  useIndexedDB,
  useLocale,
  usePlayerActions,
  useTranslate,
  useUser,
} from '.'
import { Like, type TPlaylistDialogFunctions } from '@/components'
import {
  type TActionMenuItem,
  UI_Button,
  UI_IconSvg,
  UI_Typography,
} from '@/ui'
import { FollowArtist } from '@/components/followArtist/FollowArtist'
import {
  isAdPlayingSelector,
  listenNextSongs,
  removeMySong,
  removeMySongFromAllData,
  setListenNext,
  useAppSelector,
  useDeletePlaylistMutation,
  useCopyPlaylistMutation,
  useRemoveSongFromPlaylistMutation,
} from '@/store'
import { downloadImage, errorHandler, imageUrl } from '@/tools'
import { TCategoryType, TError, TMusic } from '@/types'

type TUseActionsArgs = {
  data: TMusic
  type?: TCategoryType
  playlistId?: number
  isFollowArtist?: boolean
  isAddToPlaylist?: boolean
  isCopyPlaylist?: boolean
  isPlayPause?: boolean
  isListenNext?: boolean
  isAddToFavorites?: boolean
  isGoToAlbum?: boolean
  goToAlbumCallback?: () => void
  isGoToArtist?: boolean
  goToArtistCallback?: () => void
  isShare?: boolean
  isShareUrl?: boolean
  artistId?: number
  isFollowedArtist?: boolean
  isRemovePlaylist?: boolean
  isEditPlaylist?: boolean
  isRemoveMyPlaylist?: boolean
}

type TUseActionsOutput = {
  actionMenu: Array<Array<TActionMenuItem>>
  addToPlaylistRef: MutableRefObject<
    | {
        open: () => void
      }
    | undefined
  >
}

export const useActions = (args: TUseActionsArgs): TUseActionsOutput => {
  const {
    data,
    type,
    playlistId,
    isFollowArtist,
    isAddToPlaylist,
    isCopyPlaylist,
    isPlayPause,
    isListenNext,
    isAddToFavorites,
    isGoToAlbum,
    goToAlbumCallback,
    isGoToArtist,
    goToArtistCallback,
    isShare,
    isShareUrl,
    artistId,
    isFollowedArtist,
    isRemovePlaylist,
    isEditPlaylist,
    isRemoveMyPlaylist,
  } = args

  const {
    id,
    name_en: english_name,
    name: persian_name,
    is_favorite,
    album,
    artists,
  } = data
  const { isPersian } = useLocale()
  const { translate } = useTranslate()
  const _name = isPersian ? persian_name : english_name
  const addToPlaylistRef = useRef<TPlaylistDialogFunctions>()
  const listenNext = useAppSelector(listenNextSongs)
  const { push, replace } = useRouter()
  const { removeIndexedDB } = useIndexedDB()
  const dispatch = useDispatch()
  const { isLogin } = useUser()
  const isAdPlaying = useAppSelector(isAdPlayingSelector)
  const { handleClickPlay, handleListenNext, isPlaying } = usePlayerActions({
    item: data,
  })

  const [executeDeleteSongFromMyPlaylist] = useRemoveSongFromPlaylistMutation()

  const [executeDeletePlaylist] = useDeletePlaylistMutation()

  const [executeCopyPlaylist] = useCopyPlaylistMutation()

  const handleRemoveFromPlaylist = () => {
    executeDeleteSongFromMyPlaylist({
      id: Number(playlistId),
      body: {
        song_id: String(id),
      },
    })
      .then(() => {
        toast.success(`"${_name}" was removed from current playlist.`)
        try {
          replace(window.location.href)
        } catch {}
      })
      .catch((error: TError) => {
        errorHandler(error)
      })
  }

  const handleRemovePlaylist = () => {
    executeDeletePlaylist(id)
      .then(() => {
        toast.success(`"${_name}" was removed.`)
        push('/libraries')
      })
      .catch((error: TError) => {
        errorHandler(error)
      })
  }
  const handleCopyPlaylist = () => {
    executeCopyPlaylist(id)
      .then(() => {
        toast.success(`"${_name}" was copied.`)
        push('/libraries')
      })
      .catch((error: TError) => {
        errorHandler(error)
      })
  }

  let actionMenu: Array<Array<TActionMenuItem>> = [[], [], [], []]

  if (isEditPlaylist) {
    actionMenu[0].push({
      id: 1,
      name: translate('actions.edit-playlist'),
      icon: 'Edit',
      action: (e) => {
        e?.stopPropagation()
        addToPlaylistRef.current?.edit()
      },
    })
  }
  if (isRemovePlaylist) {
    actionMenu[0].push({
      id: 2,
      name: translate('actions.remove-playlist'),
      icon: 'Remove',
      action: (e) => {
        e?.stopPropagation()
        handleRemovePlaylist()
      },
    })
  }
  if (isPlayPause) {
    actionMenu[0].push({
      id: 3,
      name: isPlaying ? translate('actions.pause') : translate('actions.play'),
      icon: isPlaying ? 'Pause' : 'Play',
      disabled: isAdPlaying || !isLogin,
      action: (e) => {
        if (!isLogin) return
        e?.stopPropagation()
        if (isAdPlaying) return
        handleClickPlay(data)
      },
    })
  }
  if (isListenNext) {
    actionMenu[0].push({
      id: 4,
      name: translate('actions.listen-next'),
      icon: 'AddSong',
      action: (e) => {
        e?.stopPropagation()
        handleListenNext(data)
      },
    })
  }
  if (isFollowArtist) {
    actionMenu[1].push({
      id: 5,
      name: translate('actions.follow-artist'),
      icon: 'AddPeople',
      renderer: () => (
        <FollowArtist
          id={Number(artistId)}
          isFollow={Boolean(isFollowedArtist)}
        >
          {(_handleClick, _isFollow, _loading) => (
            <li className='flex'>
              <UI_Button
                styleLess
                onClick={_handleClick}
                className={twMerge(
                  'flex text-white bg-transparent border-none outline-none justify-start items-center py-2 lg:px-3 cursor-pointer space-x-2',
                )}
              >
                <UI_IconSvg
                  component={_isFollow ? 'AddedPeople' : 'AddPeople'}
                  className={twMerge('stroke-white', _loading && 'beating')}
                />
                <UI_Typography
                  variant='enLabelRegularLabel1'
                  className='capitalize rtl:pr-2'
                >
                  {_isFollow
                    ? translate('actions.un-follow-artist')
                    : translate('actions.follow-artist')}
                </UI_Typography>
              </UI_Button>
            </li>
          )}
        </FollowArtist>
      ),
    })
  }
  if (isAddToFavorites) {
    actionMenu[1].push({
      id: 6,
      name: translate('actions.add-to-favorites'),
      icon: 'Heart',
      renderer: () => (
        <Like id={id} isFavorite={Boolean(is_favorite)}>
          {(handleClick, favorite) => (
            <li className='flex'>
              <UI_Button
                styleLess
                onClick={handleClick}
                className={twMerge(
                  'flex text-white bg-transparent border-none outline-none justify-start items-center py-2 lg:px-3 cursor-pointer space-x-2',
                )}
              >
                <UI_IconSvg
                  component={favorite ? 'HeartFilled' : 'Heart'}
                  className='stroke-white'
                />
                <UI_Typography
                  variant='enLabelRegularLabel1'
                  className='capitalize rtl:pr-2 font-peyda'
                >
                  {favorite
                    ? translate('actions.remove-from-favorites')
                    : translate('actions.add-to-favorites')}
                </UI_Typography>
              </UI_Button>
            </li>
          )}
        </Like>
      ),
    })
  }
  if (isAddToPlaylist) {
    actionMenu[1].push({
      id: 7,
      name:
        type === 'playlist'
          ? translate('actions.remove-from-playlist')
          : translate('actions.add-to-playlist'),
      icon: type === 'playlist' ? 'Remove' : 'AddToPlaylist',
      disabled: !isLogin,
      action: (e) => {
        e?.stopPropagation()
        if (!isLogin) return
        type === 'playlist'
          ? handleRemoveFromPlaylist()
          : addToPlaylistRef.current?.open()
      },
    })
  }
  if (isGoToAlbum) {
    actionMenu[2].push({
      id: 8,
      name: translate('actions.go-to-album'),
      icon: 'Album',
      disabled: type === 'album' || !album?.id,
      action: (e) => {
        e?.stopPropagation()
        if (album?.id) {
          goToAlbumCallback?.()
          push(`/album/${album?.id}?id=${album?.id}`)
        }
      },
    })
  }
  if (isGoToArtist) {
    actionMenu[2].push({
      id: 9,
      name: translate('actions.go-to-artist'),
      icon: 'ArtistFilled',
      disabled: type === 'artist' || !artists?.[0]?.id,
      action: (e) => {
        e?.stopPropagation()
        if (artists?.[0]?.id) {
          goToArtistCallback?.()
          push(`/artist/${artists?.[0]?.id}?id=${artists?.[0]?.id}`)
        }
      },
    })
  }
  if (isShare) {
    actionMenu[3].push({
      id: 10,
      name: translate('actions.share'),
      icon: 'Share',
      action: (e) => {
        e?.stopPropagation()
        try {
          downloadImage(imageUrl(id, 'songs'), 'Blob').then(() => {
            navigator.share({
              title: _name,
              text: _name,
              url: isShareUrl
                ? window.location.href
                : `${window.location.origin}/track/${id}`,
            })
          })
        } catch {
          navigator.clipboard.writeText(
            isShareUrl
              ? window.location.href
              : `${window.location.origin}/track/${id}`,
          )
          toast.success('Your favorite music address was copied')
        }
      },
    })
  }
  if (isRemoveMyPlaylist) {
    actionMenu[3].push({
      id: 11,
      name: translate('actions.remove'),
      icon: 'Remove',
      action: (e) => {
        e?.stopPropagation()
        try {
          removeIndexedDB(String(data?.my_music?.id))
          dispatch(removeMySongFromAllData(data))
          dispatch(removeMySong(data))
          dispatch(
            setListenNext(
              listenNext.filter((x) => x?.my_music?.id !== data?.my_music?.id),
            ),
          )
        } catch {
          toast.success('Your favorite music address was copied')
        }
      },
    })
  }
  if (isCopyPlaylist) {
    actionMenu[0].push({
      id: 12,
      name: translate('actions.copy-playlist'),
      icon: 'Add',
      action: (e) => {
        e?.stopPropagation()
        handleCopyPlaylist()
      },
    })
  }
  actionMenu = actionMenu.filter((element) => element.length > 0)
  return { actionMenu, addToPlaylistRef }
}
