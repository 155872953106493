import React from 'react'

export const preventInvalidMobileInputKeyDown: React.KeyboardEventHandler<
  HTMLInputElement
> = (event) => {
  if (
    !/[0-9+]/.test(event.key) &&
    !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(event.key)
  ) {
    event.preventDefault()
  }
}
