import Link from 'next/link'
import {
  ButtonHTMLAttributes,
  CSSProperties,
  DetailedHTMLProps,
  ElementType,
  forwardRef,
  useMemo,
} from 'react'
import { twMerge } from 'tailwind-merge'
import { TVariantClasses, iconType, UI_IconSvg, UI_Typography } from '@/ui'
import { Element } from '@/types'

export type TButtonVariant =
  | 'primary'
  | 'secondary'
  | 'ghost'
  | 'tertiary'
  | 'dark'
export type ButtonTypographyVariant = TVariantClasses

type TUI_Button = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  startAdornment?: {
    icon: iconType
    className?: string
    fill?: string
    style?: CSSProperties
  }
  endAdornment?: {
    icon: iconType
    className?: string
    fill?: string
    style?: CSSProperties
  }
  children?: React.ReactNode
  href?: string
  typography?: {
    variant: ButtonTypographyVariant
    component?: ElementType
    className?: string
    style?: CSSProperties
  }
  variant?: TButtonVariant
  loading?: boolean
  disabled?: boolean
  className?: string
  fullWidth?: boolean
  styleLess?: boolean
}

export const UI_Button = forwardRef<HTMLButtonElement, TUI_Button>(
  (props: TUI_Button, ref): Element => {
    const {
      startAdornment,
      endAdornment,
      children,
      href,
      typography,
      variant = 'primary',
      loading = false,
      disabled,
      className,
      fullWidth,
      styleLess,
      ...otherProps
    } = props

    const classNames = 'flex items-center justify-start gap-2'
    const disabledClass = useMemo(
      () => (disabled ? 'button-disabled' : ''),
      [disabled],
    )
    const InnerContent = (
      <>
        {startAdornment?.icon && (
          <UI_IconSvg
            component={startAdornment?.icon}
            className={startAdornment.className}
            fill={startAdornment.fill}
            style={startAdornment?.style}
          />
        )}
        {children && (
          <UI_Typography
            variant={typography?.variant}
            component={typography?.component}
            className={typography?.className}
            style={typography?.style}
          >
            {loading ? 'Wait...' : children}
          </UI_Typography>
        )}
        {endAdornment?.icon && (
          <UI_IconSvg
            component={endAdornment?.icon}
            className={endAdornment.className}
            fill={endAdornment.fill}
            style={endAdornment?.style}
          />
        )}
      </>
    )

    return (
      <button
        ref={ref}
        className={twMerge(
          'rounded-radius-200 py-4 px-6',
          'select-none',
          'h-10',
          'cursor-pointer',
          fullWidth ? 'w-full' : 'w-fit',
          startAdornment?.icon && 'ltr:pl-4 rtl:pr-4',
          endAdornment?.icon && 'ltr:pr-4 rtl:pl-4',
          !children && 'px-6',
          !styleLess && variant,
          disabledClass,
          styleLess &&
            'outline-none shadow-none border-none bg-transparent p-0 m-0 hover:hover:opacity-100 w-fit h-fit',
          className,
        )}
        {...otherProps}
      >
        {styleLess && children}
        {!styleLess && href && (
          <Link href={href} className={twMerge(classNames, disabledClass)}>
            {InnerContent}
          </Link>
        )}
        {!styleLess && !href && InnerContent}
      </button>
    )
  },
)

UI_Button.displayName = 'UI_Button'
