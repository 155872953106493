export const downloadImage = async (
  imageUrl: string,
  type: 'string' | 'Blob' = 'string',
): Promise<string | Blob> => {
  try {
    const response = await fetch(imageUrl)
    const image = await response.blob()
    if (type === 'string') {
      return URL.createObjectURL(image)
    } else {
      return image
    }
  } catch (error) {
    return 'image not found'
  }
}
