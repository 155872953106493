import { twMerge } from 'tailwind-merge'
import { UI_IconSvg } from '@/ui'
import { Element } from '@/types'
import { useLocale, useMobile } from '@/hooks'

export const ScrollToTop = (): Element | null => {
  const isMobile = useMobile()
  const { isPersian } = useLocale()
  const handleScrollToTop = () => {
    document.getElementById('page-body')?.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <div
      className={twMerge(
        ' p-3 lg:p-4 bg-black rounded-full sticky flex justify-center items-center ',
        'left-[calc(85%)] lg:left-[calc(95%)] -mt-20 lg:-mt-32 mb-4 z-10',
        isPersian && 'right-[calc(85%)] lg:right-[calc(90%)]',
      )}
    >
      <UI_IconSvg
        component='ArrowUpBig'
        onClick={handleScrollToTop}
        width={isMobile ? 24 : 32}
        height={isMobile ? 24 : 32}
        className='cursor-pointer'
      />
    </div>
  )
}
