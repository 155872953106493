import * as React from 'react'
import type { SVGProps } from 'react'
const SvgNight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Night_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Night_svg__a)'>
      <path
        fill={props.color}
        d='M13.1 23c-1.4 0-2.713-.267-3.938-.8a10.279 10.279 0 0 1-3.2-2.162 10.279 10.279 0 0 1-2.162-3.2A9.756 9.756 0 0 1 3 12.9c0-2.433.775-4.58 2.325-6.438C6.875 4.604 8.85 3.45 11.25 3a10.1 10.1 0 0 0 .275 4.838 9.867 9.867 0 0 0 2.5 4.137 9.867 9.867 0 0 0 4.138 2.5A10.1 10.1 0 0 0 23 14.75c-.433 2.4-1.583 4.375-3.45 5.925C17.683 22.225 15.533 23 13.1 23Zm0-2a7.912 7.912 0 0 0 4.075-1.1 7.914 7.914 0 0 0 2.95-3.025 12.403 12.403 0 0 1-4.075-1.088 11.884 11.884 0 0 1-3.45-2.412 12.087 12.087 0 0 1-2.425-3.45A11.804 11.804 0 0 1 9.1 5.85a7.797 7.797 0 0 0-3.012 2.963A8.048 8.048 0 0 0 5 12.9c0 2.25.787 4.162 2.362 5.737C8.938 20.212 10.85 21 13.1 21Z'
      />
    </g>
  </svg>
)
export default SvgNight
