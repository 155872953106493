import * as React from 'react'
import type { SVGProps } from 'react'
const SvgOrder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Order_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Order_svg__a)'>
      <path
        fill={props.color}
        d='M9 13a.967.967 0 0 1-.713-.287A.967.967 0 0 1 8 12V5.825L6.125 7.7a.933.933 0 0 1-.688.275c-.275 0-.512-.092-.712-.275-.2-.2-.3-.437-.3-.712 0-.275.1-.513.3-.713L8.3 2.7c.1-.1.208-.17.325-.212.116-.042.241-.063.375-.063.133 0 .258.02.375.063A.877.877 0 0 1 9.7 2.7l3.6 3.6c.2.2.296.433.287.7-.008.267-.112.5-.312.7-.2.183-.434.28-.7.288a.916.916 0 0 1-.7-.288L10 5.825V12c0 .283-.096.52-.288.713A.967.967 0 0 1 9 13Zm6 8.575c-.133 0-.258-.02-.375-.063a.876.876 0 0 1-.325-.212l-3.6-3.6a.916.916 0 0 1-.288-.7c.009-.267.113-.5.313-.7.2-.183.433-.28.7-.288.266-.008.5.088.7.288L14 18.175V12c0-.283.096-.52.287-.713A.968.968 0 0 1 15 11c.283 0 .52.096.712.287.192.192.288.43.288.713v6.175l1.875-1.875a.933.933 0 0 1 .687-.275c.275 0 .513.092.713.275.2.2.3.438.3.712 0 .276-.1.513-.3.713L15.7 21.3c-.1.1-.208.17-.325.212a1.106 1.106 0 0 1-.375.063Z'
      />
    </g>
  </svg>
)
export default SvgOrder
