import { NextPage } from 'next'
import dynamic from 'next/dynamic'

export const Header: NextPage = dynamic(
  async () => (await import('@/layout/header/Header')).Header,
  {
    ssr: false,
  },
)

export const Footer: NextPage = dynamic(
  async () => (await import('@/layout/footer/Footer')).Footer,
  {
    ssr: false,
  },
)

export const Navigation = dynamic(
  async () => (await import('@/components/navigation/Navigation')).Navigation,
  {
    ssr: false,
  },
)

export const Navbar = dynamic(
  async () => (await import('@/layout/sidebar/Sidebar')).Sidebar,
  {
    ssr: false,
  },
)

export const HeaderBackMobile = dynamic(
  async () =>
    (await import('@/layout/header/mobile/HeaderBackMobile')).HeaderBackMobile,
  {
    ssr: false,
  },
)

export const HeaderHomeMobile = dynamic(
  async () =>
    (await import('@/layout/header/mobile/HeaderHomeMobile')).HeaderHomeMobile,
  {
    ssr: false,
  },
)

export const HeaderSearchMobile = dynamic(
  async () =>
    (await import('@/layout/header/mobile/HeaderSearchMobile'))
      .HeaderSearchMobile,
  {
    ssr: false,
  },
)

export const Sidebar = dynamic(
  async () => (await import('@/layout/sidebar/Sidebar')).Sidebar,
  {
    ssr: false,
  },
)

export const Player = dynamic(
  async () => (await import('@/components/player/Player')).Player,
  {
    ssr: false,
  },
)

export const PlayerMobile = dynamic(
  async () =>
    (await import('@/components/playerMobile/PlayerMobile')).PlayerMobile,
  {
    ssr: false,
  },
)

export const ProfileMenu = dynamic(
  async () =>
    (await import('@/components/profileMenu/ProfileMenu')).ProfileMenu,
  {
    ssr: true,
  },
)

export const NotificationMenu = dynamic(
  async () =>
    (await import('@/components/notificationMenu/NotificationMenu'))
      .NotificationMenu,
  {
    ssr: false,
  },
)

export const Library = dynamic(
  async () => (await import('@/layout/sidebar/Library')).Library,
  {
    ssr: false,
  },
)

export const ErrorPage = dynamic(
  async () => {
    return (await import('@/components/error/Error')).ErrorHandle
  },
  {
    ssr: false,
  },
)

export const Actions = dynamic(
  async () => (await import('@/components/horizontalCardList/Actions')).Actions,
  {
    ssr: false,
  },
)

export const Lyrics = dynamic(
  async () => (await import('@/components/player/Lyrics')).Lyrics,
  {
    ssr: false,
  },
)

export const AlbumsShelf = dynamic(
  async () =>
    (await import('@/containers/detail/comp/AlbumsShelf')).AlbumsShelf,
  {
    ssr: false,
  },
)

export const EmptyPlaylist = dynamic(
  async () =>
    (await import('@/containers/detail/comp/EmptyPlaylist')).EmptyPlaylist,
  {
    ssr: false,
  },
)

export const DetailActions = dynamic(
  async () =>
    (await import('@/containers/detail/info/DetailActions')).DetailActions,
  {
    ssr: false,
  },
)

export const HeaderAbout = dynamic(
  async () => (await import('@/layout/header/HeaderAbout')).HeaderAbout,
  {
    ssr: false,
  },
)

export const Like = dynamic(
  async () => (await import('@/components/like/Like')).Like,
  {
    ssr: false,
  },
)

export const TopResults = dynamic(
  async () => {
    return (await import('@/containers/search/comp/topResults/TopResults'))
      .TopResults
  },
  {
    ssr: false,
  },
)

export const TopGenres = dynamic(
  async () => {
    return (await import('@/containers/search/comp/topGenres/TopGenres'))
      .TopGenres
  },
  {
    ssr: false,
  },
)

export default Header
