import { Row as LayerRow } from '@/components'
import { Children, Element } from '@/types'

type RowProps<T> = Children & {
  row: T
  onDoubleClickRow?: (item?: T) => void
  index: number
}

export const Row = <T,>(props: RowProps<T>): Element => {
  const { children } = props
  return (
    <LayerRow className='justify-between self-stretch items-center'>
      {children}
    </LayerRow>
  )
}
