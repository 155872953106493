// import { useGetSettingsListQuery } from '@/store'
import { TSettings } from '@/types'

type TUseSetting = {
  isForceLogin: boolean
  isNotSubscription: boolean
}

export const useSetting = (): TUseSetting => {
  // const { data: { items = {} } = {} } = useGetSettingsListQuery({
  //   type: 'global',
  //   params: {},
  // })
  const settings = {}
  const isForceLogin = false
  const isNotSubscription = false

  return { isForceLogin, isNotSubscription }
}
