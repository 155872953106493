import { twMerge } from 'tailwind-merge'
import { CSSProperties, ElementType, ReactNode, forwardRef } from 'react'
import { useMobile } from '@/hooks'

type TRowProps = {
  component?: ElementType
  children?: ReactNode
  className?: string
  onlyWeb?: boolean
  onlyMobile?: boolean
  style?: CSSProperties
  onClick?: () => void
}

export const Row = forwardRef((props: TRowProps, ref): ReactNode | null => {
  const {
    component: Component = 'div',
    className,
    children,
    onlyWeb,
    onlyMobile,
    onClick,
    ...otherProps
  } = props
  const isMobile = useMobile()

  if (onlyWeb && isMobile) return null
  else if (onlyMobile && !isMobile) return null

  return (
    <Component
      ref={ref}
      className={twMerge('flex w-full', className)}
      onClick={onClick}
      {...otherProps}
    >
      {children}
    </Component>
  )
})

Row.displayName = 'Row'

export const Col = forwardRef((props: TRowProps, ref): ReactNode | null => {
  const {
    component: Component = 'div',
    className,
    children,
    onlyWeb,
    onlyMobile,
    onClick,
    ...otherProps
  } = props
  const isMobile = useMobile()

  if (onlyWeb && isMobile) return null
  else if (onlyMobile && !isMobile) return null

  return (
    <Component
      ref={ref}
      className={twMerge('flex flex-col w-full', className)}
      onClick={onClick}
      {...otherProps}
    >
      {children}
    </Component>
  )
})

Col.displayName = 'Col'

export const Badge = (
  props: Omit<TRowProps, 'component'> & { isBorder?: boolean },
): ReactNode | null => {
  const { className, children, onlyWeb, onlyMobile, isBorder, onClick } = props
  const isMobile = useMobile()

  if (onlyWeb && isMobile) return null
  else if (onlyMobile && !isMobile) return null

  return (
    <span
      onClick={onClick}
      className={twMerge(
        isBorder ? 'badge-component badge-component-border' : 'badge-component',
        className,
      )}
    >
      {children}
    </span>
  )
}

export const MobileView = (
  props: Pick<TRowProps, 'children'>,
): ReactNode | null => {
  const { children } = props
  const isMobile = useMobile()

  if (isMobile) return children
  return null
}

export const WebView = (
  props: Pick<TRowProps, 'children'>,
): ReactNode | null => {
  const { children } = props
  const isMobile = useMobile()
  if (!isMobile) return children
  return null
}
