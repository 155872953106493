import * as React from 'react'
import type { SVGProps } from 'react'
const SvgArrowDropDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='arrow_drop_down_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#arrow_drop_down_svg__a)'>
      <path
        fill='#B3BAC3'
        d='m11.3 14.3-2.6-2.6c-.317-.317-.388-.68-.213-1.087.175-.409.488-.613.938-.613h5.15c.45 0 .763.204.938.613.175.408.104.77-.213 1.087l-2.6 2.6c-.1.1-.208.175-.325.225A.942.942 0 0 1 12 14.6a.941.941 0 0 1-.375-.075 1.038 1.038 0 0 1-.325-.225Z'
      />
    </g>
  </svg>
)
export default SvgArrowDropDown
