import { useMediaQuery } from 'react-responsive'
import { useAppContext, useAppSelector } from '@/store'

export const useMobile = (): boolean => {
  const isMobile = useAppSelector((state) => state.ssr.isMobile)
  return Boolean(isMobile)
}
export const useTablet = (): boolean => {
  const { globalState } = useAppContext()
  return Boolean(globalState?.isTablet)
}
export const useLaptop = (): boolean =>
  useMediaQuery({ minWidth: 1024, maxWidth: 1439 })
export const useDesktop = (): boolean =>
  useMediaQuery({ minWidth: 1440, maxWidth: 2559 })
export const useFulHD = (): boolean => useMediaQuery({ minWidth: 2560 })
