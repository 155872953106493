/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  buildCreateApi,
  coreModule,
  reactHooksModule,
} from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'
import { baseQueryWithReAuth } from '../tools'
import { TMusic, TPlaylist } from '@/types'
import { ParameterNormalize, TParams, objectToQueryString } from '@/tools'

type TResults = {
  items: Array<TPlaylist>
  total: number
  page: number
}

type AResults = {
  results: Array<TPlaylist>
  count: number
}

type MResults = {
  results: Array<TMusic>
  count: number
}

export type TResult = TPlaylist

type TResponse = {
  id: number
  body: {
    song_id: any
  }
}

const createApi = buildCreateApi(
  coreModule(),
  reactHooksModule({ unstable__sideEffectsInRender: true }),
)

export const playlistApi = createApi({
  reducerPath: 'api/playlists',
  baseQuery: baseQueryWithReAuth,
  extractRehydrationInfo(action: any, { reducerPath }: any) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
  tagTypes: ['Playlist', 'Songs'],
  endpoints: (builder) => ({
    getPlaylist: builder.query<TResult, number>({
      query: (id) => `/api/playlists/${id}/`,
      providesTags: (result, error, id) => [{ type: 'Playlist', id }],
    }),
    getPlaylistWithQuery: builder.query<TResults, string>({
      query: (query) => `/playlists${query}`,
      providesTags: ['Playlist'],
    }),

    getMyPlaylists: builder.query<
      AResults,
      { limit?: number; offset?: number }
    >({
      query: (params) => ({ url: '/api/playlists/my/', method: 'GET', params }),
      providesTags: ['Playlist'],
    }),

    getPlayListSongs: builder.query<MResults, { id: any; params?: any }>({
      query: ({ id, params }) => ({
        url: `/api/playlists/${id}/songs/`,
        method: 'GET',
        params,
      }),
      providesTags: ['Playlist', 'Songs'],
    }),

    addPlaylist: builder.mutation<TResult, any>({
      query: (body) => ({
        url: '/api/playlists/my/',
        method: 'POST',
        body,
      }),
      transformResponse: (response): any => response,
      invalidatesTags: ['Playlist'],
    }),

    editPlaylist: builder.mutation<TResults, { id: number; body: FormData }>({
      query: ({ id, body }) => ({
        url: `/playlists/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Playlist'],
    }),
    copyPlaylist: builder.mutation<number, number>({
      query: (id) => ({
        url: `/playlists/${id}/copy`,
        method: 'POST',
        body: {},
      }),
      transformResponse: (response, meta, arg): any => {
        return arg
      },
      invalidatesTags: ['Playlist'],
    }),

    addSongToPlaylist: builder.mutation<TResponse, TResponse>({
      query: ({ id, body }) => ({
        url: `/api/playlists/${id}/song/`,
        method: 'POST',
        body,
      }),
      transformResponse: (response, meta, arg): any => {
        return arg
      },
    }),

    removeSongFromPlaylist: builder.mutation<TResponse, TResponse>({
      query: ({ id, body }) => ({
        url: `/api/playlists/${id}/songs/${body.song_id}/`,
        method: 'DELETE',
      }),
      transformResponse: (response, meta, arg): any => {
        return arg
      },
      invalidatesTags: ['Playlist'],
    }),

    deletePlaylist: builder.mutation<number, number>({
      query: (id) => ({
        url: `/playlists/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (response, meta, arg): any => {
        return arg
      },
      invalidatesTags: ['Playlist'],
    }),
  }),
})

export const {
  useAddPlaylistMutation,
  useCopyPlaylistMutation,
  useEditPlaylistMutation,
  useAddSongToPlaylistMutation,
  useDeletePlaylistMutation,
  useGetMyPlaylistsQuery,
  useGetPlaylistQuery,
  useGetPlaylistWithQueryQuery,
  useLazyGetMyPlaylistsQuery,
  useLazyGetPlaylistQuery,
  useRemoveSongFromPlaylistMutation,
  useGetPlayListSongsQuery,
  util: {
    getRunningQueriesThunk: getRunningPlaylistsQueriesThunk,
    resetApiState: resetPlaylistApiState,
  },
} = playlistApi

export const {
  addPlaylist,
  copyPlaylist,
  addSongToPlaylist,
  removeSongFromPlaylist,
  deletePlaylist,
  editPlaylist,
  getMyPlaylists,
  getPlaylist,
  getPlayListSongs,
} = playlistApi.endpoints
