/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  buildCreateApi,
  coreModule,
  reactHooksModule,
} from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'
import { baseAuthQueryWithReAuth } from '../tools'
import { TProfile } from '@/types'

type TResult = {
  items: TProfile
  total: number
}

type AResult = TProfile

const createApi = buildCreateApi(
  coreModule(),
  reactHooksModule({ unstable__sideEffectsInRender: true }),
)

export const profileApi = createApi({
  reducerPath: 'api/profile',
  baseQuery: baseAuthQueryWithReAuth,
  extractRehydrationInfo(action: any, { reducerPath }: any) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
  tagTypes: ['Profile'],
  endpoints: (builder) => ({
    getProfile: builder.query<TProfile, void>({
      query: () => '/api/profile/',
      providesTags: ['Profile'],
    }),
    updateProfile: builder.mutation<TResult, FormData>({
      query: (body) => {
        return {
          url: '/profile',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['Profile'],
    }),
    updateMobile: builder.mutation<TResult, FormData>({
      query: (body) => {
        return {
          url: '/change_mobile',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['Profile'],
    }),
  }),
})

export const {
  useGetProfileQuery,
  useLazyGetProfileQuery,
  useUpdateProfileMutation,
  useUpdateMobileMutation,
  util: { getRunningQueriesThunk: getRunningProfileQueriesThunk },
} = profileApi

export const { getProfile, updateProfile } = profileApi.endpoints
