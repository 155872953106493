import * as React from 'react'
import type { SVGProps } from 'react'
const SvgOptionsCircleFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Options_circle_-_filled_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Options_circle_-_filled_svg__a)'>
      <path
        fill={props.color}
        d='M7 13.5c.417 0 .77-.146 1.063-.438.291-.291.437-.645.437-1.062 0-.417-.146-.77-.438-1.063A1.446 1.446 0 0 0 7 10.5c-.417 0-.77.146-1.063.438A1.447 1.447 0 0 0 5.5 12c0 .417.146.77.438 1.063.291.291.645.437 1.062.437Zm5 0c.417 0 .77-.146 1.063-.438.291-.291.437-.645.437-1.062 0-.417-.146-.77-.438-1.063A1.446 1.446 0 0 0 12 10.5c-.417 0-.77.146-1.063.438A1.446 1.446 0 0 0 10.5 12c0 .417.146.77.438 1.063.291.291.645.437 1.062.437Zm5 0c.417 0 .77-.146 1.063-.438.291-.291.437-.645.437-1.062 0-.417-.146-.77-.438-1.063A1.446 1.446 0 0 0 17 10.5c-.417 0-.77.146-1.063.438A1.446 1.446 0 0 0 15.5 12c0 .417.146.77.438 1.063.291.291.645.437 1.062.437ZM12 22a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0 1 12 22Z'
      />
    </g>
  </svg>
)
export default SvgOptionsCircleFilled
