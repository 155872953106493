import { MutableRefObject, useEffect, useRef } from 'react'

type TUseInfiniteScrollInput = {
  callback: () => void
}

type TUseInfiniteScrollOutput = {
  observerTarget: MutableRefObject<null>
}

export const useInfiniteScroll = (
  args: TUseInfiniteScrollInput,
): TUseInfiniteScrollOutput => {
  const { callback } = args
  const observerTarget = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          callback()
        }
      },
      { threshold: 1 },
    )

    if (observerTarget.current) {
      observer.observe(observerTarget.current)
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current)
      }
    }
  }, [observerTarget, callback])

  return { observerTarget }
}
