import { twMerge } from 'tailwind-merge'
import { UI_Button } from '@/ui'
import { Element, TMusic } from '@/types'
import { usePlayerActions } from '@/hooks'
import { isAdPlayingSelector, selectedSong, useAppSelector } from '@/store'

type TSkipAdProps = {
  isIcon: boolean
  variant?: 'primary' | 'secondary' | 'tertiary'
  className?: string
}

export const SkipAd = (props: TSkipAdProps): Element => {
  const { isIcon = true, variant = 'primary', className } = props
  const selectedTrack = useAppSelector(selectedSong)
  const isAdPlaying = useAppSelector(isAdPlayingSelector)
  const { handleNextSong } = usePlayerActions({
    item: selectedTrack as TMusic,
  })
  const handleSkip = () => {
    if (isAdPlaying) {
      handleNextSong()
    }
  }

  return (
    <UI_Button
      onClick={handleSkip}
      variant='secondary'
      className={twMerge(
        '!border-content-content-inactive !border-solid !pe-4',
        variant === 'secondary'
          ? '!bg-cards-glass-primary !border-solid !border-content-content-inactive'
          : '!bg-cards-glass-secondary2 !border-solid !border-border-border-primary !backdrop-blur-xl',
        className,
      )}
      endAdornment={{
        icon: 'Next',
        className: twMerge(!isIcon && 'hidden'),
        fill: 'fill-content-content-tertiary',
      }}
      typography={{
        variant: 'enCaptionBold',
        className: 'text-content-content-tertiary -mt-1',
      }}
    >
      Skip Ad
    </UI_Button>
  )
}
