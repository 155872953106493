import { ElementType, HTMLProps, ReactElement, ReactNode, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'
import { useMobile } from '@/hooks'

const alignClasses = {
  center: 'text-center',
  start: 'text-start',
  end: 'text-end',
  left: 'text-left',
  right: 'text-right',
  justify: 'text-justify',
} as const

const baseClass = 'font-peyda'

const commonClasses = {
  enBodyRegularText1: `${baseClass} text-lg leading-[28px]`,
  enBodyRegularText2: `${baseClass} text-base leading-[24px]`,
  enBodyRegularText3: `${baseClass} text-sm leading-[20px]`,
  enBodyBoldText1: `${baseClass} font-bold text-lg leading-[28px]`,
  enBodyBoldText2: `${baseClass} font-bold text-base leading-[24px]`,
  enBodyBoldText3: `${baseClass} font-bold text-sm leading-[20px]`,
  enCaptionRegular: `${baseClass} text-xs leading-[16px]`,
  enCaptionBold: `${baseClass} font-bold text-xs leading-[16px]`,
  enLabelBoldLabel1: `${baseClass} font-bold text-sm`,
  enLabelBoldLabel2: `${baseClass} font-bold text-base`,
  enLabelBoldLabel3: `${baseClass} font-bold text-lg`,
  enLabelRegularLabel1: `${baseClass} text-sm`,
  enLabelRegularLabel2: `${baseClass} text-base`,
  enLabelRegularLabel3: `${baseClass} text-lg`,
  enLabelTag: `${baseClass} shrink-0 text-3xs leading-normal`,
  enSubtitle1: `${baseClass} text-base leading-[10px] font-bold`,
  enLabelRegularLabel4: `${baseClass} text-3xs leading-normal`,
  enLabelCaption: `${baseClass} text-xs leading-[14px]`,
}

export const variantClasses = {
  enDesktopDisplay1: `${baseClass} text-8xl font-bold uppercase`,
  enDesktopDisplay2: `${baseClass} text-6xl font-bold uppercase leading-[64px]`,
  enDesktopDisplay3: `${baseClass} text-6xl font-bold uppercase leading-[64px]`,
  enDesktopTitle1: `${baseClass} text-5xl font-bold leading-[52px]`,
  enDesktopTitle2: `${baseClass} text-21xl font-bold leading-[44px]`,
  enDesktopTitle3: `${baseClass} text-[32px] font-bold leading-[40px]`,
  enDesktopTitle4: `${baseClass} text-3xl font-bold leading-[32px]`,
  enDesktopTitle5: `${baseClass} text-xl font-bold leading-[24px]`,
  enDesktopTitle6: `${baseClass} text-xl font-bold leading-[24px]`,
  subtitle1: `${baseClass} text-[28px] leading-[24px]`,
  subtitle2: `${baseClass} text-[24px] leading-[24px]`,
  subtitle3: `${baseClass} text-[20px] leading-[24px]`,
  ...commonClasses,
} as const

const variantMobileClasses = {
  enDesktopDisplay1: `${baseClass} text-[42px] font-extrabold leading-[44px] uppercase`,
  enDesktopDisplay2: `${baseClass} text-[40px] font-bold leading-[44px]`,
  enDesktopDisplay3: `${baseClass} text-4xl font-bold leading-[40px]`,
  enDesktopTitle1: `${baseClass} text-3xl font-bold leading-[32px]`,
  enDesktopTitle2: `${baseClass} text-2xl font-bold leading-[28px]`,
  enDesktopTitle3: `${baseClass} text-xl font-bold leading-[24px]`,
  enDesktopTitle4: `${baseClass} text-base leading-[32px]`,
  enDesktopTitle5: `${baseClass} text-base font-bold leading-[24px]`,
  enDesktopTitle6: `${baseClass} text-xl font-bold leading-[24px]`,
  subtitle1: `${baseClass} text-base leading-[24px]`,
  subtitle2: `${baseClass} text-[15px] leading-[20px]`,
  subtitle3: `${baseClass} text-[14px] leading-[22px]`,
  ...commonClasses,
} as const

export type TVariantClasses = keyof typeof variantClasses

export interface IUI_TypographyProps extends HTMLProps<HTMLElement> {
  variant?: TVariantClasses
  component?: ElementType
  align?: keyof typeof alignClasses
  className?: string
  children?: ReactNode
}

export const UI_Typography = (props: IUI_TypographyProps): ReactElement => {
  const {
    variant = 'enBodyRegularText1',
    component: Component = 'span',
    align = 'start',
    className,
    children,
    ...otherProps
  } = props

  const isMobile = useMobile()
  const classes = useMemo(
    () => (isMobile ? variantMobileClasses : variantClasses),
    [isMobile],
  )

  return (
    <Component
      className={twMerge(
        'relative top-[2.5px]',
        classes[variant],
        alignClasses[align],
        className,
      )}
      {...otherProps}
    >
      {children}
    </Component>
  )
}
