import { wrapper } from '@/store'

export const ssrConfig = wrapper?.getServerSideProps(
  () =>
    async (
      context,
    ): Promise<{
      props: {
        uaString: string
      }
    }> => {
      const uaString = String(context?.req?.headers['user-agent'])
      return {
        props: {
          uaString,
        },
      }
    },
)
