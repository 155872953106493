import ReactDOM from 'react-dom'
import { m } from 'framer-motion'
import { twMerge } from 'tailwind-merge'
import { ReactNode, useEffect } from 'react'
import { UI_IconSvg, UI_Typography } from '@/ui'
import { Element } from '@/types'
import { useLocale, useMobile } from '@/hooks'

type TUI_ModalProps = {
  children: Element
  isOpen: boolean
  title?: string | ReactNode
  titleOptions?: {
    alignment?: 'left' | 'center' | 'right'
  }
  onClose: () => void
  onClickBack?: () => void
  styleLess?: boolean
  className?: string
}

export const UI_Modal = (props: TUI_ModalProps): Element | null => {
  const {
    isOpen,
    title,
    children,
    titleOptions,
    onClose,
    onClickBack,
    styleLess,
    className,
  } = props
  const isMobile = useMobile()
  const { isPersian } = useLocale()
  const el = document.getElementById('react-portal') as HTMLElement

  useEffect(() => {
    const body = document.getElementById('page-body')
    if (body) {
      body.style.overflowY = 'hidden'
    }
    return () => {
      if (body) {
        body.style.overflowY = 'auto'
      }
    }
  }, [isOpen])

  if (!isOpen) return null
  const render = (
    <div
      className={twMerge(
        'w-full h-[100dvh] fixed overflow-hidden inset-0 z-[999]',
        'flex 2md:items-center items-end justify-center',
        'bg-cards-glass-secondary backdrop-blur-md',
      )}
    >
      <div className='absolute inset-0 z-[-1]' onClick={onClose} />
      <m.div
        initial={{ y: 100 }}
        animate={{ y: 0 }}
        className={twMerge(
          'w-fit 2md:min-w-[800px] min-w-full 2md:p-8 p-4 2md:max-h-full max-h-[70dvh]',
          'flex flex-col items-center justify-start gap-8',
          !styleLess &&
            'border-content-tertiary border-[1.5px] 2md:border-solid border-none 2md:rounded-2xl rounded-t-2xl',
          !styleLess &&
            '2md:bg-cards-glass-secondary bg-cards-glass-secondary backdrop-blur-2xl',
          className,
        )}
      >
        <div className='self-stretch flex items-center justify-between gap-4'>
          {typeof onClickBack === 'function' && (
            <UI_IconSvg
              component='ArrowBackBig'
              className='cursor-pointer rtl:rotate-180'
              onClick={onClickBack}
            />
          )}

          <UI_Typography
            variant='enDesktopTitle3'
            className='flex-1'
            align={titleOptions?.alignment}
          >
            {title}
          </UI_Typography>

          {!isMobile && (
            <UI_IconSvg
              component='Close'
              className={twMerge(
                'cursor-pointer absolute top-4',
                isPersian ? 'left-4' : 'right-4',
              )}
              onClick={onClose}
            />
          )}
        </div>
        <div className='self-stretch'>{children}</div>
      </m.div>
    </div>
  )
  return ReactDOM.createPortal(render, el)
}
