import { LibraryData, TLibraryData } from './Library'
import { UI_Button } from '@/ui'
import { Element } from '@/types'
import { useAppContext } from '@/store'
import { useTranslate } from '@/hooks'

type TLibraryItemProps = {
  item: TLibraryData
}

export const LibraryItem = (props: TLibraryItemProps): Element => {
  const { item } = props
  const { translate } = useTranslate()
  const { globalState, changeGlobalState } = useAppContext()
  const activeItem = globalState?.searchInHeader?.selectedLibrary

  const titleMapper: Record<TLibraryData, string> = {
    [LibraryData.All]: translate('global.all'),
    [LibraryData.Playlist]: translate('global.playlists'),
    [LibraryData.Artist]: translate('global.artists'),
    [LibraryData.Track]: translate('global.tracks'),
    [LibraryData.Album]: translate('global.albums'),
  }

  const handleSelect = () => {
    if (activeItem === item) {
      changeGlobalState?.({
        key: 'searchInHeader',
        value: {
          ...globalState?.searchInHeader,
          selectedLibrary: 'all',
        },
      })
    } else {
      changeGlobalState?.({
        key: 'searchInHeader',
        value: {
          ...globalState?.searchInHeader,
          selectedLibrary: item,
        },
      })
    }
  }

  return (
    <UI_Button
      onClick={handleSelect}
      className='!text-base !rounded-full capitalize !w-fit !h-8 !py-2 !px-4 !border-[1px] !border-solid !border-border-border-primary'
      variant={activeItem === item ? 'primary' : 'secondary'}
    >
      {titleMapper[item]}
    </UI_Button>
  )
}
