import { m } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Card } from './Card'
import { OtherPlayerActions } from './OtherPlayerActions'
import { SeekBar } from './SeekBar'
import { CardAd, Row } from '@/components'
import { UI_Button } from '@/ui'
import { Element } from '@/types'
import {
  isAdPlayingSelector,
  isPlayingSelector,
  selectedSong,
  setIsPlaying,
  setPlayerLoadingId,
  useAppContext,
  useAppDispatch,
  useAppSelector,
} from '@/store'
import { Lyrics } from '@/pages/global'
import {
  useAdvertisement,
  usePlayerActions,
  useToggleMouseover,
  useTranslate,
} from '@/hooks'
import { displaySkipAdTimeSecond } from '@/variables'

export const Player = (): Element => {
  const playerRef = useRef<HTMLVideoElement | null>(null)
  const { globalState, changeGlobalState } = useAppContext()
  const { translate } = useTranslate()
  const selectedTrack = useAppSelector(selectedSong)
  const { id, image } = selectedTrack ?? {}
  const { adItem } = useAdvertisement()
  const isPlayerFullScreen = Boolean(globalState?.isPlayerFullScreen)
  const [src, setSrc] = useState('')
  const { isMouseOver } = useToggleMouseover(2000)
  const isMusicVideo = selectedTrack?.medias?.video
  const [displaySkipAd, setDisplaySkipAd] = useState(false)
  const isPlaying = useAppSelector(isPlayingSelector)
  const isAdPlaying = useAppSelector(isAdPlayingSelector)
  const transitionClass = 'transition-all duration-300 ease-in-out'
  const { handleFullScreen } = usePlayerActions({})
  const intervalRef = useRef<NodeJS.Timeout>()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isAdPlaying) {
      intervalRef.current = setInterval(() => {
        const _currentTime = playerRef.current?.currentTime
        if (Number(_currentTime) > displaySkipAdTimeSecond) {
          setDisplaySkipAd(Number(_currentTime) >= displaySkipAdTimeSecond)
          clearInterval(intervalRef.current)
        }
      }, 1000)
    }
    return () => clearInterval(intervalRef.current)
  }, [isAdPlaying])

  useEffect(() => {
    if (image) {
      setSrc(image)
    }
  }, [image])

  useEffect(() => {
    return () => {
      dispatch(setIsPlaying(false))
      changeGlobalState?.({ key: 'isPlayerFullScreen', value: false })
      dispatch(setPlayerLoadingId(0))
    }
  }, [])

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.5,
      }}
      className={twMerge(isPlayerFullScreen && 'fixed inset-0', 'ltr')}
    >
      <div
        className={twMerge(
          'select-none w-full h-fill bg-cover',
          'self-stretch flex flex-col items-start',
          'overflow-hidden relative',
          'rounded-3xl bg-cards-glass-secondary backdrop-blur-2xl text-sm',
          isPlayerFullScreen && isMusicVideo && 'flex-col-reverse',
        )}
      >
        {!isMusicVideo && isPlayerFullScreen && <Lyrics isAd={isAdPlaying} />}
        <div
          className={twMerge(
            'w-full self-stretch flex flex-row items-center justify-between py-3 px-6 z-[2]',
            !isPlayerFullScreen && 'bg-cards-glass-secondary backdrop-blur-2xl',
            isPlayerFullScreen && 'flex-col',
          )}
        >
          {!isPlayerFullScreen &&
            (isAdPlaying ? (
              <CardAd
                displaySkipAdButton={displaySkipAd}
                id={1}
                image_path={`${process.env.NEXT_CONTENT_URL}${adItem?.cover_path}`}
                link={adItem?.extra?.link}
                subTitle=''
                title={adItem?.name}
              />
            ) : (
              <Card isMouseOver={isMouseOver} />
            ))}

          <SeekBar
            ref={playerRef}
            isMouseOver={isMouseOver}
            isAd={isAdPlaying}
          />
          <div
            className={twMerge(
              isPlayerFullScreen
                ? 'w-full self-stretch flex flex-row items-center justify-between -mt-6 relative -z-1 h-12'
                : 'flex-1',
            )}
          >
            {isPlayerFullScreen &&
              (isAdPlaying ? (
                <Row
                  className={twMerge(
                    'gap-12 items-center',
                    isMusicVideo ? 'w-fit' : 'justify-end',
                    transitionClass,
                    'absolute -mt-56',
                    isMusicVideo && !isMouseOver && isPlaying && 'opacity-0',
                  )}
                >
                  <CardAd
                    displaySkipAdButton={displaySkipAd}
                    isOnlySkipAdButton={!isMusicVideo}
                    isImage={false}
                    id={1}
                    image_path={`${process.env.NEXT_CONTENT_URL}${adItem?.cover_path}`}
                    link={adItem?.extra?.link}
                    subTitle=''
                    title={adItem?.name}
                    variant='secondary'
                  />
                  {isMusicVideo && (
                    <UI_Button
                      href={adItem?.extra?.link}
                      onClick={handleFullScreen}
                      endAdornment={{
                        icon: 'ArrowRight',
                        fill: 'fill-cards-fill-primary',
                        className: '-mr-1',
                      }}
                      typography={{
                        variant: 'enBodyBoldText3',
                        className: 'text-cards-fill-primary -mt-0.5',
                      }}
                      className='!bg-content-content-secondary !rounded-md ps-4 pe-3'
                      variant='secondary'
                    >
                      {translate('global.see-more')}
                    </UI_Button>
                  )}
                </Row>
              ) : (
                <Card isMouseOver={isMouseOver} />
              ))}

            <OtherPlayerActions ref={playerRef} isMouseOver={isMouseOver} />
          </div>
        </div>

        {!id && !isAdPlaying && (
          <div className='inset-0 absolute bg-cards-glass-secondary z-[2]' />
        )}
      </div>
      {isPlayerFullScreen && (
        <div
          style={{ backgroundImage: `url(${src})` }}
          className='inset-0 absolute bg-center -z-[2] bg-cover transition-all'
        />
      )}
    </m.div>
  )
}
