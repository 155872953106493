import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSkipForward = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={props.color}
      d='M3.516 17.293a10.002 10.002 0 0 0 3.038 3.094 1 1 0 0 0 1.089-1.678A8.001 8.001 0 1 1 18.141 6.873h-2.24a.883.883 0 0 0-.426.216.908.908 0 0 0-.293.67.9.9 0 0 0 .75.894h4.533c.028 0 .055 0 .081-.002l.053-.004h.749v-.725a1.691 1.691 0 0 0 .007-.16V3.187a.904.904 0 0 0-.89-.71.919.919 0 0 0-.747.382.876.876 0 0 0-.143.325v2.289a9.995 9.995 0 0 0-7.007-3.456A10.004 10.004 0 0 0 2.496 8.89a10.001 10.001 0 0 0 1.02 8.404Z'
    />
    <path
      fill={props.color}
      d='M12.275 15.138v6.524h1.554v-9.338h-1.554c0 .215-.066.462-.196.742-.055.121-.123.24-.205.354a2.751 2.751 0 0 1-.37.416 3.18 3.18 0 0 1-.965.616c-.225.098-.471.168-.74.209-.19.029-.392.043-.604.043v1.442c1.297 0 2.323-.336 3.08-1.008ZM15.559 12.45l-.658 5.068h1.456a2.16 2.16 0 0 1 .742-.588c.289-.15.56-.224.812-.224.223 0 .443.047.658.14a1.833 1.833 0 0 1 .573.392c.178.168.318.369.42.602.103.224.154.476.154.756 0 .27-.046.523-.14.756a1.924 1.924 0 0 1-.392.616c-.158.168-.35.303-.574.406-.214.094-.447.14-.7.14-.345 0-.672-.13-.98-.392-.298-.27-.522-.653-.671-1.148H14.62c.214.943.615 1.647 1.204 2.114a3.27 3.27 0 0 0 2.072.7c.588 0 1.096-.088 1.526-.266.28-.122.53-.266.746-.432.115-.088.22-.182.317-.282.28-.299.49-.64.63-1.022s.21-.78.21-1.19c0-.439-.083-.85-.251-1.232a3.046 3.046 0 0 0-.672-1.022 2.97 2.97 0 0 0-.593-.473 3.29 3.29 0 0 0-.415-.213 3.1 3.1 0 0 0-1.26-.252c-.14 0-.35.014-.63.042a3.21 3.21 0 0 0-.883.238l.07-.546c.066-.457.127-.928.183-1.414h3.948V12.45h-5.264Z'
    />
  </svg>
)
export default SvgSkipForward
