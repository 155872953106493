import Link from 'next/link'
import { twMerge } from 'tailwind-merge'
import { Logo } from '../sidebar/Logo'
import { UI_IconSvg, UI_Typography } from '@/ui'
import { Element } from '@/types'
import { useMobile, useTranslate } from '@/hooks'

type TFooterProps = {
  className?: string
}

export const Footer = (props: TFooterProps): Element | null => {
  const { className } = props
  const isMobile = useMobile()
  const { translate } = useTranslate()

  if (isMobile) {
    return null
  }

  const usefulLinks = [
    { title: translate('footer.support'), link: '/' },
    { title: translate('footer.web-player'), link: '/' },
    { title: translate('footer.free-mobile-app'), link: '/' },
  ]

  const companyLinks = [
    {
      title: translate('footer.about'),
      link: '/about',
    },
    {
      title: translate('footer.contact'),
      link: '/contact-us',
    },
    {
      title: translate('footer.jobs'),
      link: '/',
    },
    {
      title: translate('footer.for-the-record'),
      link: '/',
    },
  ]

  const communities = [
    { title: translate('footer.for-artists'), link: '/' },
    { title: translate('footer.developers'), link: '/' },
    { title: translate('footer.advertising'), link: '/' },
    { title: translate('footer.investors'), link: '/' },
    { title: translate('footer.vendors'), link: '/' },
  ]

  const links = [
    { title: translate('footer.legal'), link: '/' },
    { title: translate('footer.privacy-center'), link: '/' },
    { title: translate('footer.privacy-policy'), link: '/' },
    { title: translate('footer.cookies'), link: '/' },
    { title: translate('footer.about-ads'), link: '/' },
  ]

  return (
    <footer className={twMerge('w-full', className)}>
      <div className='self-stretch gap-12 rounded-radius-400 bg-cards-glass-secondary w-full flex flex-col items-start justify-start py-8 px-[88px] text-xs'>
        <div className='w-full flex flex-row items-start justify-between'>
          <div className='flex flex-col items-start justify-start gap-6 text-xl text-gray-50'>
            <Logo height={64} width={64} />

            <div className='flex flex-row items-start justify-start gap-3'>
              <div className='rounded-curve w-8 h-8 flex flex-row items-center justify-center p-1.5 box-border'>
                <UI_IconSvg component='Linkedin' />
              </div>
              <div className='rounded-curve w-8 h-8 flex flex-row items-center justify-center p-1.5 box-border'>
                <UI_IconSvg component='Twitter' />
              </div>
              <div className='rounded-curve w-8 h-8 flex flex-row items-center justify-center p-1.5 box-border'>
                <UI_IconSvg component='Instagram' />
              </div>
            </div>
          </div>
          <div className='flex flex-col items-start justify-start gap-3'>
            <div className='flex flex-row items-center justify-center'>
              <UI_Typography variant='enCaptionRegular' component='div'>
                {translate('footer.communities')}
              </UI_Typography>
            </div>
            <div className='flex flex-col items-start justify-start gap-2 text-content-content-primary'>
              {communities?.map((item) => (
                <Link
                  key={item.title}
                  href={item.link}
                  className='flex flex-row items-center justify-start'
                >
                  <UI_Typography variant='enCaptionBold'>
                    {item.title}
                  </UI_Typography>
                </Link>
              ))}
            </div>
          </div>
          <div className='flex flex-col items-start justify-start gap-3'>
            <div className='flex flex-row items-center justify-center'>
              <UI_Typography variant='enCaptionRegular' component='div'>
                {translate('footer.useful-links')}
              </UI_Typography>
            </div>
            <div className='flex flex-col items-start justify-start gap-2 text-content-content-primary'>
              {usefulLinks?.map((item) => (
                <Link
                  key={item.title}
                  href={item.link}
                  className='flex flex-row items-center justify-start'
                >
                  <UI_Typography variant='enCaptionBold'>
                    {item.title}
                  </UI_Typography>
                </Link>
              ))}
            </div>
          </div>
          <div className='flex flex-col items-start justify-start gap-3'>
            <div className='flex flex-row items-center justify-center'>
              <UI_Typography variant='enCaptionRegular' component='div'>
                {translate('footer.company')}
              </UI_Typography>
            </div>
            <div className='flex flex-col items-start justify-start gap-2 text-content-content-primary'>
              {companyLinks.map((item) => (
                <Link
                  key={item.title}
                  href={item.link}
                  className='flex flex-row items-center justify-start'
                >
                  <UI_Typography variant='enCaptionBold'>
                    {item.title}
                  </UI_Typography>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className='w-full flex flex-row items-start justify-start gap-4 text-3xs text-content-content-tertiary'>
          {links?.map((item) => (
            <div
              key={item.title}
              className='flex flex-row items-center justify-center'
            >
              <Link
                href='/'
                className='flex flex-row items-center justify-start'
              >
                <UI_Typography variant='enLabelRegularLabel4'>
                  {item.title}
                </UI_Typography>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </footer>
  )
}
