import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSkipBack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={props.color}
      d='M21.177 17.41a10.003 10.003 0 0 1-3.038 3.093 1 1 0 0 1-1.09-1.677A8 8 0 1 0 6.551 6.989h2.241a.883.883 0 0 1 .425.216c.18.165.294.405.294.67a.9.9 0 0 1-.751.894H4.227a1.66 1.66 0 0 1-.133-.005h-.75v-.726a1.687 1.687 0 0 1-.006-.16V3.303a.904.904 0 0 1 .89-.71.92.92 0 0 1 .746.382.874.874 0 0 1 .143.325v2.289A9.997 9.997 0 0 1 16.404 2.83a10 10 0 0 1 4.773 14.58ZM4.388 15.234v6.524h1.554V12.42H4.388c0 .215-.065.462-.196.742-.121.27-.313.527-.574.77a3.181 3.181 0 0 1-.966.616c-.382.168-.83.252-1.344.252v1.442c1.297 0 2.324-.336 3.08-1.008Z'
    />
    <path
      fill={props.color}
      d='m7.672 12.546-.658 5.068H8.47a2.16 2.16 0 0 1 .742-.588c.29-.15.56-.224.812-.224.224 0 .444.047.658.14.215.093.406.224.574.392.178.168.317.369.42.602.103.224.154.476.154.756 0 .27-.047.523-.14.756a1.924 1.924 0 0 1-.392.616c-.159.168-.35.303-.574.406-.215.093-.448.14-.7.14-.345 0-.672-.13-.98-.392a2.036 2.036 0 0 1-.38-.469 3 3 0 0 1-.292-.68H6.734c.215.944.616 1.648 1.204 2.115.597.466 1.288.7 2.072.7.588 0 1.097-.089 1.526-.266.43-.187.784-.425 1.064-.714.28-.299.49-.64.63-1.022.14-.383.21-.78.21-1.19 0-.439-.084-.85-.252-1.232a3.045 3.045 0 0 0-.672-1.022 3.068 3.068 0 0 0-1.008-.686 2.896 2.896 0 0 0-.542-.176 3.31 3.31 0 0 0-.964-.067c-.108.007-.236.018-.384.033-.27.028-.565.107-.882.238l.07-.546c.066-.457.126-.929.182-1.414h3.948v-1.274H7.672Z'
    />
  </svg>
)
export default SvgSkipBack
