import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import en from '@/public/language/en.json'
import fa from '@/public/language/fa.json'
import { flattenMessages, INestedMessages } from '@/tools/flattenMessages'

export type Locale = 'en' | 'fa'
const messages: Record<Locale, INestedMessages> = {
  en,
  fa,
}

type TUseLocale = {
  locale: string | undefined
  switchLocale: (locale: Locale) => void
  messages: Record<string, string>
  isPersian: boolean
}

export const useLocale = (): TUseLocale => {
  const router = useRouter()
  const flattenedMessages = useMemo(
    () => flattenMessages(messages[router.locale as keyof typeof messages]),
    [router],
  )

  const switchLocale = useCallback(
    (locale: Locale) => {
      if (locale === router.locale) {
        return
      }
      const path = router.asPath
      router.replace(path, path, { locale })
    },
    [router],
  )
  return {
    locale: router.locale,
    switchLocale,
    messages: flattenedMessages,
    isPersian: router.locale === 'fa',
  }
}
