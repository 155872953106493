import React from 'react'
import { Element } from '@/types'

type DateTimeProps = {
  date: string
}

export const DateTime = (props: DateTimeProps): Element => {
  const { date } = props
  return (
    <bdi>
      {new Date(date).toLocaleDateString('zh-Hans-CN')}
      {' - '}
      {new Date(date).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      })}
    </bdi>
  )
}
