/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  buildCreateApi,
  coreModule,
  reactHooksModule,
} from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'
import { baseQueryWithReAuth } from '../tools'
import { TGenre, TMusic } from '@/types'
import { TParams } from '@/tools'

type TResult = {
  results: Array<TGenre>
  total: number
}

type TResultSongs = {
  results: Array<TMusic>
  count: number
}

type GenrePrams = { id: string | string[]; limit?: number; offset?: number }

const createApi = buildCreateApi(
  coreModule(),
  reactHooksModule({ unstable__sideEffectsInRender: true }),
)

export const categoryApi = createApi({
  reducerPath: 'api/category',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getCategoryList: builder.query<TResult, TParams>({
      query: (params) => ({ url: '/api/categories/', method: 'GET', params }),
    }),

    getCategorySongs: builder.query<TResultSongs, GenrePrams>({
      query: ({ id, limit, offset }) => ({
        url: `/api/categories/${id}/songs/`,
        method: 'GET',
        params: { limit, offset },
      }),
    }),
  }),
})

export const {
  useGetCategorySongsQuery,
  useLazyGetCategorySongsQuery,
  useGetCategoryListQuery,
  useLazyGetCategoryListQuery,
} = categoryApi

export const { getCategoryList, getCategorySongs } = categoryApi.endpoints
