import * as React from 'react'
import type { SVGProps } from 'react'
const SvgHeart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <mask
      id='Heart_svg__a'
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill='#D9D9D9' d='M0 0h24v24H0z' />
    </mask>
    <g mask='url(#Heart_svg__a)'>
      <path
        fill={props.color}
        d='M12 20.325c-.233 0-.47-.042-.713-.125a1.652 1.652 0 0 1-.637-.4l-1.725-1.575a68.355 68.355 0 0 1-4.788-4.813C2.714 11.822 2 10.067 2 8.15c0-1.567.525-2.875 1.575-3.925C4.625 3.175 5.933 2.65 7.5 2.65c.883 0 1.717.188 2.5.563.783.375 1.45.887 2 1.537a5.956 5.956 0 0 1 2-1.537 5.717 5.717 0 0 1 2.5-.563c1.567 0 2.875.525 3.925 1.575C21.475 5.275 22 6.583 22 8.15c0 1.917-.708 3.675-2.125 5.275a59.847 59.847 0 0 1-4.825 4.825l-1.7 1.55a1.652 1.652 0 0 1-.637.4c-.242.083-.48.125-.713.125ZM11.05 6.75c-.483-.683-1-1.204-1.55-1.562-.55-.359-1.217-.538-2-.538-1 0-1.833.333-2.5 1s-1 1.5-1 2.5c0 .867.308 1.788.925 2.762.617.975 1.354 1.921 2.213 2.838a38.412 38.412 0 0 0 2.65 2.575c.908.8 1.645 1.458 2.212 1.975a180.24 180.24 0 0 1 2.213-1.975c.908-.8 1.791-1.658 2.65-2.575a19.092 19.092 0 0 0 2.212-2.838c.617-.974.925-1.895.925-2.762 0-1-.333-1.833-1-2.5s-1.5-1-2.5-1c-.783 0-1.45.18-2 .538-.55.358-1.067.879-1.55 1.562a1.138 1.138 0 0 1-.95.5 1.138 1.138 0 0 1-.95-.5Z'
      />
    </g>
  </svg>
)
export default SvgHeart
